/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gear': {
    width: 37,
    height: 36,
    viewBox: '0 0 37 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.347 19.9c0-.543.188-1.086.188-1.81 0-.723 0-1.266-.188-1.809l3.963-3.075c.377-.362.377-.724.189-1.085l-3.775-6.332c-.189-.181-.566-.362-1.132-.181l-4.719 1.81c-.943-.725-2.076-1.267-3.208-1.81L22.91.905C23.1.362 22.533 0 22.155 0h-7.549c-.377 0-.944.362-.944.724l-.754 4.884c-1.133.362-2.076 1.085-3.209 1.81l-4.53-1.81c-.565-.18-.943 0-1.32.362L.074 12.302c-.189.18 0 .723.377 1.085l3.964 2.894c0 .543-.189 1.086-.189 1.81 0 .723 0 1.266.189 1.808L.45 22.976c-.377.362-.377.723-.188 1.085l3.774 6.332c.19.18.567.362 1.133.18l4.718-1.808c.944.723 2.076 1.266 3.208 1.809l.755 4.703c0 .362.378.724.944.724h7.549c.377 0 .944-.362.944-.724l.755-4.703c1.132-.543 2.264-1.086 3.208-1.81l4.718 1.81c.378.18.944 0 1.133-.362l3.774-6.332c.189-.361.189-.904-.189-1.085l-4.34-2.895zM18.38 24.422c-3.586 0-6.606-2.894-6.606-6.332 0-3.437 3.02-6.331 6.606-6.331 3.585 0 6.605 2.894 6.605 6.331 0 3.438-3.02 6.332-6.605 6.332z" _fill="#1E1E28"/>'
  }
})
