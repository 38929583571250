<template>
  <form @submit.prevent="onSubmit" id="userFilter">
    <socamodal
      :title="$t('user.filters.title')"
      :submitText="$t('user.filters.submit')"
      @close="closeFilters"
      @submit="onSubmit">
      <template v-slot:header>
        <div @click="onReset" class="modalHeaderAction">{{$t('user.filters.reset') }}</div>
      </template>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <div class="formElement">
            <label>{{ $t('user.stations') }}</label>
            <StationSelect :useStore="false" :value="filters.stations"
              @valueChanged="value => {setFilterValue('stations', value)}"
            />
          </div>
          <FormSelect v-model="filters.role" :label="'user.role'" :items="roles" :withAll="true"></FormSelect>
          <div class="formElement">
              <label>{{$t('user.externalUser') }}</label>
              <selectpl v-model="filters.externalUser">
                <option :value="null">{{$t('common.all') }}</option>
                <option :value="true">{{$t('common.true') }}</option>
                <option :value="false">{{$t('common.false') }}</option>
              </selectpl>
          </div>
          <FormSelect v-model="filters.services" :label="'user.services'" :items="services.map(s => s.code)" :withAll="true" :valueAsKey="true"></FormSelect>
          <FormSelect v-model="filters.transporters" :label="'user.transporters'" :items="transporters" :withAll="true" :valueAsKey="false"></FormSelect>
          <FormSelect v-model="filters.status" :label="'user.status'" :items="statuses" :withAll="true"></FormSelect>
        </div>
      </div>
    </socamodal>
  </form>
</template>

<script>
import FormSelect from '@/components/Form/Select'
import StationSelect from '@/components/Common/StationSelect'

export default {
  components: { FormSelect, StationSelect },
  name: 'UserFilters',
  data () {
    return {
      stations: [],
      roles: [],
      transporters: {},
      services: [],
      statuses: [],
      filters: {
        query: null,
        role: null,
        status: null,
        stations: null,
        transporters: null,
        services: null,
        externalUser: null
      },
      stationsSearchResult: []
    }
  },
  computed: {
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      if (this.$store.getters.selectedStations.length > 0) {
        return this.$store.getters.selectedStations
      }
      return []
    }
  },
  created () {
    this.stations = []
    this.roles = this.$sncfRefs.getUserRoles()
    this.services = this.$sncfRefs.getServicesModelsForFilters()
    this.statuses = this.$sncfRefs.getUserStatuses()
    for (const t of this.$sncfRefs.getTransportersModelsForFilters()) {
      this.transporters[t.code] = t.name
    }

    this.filters = Object.assign(this.filters, this.$store.getters['userFilters/filters'])

    // Synchro to context stations
    this._.defaults(this.filters, { stations: this.$store.getters.selectedStations })
    this.$store.watch(
      (state, getters) => getters.selectedStations,
      (newValue, oldValue) => {
        this.filters.stations = this.$store.getters.selectedStations
      }
    )
  },
  methods: {
    setFilterValue (fieldname, value) {
      this.filters[fieldname] = value
    },
    closeFilters (evt) {
      this.$emit('close')
    },
    onSubmit (evt) {
      this.$store.commit('userFilters/setFilters', this.filters)
      this.$emit('filteredUsers')
    },
    onReset (evt) {
      this.filters = {
        query: this.filters.query,
        role: null,
        status: null,
        stations: null,
        transporters: null,
        services: null,
        externalUser: null
      }
    }
  }
}
</script>
