// Gère l'enregistrement du service worker ainsi que l'installation de l'application
import store from '@/store/store.js'

let installPromptEvent = null

export default {
  init () {
    // Enregistrement du service worker (utile aux notifcations et push...)
    navigator.serviceWorker.register(window.document.location.origin + '/serviceWorker.js')
      .catch(e => console.log('serviceWorker.register', e))

    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('The device can install the standalone app')
      // Stash the event so it can be triggered later.
      installPromptEvent = e

      store.state.canInstallPWA = true
    })

    window.addEventListener('appinstalled', (evt) => {
      console.log('The standalone app is installed')
      alert('pwa installed')
    })
  },
  promptInstall () {
    if (installPromptEvent !== null) {
      // Show the prompt
      installPromptEvent.prompt()
      // Wait for the user to respond to the prompt
      installPromptEvent.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt')
            store.state.canInstallPWA = false
          } else {
            console.log('User dismissed the A2HS prompt')
          }
          installPromptEvent = null
        })
    }
  }
}
