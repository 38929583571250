/* Global Scss with Foundation6 CSS */
import './styles/global.scss'

/* eslint-disable import/no-webpack-loader-syntax */
import 'script-loader!jquery/dist/jquery.min'
import 'script-loader!what-input/dist/what-input.min'
import 'script-loader!foundation-sites/dist/js/foundation.min'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/vue-i18n'
import Vue2Filters from 'vue2-filters'
import VueLodash from 'vue-lodash'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSVGIcon from 'vue-svgicon'
import SimpleVueValidation from 'simple-vue-validator'
import VTooltip from 'v-tooltip'
import store from './store/store'
import VueSocketIO from 'vue-socket.io'
import UUID from 'vue-uuid'
import ToggleButton from 'vue-js-toggle-button'
import SelectWithPlaceholder from '@/components/Form/SelectWithPlaceholder'
import sncfRefs from '@/services/sncfRefs'
import permissionService from '@/services/permissionService'
import toastService from '@/services/toast'
import backendConnector from '@/services/backendConnector'
import validationLocalizer from '@/services/validationLocalizer'
import simplebar from '@/components/Common/SimpleBar'
import socamodal from '@/components/Common/SocaModal'
import socalightmodal from '@/components/Common/SocaLightModal'
import lodash from 'lodash'
import quillEditor from '@/components/News/QuillEditor'
import vueDebounce from 'vue-debounce'

let initializeVue = () => {
  validationLocalizer.init()
  Vue.use(new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_BACK_WS_URL,
    options: { transports: ['websocket'] }
  }))

  Vue.use(VueAxios, axios)
  Vue.use(VueSVGIcon, {
    classPrefix: 'svg'
  })
  Vue.use(vueDebounce, {
    defaultTime: '500ms'
  })
  Vue.use(VTooltip)
  Vue.use(ToggleButton)

  Vue.use(VueLodash, { lodash: lodash })
  Vue.use(Vue2Filters)
  Vue.use(SimpleVueValidation)
  Vue.use(UUID)
  Vue.use({
    install () {
      // specific sncf refs
      Vue.sncfRefs = sncfRefs
      Vue.prototype.$sncfRefs = sncfRefs
      // specific permission service
      Vue.perms = permissionService
      Vue.prototype.$perms = permissionService
      // other services
      Vue.toastService = toastService
      Vue.prototype.$toastService = toastService
      Vue.backendConnector = backendConnector
      Vue.prototype.$backendConnector = backendConnector
    }
  })

  // Custom default component
  Vue.component('selectpl', SelectWithPlaceholder)
  Vue.component('simplebar', simplebar)
  Vue.component('socamodal', socamodal)
  Vue.component('socalightmodal', socalightmodal)
  Vue.component('quill-editor', quillEditor)

  Vue.config.productionTip = false

  new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
}

sncfRefs.initialize().then(initializeVue)
