<template>
  <socamodal
      class="modalAgent"
      :title="$t('prestation.affectAgent')"
      @close="closeAffect"
      @submit="submit">
      <span class="subtitle">{{$t('prestation.trajet.agent') }}</span>
      <div class="formElement darkFormElement">
        <input type="text"
          :placeholder="`${$t('prestation.trajet.agentname') }`"
          v-model="agent"
        />
        <small v-if="validation.hasError('agent')" class="error">{{ validation.firstError('agent') }}</small>
      </div>
  </socamodal>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'AffectAgent',
  data () {
    return {
      agent: null
    }
  },
  props: { agentOld: String },
  mounted () {
    this.agent = this.agentOld
  },
  methods: {
    closeAffect () {
      this.$emit('closeAffect')
    },
    submit () {
      this.$validate().then((success) => {
        if (success) {
          this.$emit('affectAgent', this.agent)
        }
      })
    }
  },
  validators: {
    'agent': function (value) {
      return Validator.value(value).maxLength(50)
    }
  }
}
</script>
