<template>
  <div class="oneNotif">
    <div class="oneNotifTime">{{ notifTimeLabel }}</div>
    <div class="oneNotifTitle">
      <span>{{ $t('notification.alertNotif') }}</span>
    </div>
    <router-link :to="{ name: 'stationDetail', params: { codeUic: notification.uic }}" class="alertNotifCard grid-x">
      <div class='alertNotifCardContent'>
        <div class="justUpdatedIndicator"/>
        <div class="alertNotifStatus alertNotifStatusVertical">
          {{ $t('notification.alertNotif') }}
        </div>
        <div class="alertNotifAppendices">
          <div class="alertNotification">
            <svgicon class="alertNotificationIcon" icon="exceptionnal" />
          </div>
        </div>
        <div class="alertNotifCardContentLine alertNotifStationLine">
          <p class="text-wrapper">{{ $t('notification.alertNotifEndDate', {date: formattedDate}) }}</p>
          <p class="alertNotifType">{{ $t('notification.type.alertNotifType') }} {{$t('station.alertTypeList.'+notification.AlertStation.type)}}</p>
          <div class="overlap-2">
            <p class="une-situation">
              {{ $t('notification.alertNotifDescriptionBold', { name: stationName }) }}
            </p>
            <p class="alertNotiftext">{{ $t('notification.alertNotifDescription', { initiator: notification.initiator }) }}</p>
          </div>
        </div>
      </div>
    </router-link>
    <NotificationButtonUnderstood :notification="notification"/>
  </div>
</template>

<script>
import moment from 'moment'
import NotificationButtonUnderstood from '@/components/Notification/NotificationButtonUnderstood'

export default {
  name: 'AlertNotification',
  props: { notification: Object },
  computed: {
    notifTimeLabel: function () {
      return moment(this.notification.createdDate).locale(this.$i18n.locale).calendar()
    },
    formattedDate() {
      return moment(this.notification.AlertStation.endOfAlert).format('DD/MM/YYYY - HH:mm')
    },
    stationName() {
      return this.notification.Gare.name
    },
  },
  components: {
    NotificationButtonUnderstood,
  }
}
</script>
