<template>
  <div></div>
</template>

<script>
export default {
  name: "PrestaPrint",
  created() {
    let prestaHerokuExternalId = this.$route.params.herokuExternalId
    this.$backendConnector
      .printPrestation(prestaHerokuExternalId)
      .then((result) => {
        document.write(result)
        setTimeout(function() {
          window.print()
          setTimeout(window.close, 100)
        }, 1000)
      })
      .catch((err) => console.log(err))
  },
}
</script>
