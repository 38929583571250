<template>
  <div v-if="showPill" id="socaEnvPill" :class="'env-'+env"><strong>{{ envMinStr }}</strong></div>
</template>

<script>
export default {
  name: 'EnvPill',

  data: function () {
    return {
      env: null,
      showPill: false,
      envMinStr: null
    }
  },
  mounted: function () {
    this.env = process.env.NODE_ENV || 'NO_ENV'
    if (this.env !== 'production') {
      this.envMinStr = this.minEnvStr(this.env)
      this.showPill = true
    }
  },
  methods: {
    minEnvStr: (env) => {
      switch (env) {
        case 'devlocal':
          return 'local'
        case 'development':
          return 'dev'
        case 'staging':
          return 'staging'
        case 'preprod':
          return 'preprod'
        case 'formation':
          return 'Formation'
        default:
          return env.substring(0, 6)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#socaEnvPill {
  background: #B5C8CA;
  &.env-devlocal {
    background: #E3D326;
  }
  &.env-development {
    background: #1E7FB2;
  }
  &.env-staging {
    background: #D73C05;
  }
  &.env-preprod {
    background: #9D1EB2;
  }
  &.env-formation {
    background: #CD0037;
  }
}
</style>
