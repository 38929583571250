/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'news': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M15.938 0H5.312A1.062 1.062 0 004.25 1.063V4.25H1.062A1.062 1.062 0 000 5.313v8.5A3.191 3.191 0 003.188 17h10.624A3.191 3.191 0 0017 13.812V1.063A1.06 1.06 0 0015.937 0zM2.124 6.375H4.25v7.438a1.063 1.063 0 01-2.125 0V6.375zm12.75 7.438a1.065 1.065 0 01-1.063 1.062H6.18c.126-.34.192-.7.196-1.063V2.126h8.5v11.688z" _fill="#75757A"/>'
  }
})
