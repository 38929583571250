<template>
  <div class="adminPage">
    <div class="adminHeader">
      <div class="pageTitle">
        <div class="socaContent adminContent">
          <div class="headerTitle">
            {{ $t("featureFlipping.list.title") }}
          </div>
        </div>
      </div>
    </div>
    <div class="socaContent adminContent">
      <FeatureFlippingListItems :features="features" />
    </div>
  </div>
</template>

<script>
import FeatureFlippingListItems from "@/components/FeatureFlipping/ListItems"

export default {
  name: "FeatureFlippingList",
  components: { FeatureFlippingListItems },
  data() {
    return {
      features: null,
    }
  },
  mounted() {
    this.fetchFeatures()
  },
  methods: {
    fetchFeatures() {
      this.features = this.$sncfRefs.getFeaturesFlipped()
    },
  },
}
</script>

<style lang="scss" scoped></style>
