import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocale () {
  const store = window.localStorage.socastore ? JSON.parse(window.localStorage.socastore) : null
  return (store && store.locale) ? store.locale : (process.env.I18N_LOCALE || 'fr')
}

export default new VueI18n({
  locale: loadLocale(),
  fallbackLocale: process.env.I18N_FALLBACK_LOCALE || 'fr',
  messages: {} // locales messages are fetched from the back via sncfRefs
})
