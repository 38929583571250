<template>
  <div class="socaModal socaLightModal modalContainer socaContent">
    <div class="lightModal">
      <div class="modalHeader">
        <div class="headerTitle modalTitle">{{ title }}</div>
        <slot name="header"></slot>
        <div v-if="closable" @click.prevent="$emit('close')" class="modalClose"><svgicon icon="close"/></div>
      </div>
      <div class="modalContent">
        <slot />
      </div>
      <div class="modalFooter">
        <slot name="footer">
          <button @click.prevent="$emit('submit')" class="button-theme">{{ submitText || $t('common.confirm')}}</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocaLightModal',
  props: {
    title: {
      type: String
    },
    submitText: {
      type: String
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
}
</script>
