<template>
  <form @submit.prevent="onSubmit" id="prestationFilter">
    <socamodal :title="$t('prestation.filter')" :submitText="$t('prestation.filters.submit')" @close="closeFilters" @submit="onSubmit">
      <template v-slot:header>
        <div @click="onReset" class="modalHeaderAction">{{ $t("prestation.filters.reset") }}</div>
      </template>

      <div class="filtersBlock">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-6 formElement startPeriodContainer">
            <div class="period">{{ $t("prestation.filters.period.start") }}</div>
            <div class="grid-x">
              <div class="cell small-6 formElement">
                <label>{{ $t("common.date") }}</label>
                <Datepicker v-model="filters.datePrestation.start"></Datepicker>
              </div>
              <div class="cell small-6 formElement">
                <label>{{ $t("common.hour") }}</label>
                <Timepicker v-model="filters.datePrestation.startHour" />
              </div>
            </div>
          </div>
          <div class="cell small-12 medium-6 formElement endPeriodContainer">
            <div class="period">{{ $t("prestation.filters.period.stop") }}</div>
            <div class="grid-x">
              <div class="cell small-6 formElement">
                <label>{{ $t("common.date") }}</label>
                <Datepicker v-model="filters.datePrestation.stop" />
              </div>
              <div class="cell small-6 formElement">
                <label>{{ $t("common.hour") }}</label>
                <Timepicker v-model="filters.datePrestation.stopHour" />
              </div>
            </div>
          </div>
        </div>
        <div class="errorDateInterval" v-if="intervalDateError">
          <small>{{ $t("prestation.filters.dateIntervalError") }}</small>
        </div>
      </div>
      <div class="prestaDirectFiltersBlock">
        <div class="prestaDirectFilters filterMention">
          <div class="filterTitle">{{ $t("prestation.filters.filterByMention") }}</div>
          <div class="filterContent">
            <div
              class="filterValue"
              v-for="mentionTN in mentionsTN"
              :key="mentionTN"
              :value="mentionTN"
              :class="{ filterChecked: filters.mention.includes(mentionTN) }"
              v-on:click="filters.mention.includes(mentionTN) ? filters.mention.splice(filters.mention.indexOf(mentionTN), 1) : filters.mention.push(mentionTN)"
            >
              {{ mentionTN }}
            </div>
          </div>
        </div>
        <div class="prestaDirectFilters filterStatus">
          <div class="filterTitle">{{ $t("prestation.filters.filterByStatus") }}</div>
          <div class="filterContent">
            <div
              class="filterValue"
              v-for="statusTN in statusesTN"
              :key="statusTN"
              :value="statusTN"
              :class="{ filterChecked: filters.status.includes(statusTN) }"
              v-on:click="filters.status.includes(statusTN) ? filters.status.splice(filters.status.indexOf(statusTN), 1) : filters.status.push(statusTN)"
            >
              {{ $t("prestation.status." + statusTN) }}
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <div class="filtersBlock">
            <div class="formElement">
              <label>{{ $t("prestation.filters.reason") }}</label>
              <Multiselect v-model="filters.reasons" :options="reasons" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`" :disabled="blockMotif">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ $t('prestation.reason.'+ props.option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ $t('prestation.reason.'+ props.option) }}</span>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <div class="filtersBlock">
            <div class="formElement">
              <label>{{ $t("prestation.filters.trainNumber") }}</label>
              <input type="text" v-model="filters.trainNumber" :disabled="isOnBoard"/>
            </div>
            <div class="formElement" v-if="!isOnBoard">
              <label>{{ $t("prestation.filters.missionCode") }}</label>
              <input type="text" v-model="filters.missionCode" />
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.stations") }}</label>
              <StationSelect :useStore="false" :value="filters.stations" @valueChanged="selectStations" />
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.transportMode") }}</label>
              <Multiselect v-model="filters.transportMode" :options="transportModes" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ $t("common.transportMode." + $sncfRefs.getTransportModeTechnicalNameByLabel(props.option)) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ $t("common.transportMode." + $sncfRefs.getTransportModeTechnicalNameByLabel(props.option)) }}</span>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-6">
          <div class="filtersBlock">
            <div class="formElement">
              <label>{{ $t("prestation.filters.parcours") }}</label>
              <input type="text" v-model="filters.parcours" />
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.number") }}</label>
              <input type="text" v-model="filters.number" />
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.name") }}</label>
              <input type="text" v-model="filters.name" />
            </div>
            <div class="cell small-12 medium-6">
              <div class="formElement">
                <label>{{ $t("prestation.filters.specAssist") }}</label>
                <Multiselect
                  v-model="filters.specAssist"
                  :options="specAssists"
                  :custom-label="specFullLetters"
                  :multiple="true"
                  :searchable="false"
                  :show-labels="false"
                  :placeholder="`${$t('common.select')}`"
                ></Multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-6">
          <div class="filtersBlock">
            <div class="formElement">
              <label>{{ $t("prestation.filters.guarantee") }}</label>
              <selectpl v-model="filters.garantee">
                <option value="">{{ $t("common.all") }}</option>
                <option v-for="option in typesPresta" :value="option" :key="option">{{ $t("common.garantee." + $sncfRefs.getPrestaTypeTechnicalNameByLabel(option)) }}</option>
              </selectpl>
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.transporter") }}</label>
              <Multiselect v-model="filters.transporter" :options="transportersCodes" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`" :disabled="isOnBoard">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ transporterLabel(props.option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ transporterLabel(props.option) }}</span>
                </template>
              </Multiselect>
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.service") }}</label>
              <Multiselect
                v-model="filters.service"
                :options="services.map((s) => s.code)"
                :multiple="true"
                :searchable="false"
                :show-labels="false"
                :placeholder="`${$t('common.select')}`"
              ></Multiselect>
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.assistResp") }}</label>
              <Multiselect v-model="filters.assistResp" :options="assistResp" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ $t('prestation.'+ props.option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ $t('prestation.'+ props.option) }}</span>
                </template>
              </Multiselect>
            </div>
            <div class="formElement">
              <label>{{ $t("prestation.filters.assistNature") }}</label>
              <Multiselect v-model="filters.assistNature" :options="assistNature" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ $t('prestation.'+ props.option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ $t('prestation.'+ props.option) }}</span>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-6" v-if="this.$perms.isUserAllowed('presta.stations.all') || this.$perms.isUserAllowed('stations.regions') || this.$perms.isUserAllowed('stations.agencies')">
          <div class="filtersBlock">
            <div class="formElement" v-if="this.$perms.isUserAllowed('presta.stations.all') || this.$perms.isUserAllowed('stations.regions')">
              <label>{{ $t("prestation.filters.regions") }}</label>
              <Multiselect v-model="filters.region" :options="regionsCodes" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`">
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <span>{{ $sncfRefs.getRegionLabelByCode(props.option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ $sncfRefs.getRegionLabelByCode(props.option) }}</span>
                </template>
              </Multiselect>
            </div>
            <div class="formElement" v-if="this.$perms.isUserAllowed('presta.stations.all') || this.$perms.isUserAllowed('stations.agencies')">
              <label>{{ $t("prestation.filters.agencies") }}</label>
              <Multiselect v-model="filters.agency" :options="agencies" :multiple="true" :searchable="false" :show-labels="false" :placeholder="`${$t('common.select')}`"></Multiselect>
            </div>
          </div>
        </div>
      </div>
    </socamodal>
  </form>
</template>

<script>
import moment from 'moment'
import Multiselect from 'vue-multiselect/src/Multiselect'

import Datepicker from '@/components/Common/Datepicker'
import Timepicker from '@/components/Common/Timepicker'
import StationSelect from '@/components/Common/StationSelect'
import userService from '@/services/userService'

export default {
  name: 'PrestationFilters',
  data () {
    return {
      specAssists: [],
      transporters: [],
      services: [],
      mentionsTN: [],
      statusesTN: [],
      typesPresta: [],
      transportModes: [],
      regionsCodes: [],
      agencies: [],
      intervalDateError: false,
      reasons: [],
      assistNature: [],
      assistResp: [],
      blockMotif: false,
    }
  },
  created () {
    this.specAssists = this.$sncfRefs.getSpecAssist().map((a) => a.acronym)
    this.transporters = this.$sncfRefs.getTransportersModelsForFilters()
    this.services = this.$sncfRefs.getServicesModelsForFilters(true)
    this.mentionsTN = Object.values(this.$sncfRefs.getMentionsByTechnicalName()).map((m) => m.sigle)
    this.statusesTN = this.$sncfRefs.getPrestaStatusesTechnicalNames()
    this.typesPresta = this.$sncfRefs.getPrestaTypes()
    this.transportModes = this.$sncfRefs.getTransportModes()
    this.regionsCodes = this.$sncfRefs.getRegionsCodes()
    this.agencies = this.$sncfRefs.getAgenciesNames()
    this.reasons = this.$sncfRefs.getPrestaReasonsTechnicalNames()
    this.assistNature = this.$sncfRefs.getNaturesAssistance()
    this.assistResp = this.$sncfRefs.getResponsablesAssistance()

    if (this.filters.datePrestation.start && !(this.filters.datePrestation.start instanceof Date)) {
      this.filters.datePrestation.start = new Date(this.filters.datePrestation.start)
    }
    if (this.filters.datePrestation.stop && !(this.filters.datePrestation.stop instanceof Date)) {
      this.filters.datePrestation.stop = new Date(this.filters.datePrestation.stop)
    }

    this.filters.mention = this._.clone(this.$store.getters['homeFilters/mentions'])
    this.filters.status = this._.clone(this.$store.getters['homeFilters/statuses'])

    // Synchro to context stations
    if (this.filters.stations === null) {
      this.filters.stations = this.$store.getters.selectedStations
    }
    this.$store.watch(
      (state, getters) => getters.selectedStations,
      (newValue, oldValue) => {
        this.filters.stations = this.$store.getters.selectedStations
      }
    )
  },
  mounted() {
    this.$root.$on('filterPrestationReset', () => {
      this.onReset()
    })
  },
  watch: {
    'filters.datePrestation': {
      deep: true,
      handler () {
        this.controlDates()
      }
    },
    'filters.status': {
      deep: false,
      handler () {
        this.blockMotif = !this.filters.status.some(status => [ 'reported', 'notDone' ].includes(status))
      }
    }
  },
  computed: {
    filters() { return this.$store.getters['homeFilters/modalFilters'] },

    transportersCodes () {
      return this.transporters.map((t) => t.code)
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  methods: {
    transporterLabel (code) {
      return this.transporters.filter((t) => t.code === code)[0].name
    },
    closeFilters (evt) {
      this.$emit('close')
    },
    onSubmit (evt) {
      this.controlDates()
      if (!this.intervalDateError) {
        this.$store.commit('homeFilters/modalFilters', this.filters)
        this.$store.commit('homeFilters/modalFiltered', true)
        this.$emit('filteredPrestations')
      } else {
        this.$toastService.showToast(this.$t('prestation.filters.dateIntervalError'), null, 'error', 5000, null)
      }
    },
    onReset (evt) {
      this.isOnBoard ? this.$store.commit('homeFilters/resetFiltersExceptTrainNumber') : this.$store.commit('homeFilters/resetModalFilters')
      this.$store.commit('homeFilters/resetDaySelected')
      this.$store.commit('homeFilters/modalFiltered', this.isOnBoard)
      this.$store.commit('homeFilters/resetDirectFilters')
      this.$emit('filteredPrestations')
    },
    selectStations (value) {
      this.filters.stations = value
    },
    translateMentionTN (mentionTN) {
      return this.$sncfRefs.getMentionsBySigle(mentionTN)
    },
    translateStatusTN (statusTN) {
      return this.$t('prestation.status.' + statusTN, this.$i18n.locale)
    },
    specFullLetters (spec) {
      const specs = this.$sncfRefs.getSpecAssist()
      return specs.find((s) => s.acronym === spec).complete
    },
    controlDates () {
      if (this.filters.datePrestation.start && this.filters.datePrestation.stop) {
        let startHour = moment(this.filters.datePrestation.startHour, 'h:mm')
        let stopHour = moment(this.filters.datePrestation.stopHour, 'h:mm')

        let start = moment(this.filters.datePrestation.start).add({ hours: startHour.hour(), minutes: startHour.minute() })
        let stop = moment(this.filters.datePrestation.stop).add({ hours: stopHour.hour(), minutes: stopHour.minute() })

        this.intervalDateError = start.isAfter(stop)
      } else {
        this.intervalDateError = false
      }
    }
  },
  components: {
    Datepicker,
    Multiselect,
    Timepicker,
    StationSelect
  }
}
</script>
