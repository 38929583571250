/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inaccessible': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#a)"><circle pid="0" cx="10" cy="10" r="10" _fill="#ED2939"/><path pid="1" d="M14.75 8.375v-1.25H6v1.25h3.125v1.709l-2.418 4.231 1.086.62 2.32-4.06h.524l2.32 4.06 1.085-.62-2.417-4.231V8.375h3.125zM10.375 6.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" _fill="#fff"/><circle pid="2" cx="10" cy="10" r="5.5" _fill="#ED2939" _stroke="#fff"/><g filter="url(#b)"><path pid="3" d="M10.42 6.4H9.34v4.374h1.08V6.4zm-1.26 5.742c0 .387.341.684.72.684.395 0 .72-.315.72-.702 0-.387-.334-.684-.72-.684a.713.713 0 00-.72.702z" _fill="#fff"/></g></g><defs><clipPath id="a"><path pid="4" _fill="#fff" d="M0 0h20v20H0z"/></clipPath><filter id="b" x="5.159" y="6.4" width="9.44" height="14.426" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3875_118191"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_3875_118191" result="shape"/></filter></defs>'
  }
})
