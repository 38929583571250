<template>
  <socamodal
    class="modalQualify"
    :title="$t('qualifypresta')"
    @close="closeQualify"
    @submit="submit">
      <div class="grid-x grid-margin-x">
        <div class="formElement status cell small-6 ">
            <label>{{$t('prestation.trajet.statuses') }} <small v-if="validation.hasError('prestation.status')" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <select v-model="prestation.statusTN" :disabled="isDisabled" >
              <option v-for="statusTN in statusesTN" :value="statusTN" :key="statusTN">{{ $t('prestation.status.'+statusTN) }}</option>
            </select>
        </div>
        <div class="formElement reason cell small-6">
            <label>{{$t('prestation.trajet.motifs') }} <span v-if="mandatoryReason">*</span> <small v-if="validation.hasError('prestation.reason')" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <select v-model="prestation.reason" :disabled="blockMotif">
              <option v-for="motif in motifs" :value="motif" :key="motif">{{ $t('prestation.reason.'+motif) }}</option>
            </select>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-6" v-if="!isOnBoard">
            <label><span v-tooltip="{
                content: $t('tooltip.bagage'),
                trigger: 'hover click',
              }">{{ $t('parcours.bagageNumber') }}</span> <span v-if="mandatoryBagage">*</span> <small v-if="validation.hasError('parcours.bagage')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
            <input type="number" min="0" placeholder="bagage" v-model="parcours.bagage" :disabled="!canQualify">
            </label>
        </div>
        <div class="formElement cell small-6" v-if='canSeeManager'>
          <label>{{$t('prestation.station.manager') }} <small v-if="validation.hasError('prestation.manager')" class="error">{{ validation.firstError('prestation.manager') }}</small></label>
            <input type="text"
              :placeholder="`${$t('prestation.station.manager') }`"
              v-model="prestation.manager" />
        </div>
      </div>

      <div class="grid-x grid-margin-x">
          <div class="cell small-6 formElement">
              <label>{{$t('prestation.trajet.traincar') }} <span v-if="mandatoryCarAndPlace">*</span> <small v-if="validation.hasError('prestation.trainCar')" class="error">{{ validation.firstError('prestation.trainCar') }}</small></label>
              <input type="text" :disabled="!canQualify"
              :placeholder="`${$t('prestation.trajet.traincarplaceholder') }`"
              v-model="prestation.trainCar"
              />
          </div>
          <div class="cell small-6 formElement">
              <label>{{$t('prestation.trajet.trainplace') }} <span v-if="mandatoryCarAndPlace">*</span> <small v-if="validation.hasError('prestation.trainPlace')" class="error">{{ validation.firstError('prestation.trainPlace') }}</small></label>
              <input type="text" :disabled="!canQualify"
              :placeholder="`${$t('prestation.trajet.trainplaceplaceholder') }`"
              v-model="prestation.trainPlace"
              />
          </div>
      </div>
      <div class="grid-x grid-margin-x" v-if="!isOnBoard">
        <div class="cell presentationTimeHour formElement small-12">
            <label>{{$t('prestation.station.presentationTime') }} <span v-if="mandatoryPresentationTime">*</span> <small v-if="validation.hasError('prestation.presentationTime')" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <Timepicker v-model="prestation.presentationTime" :disabled="!canQualify" />
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell formElement small-6">
            <label>{{$t('prestation.station.commentary') }}</label>
            <span>{{ prestation.commentary || '-' }}</span>
        </div>
        <div class="cell formElement small-6">
            <label>{{$t('prestation.transporter.commentary') }}</label>
            <span>{{ (
                (prestation.commentaryTransporter || '') +
                (prestation.commentaryTransporter ? '\r\n' : '') +
                (prestation.commentarySecondaryTransporter || '') +
                (prestation.commentarySecondaryTransporter ? '\r\n' : '') +
                (prestation.commentaryPfu ? $t(`prestation.pfuComment.${prestation.commentaryPfu}`) : '') +
                (prestation.commentaryPfu ? '\r\n' : '') +
                (prestation.commentaryPfuOther || '')
              ) || '-'
            }}</span>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell formElement small-6">
            <label>{{$t('prestation.station.commentaryqualif') }} <span v-if="mandatoryCommentaryQualif">*</span> <small v-if="validation.hasError('prestation.commentaryQualif')" class="error"><!--{{ $t('error.field.mandatory') }}--> {{ validation.firstError('prestation.commentaryQualif') }}</small></label>
            <textarea rows="5" cols="33" :disabled="!canQualify"
              :placeholder="`${$t('prestation.station.commentaryplaceholder') }`"
              v-model="prestation.commentaryQualif"></textarea>
        </div>
        <div class="cell formElement small-6">
            <label>{{$t('prestation.transporter.commentaryqualifother') }}</label>
            <span>{{ prestation.commentaryQualifOther || '-' }}</span>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-6" v-if='isDeparture && !isOnBoard'>
            <label>{{$t('prestation.solbord.status') }} <span v-if="mandatoryOnboardingDocStatus">*</span> <small v-if="validation.hasError('prestation.onboardingDocStatus') && !isOnBoard" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <select v-model="prestation.onboardingDocStatus" :disabled="!canCheckSolBord">
              <option value=''>-</option>
              <option v-for="(status, idx) in onboardingDocStatus" :value="idx" :key="idx">{{status}}</option>
            </select>
        </div>
        <div class="formElement cell small-6" v-if="prestation.onboardingDocStatus === 'otherNo'">
            <label>{{$t('prestation.solbord.comment') }} <span v-if="mandatoryOnboardingDocComment">*</span> <small v-if="validation.hasError('prestation.onboardingDocComment')" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <textarea rows="5" cols="33"
              :placeholder="`${$t('prestation.station.commentaryplaceholder') }`"
              v-model="prestation.onboardingDocComment"></textarea>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-6" v-if='warneable'>
            <label>{{$t('prestation.userAwareInfo') }} <small v-if="validation.hasError('prestation.userAwareInfo')" class="error">{{ $t('error.field.mandatory') }}</small></label>
            <Multiselect
              class="large"
              v-model="customerInformationType"
              :options="customerInformationList.map(option => $t('prestation.'+option))"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :placeholder="`${$t('common.select')}`"
              :show-labels="false">
            </Multiselect>
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="values.length">
              <span v-show="!isOpen" v-for="value in values" :key="value">
                {{ value }}
              </span>
            </template>
        </div>
      </div>
  </socamodal>
</template>

<script>
import common from '@/services/common'
import userService from '@/services/userService'
import moment from 'moment'
import SimpleVueValidation from 'simple-vue-validator'

import Multiselect from 'vue-multiselect/src/Multiselect'
import Timepicker from '@/components/Common/Timepicker'
import { SPONTANE } from '@/utils/constantsUtils'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'Qualify',
  data () {
    return {
      prestation: null,
      parcours: null,
      motifs: [],
      statusesTN: [],
      isDisabled: false,
      now: moment(),
      blockMotif: false,
      hasChangedComment: false,
      isDeparture: false,
      canCheckSolBord: false,
      canQualify: false,
      toQualify: false, // determine if you update or qualify the prestation
      customerInformationList: [],
      customerInformationType: []
    }
  },
  props: { prestationData: Object, parcoursData: Object },
  created () {
    this.prestation = this.prestationData
    this.prestation.presentationTime = common.getTimezonedHour(this.prestation.presentationTime)

    // Heroku transforme l'array en string donc on doit caster
    if (typeof this.prestation.customerNotificationInformation === "string") {
      this.prestation.customerNotificationInformation = this.prestation.customerNotificationInformation.match(/\w+/g)
    }

    this.customerInformationList = this.$sncfRefs.getCustomerInformationList()
    if (this.prestation.customerNotificationInformation !== null) {
      this.customerInformationType = this.prestation.customerNotificationInformation.map(item => this.$t('prestation.' + item))
    }

    this.parcours = this.parcoursData

    this.getStatuses()
    this.getMotifs()

    const datePrestation = moment(this.prestation.datePrestation)
    const statusTN = this.prestation.statusTN

    this.canQualify = this.$perms.isUserAllowed('presta.management')
    let canQualifLong = this.$perms.isUserAllowed('presta.qualif.update.delay.long')
    let canQualifShort = this.$perms.isUserAllowed('presta.qualif.update.delay.short')
    let canQualifAllStatus = this.$perms.isUserAllowed('presta.requalif')
    this.isDisabled =
      (!canQualifLong && !canQualifShort) ||
      (!canQualifAllStatus && statusTN !== 'todo') ||
      (canQualifShort && datePrestation.diff(this.now, 'days') > 2) ||
      (canQualifLong && datePrestation.diff(this.now, 'months') > 1)

    this.canCheckSolBord = this.canQualify && this.prestation.transporter !== 'TRANSILIEN'
  },
  methods: {
    closeQualify () {
      this.$emit('closeQualify')
    },
    getStatuses () {
      this.onboardingDocStatus = this.$sncfRefs.getOnboardingDocStatusByTechnicalName()
      this.statusesTN = this.$sncfRefs.getPrestaStatusesTechnicalNames()
      const mentions = this.$sncfRefs.getMentionsByTechnicalName()
      if ((!this.$perms.isUserAllowed('presta.report') && this.isOnBoard) ||
          ![mentions.departure.sigle, mentions.transitDeparture.sigle, mentions.none.sigle].includes(this.prestation.mention)) {
        this.statusesTN.splice(this.statusesTN.indexOf('reported'), 1)
      }
      if (this.prestation.mention === mentions.none.sigle) {
        this.statusesTN.splice(this.statusesTN.indexOf('done'), 1)
      }
      if ([mentions.departure.sigle, mentions.transitDeparture.sigle].includes(this.prestation.mention)) {
        this.isDeparture = true
      }
      if (this.isDeparture) {
        this.prestation.onboardingDocStatus = this.prestation.onboardingDocStatus === '' ? '' : this.prestation.onboardingDocStatus
      }

      if (!this.$perms.isUserAllowed('presta.cancel')) {
        this.statusesTN.splice(this.statusesTN.indexOf('cancelled'), 1)
      } else if (!this.$perms.isUserAllowed('presta.management')) {
        this.isDisabled = false
        this.statusesTN = ['todo', 'cancelled']
      }
    },
    getMotifs () {
      this.motifs = this.$sncfRefs.getPrestaReasonsTechnicalNames()
      const mention = this.$sncfRefs.getMentionsByTechnicalName()
      this.blockMotif = false
      if (this.prestation.statusTN === 'notDone') {
        const toRemove = ['trainRemovedOtherStation', 'substitutionOtherStation', 'postponementArrival']
        if (this.prestation.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed) {
          toRemove.push('customerCancellation')
        } else {
          toRemove.push('rejectionOtherStation')
        }
        // Le motif "Non respect du délai par le client" doit apparaître pour les prestations D/DC/N, pas A/AC
        if (![mention.departure.sigle, mention.transitDeparture.sigle, mention.none.sigle].includes(this.prestation.mention)) {
          toRemove.push('customerDelay')
        }
        // Le motif "Cause report gare de départ" doit apparaître uniquement pour les prestas A/AC
        if (![mention.arrival.sigle, mention.transitArrival.sigle].includes(this.prestation.mention)) {
          toRemove.push('postponementDeparture')
        }
        // Les motifs 'Autres gares' ne sont pas disponible pour les D et DC
        if ([mention.departure.sigle, mention.transitDeparture.sigle].includes(this.prestation.mention)) {
          toRemove.push('entryErrorOtherStation')
          toRemove.push('wheelchairOtherStation')
        }
        for (let i in toRemove) {
          this.motifs.splice(this.motifs.indexOf(toRemove[i]), 1)
        }
      } else if (this.prestation.statusTN === 'reported') {
        const toDel = ['trainRemovedOtherStation', 'substitutionOtherStation', 'customerCancellation',
          'wheelchair', 'wheelchairOtherStation', 'noShowClient', 'excessBaggage', 'postponementArrival', 'entryErrorOtherStation']
        toDel.map(m => this.motifs.splice(this.motifs.indexOf(m), 1))
        if ([mention.departure.sigle].includes(this.prestation.mention)) {
          this.motifs.splice(this.motifs.indexOf('postponementDeparture'), 1)
        }
        // Spontanée uniquement : Ajout de "Refus impossibilité autre gare"
        if (this.prestation.service !== SPONTANE) {
          this.motifs.splice(this.motifs.indexOf('rejectionOtherStation'), 1)
        }
      } else if ([ 'todo', 'done', 'cancelled' ].includes(this.prestation.statusTN)) {
        this.blockMotif = true
      }
    },
    submit () {
      if (this.prestation.statusTN === 'reported') {
        this.prestation.state = 'reportée'
      }
      if (this.customerInformationType.length > 0) {
        this.prestation.warningSource = this.$store.getters.userFullName
        this.prestation.warningDatetime = (new Date()).toISOString()
        this.prestation.customerNotificationInformation = this.customerInformationList
          .filter(item =>
            this.customerInformationType.includes(this.$t('prestation.' + item)
            )
          ).join(';')
      } else {
        this.prestation.customerNotificationInformation = ''
      }

      const self = this
      this.$validate().then(function (success) {
        if (success) {
          const data = { prestation: self.prestation, bagage: self.parcours.bagage }
          if (!self.toQualify) {
            self.$emit('update', data)
          } else {
            if (self.checkQualificationTime()) {
              if ((self.prestation.qualified && self.$perms.isUserAllowed('presta.requalif')) ||
                  (self.prestation.qualified && self.hasChangedComment) ||
                  !self.prestation.qualified) {
                self.$emit('qualify', data)
              } else {
                self.$toastService.showToast(self.$t('prestation.qualify.already'), null, 'error')
                return false
              }
            }
          }
          self.closeQualify()
        } else {
          if (self.prestation.statusTN === 'done' && !self.prestation.onboardingDocStatus) {
            self.$toastService.showToast(self.$t('prestation.qualify.onboardingNeeded'), null, 'error')
            return false
          }
        }
      })
    },
    checkQualificationTime () {
      const statusTN = this.prestation.statusTN
      const datePrestation = moment(this.prestation.datePrestation)
      let nextEvening = moment(this.prestation.datePrestation).add(1, 'days').endOf('day')
      let canQualifLong = this.$perms.isUserAllowed('presta.qualif.update.delay.long')
      let canQualifShort = this.$perms.isUserAllowed('presta.qualif.update.delay.short')

      if (
        (canQualifShort && this.now.diff(datePrestation.clone().startOf('day'), 'days') > 2) ||
        (canQualifShort && this.now.isAfter(nextEvening)) ||
        (canQualifLong && this.now.diff(datePrestation, 'months', true) > 1)
      ) {
        this.$toastService.showToast(this.$t('prestation.qualify.delay'), null, 'error')
        return false
      } else if (statusTN === 'done' && datePrestation.diff(this.now, 'minutes') > 30) {
        this.$toastService.showToast(this.$t('prestation.qualify.tooearly'), null, 'error')
        return false
      } else {
        return true
      }
    }
  },
  computed: {
    warneable: function () {
      return this.prestation && this.prestation.state !== this.$sncfRefs.getPrestaStatesByTechnicalName().reported
    },
    canSeeManager: function () {
      return this.$perms.isUserAllowed('presta.canSeeManager')
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    mandatoryBagage() {
      // si non réalisé avec motif noshow ou erreur saisie alors non obligatoire
      return !this.isOnBoard && this.prestation.statusTN !== 'cancelled' && !(this.prestation.statusTN === 'notDone' && ['entryError', 'noShowClient'].includes(this.prestation.reason))
    },
    mandatoryCarAndPlace() {
      if (this.$store.getters.userRole === 'TRANSPORTER') {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnGarenteed().includes(this.prestation.transporter) && this.prestation.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi
      } else {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous().includes(this.prestation.transporter) && this.prestation.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi
      }
    },
    mandatoryPresentationTime() {
      // si non réalisé avec motif noshow ou erreur saisie alors non obligatoire
      return this.canQualify && this.prestation.mention === this.$sncfRefs.getMentionsByTechnicalName().departure.sigle && !this.isOnBoard && !(this.prestation.statusTN === 'notDone' && ['entryError', 'noShowClient'].includes(this.prestation.reason))
    },
    mandatoryReason() {
      return this.prestation.statusTN === 'notDone' || this.prestation.statusTN === 'reported'
    },
    mandatoryCommentaryQualif() {
      return this.canQualify && this.prestation.reason === 'other'
    },
    mandatoryOnboardingDocStatus() {
      return this.canQualify && this.isDeparture && this.prestation.statusTN === 'done' && this.prestation.transporter !== 'TRANSILIEN' && !this.isOnBoard
    },
    mandatoryOnboardingDocComment() {
      return this.canQualify && this.isDeparture && this.prestation.onboardingDocStatus === 'otherNo'
    }
  },
  watch: {
    'prestation.statusTN': function (newStatus, old) {
      this.getMotifs()
      if (newStatus && old) {
        this.prestation.reason = null
      }
      if (newStatus === 'todo') {
        this.toQualify = false
      } else {
        this.toQualify = true
      }
      if (this.prestation.mention === 'D' || this.prestation.mention === 'DC') {
        if (newStatus === 'todo' || newStatus === 'done') {
          this.canCheckSolBord = true
        } else {
          this.prestation.onboardingDocStatus = null
          this.canCheckSolBord = false
        }
      }
    },
    'prestation.mention': function (val) {
      this.getStatuses()
      this.getMotifs()
    },
    'prestation.commentaryQualif': function (val, old) {
      if (val !== old && old !== undefined) this.hasChangedComment = true
    }
  },
  validators: {
    'parcours.bagage': function (value) {
      if (this.mandatoryBagage) {
        return Validator.value(value).required().integer().greaterThanOrEqualTo(0)
      }
    },
    'prestation.trainCar': function (value) {
      if (this.prestation.statusTN !== 'cancelled') {
        if (this.mandatoryCarAndPlace) {
          return Validator.value(value).required().maxLength(30)
        } else {
          return Validator.value(value).maxLength(30)
        }
      }
    },
    'prestation.trainPlace': function (value) {
      if (this.prestation.statusTN !== 'cancelled') {
        if (this.mandatoryCarAndPlace) {
          return Validator.value(value).required().maxLength(30)
        } else {
          return Validator.value(value).maxLength(30)
        }
      }
    },
    'prestation.presentationTime': function (value) {
      if (this.mandatoryPresentationTime) {
        return Validator.value(value).required()
      }
    },
    'prestation.reason': function (value) {
      if (this.mandatoryReason) {
        return Validator.value(value).required()
      }
    },
    'prestation.commentaryQualif': function (value) {
      if (this.mandatoryCommentaryQualif) {
        return Validator.value(value).required().maxLength(800)
      } else {
        return Validator.value(value).maxLength(800)
      }
    },
    'prestation.onboardingDocStatus': function (value) {
      if (this.mandatoryOnboardingDocStatus) {
        return Validator.value(value).required()
      }
    },
    'prestation.onboardingDocComment': function (value) {
      if (this.onboardingDocComment) {
        return Validator.value(value).required()
      }
    },
    'prestation.manager': function (value) {
      return Validator.value(value).maxLength(50)
    }
  },
  components: { Timepicker, Multiselect }
}
</script>
