<template>
  <form name="userForm" class="socaContent">
    <div class="userName" v-if="isUpdate">{{ value.title }}</div>
      <div class="formElement">
        <label>{{$t('news.title') }}* <small v-if="validation.hasError('value.title')" class="error">{{ $t('error.field.mandatory') }}</small></label>
        <input type="text" v-model="value.title" />
      </div>
      <div class="formElement">
        <label>{{$t('news.content') }}* <small v-if="validation.hasError('value.content')" class="error">{{ $t('error.field.mandatory') }}</small></label>
        <quill-editor v-model="value.content" @input="onEditorChange($event)" />
      </div>
      <div class="formElement">
        <p class="news-explanation">{{ $t('news.explanation') }}</p>
      </div>
      <div class="formElement dates grid-x grid-margin-x">
          <div class="cell large-6 small-6">
            <label> {{$t('news.startDate') }}*
                <small v-if="validation.hasError('value.startDate')" class="error">{{ $t('error.field.mandatory') }}</small>
            </label>
            <Datepicker v-model="value.startDate"></Datepicker>
          </div>
          <div class="cell large-6 small-6">
            <label> {{$t('news.stopDate') }}*
                <small v-if="validation.hasError('value.stopDate')" class="error">{{ $t('error.field.mandatory') }}</small>
            </label>
            <Datepicker v-model="value.stopDate"></Datepicker>
          </div>
          <div class="cell large-6 small-6">
            <label> {{$t('news.destination') }}
                <small v-if="validation.hasError('value.destination')" class="error">{{ $t('error.field.mandatory') }}</small>
            </label>
            <Multiselect
              v-model="roleDestination"
              :options="roleAvailables.map(option => $t('user.roleList.'+option))"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :placeholder="`${$t('common.select')}`"
              :show-labels="false"
              >
            </Multiselect>
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="values.length">
              <span v-show="!isOpen" v-for="value in values" :key="value">
                {{ value }}
              </span>
            </template>
          </div>
      </div>

    <button type="button" class="button-theme" @click.prevent="validate">{{ $t(validateLabel) }}</button>
  </form>
</template>

<script>
import Datepicker from '@/components/Common/Datepicker'
import SimpleVueValidation from 'simple-vue-validator'
import { ADMINNAT, AU, CONSULTANT, DT, ONBOARD, SUPERVISOR, REFERENT, OPERATOR, ENCADRANT_GAREETCO, ENCADRANT_TRANSPO, REFERENT_GAREETCO, REFERENT_TRANSPO, OEUVRANT_GAREETCO, OEUVRANT_TRANSPO } from '@/services/userService'
import Multiselect from 'vue-multiselect/src/Multiselect'

const Validator = SimpleVueValidation.Validator
const ALL = 'ALL'
export default {
  name: 'NewsForm',
  props: { value: Object, validateLabel: String, isUpdate: Boolean },
  components: { Datepicker, Multiselect },
  data () {
    return {
      newsReaded: null,
      roleDestination: [],
      roleAvailables: [
        ALL,
        ADMINNAT,
        AU,
        SUPERVISOR,
        REFERENT,
        OPERATOR,
        CONSULTANT,
        DT,
        ONBOARD
      ]
    }
  },
  created () {
    if (!this.value.rolesTargeted || this.value.rolesTargeted?.length === 0) {
      this.roleDestination = [this.$t('user.roleList.' + ALL)]
    } else {
      this.roleDestination = this.value.rolesTargeted.reduce((result, role) => {
        switch (role) {
          case ENCADRANT_GAREETCO:
            return [...result, this.$t('user.roleList.' + SUPERVISOR)]
          case ENCADRANT_TRANSPO:
            return result
          case REFERENT_GAREETCO:
            return [...result, this.$t('user.roleList.' + REFERENT)]
          case REFERENT_TRANSPO:
            return result
          case OEUVRANT_GAREETCO:
            return [...result, this.$t('user.roleList.' + OPERATOR)]
          case OEUVRANT_TRANSPO:
            return result
          default:
            return [...result, this.$t('user.roleList.' + role)]
        }
      }, [])
    }
  },
  watch: {
    'roleDestination': function(value, old) {
      if (value.includes(this.$t('user.roleList.' + ALL))) {
        if (value.length === 9) {
          this.roleDestination = []
        } else {
          this.roleDestination = this.roleAvailables.filter(role => role !== ALL).map(role => this.$t('user.roleList.' + role))
        }
      }
    }
  },
  methods: {
    validate: async function () {
      // Si on ne rempli pas ce champs, on associe cette news à tout le monde
      if (this.roleDestination.length === 0) {
        this.roleDestination = this.roleAvailables.filter(role => role !== ALL)
      }
      this.value.rolesTargeted = this.roleAvailables
        .filter(option => this.roleDestination.includes(this.$t('user.roleList.' + option)))
        .map(role => {
          if (role === SUPERVISOR) {
            return [ENCADRANT_GAREETCO, ENCADRANT_TRANSPO]
          }
          if (role === REFERENT) {
            return [REFERENT_GAREETCO, REFERENT_TRANSPO]
          }
          if (role === OPERATOR) {
            return [OEUVRANT_GAREETCO, OEUVRANT_TRANSPO]
          }
          return role
        })
        .flat()
      let success = await this.$validate()
      if (success) {
        this.$emit('input', this.value)
      }
    },
    onEditorChange: async function (html) {
      let prevNews = this._.cloneDeep(this.value)
      if (prevNews.title !== '' && prevNews.content !== '') {
        prevNews.content = html
        prevNews.id = '000'
        prevNews.NewsReads = ['noread']
        this.$emit('prevNewsEmit', prevNews)
      }
    }
  },
  computed: {
    canCreate: function () {
      return this.$perms.isUserAllowed('news.creation')
    }
  },
  validators: {
    'value.title': function (value) {
      return Validator.value(value).required()
    },
    'value.content': function (value) {
      return Validator.value(value).required()
    },
    'value.startDate': function (value) {
      return Validator.value(value).required()
    },
    'value.stopDate': function (value) {
      return Validator.value(value).required()
    }
  }
}
</script>
