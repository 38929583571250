/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'station': {
    width: 57,
    height: 36,
    viewBox: '0 0 57 36',
    data: '<path pid="0" d="M56.368 14.216L28.458 0 .589 14.216s-.589.32-.589.92c0 .602.505 1.082 1.095 1.082h2.02V36h7.325v-8.93c0-1.922 1.642-3.484 3.747-3.484 2.063 0 3.704 1.562 3.746 3.484v8.89h5.431V23.786c0-2.643 2.273-4.805 5.136-4.805 2.82 0 5.094 2.162 5.136 4.805V35.96h5.388v-8.89c0-1.922 1.642-3.484 3.705-3.484s3.705 1.562 3.747 3.484v8.89h7.367V16.178h2.063c.63 0 1.094-.48 1.094-1.081a1.166 1.166 0 00-.632-.881zm-27.91 1.241c-2.905 0-5.262-2.282-5.262-5.045 0-2.764 2.4-5.046 5.262-5.046 2.947 0 5.304 2.283 5.304 5.046 0 2.763-2.357 5.045-5.304 5.045z" _fill="#1E1E28"/><path pid="1" d="M31.994 8.45c-.126-.2-.379-.24-.59-.12l-2.946 1.921-2.4-1.16c-.21-.081-.463 0-.547.2-.084.2 0 .44.21.52l2.821 1.361L31.91 9.01c.126-.16.21-.4.084-.56z" _fill="#1E1E28"/>'
  }
})
