<template>
  <div class="adminPage userDetail">
    <div class='socaContent'>
        <div class="pageTitleLight">{{$t('user.details.title') }}</div>
        <div class="details" v-if="user">
          <span class="name">{{ user.lastname }} {{ user.firstname }}<UnlinkedIndicator :element="user" type="user"/></span>
          <div class="itemLine grid-x grid-margin-x statusLine">
            <div class="cell small-6 large-3">
              <span class="userStatus" :class="'status-' + user.status">{{ $t('user.statusList.' + user.status) }}</span>
            </div>
            <div class="cell small-6 large-3">
              <span class="sncfID">ID SNCF&nbsp;:&nbsp;</span><span class="code">{{ user.code }}</span>
            </div>
          </div>
          <div class="grid-x grid-margin-x stations">
            <span class="cell small-5 large-4">{{ $t('user.stations') }}</span>
            <span class="cell small-7 large-8">{{ stationsDisplay(user.stations) }}</span>
          </div>
          <div class="grid-x grid-margin-x stations">
            <span class="cell small-5 large-4">{{ $t('user.agencies') }}</span>
            <span class="cell small-7 large-8">{{ user.agencies.map((agency) => agency.name).join(', ') }}</span>
          </div>
          <div class="grid-x grid-margin-x stations">
            <span class="cell small-5 large-4">{{ $t('user.regions') }}</span>
            <span class="cell small-7 large-8">{{ user.regions.map((region) => $sncfRefs.getRegionLabelByCode(region.codeRegion)).join(', ') }}</span>
          </div>
          <div class="grid-x grid-margin-x role">
            <span class="cell small-5 large-4">{{ $t('user.role') }}</span>
            <span class="cell small-7 large-8">{{ $t('user.roleList.' + user.role) }}</span>
          </div>
          <div class="grid-x grid-margin-x role">
            <span class="cell small-5 large-4">{{ $t('user.externalUser') }}</span>
            <span class="cell small-7 large-8">{{ user.externalUser ? $t('common.' + user.externalUser) : $t('common.false') }}</span>
          </div>
          <div class="grid-x grid-margin-x transporters">
            <span class="cell small-5 large-4">{{ $t('user.transporters') }} </span>
            <span class="cell small-7 large-8">{{user.transporters.length > 0 ? transportersNames : '-' }}</span>
          </div>
          <div class="grid-x grid-margin-x services">
            <span class="cell small-5 large-4">{{ $t('user.services') }} </span>
            <span class="cell small-7 large-8">{{user.services.length > 0 ? servicesNames : '-' }}</span>
          </div>
          <div class="grid-x grid-margin-x services">
            <router-link :to="{ name: 'userClone', params: { code: user.code }}" class="hollowButton cell small-6 large-6">{{ $t('common.duplicate') }}</router-link>
            <a href="#" v-if="$perms.isUserAllowed('users.remove')" @click.prevent="modalConfirmDelete = true" class="hollowButton cell small-6 large-6">{{ $t('common.delete') }}</a>
          </div>
          <router-link :to="{ name: 'userEdit', params: { code: user.code }}" class="button submit button-theme">{{ $t('common.modify') }}</router-link>
        </div>
        <div class="back">
          <router-link to="/users" class="backButton">{{$t('common.back_to_results')}}</router-link>
        </div>
    </div>
    <ConfirmModal :title="$t('user.form.removeConfirm', { user: user.code })" v-if="modalConfirmDelete" @cancel="modalConfirmDelete = false" @confirm="confirmRemove(user.code)">
    </ConfirmModal>
  </div>
</template>

<script>
import UnlinkedIndicator from '@/components/Common/UnlinkedIndicator'
import ConfirmModal from '@/components/Common/ConfirmModal'
import userService from '@/services/userService'

export default {
  name: 'UserDetail',
  data () {
    return {
      user: null,
      transportersNames: null,
      servicesNames: null,
      modalConfirmDelete: false
    }
  },
  created () {
    return this.fetchDatas(this.$route.params.code)
  },
  methods: {
    fetchDatas (code) {
      return this.$backendConnector.getUser(code)
        .then(res => {
          this.user = res
          this.transportersNames = res.transporters.map(t => t.name).toString()
          this.servicesNames = res.services.map(t => t.name).toString()
        })
        .catch(err => console.log(err))
    },
    stationsDisplay (stations, max = null) {
      let stationsNames = stations.map(station => { return station.name })
      if (max != null && stationsNames.length > max) {
        stationsNames = stationsNames.slice(0, max)
      }
      return stationsNames.join(', ')
    },
    confirmRemove (userCode) {
      this.modalConfirmDelete = false
      userService.removeUser(userCode).then(response => {
        if (!response) { return }
        return this.$router.push({ name: 'users' })
      }).then(() => {
        this.$toastService.showToast(this.$t('user.form.removeDone', { user: userCode }), null, 'success')
      })
    }
  },
  components: { UnlinkedIndicator, ConfirmModal }
}
</script>
