export let typeAssists = {
  AS: { acronym: 'AS', specs: 'LR | GC | LS | PG', details: 'CA | PE | SE | AP | MV | DEA' },
  RE: { acronym: 'RE', specs: 'FE | FM', details: 'FNTR | FTR | FNPL | FPL | FPLQP | FPLNQP | AP' },
  BF: { acronym: 'BF', specs: 'LR', details: 'FTR | AP | DEA | PE | SE' },
  RF: { acronym: 'RF', specs: 'LR', details: 'FTR | AP | DEA | PE | SE' }
}

export let specAssists = {
  GC: { acronym: 'GC', details: 'CA | DEA | MV | PE | SE | AP' },
  LR: { acronym: 'LR', details: 'DEA | FTR | PE | SE | AP' },
  LS: { acronym: 'LS', details: 'CA | DEA | MV | PE | SE | AP' },
  PG: { acronym: 'PG', details: 'CA | DEA | MV | PE | SE | AP' },
  FE: { acronym: 'FE', details: 'FPL | FNPL | FTR | FNTR | FPLQP | FPLNQP | AP' },
  FM: { acronym: 'FM', details: 'FPL | FNPL | FTR | FNTR | FPLQP | FPLNQP | AP' }
}

export let detailsAssists = {
  AP: { acronym: 'AP' },
  CA: { acronym: 'CA' },
  DEA: { acronym: 'DEA' },
  PE: { acronym: 'PE' },
  MV: { acronym: 'MV' },
  FNPL: { acronym: 'FNPL' },
  FNTR: { acronym: 'FNTR' },
  FPL: { acronym: 'FPL' },
  FPLQP: { acronym: 'FPLQP' },
  FPLNQP: { acronym: 'FPLNQP' },
  SE: { acronym: 'SE' },
  FTR: { acronym: 'FTR' }
}

function getSpecifications (completeString, assistsObject, field) {
  let assist = completeString && completeString.split('-')[0].trim()
  return assistsObject[assist][field]
}

function assistAcronymToDetails (acronym, assistsObject) {
  let assist = assistsObject[acronym]
  return assist && assist.allLetters
}

export default {
  getSpecAssist: (assist) => getSpecifications(assist, typeAssists, 'specs'),
  getDetailAssist: (assist) => getSpecifications(assist, typeAssists, 'details'),
  typeAssistDetails: (acronym) => assistAcronymToDetails(acronym, typeAssists),
  specAssistDetails: (acronym) => assistAcronymToDetails(acronym, specAssists),
  detailAssistDetails: (acronym) => assistAcronymToDetails(acronym, detailsAssists),
  getDetailAssistSecondary: (assist) => getSpecifications(assist, specAssists, 'details')
}
