<template>
  <div class="socaContent">
      <div id="newsListHeader">
        <div class="grid-x grid-margin-x">
          <div class="cell large-5 small-5 pageTitleLight">
            {{ $t('news.list.title')}}
          </div>
        </div>
      </div>
      <div class="newsListMain">
        <NewsListPublicItems :news="news" />
      </div>
  </div>
</template>

<script>
import NewsListPublicItems from '@/components/News/ListItemsPublic'
import moment from 'moment'

export default {
  name: 'NewsListPublic',
  components: { NewsListPublicItems },
  data () {
    return {
      news: null
    }
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    fetchNews () {
      let params = { params: { frontDate: moment().format('YYYY-MM-DD'), when: 'past' } }
      this.$backendConnector.getAllNews(params)
        .then(res => { this.news = res.sort((a, b) => (a.startDate < b.startDate) ? 1 : -1) })
    }
  }
}
</script>
