<template>
<div class="infoPanel journey" v-bind:class="{ 'panelIsCompleted': journey }">
    <a v-if='canEdit && !isOnBoardPrestation' @click="openModal" class="toCompleteButton editButton">
      <svgicon icon="pencil"/> <span>{{$t('prestation.toComplete')}}</span>
    </a>
    <div class="show-for-small-only">
      <div class="infoPanelLine botBd infoPanelTitle">
        <span>{{$t('journey.journey') }}</span>
      </div>
      <div v-if="journey">
        <div class="infoPanelLine botBd">
          <span class="journeyDate">{{ datePrestationDateLbl }}</span>
        </div>
        <div class="infoPanelLine infoPanelLineFlex">
          <div class="trainHour">{{ datePrestationHourLbl }}</div>
          <TrainNumberBox :prestation="journey" class="trainNumberBoxSmall"/>
          <span class="transporter">{{ $sncfRefs.getTransportersByCode(journey.transporter) }}</span>
        </div>
        <div class="infoPanelLine infoService botBd" v-if="journey.service">
          <span class="infoServiceLabel">{{ $t('prestation.trajet.service')}}</span>
          <span>{{journey.service}}</span>
        </div>
        <div class="infoPanelLine journeyCarAndPlace">
          <span class="journeyCar">
            <span class="fieldLabel">{{ $t('prestation.trajet.traincar')}}</span>
            <span>&nbsp;{{journey.trainCar}}</span>
          </span>
          <span class="journeyPlace">
            <span class="fieldLabel">{{ $t('prestation.trajet.trainplace')}}</span>
            <span>&nbsp;{{journey.trainPlace}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="show-for-medium">
      <div class="infoPanelLine botBd infoPanelTitle grid-x">
        <div class="medium-1">{{$t('journey.journey') }}</div>
        <div class="medium-11"><span v-if="journey" class="journeyDate">{{ datePrestationDateLbl }}</span></div>
      </div>
      <div v-if="journey" class="grid-x infoPanelLine">
        <div class="medium-1">
          <span class="trainHour">{{ datePrestationHourLbl }}</span>
        </div>
        <div class="medium-11 flexAlignCenter">
          <TrainNumberBox :prestation="journey" />
          <span class="transporter">{{ $sncfRefs.getTransportersByCode(journey.transporter) }}</span>
          <div class="journeyCarAndPlace">
            <span class="journeyCar">
              <span class="fieldLabel">{{ $t('prestation.trajet.traincar')}}</span>
              <span>&nbsp;{{journey.trainCar}}</span>
            </span>
            <span class="journeyPlace">
              <span class="fieldLabel">{{ $t('prestation.trajet.trainplace')}}</span>
              <span>&nbsp;{{journey.trainPlace}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openedModal">
      <JourneyForm @closeTrajet="closeModal" @saveJourney="saveJourney" :journeyData="journey" :journeyDate='journeyDate' :stationDataFrom="stationDataFrom" :segmentsIndex="segmentsIndex"/>
    </div>
</div>
</template>

<script>
import JourneyForm from '@/components/Prestations/Creation/Part/JourneyForm'
import moment from 'moment'
import TrainNumberBox from '@/components/Common/TrainNumberBox'
import userService from '@/services/userService'

export default {
  name: 'JourneyCard',
  props: { onBoardJourneyData: Object, journey: Object, stationDataFrom: Object, canEdit: Boolean, segmentsIndex: Number, journeyDate: Date },
  data () {
    return {
      openedModal: false
    }
  },
  created () {
    if (this.onBoardJourneyData && this.isOnBoard && !this.journey) {
      this.journey = this.onBoardJourneyData
      this.$emit('journeyData', this.journey)
    }
  },
  mounted () {
    if (this.journey != null) {
      this.journey.datePrestationHour = this.datePrestationHourLbl
    }
  },
  methods: {
    saveJourney (journeyData) {
      this.$emit('journeyData', journeyData)
    },
    openModal () {
      this.openedModal = true
    },
    closeModal () {
      this.openedModal = false
    }
  },
  computed: {
    datePrestationDateLbl: function () {
      if (this.journey) {
        return moment(this.journey.datePrestation).locale(this.$i18n.locale).format('Do MMMM YYYY')
      }
      return null
    },
    datePrestationHourLbl: function () {
      if (this.journey) {
        return moment(this.journey.datePrestation).locale(this.$i18n.locale).format('HH:mm')
      }
      return null
    },
    isOnBoardPrestation() {
      return this.$store.getters.onBoardPrestation
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
  },
  components: {
    JourneyForm,
    TrainNumberBox
  }
}
</script>
