import { oidcSettings } from '../../config/oidc'
import backendConnector from '@/services/backendConnector'
import userService from '@/services/userService'
import store from '@/store/store.js'

let authService = {

  isAuthenticated: () => {
    return store.getters['auth/isAuthenticated'] && store.getters['userLoaded']
  },

  signIn: (destinationRoute) => {
    store.commit('auth/generateState')
    store.commit('auth/generateNonce')
    store.commit('auth/setDestinationRoute', destinationRoute)
    const urlParams = {
      response_type: oidcSettings.response_type,
      client_id: oidcSettings.client_id,
      scope: oidcSettings.scope,
      redirect_uri: oidcSettings.redirect_uri,
      state: store.getters['auth/state'],
      nonce: store.getters['auth/nonce']
    }
    var queryString = Object.keys(urlParams)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(urlParams[k]))
      .join('&')
    window.location = oidcSettings.authority + '/authorize?' + queryString
  },

  signinRedirectCallback: async function (codeResponse, redirectUri) {
    if (codeResponse.state !== store.getters['auth/state']) {
      throw new Error('Auth : invalid state')
    }
    store.commit('auth/clearState')
    const tokenResponse = await backendConnector.auth({
      code: codeResponse.code,
      redirectUri: redirectUri,
      nonce: store.getters['auth/nonce']
    })
    if (!tokenResponse) {
      return null
    }

    store.commit('auth/setAccessToken', tokenResponse.authData.access_token)
    store.commit('auth/setExpiresAt', tokenResponse.authData.expires_at)
    store.commit('setUser', tokenResponse.user)
    userService.loadUserInfos()

    return true
  },

  signOut: () => {
    store.commit('auth/clear')
    userService.eraseUser()
  }
}

export default authService
