<template>
  <div class="socaModal modalContainer">
    <div class="modalHeader">
      <div>
        <div class="headerTitle modalTitle">{{ title }}</div>
        <slot name="header"></slot>
        <div @click.prevent="$emit('close')" class="modalClose"><svgicon icon="close"/></div>
      </div>
    </div>
    <simplebar data-simplebar-auto-hide="false" class="modalContent">
      <slot />
    </simplebar>
    <div class="modalFooter">
      <slot name="footer">
        <button type="submit" class="button submit button-theme" @click.prevent="$emit('submit')">{{ submitText || $t('common.validate') }}</button>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocaModal',
  props: {
    title: {
      type: String
    },
    submitText: {
      type: String
    }
  }
}
</script>
