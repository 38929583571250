/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" d="M16 11.5v3.333a1.666 1.666 0 01-1.667 1.667H2.667A1.667 1.667 0 011 14.833V11.5m3.333-4.167L8.5 11.5m0 0l4.167-4.167M8.5 11.5v-10" _stroke="#2E2E37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
