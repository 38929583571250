/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refuse': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.3 2.1A.99.99 0 1011.9.7L7 5.6 2.1.7A.99.99 0 10.7 2.1L5.6 7 .7 11.9a.99.99 0 101.4 1.4L7 8.4l4.9 4.9a.99.99 0 101.4-1.4L8.4 7l4.9-4.9z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.3 2.1A.99.99 0 1011.9.7L7 5.6 2.1.7A.99.99 0 10.7 2.1L5.6 7 .7 11.9a.99.99 0 101.4 1.4L7 8.4l4.9 4.9a.99.99 0 101.4-1.4L8.4 7l4.9-4.9z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="7" y1="0" x2="7" y2="14" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
