<template>
    <div>
        <router-link :to="{ name: 'stationDetail', params: { codeUic: station.codeUic }}" class="stationCard">
            <div class="stationCardLine">
              <span class="codeUic">{{ station.codeUic }}</span>
              <span class="stationName">{{ station.name }}</span>
            </div>
            <div class="stationCardLine">
              <StationTags :station="station"></StationTags>
              <span class="stationRegion">{{ $sncfRefs.getRegionLabelByCode(station.region) }}</span>
            </div>
        </router-link>
    </div>
</template>

<script>
import StationTags from '@/components/Stations/Tags'

export default {
  name: 'StationCard',
  props: { station: Object },
  components: { StationTags }
}
</script>
