import moment from 'moment'

const planningDays = [
  {
    indexDay: 1,
    dateStr: moment().add(1, 'day').format('YYYY-MM-DD'),
    date: moment().add(1, 'day').startOf('day').toDate()
  }
]
function getPlanningDayByIndex (ind) {
  for (var i in planningDays) {
    if (planningDays[i].indexDay === ind) {
      return planningDays[i]
    }
  }
}

export default {
  namespaced: true,
  state: {
    planningSelected: 1
  },
  mutations: {
    setPlanningSelectedByIndex (state, dayIndex) {
      dayIndex = parseInt(dayIndex)
      if ([0, 1, 2].includes(dayIndex)) {
        state.planningSelected = dayIndex
      }
    },
    setLastUpdateDate (state, lastUpdateDate) {
      state.lastUpdate = lastUpdateDate
    }
  },
  getters: {
    planningSelected: state => {
      return getPlanningDayByIndex(state.planningSelected) || getPlanningDayByIndex(1)
    },
    planningDaysSelect: () => {
      return planningDays
    }
  }
}
