/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elevator': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M5.556 16.667h1.11c.315 0 .58-.107.793-.32.212-.213.319-.477.319-.791v-3.334c.315 0 .579-.106.792-.32.213-.212.319-.476.319-.79V9.443c0-.61-.218-1.134-.652-1.57a2.143 2.143 0 00-1.57-.652H5.556c-.612 0-1.135.218-1.57.652a2.14 2.14 0 00-.653 1.57v1.667c0 .315.107.579.32.791.213.214.477.32.791.32v3.334c0 .314.107.578.32.79.213.214.477.32.792.32zM6.11 6.11c.389 0 .718-.134.986-.403.269-.268.403-.597.403-.986s-.134-.718-.403-.986a1.34 1.34 0 00-.986-.403 1.34 1.34 0 00-.985.403 1.342 1.342 0 00-.404.986c0 .39.135.718.404.986.268.269.596.403.985.403zm6 2.778h3.556a.516.516 0 00.486-.278c.102-.185.097-.38-.014-.583L14.36 5.194c-.037-.055-.194-.138-.472-.25a.647.647 0 00-.265.056.465.465 0 00-.207.194l-1.778 2.834c-.111.203-.116.398-.013.583a.514.514 0 00.485.278zm1.778 6.167c.093 0 .18-.019.264-.056a.465.465 0 00.208-.194l1.778-2.834c.111-.204.116-.398.014-.583a.516.516 0 00-.486-.278H12.11a.514.514 0 00-.485.278c-.103.185-.098.38.013.583l1.778 2.834c.037.055.194.138.472.25zM2.222 20a2.143 2.143 0 01-1.57-.652A2.143 2.143 0 010 17.778V2.222c0-.61.217-1.134.652-1.57A2.143 2.143 0 012.222 0h15.556c.61 0 1.134.217 1.57.652.435.436.652.96.652 1.57v15.556c0 .61-.217 1.134-.652 1.57a2.143 2.143 0 01-1.57.652H2.222z" _fill="#27AE60"/>'
  }
})
