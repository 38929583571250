<template>
  <div class="" v-if="histo && presta">
    <div class="histoParcoursLine grid-container">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 histoDate">{{ histo.modifiedDate | prettyDate }}</div>
        <div class="cell small-8 histoPrestaInfoStation">{{ presta.garePresta.name }}</div>
        <div class="cell auto histoPrestaMention">{{ presta.mention }}</div>
        <div class="cell small-12 histoPrestaActionBy" v-if="histo.fieldName === 'CREATED'">
          {{ $t('prestation.createdBy') }}  <span  style="color: red;">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}}</span>
        </div>
        <div class="cell small-12  histoPrestaActionBy" v-else-if="histo.fieldName === 'OLD_SOCA'">
          {{ $t('prestation.oldSocaBy') }}  <span style="color: red;">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}} : </span><span>{{histo.newValue}}</span>
        </div>
        <div class="cell small-12  histoPrestaActionBy" v-else>
          <span >{{ $t('prestation.fieldhisto.'+ histo.fieldName) }}</span> {{ $t('common.updatedBy') }} <span>{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}}</span>
          <span v-if="histo.oldValue && histo.fieldName !== 'state'">
            {{ $t('common.histo.by') }} <span>{{ $t('prestation.fieldhisto.' + stopAndGoTechnicalName(histo.oldValue)) }}</span>
          </span>
          <span v-if="histo.newValue">
            {{$t('common.histo.to')}}
              <span v-if="histo.fieldName !== 'reason'">{{ $t('prestation.fieldhisto.' + stopAndGoTechnicalName(histo.newValue)) }}</span>
              <span v-else>{{$t('prestation.reason.' + histo.newValue)}}</span>
          </span>
        </div>
        <div class="cell small-12 histoPrestaDetail">
          <div class=grid-x>
            <div class="cell small-7 histoPrestaName">
              <router-link :to="{ name: 'prestationDetail', params: { herokuExternalId: presta.herokuExternalId } }" v-if="canAccessStation">
                <span>{{ presta.number || '(' + presta.herokuExternalId + ')' }}</span>
              </router-link>
              <div v-else>
                <span>{{ presta.number || '(' + presta.herokuExternalId + ')' }}</span>
              </div>
            </div>
            <div class="cell small-5">
              <div :class="`histoPrestaStatus prestationStatus prestationStatus-${presta.statusTN}`"
                v-if="presta.statusTN !== null"
                >{{ $t('prestation.status.'+presta.statusTN) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HistoParcoursLine',
  props: { histo: Object, presta: Object },
  data () {
    return {
      canAccessStation: false
    }
  },
  created () {
  },
  methods: {
    stopAndGoTechnicalName (state) {
      if (state.startsWith('STARTED')) return 'started'
      else if (state.startsWith('COMPLETED')) return 'completed'
      else return null
    }
  },
  filters: {
    prettyDate (date) {
      return date ? moment(date).format('DD/MM/YYYY - HH:mm') : null
    }
  },
  computed: {
    showPrestaDate () {
      return moment(this.presta.datePrestation).locale(this.$i18n.locale).format('DD MMM - HH:mm')
    }
  },
  mounted () {
    if (this.$store.getters.possibleStations.map(s => s.codeUic).includes(this.presta.garePresta.codeUic) ||
      this.$perms.isUserAllowed('presta.stations.all')) {
      this.canAccessStation = true
    }
  }
}
</script>
