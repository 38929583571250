<template>
  <form name="userForm" class="socaContent">
    <div class="userName" v-if="isUpdate">{{ value.lastname + ' ' + value.firstname }}</div>
    <StatusToogle class="status" v-model="value.status" :showLabel="false" v-if="isUpdate" />
    <fieldset name="personal" class="grid-x">
      <legend>{{ $t('user.form.personal') }}</legend>
      <div class="formElement lastname">
        <label>{{$t('user.lastname') }}* <small v-if="validation.hasError('value.lastname')" class="error">{{ validation.firstError('value.lastname') }}</small></label>
        <input type="text" v-model="value.lastname" :disabled="isUpdate && userFirstConnectionDone" />
      </div>
      <div class="formElement firstname">
        <label>{{$t('user.firstname') }}* <small v-if="validation.hasError('value.firstname')" class="error">{{ validation.firstError('value.firstname') }}</small></label>
        <input type="text" v-model="value.firstname" :disabled="isUpdate && userFirstConnectionDone" />
      </div>
      <div class="formElement code">
        <label>{{$t('user.code') }}* <small v-if="validation.hasError('value.code')" class="error">{{ validation.firstError('value.code') }}</small></label>
        <input type="text" v-model="value.code" :disabled="isUpdate" />
      </div>
    </fieldset>
    <fieldset name="perimeter" class="grid-x">
      <legend>{{ $t('user.form.perimeter') }}</legend>
      <div class="formElement role">
        <label>{{$t('user.role') }}* <small v-if="validation.hasError('value.role')" class="error">{{ validation.firstError('value.role') }}</small></label>
        <selectpl v-model="value.role">
          <option v-for="(option, index) in roles" :value="index" :key="index">{{option}}</option>
        </selectpl>
      </div>
      <div class="formElement stations" v-if="showStationsField">
        <label>{{$t('user.stations') }}  <small v-if="validation.hasError('value.stations.length')" class="error">{{ validation.firstError('value.stations.length') }}</small></label>
        <StationSelect :useStore="false" :value="value.stations"
          :allStations="$perms.isUserAllowed('users.stations.all')"
          @valueChanged="value => {this.value.stations = value}"
          placeholderNoSelection="-"
        />
      </div>
      <div class="formElement" data-cy="agencies" v-if="showAgenciesField">
        <label>{{$t('user.agencies') }}</label>
        <Multiselect v-model="value.agencies" :options="agencies"
          :multiple="true" :searchable="false" :show-labels="false"
          label="name" track-by="code" :placeholder="`${$t('common.selectOption') }`">
        </Multiselect>
      </div>
      <div class="formElement" data-cy="regions" v-if="showRegionField">
        <label>{{$t('user.regions') }}</label>
        <Multiselect v-model="value.regions" :options="regions"
          :multiple="true" :searchable="false" :show-labels="false"
          track-by="codeRegion" :placeholder="`${$t('common.selectOption') }`">
          <template slot="tag" slot-scope="props">
            <span class="multiselect__tag">
              <span>{{ $sncfRefs.getRegionLabelByCode(props.option.codeRegion) }}</span>
              <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <span>{{ $sncfRefs.getRegionLabelByCode(props.option.codeRegion) }}</span>
          </template>
        </Multiselect>
      </div>
      <div class="formElement" v-if="showServicesField">
        <label>{{$t('user.services') }}</label>
        <Multiselect v-model="value.services" :options="services"
          :multiple="true" :searchable="false" :show-labels="false"
          label="name" track-by="code" :placeholder="`${$t('common.selectOption') }`">
        </Multiselect>
      </div>
      <div class="formElement transporters" v-if="showTransportersField">
        <label>{{$t('user.transporters') }}* <small v-if="validation.hasError('value.transporters.length')" class="error">{{ validation.firstError('value.transporters.length') }}</small></label>
        <Multiselect v-model="value.transporters" :options="transporters"
          :multiple="true" :searchable="false" :show-labels="false"
          label="name" track-by="code" :placeholder="`${$t('common.selectOption') }`">
        </Multiselect>
      </div>
      <div class="formElement externalUser" v-if="canEditExternal">
        <label>{{ $t('user.externalUser') }}</label>
        <div class="external">
          <label><input type="radio" value="true" v-model="value.externalUser"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" value="false" v-model="value.externalUser"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement" v-else>
        <label>{{ $t('user.externalUser') }}</label>
        <div class="external">
          {{ value.externalUser ? $t('common.' + value.externalUser) : $t('common.false') }}
        </div>
      </div>
      <StatusToogle class="status" v-model="value.status" :showLabel="true" v-if="!isUpdate" />
    </fieldset>
    <button type="button" class="button-theme" @click.prevent="validate">{{ $t(validateLabel) }}</button>
  </form>
</template>

<script>
import StationSelect from '@/components/Common/StationSelect'
import StatusToogle from '@/components/Users/StatusToogle'
import Multiselect from 'vue-multiselect/src/Multiselect'
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'UserForm',
  props: { value: Object, validateLabel: String, isUpdate: Boolean },
  components: { StationSelect, Multiselect, StatusToogle },
  data () {
    return {
      roles: [],
      services: this.$sncfRefs.getServicesModelsForUpsert(),
      transporters: this.$sncfRefs.getTransportersModels(),
      agencies: [],
      regions: [],
      allPermissions: this.$store.getters.userRestrictedRefs.allPermissions
    }
  },
  mounted () {
    const allAgencies = this.$sncfRefs.getAgencies()
    const allRegions = this.$sncfRefs.getRegions()
    if (this.$perms.isUserAllowed('users.all')) {
      this.roles = this.$sncfRefs.getUserRoles()
      this.agencies = allAgencies
      this.regions = allRegions
    } else {
      this.roles = this.$sncfRefs.getUserRestrictedRoles()
      this.agencies = allAgencies.filter(agency => agency.code.includes(this.$store.getters.userAgenciesCode))
      this.regions = allRegions.filter(region => region.codeRegion.includes(this.$store.getters.userRegionCodes))
    }
  },
  methods: {
    validate: async function () {
      let success = await this.$validate()
      if (success) {
        this.$emit('input', this.value)
      }
    },
  },
  computed: {
    showAgenciesField: function () {
      return (this.allPermissions && this.allPermissions[this.value.role] &&
              this.allPermissions[this.value.role].includes('stations.agencies'))
    },
    showRegionField: function () {
      return (this.allPermissions && this.allPermissions[this.value.role] &&
              this.allPermissions[this.value.role].includes('stations.regions'))
    },
    showTransportersField: function () {
      return [ 'TRANSPORTER', 'CONSULTANT', 'USERTRANSPO', 'REFTRANSPO', 'ADMINTRANSPO', 'ONBOARD' ].includes(this.value.role)
    },
    showServicesField: function () {
      return this.value.role === 'TRANSPORTER'
    },
    showStationsField: function () {
      return (this.allPermissions && this.allPermissions[this.value.role] &&
              this.allPermissions[this.value.role].includes('presta.stations.self'))
    },
    userFirstConnectionDone: function () {
      return !!this.value.lastConnection
    },
    canEditExternal: function () {
      return this.$perms.isUserAllowed('users.edit.external')
    }
  },
  watch: {
    'value.services': {
      handler (newVal) {
        console.log('value.services', newVal)
      }
    }
  },
  validators: {
    'value.lastname': function (value) {
      return Validator.value(value).required()
    },
    'value.firstname': function (value) {
      return Validator.value(value).required()
    },
    'value.code': function (value) {
      return Validator.value(value).required().regex('^[0-9A-Z]*$', this.$t('user.form.error.code'))
    },
    'value.role': function (value) {
      return Validator.value(value).required()
    },
    'value.stations.length': function (value) {
      const greaterThanMessage = this.$t('user.validation.stationsRequired')
      const lessThanOrEqualToMessage = this.$t('user.validation.tooMuchStations')
      let validator = Validator.value(value)
      const hasPrestaStationsSelf = this.allPermissions && this.allPermissions[this.value.role] && this.allPermissions[this.value.role].includes('presta.stations.self')
      const hasAgencies = (this.value.agencies && this.value.agencies.length > 0)
      const hasRegion = (this.value.regions && this.value.regions.length > 0)

      if (hasPrestaStationsSelf && !(hasAgencies || hasRegion)) {
        validator = validator.greaterThan(0, greaterThanMessage).lessThanOrEqualTo(100, lessThanOrEqualToMessage)
      } else {
        validator = validator.lessThanOrEqualTo(100, lessThanOrEqualToMessage)
      }
      return validator
    },
    'value.transporters.length': function (value = 0) {
      let validator = Validator.value(value)
      if (this.showTransportersField) {
        validator = validator.greaterThan(0, this.$t('user.validation.transportersRequired'))
      }
      return validator
    }
  }
}
</script>
