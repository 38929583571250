import { render, staticRenderFns } from "./PrestationCardContent.vue?vue&type=template&id=495ae9ff"
import script from "./PrestationCardContent.vue?vue&type=script&lang=js"
export * from "./PrestationCardContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports