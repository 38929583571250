/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shield': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.121 2.615c-.375.26-.819.548-1.32.837-1.212.7-2.855 1.463-4.726 1.805v5.947c0 .013 0 .055.006.13a6.97 6.97 0 00.043.42c.141.63.967 3.21 5.884 5.826 5.014-2.667 5.772-5.297 5.89-5.855m0 0a5.455 5.455 0 00.027-.358V5.21C12.155 4.85 10.6 4.12 9.44 3.452c-.5-.289-.944-.576-1.319-.837M16 3.364V11.4s-.024.521-.072.75c-.243 1.151-1.306 3.96-5.98 6.72-.584.344-1.222.687-1.923 1.026h-.001l-.007.004v.006l-.001.002a.024.024 0 00-.003-.001l-.005-.002h-.002L8 19.91A.065.065 0 008 19.901l-.006-.003-.002-.001c-.7-.34-1.34-.682-1.922-1.026C1.329 16.072.3 13.223.078 12.102c0-.002-.078-.565-.078-.899v-7.84h.242C4.465 3.293 8.122 0 8.122 0S11.777 3.293 16 3.364z" _fill="#75757A"/>'
  }
})
