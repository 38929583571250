/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" d="M10 9.145c0 .573-.173 1.026-.518 1.357-.346.332-.805.498-1.378.498H1.896c-.573 0-1.032-.166-1.378-.498C.173 10.17 0 9.718 0 9.145c0-.253.008-.5.025-.741a7.352 7.352 0 01.288-1.558c.075-.238.177-.471.305-.698.128-.227.275-.42.44-.58a1.85 1.85 0 01.607-.383c.24-.096.504-.143.792-.143.043 0 .142.051.299.154.156.102.332.217.529.343.196.127.452.241.767.344.315.103.63.154.948.154.317 0 .633-.051.948-.154.315-.103.57-.217.767-.344.197-.126.373-.24.53-.343.156-.103.255-.154.298-.154.288 0 .552.047.792.143.239.095.441.223.607.383.165.16.312.353.44.58.128.227.23.46.306.698a7.35 7.35 0 01.288 1.558c.016.241.024.488.024.741zM7.727 2.75a2.66 2.66 0 01-.799 1.944A2.617 2.617 0 015 5.5a2.617 2.617 0 01-1.928-.806 2.66 2.66 0 01-.8-1.944c0-.76.267-1.407.8-1.944A2.617 2.617 0 015 0c.753 0 1.396.269 1.928.806a2.66 2.66 0 01.8 1.944z" _fill="#fff"/><path pid="1" d="M10 9.145c0 .573-.173 1.026-.518 1.357-.346.332-.805.498-1.378.498H1.896c-.573 0-1.032-.166-1.378-.498C.173 10.17 0 9.718 0 9.145c0-.253.008-.5.025-.741a7.352 7.352 0 01.288-1.558c.075-.238.177-.471.305-.698.128-.227.275-.42.44-.58a1.85 1.85 0 01.607-.383c.24-.096.504-.143.792-.143.043 0 .142.051.299.154.156.102.332.217.529.343.196.127.452.241.767.344.315.103.63.154.948.154.317 0 .633-.051.948-.154.315-.103.57-.217.767-.344.197-.126.373-.24.53-.343.156-.103.255-.154.298-.154.288 0 .552.047.792.143.239.095.441.223.607.383.165.16.312.353.44.58.128.227.23.46.306.698a7.35 7.35 0 01.288 1.558c.016.241.024.488.024.741zM7.727 2.75a2.66 2.66 0 01-.799 1.944A2.617 2.617 0 015 5.5a2.617 2.617 0 01-1.928-.806 2.66 2.66 0 01-.8-1.944c0-.76.267-1.407.8-1.944A2.617 2.617 0 015 0c.753 0 1.396.269 1.928.806a2.66 2.66 0 01.8 1.944z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="5" y1="0" x2="5" y2="11" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
