/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 53,
    height: 53,
    viewBox: '0 0 53 53',
    data: '<path pid="0" d="M26.5 0C11.865 0 0 11.864 0 26.5S11.865 53 26.5 53 53 41.136 53 26.5 41.135 0 26.5 0zm0 48.182c-11.974 0-21.682-9.708-21.682-21.682 0-11.974 9.708-21.682 21.682-21.682 11.974 0 21.682 9.708 21.682 21.682 0 11.974-9.708 21.682-21.682 21.682z" _fill="#1E1E28"/><path pid="1" d="M36.136 24.09H28.91v-7.226a2.41 2.41 0 00-4.818 0v7.227h-7.227a2.41 2.41 0 000 4.818h7.227v7.227a2.41 2.41 0 004.818 0V28.91h7.227a2.41 2.41 0 000-4.818z" _fill="#1E1E28"/>'
  }
})
