<script>
// https://element.eleme.io/#/en-US/component/time-picker
import { TimePicker } from 'element-ui'
import 'element-ui/lib/theme-chalk/time-picker.css'
import locale from 'element-ui/lib/locale'
import langEn from 'element-ui/lib/locale/lang/en'
import langFr from 'element-ui/lib/locale/lang/fr'

export default {
  name: 'Timepicker',
  extends: TimePicker,
  props: {
    format: {
      type: String,
      default: 'HH:mm'
    },
    'value-format': {
      type: String,
      default: 'HH:mm'
    },
    'picker-options': {
      type: Object,
      default: () => ({
        start: '00:00',
        step: '00:01',
        end: '23:59'
      })
    }
  },
  mounted () {
    locale.use(this.$i18n.locale === 'fr' ? langFr : langEn)
  }
}
</script>
