/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M.924 8.287h5.064v5.077h3.024V8.287h5.064V5.255H9.012V.178H5.988v5.077H.924v3.032z"/>'
  }
})
