<template>
  <div class="userAssistance">
    <h4>{{ $t('common.userassistance.title') }}</h4>
    <div class="userAssistanceList">
        <div class="userAssistanceItem">
          <svgicon icon="hours"/>
          {{ $t('common.userassistance.openingtime') }}
        </div>
        <div class="userAssistanceItem">
          <svgicon icon="phone"/>
          <a :href="'tel:'+$t('common.userassistance.phone')">{{ $t('common.userassistance.phone') }}</a>
        </div>
        <div class="userAssistanceItem">
          <svgicon icon="mail"/>
          <a :href="'mailto:'+$t('common.userassistance.email')">{{ $t('common.userassistance.email') }}</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAssistance'
}
</script>

<style scoped>
</style>
