/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 49,
    height: 39,
    viewBox: '0 0 49 39',
    data: '<path pid="0" d="M0 2.167C0 .97 1.001 0 2.236 0h35.778c1.235 0 2.236.97 2.236 2.167V6.5c0 1.197-1.001 2.167-2.236 2.167H2.236C1.001 8.667 0 7.697 0 6.5V2.167zM8.75 17.333c0-1.196 1.001-2.166 2.236-2.166h35.778c1.235 0 2.236.97 2.236 2.166v4.334c0 1.196-1.001 2.166-2.236 2.166H10.986c-1.235 0-2.236-.97-2.236-2.166v-4.334zM0 32.5c0-1.197 1.001-2.167 2.236-2.167h35.778c1.235 0 2.236.97 2.236 2.167v4.333c0 1.197-1.001 2.167-2.236 2.167H2.236C1.001 39 0 38.03 0 36.833V32.5z" _fill="#1E1E28"/>'
  }
})
