<template>
  <div class="localeChanger">
    <button v-for="(lang, code) in langs" :key="`${lang.label}`"
      :value="lang.label" :class="{ 'langIsActive' : code == $i18n.locale}"
      @click="switchLocale(code)"><img :src="getImageUrl(lang)" alt="">&nbsp;{{ lang.label }}</button>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return {
      langs: {
        'en': { label: 'En', image: 'uk.svg' },
        'fr': { label: 'Fr', image: 'fr.svg' }
      }
    }
  },
  methods: {
    switchLocale (lang) {
      this.$sncfRefs.getInitMentions(lang)
      this.$sncfRefs.getInitOnboardingDocStatus(lang)
      this.$i18n.locale = lang
      this.$store.commit('updateLocale', lang)
    },
    getImageUrl (lang) {
      return require('@/assets/' + lang.image)
    }
  }
}
</script>
