<template>
  <div id="planning">
    <div id="prestationList">
      <div id="prestationListHeader" class="pageTitle">
        <div class="socaContent">
          <div class="headerTitle">
            <div class="daySelect">
              <svgicon icon="arrow"/>
              <select @change="$store.commit('planningFilters/setPlanningSelectedByIndex', $event.target.value);onChangeDaySelected()" :value="$store.getters['planningFilters/planningSelected'].indexDay">
                <option v-for="planning in $store.getters['planningFilters/planningDaysSelect']"
                  :key="planning.indexDay" :value="planning.indexDay"
                  >{{ prettyDate(planning.dateStr) }}</option>
              </select>
              <div class="headerLbl">{{ selectedDateLbl }}</div>
            </div>
          </div>
          <div class="headerSecond">
            <span class="prestationTotal">{{ $tc('prestation.prestations', nbTotalPrestations) }}</span>
          </div>
        </div>
      </div>
      <simplebar id="prestationItems" class="accordionContainer socaContent socaContent-eatContentPadding">
        <div class="lastUpdateContent grid-x">
          <div class="lastUpdateInfo small-7" v-if="lastUpdateDate">{{ $t('prestation.lastupdate') }}&nbsp;:&nbsp;{{ lastUpdateDateLbl }}</div>
          <div class="small-5">
            <button @click="downloadAcquitCsv" v-if="canDownloadAcquit">
              <svgicon icon="download" class="download-icon" />
              <span class="button-text">{{$t('prestation.downloadCsv')}}</span>
            </button>&nbsp;
          </div>
        </div>
        <svgicon class="loadingAnimation" icon="loader" v-if='stationLoading' />
        <div id="planningStationsList" class="" v-if="noStationError">
          {{$t('prestation.create.selectStation') }}
        </div>
        <div id="planningStationsList" class="" v-else>
          <ul class="accordion" data-accordion data-allow-all-closed="true" v-if="stations.length > 0">
            <li class="accordion-item" :class="{ 'is-active': stations.length === 1}" data-accordion-item v-for="stationData in stations" :key="stationData.uic">
              <a href="#" class="accordion-title" @click="onStationSelected(stationData.uic)">
                <svgicon icon="station" class="hide-for-small-only stationIcon"/>
                <span>{{ stationData.name }}</span>
                <span class="planningToAcquitStationNumber">{{ stationData.numprestaunacquitted || 0 }}</span>
                <svgicon icon="arrow" class="arrow" />
              </a>
              <div class="accordion-content" data-tab-content>
                <div v-if="selectedStation !== null && stationData.uic === selectedStation.uic">
                  <div v-if="canAcquit() && parseInt(stationData.numprestaunacquitted || 0) === 0" class="listEmptyMessage">{{ $t('prestation.listisempty') }}</div>
                  <div v-if="stationData.maxacquitowner" class="alreadyAcquittedMessage">
                    <span>{{ $t('planning.alreadyAcquitted') }}</span>
                    <span>{{ $t('common.by') }}&nbsp;<b>{{ stationData.maxacquitowner }}</b></span>
                    <span>{{ $t('common.the') }}&nbsp;<b>{{stationData.maxacquitdate|showDateForDate}}</b>&nbsp;{{ $t('common.at') }}&nbsp;<b>{{stationData.maxacquitdate|showHourForDate}}</b></span>
                    <div v-if="pdfLoaders.includes(stationData.uic)" class="button-theme-round printButton loading-button"><span><svgicon icon="loader" class="loadingButton"/></span></div>
                    <div v-else class="button-theme-round printButton" @click="downloadPdf(stationData.uic)"><svgicon icon="print"/><span>{{$t('planning.download.pdfDPlus1') }}</span></div>
                    <div v-if="csvLoaders.includes(stationData.uic)" class="button-theme-round downloadButton loading-button"><span><svgicon icon="loader" class="loadingButton"/></span></div>
                    <div v-else class="button-theme-round downloadButton" @click="downloadCsv(stationData.uic)"><svgicon icon="download"/><span>{{$t('planning.download.csvDPlus20') }}</span></div>
                  </div>
                  <div v-if="stationData.maxacquitowner && prestations.length > 0" class="newPrestaToAcquitMessage">
                      {{$t('prestation.newSinceAcquit')}}
                  </div>
                  <div v-for="prestationDetails in prestations" :key="prestationDetails.herokuExternalId">
                    <Card :prestation="prestationDetails"></Card>
                  </div>
                  <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </simplebar>
      <div class="planningButton fixedOverFooter" v-if="canAcquit()">
        <div class="socaContent">
          <button class='button' @click='acquit' v-if="selectedStation.maxacquitowner">{{ $t('planning.update') }}&nbsp;{{ selectedStation.name }}</button>
          <button class='button' @click='acquit' v-else>{{ $t('planning.acquit') }}&nbsp;{{ selectedStation.name }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card'
import moment from 'moment'
import commonService from '@/services/common'
import { uuid } from 'vue-uuid'

import prestationConverterService from '@/services/prestationConverterService'

export default {
  name: 'PrestationList',
  data () {
    return {
      stations: [],
      selectedStation: null,
      prestations: [],
      nbTotalPrestations: null,
      pageSize: 1000, // max 1000 acquittements en une seule fois ?
      isLoading: false,
      csvLoaders: [],
      pdfLoaders: [],
      lastUpdateDate: null,
      stationLoading: false,
      noStationError: false
    }
  },
  mounted () {
    this.getStationDatas()
    // Synchro to context stations
    this.$store.watch(
      (state, getters) => getters.selectedStations,
      (newValue, oldValue) => {
        this.selectedStation = null
        this.stations = []
        this.getStationDatas()
      }
    )
    this.lastUpdateDate = new Date()
  },
  methods: {
    acquit () {
      let planningDate = moment(this.$store.getters['planningFilters/planningSelected'].date).locale(this.$i18n.locale).format('YYYY-MM-DD')
      return this.$backendConnector.acquitPlanning(this.selectedStation.uic, planningDate, this.prestations, uuid.v1())
        .then((result) => {
          this.getStationDatas()
          const date = moment(result.date).locale(this.$i18n.locale).format('dddd Do MMMM YYYY')
          this.$toastService.showToast(
            this.$t('planning.acquited',
              { name: result.owner, date: date }), null, 'success')
          this.lastUpdateDate = new Date(result.date)
        })
        .catch(error => {
          console.log(error)
          if (typeof error === 'string') {
            if (error.includes('Error Acquit')) this.$toastService.showToast(this.$t('planning.notacquited', null, 'error'))
          } else {
            this.$toastService.showToast(error, null, 'error')
          }
        })
    },
    getStationDatas: function () {
      this.noStationError = false
      this.stationLoading = true
      this.prestations = [] // reinit prestations
      let uics = this.$store.getters.contextStations.reduce(function (prev, curr) {
        return [...prev, curr.codeUic]
      }, [])
      return this.$backendConnector.getStationsAcquitData(uics, this.getStartDate(), this.getDPlus1Date())
        .then(stationsData => {
          this.stationLoading = false
          this.stations = stationsData
          if (this.selectedStation !== null) { // refresh selected station data
            for (let i in this.stations) {
              if (this.stations[i].uic === this.selectedStation.uic) {
                this.selectedStation = this.stations[i]
              }
            }
            this.getPrestations()
          }
          if (!this.stations) {
            this.noStationError = true
            return false
          }
          if (this.stations && this.stations.length === 1 && this.selectedStation === null) { // autoselect if only station
            this.selectedStation = this.stations[0]
            this.getPrestations()
          }
          this.nbTotalPrestations = this.stations && this.stations.reduce(function (prev, curr) {
            return prev + parseInt(curr.numprestaunacquitted ? curr.numprestaunacquitted : 0)
          }, 0)
          this.$nextTick()
            .then(() => {
              let self = this
              setTimeout(function () {
                $(self.$el).foundation()
              }, 250)
            })
            .catch(error => console.log(error))
        })
        .catch(error => console.log(error))
    },
    onStationSelected (uicSelected) {
      for (let i in this.stations) {
        if (this.stations[i].uic === uicSelected) {
          this.prestations = []
          if (this.selectedStation !== null && this.selectedStation.uic === uicSelected) {
            this.selectedStation = null
          } else {
            this.selectedStation = this.stations[i]
            this.getPrestations()
          }
        }
      }
    },
    onChangeDaySelected () {
      this.getStationDatas()
    },
    getPrestations () {
      this.stationLoading = false
      this.isLoading = true
      return this.$backendConnector.filterPrestations({ filters: this.getBackendFilters(false) }, { pagesize: this.pageSize, page: 0 })
        .then(results => {
          this.prestations = this.prestations.concat(results.rows)
          this.isLoading = false
          this.lastUpdateDate = new Date()
        })
    },
    getBackendFilters (isAcquited = null, startDate = this.getStartDate(), endDate = this.getDPlus1Date()) {
      let backFilters = { }
      // Use selected station
      backFilters.garePrestaUic = [this.selectedStation.uic]
      backFilters.datePrestation = { start: startDate, stop: endDate }
      backFilters.garantee = 'Garantie'
      backFilters.status = [prestationConverterService.getLabelFromStatus('todo')]
      if (isAcquited !== null) {
        backFilters.acquit = isAcquited
      }
      return backFilters
    },
    getStartDate () {
      return new Date(this.$store.getters['planningFilters/planningSelected'].date).toISOString()
    },
    getDPlus1Date () {
      return moment(this.$store.getters['planningFilters/planningSelected'].date).endOf('day').add('4', 'hours').toDate().toISOString()
    },
    getDPlus20Date () {
      return moment(this.$store.getters['planningFilters/planningSelected'].date).endOf('day').add('4', 'hours').add('19', 'days').toDate().toISOString()
    },
    prettyDate (date) {
      return moment(date).locale(this.$i18n.locale).format('dddd Do MMMM YYYY')
    },
    canAcquit: function () {
      return this.$perms.isUserAllowed('presta.management') &&
      this.selectedStation !== null &&
      this.selectedStation.planningacquit && (
        parseInt(this.selectedStation.numprestaunacquitted) > 0 ||
        parseInt(this.selectedStation.nbAcknowledgments || 0) === 0
      )
    },
    downloadCsv (stationUic) {
      try {
        this.csvLoaders.push(stationUic)
        return this.$backendConnector.filterPrestationsCsv({ filters: this.getBackendFilters(null, this.getStartDate(), this.getDPlus20Date()) })
          .then(result => {
            this.csvLoaders.splice(this.csvLoaders.indexOf(stationUic), 1)
            commonService.downloadBlob(result.data, 'planning.csv')
          })
      } catch (error) {
        this.csvLoaders.splice(this.csvLoaders.indexOf(stationUic), 1)
        console.error(error)
        this.$toastService.showToast(this.$t('planning.download.error'), null, 'error')
      }
    },
    downloadPdf (stationUic) {
      try {
        this.pdfLoaders.push(stationUic)
        this.$toastService.showToast(this.$t('planning.download.alert'), null, 'warning')
        return this.$backendConnector.filterPrestationsPdf({ filters: this.getBackendFilters(true) })
          .then(result => {
            this.pdfLoaders.splice(this.csvLoaders.indexOf(stationUic), 1)
            commonService.downloadBlob(result.data, 'planning.pdf')
          })
      } catch (error) {
        this.pdfLoaders.splice(this.csvLoaders.indexOf(stationUic), 1)
        console.error(error)
        this.$toastService.showToast(this.$t('planning.download.error'), null, 'error')
      }
    },
    downloadAcquitCsv () {
      let uics = this.$store.getters.contextStations.reduce(function (prev, curr) {
        return [...prev, curr.codeUic]
      }, [])
      return this.$backendConnector.getStationsAcquitDataCsv(uics)
        .then(result => {
          console.log(result)
          commonService.downloadBlob(result, 'export.csv')
        })
    }
  },
  computed: {
    selectedDateLbl: function () {
      return this.prettyDate(this.$store.getters['planningFilters/planningSelected'].dateStr)
    },
    lastUpdateDateLbl: function () {
      return moment(this.lastUpdateDate).locale(this.$i18n.locale).format('dddd Do MMMM YYYY - HH:mm')
    },
    stationsList: function () {
      return this.searchStations.length > 0 ? this.searchStations : this.$store.getters.contextStations/* .filter(s => s.spontaneous && s.soca) */
    },
    canDownloadAcquit: function () {
      return this.$perms.isUserAllowed('planning.csv')
    }
  },
  filters: {
    showHourForDate (date) {
      return date ? moment(date).format('HH:mm') : null
    },
    showDateForDate (date) {
      return date ? moment(date).format('DD/MM/YYYY') : null
    }
  },
  components: { Card }
}
</script>
