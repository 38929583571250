<template>
  <div id="libraryDetailDocument">
    <div class="socaContent" v-if="document">
      <div class="pageTitleLight">{{ $t('library.detailDocument') }}</div>
      <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
      <div class="libraryDetailDocumentContent infoPanel" v-if="!isLoading">
        <div class="infoPanelLine">
          <div class="documentPublishDate">{{ publishDateLbl }}</div>
          <div class="documentTitle">{{ document.title }}</div>
          <div class="documentDescription">{{ document.description }}</div>
          <div class="documentInfoTab">
            <div class="documentInfoTabLabel">{{ $t('library.document.themes') }}</div>
            <div class="documentInfoTabValue">{{ document.themes.map(th => th.name).join(', ') }}</div>
          </div>
          <div class="documentInfoTab">
            <div class="documentInfoTabLabel">{{ $t('library.document.externalAccess') }}</div>
            <div class="documentInfoTabValue">{{ document.showForExternal ? $t('common.yes') : $t('common.no') }}</div>
          </div>
          <div class="documentInfoTab">
            <div class="documentInfoTabLabel">{{ $t('library.document.author') }}</div>
            <div class="documentInfoTabValue">{{ document.author }}</div>
          </div>
          <div class="documentInfoTab">
            <div class="documentInfoTabLabel">{{ $t('library.document.roles') }}</div>
            <div class="documentInfoTabValue">{{ document.roles.map(ro => $t('user.roleList.' + ro.code)).join(', ') }}</div>
          </div>
          <div class="documentFormActions">
            <button type="button" class="button-theme" @click="downloadFile">{{ $t('common.download.download') }}</button>
            <button type="button" class="button-light" @click="modify" v-if="$perms.isUserAllowed('library.createDocument')">{{ $t('common.modify') }}</button>
            <button type="button" class="button-light" @click="modalConfirmDelete = true" v-if="$perms.isUserAllowed('library.deleteDocument')">{{ $t('common.delete') }}</button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal :title="$t('library.confirmDocumentDelete', { document: document.title })" v-if="modalConfirmDelete" @cancel="modalConfirmDelete = false" @confirm="deleteDoc">
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/Common/ConfirmModal'
import commonService from '@/services/common'
import moment from 'moment'

export default {
  name: 'LibraryDetailDocument',
  data () {
    return {
      documentId: null,
      document: null,
      isLoading: false,
      modalConfirmDelete: false
    }
  },
  props: { },
  mounted () {
    this.documentId = this.$route.params.id
    this.isLoading = true
    return this.$backendConnector.getDocumentById(this.documentId)
      .then(result => {
        this.document = result
        this.isLoading = false
      })
  },
  methods: {
    downloadFile: function () {
      this.$backendConnector.libraryGetDocumentDownloadUrl(this.documentId)
        .then(fileUrl => {
          let fileNameSplit = this.document.fileKey.split('/')
          let fileName = fileNameSplit[fileNameSplit.length - 1]
          commonService.downloadUrl(fileUrl, fileName)
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    modify: function () {
      this.$router.push({ name: 'libraryEditDocument', params: { id: this.documentId } })
    },
    deleteDoc: function () {
      this.$backendConnector.deleteLibraryDocument(this.documentId)
        .then(res => {
          this.$toastService.showToast(
            this.$t('library.documentDeleted'),
            null,
            'success'
          )
          this.$router.push({ name: 'library' })
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  },
  computed: {
    publishDateLbl: function () {
      return this.document ? moment(this.document.publishDate).format('DD/MM/YYYY') : ''
    }
  },
  components: { ConfirmModal }
}
</script>
