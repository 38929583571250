
import i18n from '@/plugins/vue-i18n'
import store from '@/store/store.js'
import SimpleVueValidation from 'simple-vue-validator'

// see https://github.com/semisleep/simple-vue-validator/blob/master/src/templates.js
// for existing templates
const errorMessagesToTranslate = [
  'error', 'required', 'float', 'integer', 'number', 'lessThan', 'lessThanOrEqualTo',
  'greaterThan', 'greaterThanOrEqualTo', 'between', 'size', 'length', 'minLength', 'maxLength',
  'lengthBetween', 'in', 'notIn', 'match', 'regex', 'digit', 'email', 'url']
const translateBase = 'error.validator.'

export default {
  init: () => {
    // locale change
    store.watch(
      (state, getters) => getters.userLocale,
      (newValue, oldValue) => {
        SimpleVueValidation.extendTemplates(getTranslatedErrorMessages())
      }
    )
    SimpleVueValidation.extendTemplates(getTranslatedErrorMessages())
  }
}

function getTranslatedErrorMessages () {
  let translatedErrors = {}
  for (let i in errorMessagesToTranslate) {
    translatedErrors[errorMessagesToTranslate[i]] = i18n.t(translateBase + errorMessagesToTranslate[i])
  }
  return translatedErrors
}
