<template>
  <div class="adminPage">
    <div class='socaContent'>
        <div class="pageTitleLight">{{$t('user.form.updateTitle') }}</div>
    </div>
    <UserForm v-model="user" :validateLabel="'user.form.create'" :isUpdate="true" @input="save" v-if="user"></UserForm>
  </div>
</template>

<script>
import userService from '@/services/userService'
import UserForm from '@/components/Users/Form'

export default {
  name: 'UserCreation',
  components: { UserForm },
  data () {
    return {
      user: null
    }
  },
  created () {
    return this.fetchUser(this.$route.params.code)
  },
  methods: {
    fetchUser (code) {
      let self = this
      return userService.getUser(code)
        .then(res => {
          self.user = res
          delete self.user.updatedAt
        })
        .catch(err => console.log(err))
    },
    save: async function (user) {
      await userService.updateUser(user).then(res => {
        if (res !== false) {
          this.$toastService.showToast(this.$t('user.form.userUpdateSuccess'), null, 'success')
          this.$router.push({ name: 'users', params: { code: user.code } })
        } else {
          this.$toastService.showToast(this.$t('user.form.userUpdateError'), null, 'error')
        }
      })
    }
  }
}
</script>
