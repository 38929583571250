<template>
  <div class="libraryDocumentForm">
    <div class="grid-x grid-margin-x">
      <div></div>
      <div class="formElement cell small-12 medium-12">
        <label>{{ $t('library.document.title') }}* <small v-if="validation.hasError('document.title')" class="error">{{ validation.firstError('document.title') }}</small></label>
        <input type="text" v-model="document.title" />
      </div>
      <div class="formElement cell small-12 medium-12">
        <label>{{$t('library.document.themes') }}* <small v-if="validation.hasError('document.themes.length')" class="error">{{ validation.firstError('document.themes.length') }}</small></label>
        <Multiselect v-model="document.themes" :options="$sncfRefs.getLibraryThemes()"
          :multiple="true" :searchable="false" :show-labels="false"
          label="name" track-by="id" :placeholder="`${$t('common.selectOption') }`">
        </Multiselect>
      </div>
      <div class="formElement cell small-12 medium-6">
        <label>{{ $t('library.document.publishDate') }}* <small v-if="validation.hasError('document.publishDate')" class="error">{{ validation.firstError('document.publishDate') }}</small></label>
        <Datepicker v-model="document.publishDate"></Datepicker>
      </div>
      <div class="formElement cell small-12 medium-6">
        <label>{{ $t('library.document.author') }}* <small v-if="validation.hasError('document.author')" class="error">{{ validation.firstError('document.author') }}</small></label>
        <input type="text" v-model="document.author" />
      </div>
      <div class="formElement cell small-12 medium-12">
        <label>{{ $t('library.document.description') }}* <small v-if="validation.hasError('document.description')" class="error">{{ validation.firstError('document.description') }}</small></label>
        <textarea rows="5" cols="33" v-model="document.description"></textarea>
      </div>
      <div class="formElement cell small-12 medium-6">
        <label>{{ $t('library.document.externalAccess') }}</label>
        <div class="formRadio">
          <label><input type="radio" id="true" value="true" v-model="document.showForExternal"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" id="false" value="false" v-model="document.showForExternal"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement cell small-12 medium-6">
        <label>{{$t('library.document.roles') }}* <small v-if="validation.hasError('document.roles.length')" class="error">{{ validation.firstError('document.roles.length') }}</small></label>
        <Multiselect v-model="document.roles" :options="roles"
          :multiple="true" :searchable="false" :show-labels="false"
          label="name" track-by="code" :placeholder="`${$t('common.selectOption') }`">
        </Multiselect>
      </div>
      <div class="formElement fileField cell small-12 medium-12">
        <label>{{$t('library.document.file') }}* <small v-if="validation.hasError('document.file')" class="error">{{ validation.firstError('document.file') }}</small></label>
        <div class="infoPanel">
          <div class="infoPanelLine">
            <label for="fileUpload" class="fileUploadLabel">
              <div class="button-theme-round-light fileUploadButton">
                <svgicon icon="upload" class="button-icon"/>
                <span class="button-text">{{ $t('library.document.addfile') }}</span>
              </div>
              <input type="file" id="fileUpload" ref="fileUpload" accept=".pdf,application/zip" multiple="false" @change="fileChange"/>
            </label>
            <div class="fileUploadFileName" v-if="currentFileName">{{ currentFileName }}</div>
            <div class="fileUploadHelpText">{{ $t('library.document.filehelptext') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="documentFormActions">
      <button type="button" class="button-theme" @click.prevent="validate">{{ $t('common.validate') }}</button>
      <button type="button" class="button-light" @click.prevent="$emit('cancel')">{{ $t('common.cancel') }}</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect/src/Multiselect'
import Datepicker from '@/components/Common/Datepicker'
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator
const maxFileSize = (25 * 1024 * 1024) // 25Mo
const acceptableExtensions = ['pdf', 'zip']

export default {
  name: 'LibraryDocumentForm',
  data () {
    return {
      document: null,
      roles: [],
      currentFileName: null,
      originalFileKey: null
    }
  },
  props: {
    documentIn: Object
  },
  created () {
    this.document = this._.cloneDeep(this.documentIn)
    if (this.document.fileKey) {
      this.originalFileKey = this.document.fileKey
      this.currentFileName = /[^\\/]*$/.exec(this.originalFileKey)[0]
    }
    this.roles = Object.keys(this.$sncfRefs.getUserRoles()).map((code) => {
      return { code: code, name: this.$t('user.roleList.' + code) }
    })
  },
  mounted () {
  },
  methods: {
    validate: async function () {
      let success = await this.$validate()
      if (success) {
        this.$emit('validate', this.document, this.$refs.fileUpload.files[0])
      }
    },
    fileChange: function (event) {
      if (this.$refs.fileUpload) {
        let fileFieldList = this.$refs.fileUpload.files
        this.currentFileName = fileFieldList.length > 0 ? fileFieldList[0].name : null
      } else if (this.originalFileKey) { // using original file
        this.currentFileName = /[^\\/]*$/.exec(this.originalFileKey)[0]
      }
    }
  },
  components: { Multiselect, Datepicker },
  validators: {
    'document.title': function (value) {
      return Validator.value(value).required()
    },
    'document.themes.length': function (value) {
      return Validator.value(value).required().greaterThan(0)
    },
    'document.publishDate': function (value) {
      return Validator.value(value).required()
    },
    'document.author': function (value) {
      return Validator.value(value).required()
    },
    'document.description': function (value) {
      return Validator.value(value).required()
    },
    'document.roles.length': function (value) {
      return Validator.value(value).required().greaterThan(0)
    },
    'document.file': function () {
      if (this.document.fileKey) { // update without changing file
        return Validator.value(this.document.fileKey).required()
      } else { // new file
        let fileFieldList = this.$refs.fileUpload.files
        let file = fileFieldList.length > 0 ? fileFieldList[0] : null
        if (file) {
          let fileExtension = /[^\\.]*$/.exec(file.name)[0]
          if (!acceptableExtensions.includes(fileExtension)) {
            return Validator.value(fileExtension).in(acceptableExtensions, this.$t('library.document.filehelptext'))
          } else {
            return Validator.value(file.size).required().lessThan(maxFileSize, this.$t('library.document.filehelptext'))
          }
        }
        return Validator.value(file).required()
      }
    }
  }
}
</script>
