<template>
  <div class="prestationPage">
    <div class="socaContent socaContentNarrow">
      <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
      <div v-if="!isLoading">
        <div class="prestationPageHeader">
          <div class="pageTitleLight">{{$t('prestation.journeyHistory') }} - {{ parcours.name || '(' + parcours.herokuExternalId + ')' }}</div>
        </div>
        <div class="histoParcoursLines">
          <div v-for="histo in histos" :key="histo.id">
            <HistoParcoursLine :histo="histo" :presta="prestaByHcId[histo.prestationHcId]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HistoParcoursLine from '@/components/Prestations/Histo/HistoParcoursLine'
import moment from 'moment'

export default {
  name: 'HistoParcours',
  data () {
    return {
      isLoading: true,
      parcours: null,
      histos: [], // histos ordered by date
      prestaByHcId: {} // map presta by id
    }
  },
  created () {
    if (this.$route.params.herokuExternalId) {
      return this.$backendConnector.getParcoursHisto(this.$route.params.herokuExternalId)
        .then((res) => {
          this.parcours = res
          this.prepareHistos()
          this.isLoading = false
        })
        .catch(error => console.log(error, 'Something went wrong'))
    }
  },
  mounted () {
  },
  methods: {
    prepareHistos: function () {
      var vm = this
      if (this.parcours && this.parcours.Prestations) {
        this.parcours.Prestations.map((presta) => {
          this.prestaByHcId[presta.herokuExternalId] = presta
          presta.HistoPrestations.map((histo) => {
            this.histos.push(histo)
          })
        })
      }
      this.histos.sort(function sortprestasHistos (a, b) {
        if (moment(a.modifiedDate).isSame(moment(b.modifiedDate))) {
          let prestaDateA = vm.prestaByHcId[a.prestationHcId].datePrestation
          let prestaDateB = vm.prestaByHcId[b.prestationHcId].datePrestation
          if (moment(prestaDateA).isSame(moment(prestaDateB))) {
            if (a.fieldName === 'status' && b.fieldName === 'reason') {
              return -1
            } else {
              if (a.fieldName === 'reason' && b.fieldName !== 'reason' && b.fieldName !== 'status') {
                return -1
              } else {
                if (a.fieldName !== 'status' && (b.fieldName === 'status' || b.fieldName === 'reason')) {
                  return 1
                } else {
                  return -1
                }
              }
            }
          } else {
            if (moment(prestaDateA).isAfter(moment(prestaDateB))) {
              return 1
            } else {
              return -1
            }
          }
        } else {
          if (moment(a.modifiedDate).isAfter(moment(b.modifiedDate))) {
            return 1
          } else {
            return -1
          }
        }
      })
    }
  },
  computed: {
  },
  filters: {
  },
  components: { HistoParcoursLine }
}
</script>
