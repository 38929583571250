/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 16,
    height: 12,
    viewBox: '0 0 16 12',
    data: '<path pid="0" d="M0 0v12h16V0H0zm14.075 1L7.999 5.86 1.926 1h12.149zM1 11V1.54L7.999 7.14l7-5.599V11H1z"/>'
  }
})
