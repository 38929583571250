<template>
  <div class="adminList featureFlippingList">
    <div v-for="feature in features" v-bind:key="feature.code" class="adminListItem" :id="'new-' + feature.code">
      <div class="itemLine featureFlippingLine">
        <div>
          <span class="featureFlippingName">
            {{ feature.description | limitDescription }}
          </span>
        </div>
        <toggle-button class="featureFlippingToggle" @change="selectfeatureFlipping(feature)" v-model="feature.active" :sync="true" />
      </div>
    </div>
    <div v-if="noResults">
      <label>{{ $t("featureFlipping.list.noResults") }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureFlippingListItems",
  props: { features: Array },
  methods: {
    selectfeatureFlipping(feature) {
      this.$backendConnector.updateFeatureFlipped(feature)
    },
  },
  computed: {
    noResults() {
      return this._.isEmpty(this.features)
    },
  },
  filters: {
    limitDescription(description) {
      if (description.length > 60) {
        return description.substring(0, 60) + "..."
      } else {
        return description
      }
    },
  },
}
</script>
