<template>
    <div class="infoPanel prestaAccountPanel panelIsCompleted accountPanel">
        <a class="editButton toCompleteButton" @click="openModal" v-if="canEdit">
          <svgicon icon="pencil"/>
          <span>{{$t('common.clientfile')}}</span>
        </a>
        <div class="infoPanelLine botBd infoPanelTitle">{{$t('account.client') }}<UnlinkedIndicator :element="account" type="account"/></div>
        <div class="infoPanelLine botBd accountIdentityLine">
            <div class="clientName">{{ account.civility ? $t('common.civility.short.'+$sncfRefs.getCivilityTechnicalNameByLabel(account.civility)) : '' }} {{account.lastname}} {{account.firstname}}</div>
            <div class="bags" v-if="!isOnBoard">
                <span v-tooltip="{
                      content: $t('tooltip.bagage'),
                      trigger: 'hover click',
                    }"><svgicon icon="bag"/></span>
                <span class="bag">
                    {{parcours.bagage}}
                </span>
            </div>
            <div class="clientPhone">{{$t('account.phoneShort')}}&nbsp;:&nbsp;{{ account.phone || '-' }}</div>
            <div class="clientEmail" v-if="showMail">{{$t('account.email')}}&nbsp;:&nbsp;{{ account.email || '-' }}</div>
        </div>
        <div class="infoPanelLine botBd">
            <div class="assists">
                <PrestaAssistBox :hideSecondary="true" :showDetail="true" :element="showParcoursNeeds ? parcours : account" />
                &nbsp;<PrestaAssistBox :secondaryBox="true" :showDetail="true" :element="showParcoursNeeds ? parcours : account" />
            </div>
        </div>
        <div v-if="canEdit && openedModal">
            <AccountForm @closeModal="closeModal" @saveAccount="saveAccount" :accountData="account" :parcoursData="parcours" />
        </div>
    </div>
</template>

<script>
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import AccountForm from '@/components/Prestations/Creation/Part/AccountForm'
import UnlinkedIndicator from '@/components/Common/UnlinkedIndicator'
import userService from '@/services/userService'

export default {
  name: 'Account',
  props: {
    account: Object,
    parcours: Object,
    canEdit: { type: Boolean, default: false },
    showParcoursNeeds: { type: Boolean, default: false },
    showMail: { type: Boolean, default: false }
  },
  data () {
    return {
      openedModal: false
    }
  },
  methods: {
    saveAccount (data) {
      Object.assign(this.account, data.account)
      Object.assign(this.parcours, data.parcours)
      this.$emit('saveAccount', data)
    },
    openModal () {
      this.openedModal = true
    },
    closeModal () {
      this.openedModal = false
    }
  },
  computed: {
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  components: { AccountForm, PrestaAssistBox, UnlinkedIndicator }
}
</script>
