<template>
  <div class="toastBox alertBox noOpacity" :class="[attachedToDocumentRoot ? 'attachedToDocumentRoot' : '', type, isClickable ? 'withPointer' : '']" >
    <div class="toastContent" @click="toastClicked">
      <svgicon icon="close" class="closeButton" @click.capture="close"/>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastMessage',
  data () {
    return {
      animDuration: 1000,
      alreadyClicked: false
    }
  },
  props: {
    type: {
      type: String,
      default: 'info'
    },
    delay: {
      type: Number,
      default: 3000
    },
    attachedToDocumentRoot: {
      type: Boolean,
      default: false
    },
    isClickable: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    let that = this
    setTimeout(function () { // apparation fondu
      $(that.$el).removeClass('noOpacity')
    }, 5)

    if (this.delay > 0) {
      setTimeout(function () {
        that.close()
      }, this.delay)
    }
  },
  methods: {
    close: function () {
      let that = this
      $(this.$el).addClass('noOpacity') // disparition fondu
      setTimeout(function () {
        $(that.$el).addClass('hidden')
        that.$destroy()
        that.$el.parentNode.removeChild(that.$el)
      }, this.animDuration)
    },
    toastClicked: function () {
      if (!this.alreadyClicked) {
        this.$emit('toastClicked')
        this.alreadyClicked = true
      }
    }
  }
}
</script>
