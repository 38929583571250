<template>
  <span class="prestaAssistBox" v-if="element && specAssist != undefined">
    <div class="typeAssist" v-if="!secondaryBox">
      <span v-tooltip="{
        content: prestaAssistContent,
        trigger: 'hover click',
      }">
        {{ element.typeAssist }}
      </span>
    </div>
    <div class="specAssist" v-if="specAssist">
      <span v-tooltip="{
        content: prestaSpecContent,
        trigger: 'hover click',
      }">
        {{ specAssist }}
      </span>
    </div>
    <div class="detailAssist" v-if="showDetail && detailAssist">
      <span v-tooltip="{
        content: prestaDetailContent,
        trigger: 'hover click',
      }">
        {{ detailAssist }}
      </span>
    </div>
    <div class="specAssistSecondary" v-if="!hideSecondary && !secondaryBox && element.specAssistSecondary">
      <span v-tooltip="{
        content: prestaSpecSecondaryContent,
        trigger: 'hover click',
      }">
        {{ element.specAssistSecondary }}
      </span>
    </div>
  </span>
</template>

<script>
import assist from '@/services/assist'

export default {
  name: 'PrestaAssistBox',
  props: {
    element: Object, // account or parcours
    hideSecondary: { type: Boolean, default: false },
    showDetail: { type: Boolean, default: false },
    secondaryBox: { type: Boolean, default: false }
  },
  computed: {
    specAssist: function () {
      return this.secondaryBox ? this.element.specAssistSecondary : this.element.specAssist
    },
    detailAssist: function () {
      return this.secondaryBox ? this.element.detailAssistSecondary : this.element.detailAssist
    },
    prestaAssistContent: function () {
      return assist.typeAssistDetails(this.element.typeAssist)
    },
    prestaSpecContent: function () {
      return assist.specAssistDetails(this.specAssist)
    },
    prestaDetailContent: function () {
      return assist.detailAssistDetails(this.detailAssist)
    },
    prestaSpecSecondaryContent: function () {
      return this.element.specAssistSecondary ? assist.specAssistDetails(this.element.specAssistSecondary) : ''
    }
  }
}
</script>
