/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Car': {
    width: 163,
    height: 95,
    viewBox: '0 0 163 95',
    data: '<path pid="0" d="M161.464 35.526l-10.58-22.413C147.072 5.14 138.79.025 129.664 0H20.296C9.09.012.013 8.738 0 19.507v51.628c.009 5.9 4.984 10.682 11.123 10.69h9.327C22.07 89.49 29.083 95 37.22 95c8.138 0 15.15-5.506 16.771-13.174h52.023C107.636 89.49 114.648 95 122.785 95s15.149-5.506 16.77-13.174h13.93c5.254-.004 9.511-4.096 9.515-9.145V42.336c0-2.348-.522-4.672-1.536-6.81zm-7.423.214l-32.112-2.582V19.342l23.077-2.771 9.035 19.17zM37.221 88.405c-5.67 0-10.268-4.42-10.268-9.869 0-5.448 4.599-9.868 10.268-9.868 5.668 0 10.267 4.42 10.267 9.868s-4.599 9.869-10.267 9.869zm85.564 0c-5.669 0-10.268-4.42-10.268-9.869 0-5.448 4.599-9.868 10.268-9.868 5.668 0 10.267 4.42 10.267 9.868s-4.599 9.869-10.267 9.869zm30.7-13.158h-13.93c-1.621-7.665-8.633-13.175-16.77-13.175s-15.149 5.506-16.771 13.175H53.991c-1.621-7.665-8.633-13.175-16.77-13.175-8.138 0-15.15 5.51-16.771 13.175h-9.327c-2.361-.004-4.274-1.842-4.278-4.112V19.507c.009-7.139 6.024-12.92 13.45-12.928h109.369c3.979.008 7.825 1.398 10.82 3.914l-22.191 2.665H23.53c-1.89 0-3.422 1.472-3.422 3.29v19.736c0 1.818 1.531 3.29 3.422 3.29h94.831l37.794 3.026v30.18c0 1.415-1.198 2.567-2.67 2.567zM26.953 32.895V19.737h19.68v13.158h-19.68zm26.525 0V19.737h26.097v13.158H53.478zM86.42 19.737h28.664v13.158H86.42V19.737z" _fill="#CCC"/>'
  }
})
