export const STATION_ACCESSIBLE_FOR_ALL = 'gare accessible tous handicaps'
export const STATION_ACCESSIBLE = 'gare accessible tous handicaps hors non voyants'
export const STATION_BARELY_ACCESSIBLE = 'gare non accessible en autonomie aux UFR, non voyants, gêne cognitive'
export const STATION_INACCESSIBLE = 'gare non accessible'

export const ELEVATOR_IN_MAINTENANCE = 'EN REPARATION'
export const ELEVATOR_IN_SERVICE = 'EN SERVICE'
export const ELEVATOR_INACTIVE = 'INACTIF'
export const ELEVATOR_SCUM = 'REBUT'
export const ELEVATOR_FONCTIONNEMENT_OK = 'OK'
export const ELEVATOR_FONCTIONNEMENT_KO = 'KO'

export const SPONTANE = 'Spontané'
export const ALERT_NOTIFS = 'alertNotif'

export const PRESTA_NATURE_ASSISTANCE_GARE = 'Gare'
export const PRESTA_NATURE_ASSISTANCE_BORD = 'Bord'
export const PRESTA_NATURE_ASSISTANCE_ROUTE = 'Route'

export const journeyDataObject = {
  trainNumber: null,
  transporter: null,
  datePrestation: null,
  dateAsked: null,
  hourAsked: '',
  trainCar: null,
  trainPlace: null,
  missionCode: null,
  transportMode: null,
  service: null,
  garantee: null,
  origin: 'Front',
  status: 'À réaliser',
  statusTN: 'todo',
  passages: []
};

export const ADMINISTRATOR = 'ADMINISTRATOR'
export const REFERENT = 'REFERENT'
export const ADMINTRANSPO = 'ADMINTRANSPO'
export const REFTRANSPO = 'REFTRANSPO'
