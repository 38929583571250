/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_right': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M7.19.31a.983.983 0 000 1.413l3.95 3.82H.998A.992.992 0 000 6.537c0 .552.444.993.999.993H11.16c-1.509 1.502-3.794 3.82-3.794 3.82a.983.983 0 000 1.413.995.995 0 001.42 0L15 6.537 8.61.31c-.4-.376-1.043-.376-1.42 0z"/>'
  }
})
