import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import createMutationsSharer from 'vuex-shared-mutations'
import vuei18n from '../plugins/vue-i18n'

import homeFiltersModule from './modules/homeFilters'
import planningFiltersModule from './modules/planningFilters'
import notificationsModule from './modules/notification'
import userFiltersModule from './modules/userFilters'
import authModule from './modules/auth'

const locale = vuei18n.locale

const vuexLocalStorage = new VuexPersist({
  key: 'socastore',
  storage: window.localStorage
})

Vue.use(Vuex)

const nameAlphaSort = function (a, b) {
  if (a.name < b.name) return -1
  else if (a.name > b.name) return 1
  return 0
}

const store = new Vuex.Store({
  state: {
    impersonationUserCode: null,
    user: { },
    userRestrictedRefs: {},
    possibleStations: [],
    selectedStations: [],
    historyStations: [],
    nextStations: [],
    showFooter: true,
    locale: locale,
    charterDate: null,
    numberOfUnreadNews: 0,
    canInstallPWA: false, // propose to the user to install the standalone app ?
    trainNumber: null,
    onBoardUIC: null,
    onBoardTransporter: null,
    onBoardAccount: {},
    onBoardParcours: {},
    onBoardStationData: {},
    onBoardPrestation: null,
    isOnBoardArrival: false
  },
  mutations: {
    setImpersonationUserCode (state, userCode) {
      if (userCode === null) {
        state.trainNumber = null
        state.onBoardUIC = null
      }
      state.impersonationUserCode = userCode
    },
    setUser (state, newUser) {
      state.user = newUser
    },
    setUserStations (state, stations) {
      state.possibleStations = stations
      if (stations.length === 1) {
        state.selectedStations = [...stations]
      } else {
        state.selectedStations = []
      }
    },
    setUserRestrictedRefs (state, refs) {
      state.userRestrictedRefs = refs
    },
    setUserCharterAccepted (state, newStateForUserCharterAccepted) {
      state.user.charterAccepted = newStateForUserCharterAccepted
    },
    setCharterDate (state, charterDate) {
      state.charterDate = charterDate
    },
    eraseUser (state) {
      state.user = {}
    },
    setSelectedStations (state, stations) {
      state.selectedStations = stations.sort(nameAlphaSort)
    },
    addStationToHistory (state, station) {
      if (station) {
        if (state.historyStations.find(obj => obj.codeUic === station.codeUic)) {
          state.historyStations.splice(state.historyStations.findIndex(obj => obj.codeUic === station.codeUic), 1)
        }
        state.historyStations.push(station)
        if (state.historyStations.length > 5) {
          state.historyStations.shift()
        }
      }
    },
    updateLocale (state, newLocale) {
      state.locale = newLocale
    },
    setNumberOfUnreadNews (state, num) {
      state.numberOfUnreadNews = num
    },
    numberOfUnreadNewsMinusOne (state) {
      state.numberOfUnreadNews = state.numberOfUnreadNews - 1
    },
    numberOfUnreadNewsPlusOne (state) {
      state.numberOfUnreadNews = state.numberOfUnreadNews + 1
    },
    trainNumber(state, trainNumber) {
      state.trainNumber = trainNumber
    },
    onBoardUIC (state, val) {
      state.onBoardUIC = val
    },
    clearOnBoardRole(state) {
      state.trainNumber = null
      state.onBoardUIC = null
    },
    resetStationsUser(state) {
      state.possibleStations = []
      state.selectedStations = []
    },
    onBoardTransporter(state, val) {
      state.onBoardTransporter = val
    },
    onBoardAccount(state, val) {
      state.onBoardAccount = val
    },
    onBoardParcours(state, val) {
      state.onBoardParcours = val
    },
    onBoardStationData(state, val) {
      state.onBoardStationData = val
    },
    onBoardPrestation(state, val) {
      state.onBoardPrestation = val
    },
    isOnBoardArrival(state, val) {
      state.isOnBoardArrival = val
    },
    nextStations(state, val) {
      state.nextStations = val
    },
    resetNextStations(state) {
      state.nextStations = []
    }
  },
  actions: {},
  getters: {
    userLocale: state => { return state.locale ? state.locale : 'fr' },
    userLoaded: state => {
      return !jQuery.isEmptyObject(state.user)
    },
    impersonationUserCode: state => {
      return state.impersonationUserCode ? state.impersonationUserCode : null
    },
    isImpersonated: state => {
      return !!state.impersonationUserCode
    },
    userCode: state => {
      return state.user.code
    },
    userFullName: state => {
      return state.user.firstname + ' ' + state.user.lastname
    },
    userRole: state => {
      return state.user.role
    },
    userServices: state => {
      return state.user.services
    },
    userTransporter: state => {
      return state.user.transporter
    },
    userAgenciesCode: state => {
      return state.user.agencies
    },
    userRegionCodes: state => {
      return state.user.regions
    },
    possibleStations: state => {
      return state.possibleStations.sort(nameAlphaSort)
    },
    selectedStations: state => {
      return state.selectedStations.sort(nameAlphaSort)
    },
    contextStations: state => {
      if (state.selectedStations.length > 0) {
        return state.selectedStations
      } else {
        return state.possibleStations
      }
    },
    hasOneStation: state => {
      return (state.selectedStations.length === 1)
    },
    selectedStation: state => {
      if (state.possibleStations.length === 1) {
        return state.possibleStations[0]
      } else if (state.selectedStations.length === 1) {
        return state.selectedStations[0]
      } else {
        return false
      }
    },
    historyStations: state => {
      return state.historyStations
    },
    userRestrictedRefs: state => {
      return state.userRestrictedRefs
    },
    hasUnreadNews: state => {
      return (state.numberOfUnreadNews && state.numberOfUnreadNews > 0)
    },
    user: state => {
      return state.user
    },
    trainNumber: state => {
      return state.trainNumber
    },
    onBoardUIC: state => {
      return state.onBoardUIC
    },
    onBoardTransporter: state => {
      return state.onBoardTransporter
    },
    onBoardAccount: state => {
      return state.onBoardAccount
    },
    onBoardParcours: state => {
      return state.onBoardParcours
    },
    onBoardStationData: state => {
      return state.onBoardStationData
    },
    onBoardPrestation: state => {
      return state.onBoardPrestation
    },
    isOnBoardArrival: state => {
      return state.isOnBoardArrival
    },
    nextStations: state => {
      return state.nextStations
    },
  },
  modules: {
    homeFilters: homeFiltersModule,
    planningFilters: planningFiltersModule,
    notifications: notificationsModule,
    userFilters: userFiltersModule,
    auth: authModule
  },
  plugins: [
    vuexLocalStorage.plugin,
    createMutationsSharer({
      predicate: ['auth/setAccessToken', 'auth/setExpiresAt',
        'auth/setRefreshLock', 'auth/clearRefreshLock', 'auth/clear',
        'setSelectedStations', 'notifications/read', 'notifications/readAll']
    })
  ]
})

export default store

store.watch(
  (state, getters) => getters['userCode'],
  (newValue, oldValue) => {
    store.commit('homeFilters/reset')
  }
)
store.watch(
  (state, getters) => getters['impersonationUserCode'],
  (newValue, oldValue) => {
    store.commit('homeFilters/reset')
  }
)
