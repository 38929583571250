<template>
  <div>
    <NewsModal :news="newsOnTop" @close="closeNews" @read="readNews"/>
  </div>
</template>

<script>
import NewsModal from '@/components/News/NewsModal'
import moment from 'moment'

export default {
  name: 'NewsTopReader',
  components: { NewsModal },
  data () {
    return {
      newsList: null
    }
  },
  sockets: {
    newsCreated (data) {
      if (data.includes(this.$store.getters.userRole)) {
        this.$store.commit('numberOfUnreadNewsPlusOne')
      }
    }
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    fetchNews: function () {
      // get potential unread news
      let params = { params: { frontDate: moment().format('YYYY-MM-DD') } }
      this.$backendConnector.getUnreadNews(params)
        .then(news => {
          this.$store.commit('setNumberOfUnreadNews', news.length)
          this.newsList = news
          this.newsListChange()
        })
        .catch(err => console.log(err))
    },
    readNews (idRead) {
      this.closeNews(idRead)
      this.$backendConnector.setNewsRead(idRead)
        .then(success => {
          this.$store.commit('numberOfUnreadNewsMinusOne')
        })
        .catch(err => console.log(err))
    },
    closeNews (idRead) {
      this.newsList = this.newsList.filter(n => n.id !== idRead)
      this.newsListChange()
    },
    newsListChange () {
      this.$emit('newsListChange', this.newsList)
    }
  },
  computed: {
    newsOnTop: function () {
      if (this.newsList && this.newsList.length > 0) {
        return this.newsList[0]
      }
      this.newsListChange()
      return null
    }
  }
}
</script>
