<template>
  <div class="newsList">
    <div v-for="n in news" v-bind:key="n.id" class="newsListItem" @click="newsReaded = n" :id="'new-' + n.id">
      <div class="itemLine">
        <span class="title">{{n.title}}</span>
        <span class="date">{{ formatDate(n.startDate) }} - {{ formatDate(n.stopDate) }}
          <span class="read"><svgicon icon="news" v-if="!(n.NewsReads && n.NewsReads.length > 0)"/></span>
        </span>
      </div>
    </div>
    <div v-if="noResults">
      <label>{{ $t('news.list.noResults') }}</label>
    </div>
    <NewsModal v-if="newsReaded" :news="newsReaded" @close="newsReaded = null" @read="readNews"/>
  </div>
</template>

<script>
import NewsModal from '@/components/News/NewsModal'
import moment from 'moment'

export default {
  name: 'NewsListItemsPublic',
  components: { NewsModal },
  props: { news: Array },
  data () {
    return {
      newsReaded: null
    }
  },
  methods: {
    formatDate: function (value) {
      return moment(value).locale(this.$i18n.locale).format('Do MMMM YYYY')
    },
    readNews (idRead) {
      this.newsReaded.NewsReads = ['ok']
      this.newsReaded = null
      this.$backendConnector.setNewsRead(idRead)
        .then(success => {
          this.$store.commit('numberOfUnreadNewsMinusOne')
        })
        .catch(err => console.log(err))
    }
  },
  computed: {
    noResults () {
      return this._.isEmpty(this.news)
    }
  }
}
</script>
