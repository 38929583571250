<template>
  <div class="formElement">
      <label>{{$t(label) }}</label>
      <select :value="value" v-on:change="onChangeVal($event.target.value)">
        <option v-if="withAll" value="">{{$t('common.all') }}</option>
        <option v-for="(option, index) in items" :value="valueAsKey ? option : index" :key="index">{{option}}</option>
      </select>
  </div>
</template>

<script>

export default {
  name: 'FormSelect',
  props: {
    label: String,
    value: [Number, String, Array],
    items: [Array, Object],
    withAll: { type: Boolean, default: false },
    valueAsKey: { type: Boolean, default: false }
  },
  methods: {
    onChangeVal (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>
