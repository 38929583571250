/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment_dot': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<mask id="svgicon_comment_dot_a" fill="#fff"><path pid="0" d="M0 4.182a2 2 0 012-2h10.546a2 2 0 012 2v6.909a2 2 0 01-2 2H4.033a2 2 0 00-1.329.505L0 16V4.182z"/></mask><path pid="1" d="M0 16h-1v2.227l1.664-1.48L0 16zm2.704-2.404l-.664-.747.664.747zM2 3.182h10.546v-2H2v2zm11.546 1v6.909h2V4.18h-2zM1 16v-3.636h-2V16h2zm0-3.636V4.182h-2v8.182h2zm11.546-.273H4.033v2h8.512v-2zm-10.506.758l-2.704 2.403 1.328 1.495 2.705-2.404L2.04 12.85zm11.506-1.758a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1-7.91a1 1 0 011 1h2a3 3 0 00-3-3v2zm-8.513 8.91a3 3 0 00-1.993.758l1.329 1.494a1 1 0 01.664-.252v-2zM2 1.18a3 3 0 00-3 3h2a1 1 0 011-1v-2z" _fill="#BDBDBD" mask="url(#svgicon_comment_dot_a)"/><circle pid="2" cx="14.136" cy="3.5" r="3.5" _fill="#FFB612"/>'
  }
})
