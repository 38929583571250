<template>
  <div class="adminPage">
    <div class='socaContent'>
      <button v-if="previewNews" type='button' class="button-light previewButton" @click="previewNewsOn = true">{{ $t('news.form.preview') }}</button>
      <div class="pageTitleLight cell">{{$t('news.form.createTitle') }}</div>
      <NewsModal v-if="previewNewsOn" :news="previewNews" @close="previewNewsOn = false"/>
    </div>
    <NewsForm v-model="news" :validateLabel="'user.form.create'" :isUpdate="false" @input="save" @prevNewsEmit="prevNewsFunc"></NewsForm>
  </div>
</template>

<script>
import NewsForm from '@/components/News/Form'
import NewsModal from '@/components/News/NewsModal'
import moment from 'moment'

export default {
  name: 'NewsCreation',
  components: { NewsForm, NewsModal },
  data () {
    return {
      news: {
        title: null,
        startDate: null,
        stopDate: null,
        content: null
      },
      previewNews: null,
      previewNewsOn: false
    }
  },
  created () {
  },
  methods: {
    save: function (news) {
      let self = this
      news.id = null
      news.startDate = moment(news.startDate).format('YYYY-MM-DD')
      news.stopDate = moment(news.stopDate).format('YYYY-MM-DD')
      this.$backendConnector.createNews({ 'news': news }).then(data => { self.$router.push({ name: 'news' }) })
    },
    prevNewsFunc: function (newPrev) {
      this.previewNews = newPrev
    }
  }
}
</script>
