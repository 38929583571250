<template>
  <div>
    <section class="blockAlert" v-for="alert in alerts" :key="alert.id">
      <div class="grid-x grid-margin-x whiteBlock">
        <div class="ctaTopRight" v-if="updatable">
          <div class="garanteedIcon blackIcon" @click="updateAlert(alert.id)"><svgicon icon="pencil"/></div>
          <div class="garanteedIcon blackIcon" @click="deleteAlert(alert.id)"><svgicon icon="Delete"/></div>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.situation') }}</label>
          <span class="value">{{alert.name || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.description') }}</label>
          <span class="value">{{alert.description || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.type') }}</label>
          <span class="value">{{$t('station.alertTypeList.'+alert.type) || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.start') }}</label>
          <span class="value">{{dateFormat(alert.startAlert) + ' ' + $t('common.at') + ' ' + hourFormat(alert.startAlert) || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.end') }}</label>
          <span class="value">{{dateFormat(alert.endOfAlert) + ' ' + $t('common.at') + ' ' + hourFormat(alert.endOfAlert) || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.initiator') }}</label>
          <span class="value">{{alert.initiator || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.handicapImpacted') }}</label>
          <div v-if="handicapsImpacted(alert).length === 0">
            <span class="value">{{'-'}}</span>
          </div>
          <ul>
            <li v-for="handicap in handicapsImpacted(alert)" :key="handicap">
              <span class="value">{{$t('station.alert.'+ handicap)}}</span>
            </li>
          </ul>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.solution') }}</label>
          <span class="value">{{alert.solution || '-'}}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.isReplacementBus') }}</label>
          <span class="value">{{alert.isReplacementBus ? $t('common.yes') : $t('common.no')}}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.isReplacementBusWheelchairAccessible') }}</label>
          <span class="value">{{alert.isReplacementBusWheelchairAccessible ? $t('common.yes') : $t('common.no')}}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.specializedVehicles') }}</label>
          <span class="value">{{ alert.isTaxiSubstitution ? $t('common.yes') : $t('common.no') }}</span>
        </div>
        <div class="oneStationDetail cell small-12 large-6">
          <label>{{ $t('station.alert.contactName') }}</label>
          <span class="value">{{ alert.contactName || '-' }}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.contactEmail') }}</label>
          <span class="value">{{ alert.contactEmail || '-' }}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.contactPhone') }}</label>
          <span class="value">{{ alert.contactPhone || '-' }}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.journeyDuration') }}</label>
          <span class="value">{{ alert.alternedJourneyTime || '-' }}</span>
        </div>
        <div class="oneStationDetail cell small-6 large-6">
          <label>{{ $t('station.alert.substitutionStation') }}</label>
          <span class="value">{{ alert.alteredJourneyStation || '-' }}</span>
        </div>
      </div>
    </section>
    </div>
</template>
<script>
import moment from 'moment'

export default {
  name: "Alert",
  data() {
    return {
      alert: {}
    }
  },
  props: {
    alerts: Array,
    updatable: Boolean
  },
  methods: {
    dateFormat: function(date) {
      return moment(date).locale(this.$i18n.locale).format('DD MMMM YYYY')
    },
    hourFormat: function(date) {
      return moment(date).locale(this.$i18n.locale).format('HH:mm')
    },
    handicapsImpacted: (alert) => {
      const handicaps = []
      if (alert.isAuditive) handicaps.push("auditive")
      if (alert.isMental) handicaps.push("mental")
      if (alert.isMotor) handicaps.push("motor")
      if (alert.isVisual) handicaps.push("visual")
      if (alert.isOtherPMR) handicaps.push("otherHandicap")
      return handicaps
    },
    updateAlert: function(val) {
      this.$emit('updateAlert', val)
    },
    deleteAlert: function(val) {
      this.$emit('deleteAlert', val)
    }
  }
}
</script>
