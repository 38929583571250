import Vue from 'vue'
import VueRouter from 'vue-router'
import Prestation from '@/components/Prestations/Prestation'
import PrestaPrint from "@/components/Prestations/Print"
import PrestationCreation from '@/views/PrestationCreation'
import PrestationList from '@/components/Prestations/Search/PrestationList'
import HistoParcours from '@/components/Prestations/Histo/HistoParcours'
import Planning from '@/components/Prestations/Planning/List'
import FicheSolBord from '@/components/Prestations/SolBord/FicheSolBord'
import Station from '@/views/Station'
import StationDetail from '@/components/Stations/Detail'
import StationEdit from '@/components/Stations/Update'
import Library from '@/views/Library'
import LibraryCreateDocument from '@/components/Library/CreateDocument'
import LibraryDetailDocument from '@/components/Library/DetailDocument'
import LibraryEditDocument from '@/components/Library/EditDocument'
import UserList from '@/components/Users/List.vue'
import UserCreation from '@/components/Users/Creation.vue'
import UserEdit from '@/components/Users/Edit.vue'
import User from '@/components/Users/Detail.vue'
import NewsCreation from '@/components/News/Creation.vue'
import NewsList from '@/components/News/List.vue'
import NewsListPublic from '@/components/News/ListPublic.vue'
import NewsEdit from '@/components/News/Edit.vue'
import FeatureFlippingList from "@/components/FeatureFlipping/List.vue"
import Report from '@/views/Report'
import Charter from '@/components/Nav/Charter'
import CheckCredentials from '@/components/Auth/CheckCredentials.vue'
import Protected from '@/components/Auth/Protected.vue'
import OidcCallback from '@/components/Auth/OidcCallback.vue'
import AuthError from '@/components/Auth/AuthError.vue'
import AuthDisconnected from '@/components/Auth/AuthDisconnected.vue'
import authService from '@/services/authService'
import store from '@/store/store.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/prestations',
    name: 'prestations',
    component: PrestationList
  },
  {
    path: '/prestation/new/:step?',
    name: 'PrestationCreation',
    component: PrestationCreation,
    beforeEnter: (to, from, next) => {
      if (store.getters['user'].charterAccepted) {
        next()
      } else {
        next({
          path: '/signCharter',
        })
      }
    }
  },
  {
    path: '/prestations/:herokuExternalId',
    name: 'prestationDetail',
    component: Prestation
  },
  {
    path: '/prestations/:herokuExternalId/print',
    name: 'PrestaPrint',
    component: PrestaPrint
  },
  {
    path: '/prestations/:herokuExternalId/contactSolBord',
    name: 'FicheSolBord',
    component: FicheSolBord
  },
  {
    path: '/parcourshisto/:herokuExternalId',
    name: 'parcoursHisto',
    component: HistoParcours
  },
  {
    path: '/planning',
    name: 'planning',
    component: Planning
  },
  {
    path: '/report/:herokuExternalId/:reason',
    name: 'report',
    component: Report
  },
  {
    path: '/station',
    name: 'station',
    component: Station,
    beforeEnter: (to, from, next) => {
      if (store.getters['user'].charterAccepted) {
        next()
      } else {
        next({
          path: '/signCharter',
        })
      }
    }
  },
  {
    path: '/station/:codeUic',
    name: 'stationDetail',
    component: StationDetail
  },
  {
    path: '/station/:herokuExternalId/edit',
    name: 'stationEdit',
    component: StationEdit
  },
  {
    path: '/library',
    name: 'library',
    component: Library
  },
  {
    path: '/library/document/create',
    name: 'libraryCreateDocument',
    component: LibraryCreateDocument
  },
  {
    path: '/library/document/:id',
    name: 'libraryDetailDocument',
    component: LibraryDetailDocument
  },
  {
    path: '/library/document/:id/edit',
    name: 'libraryEditDocument',
    component: LibraryEditDocument
  },
  {
    path: '/news',
    name: 'newsPublic',
    component: NewsListPublic
  },

  {
    path: '/admin/news',
    name: 'news',
    component: NewsList
  },
  {
    path: '/admin/news/new',
    name: 'newsCreation',
    component: NewsCreation
  },
  {
    path: '/admin/news/:id/edit',
    name: 'newsEdit',
    component: NewsEdit
  },
  {
    path: '/admin/featureFlipping',
    name: 'featureFlipping',
    component: FeatureFlippingList,
    beforeEnter: (to, from, next) => {
      if (store.getters['user'].charterAccepted) {
        next()
      } else {
        next({
          path: '/signCharter',
        })
      }
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UserList
  },
  {
    path: '/user/new',
    name: 'userCreation',
    component: UserCreation
  },
  {
    path: '/user/:code',
    name: 'user',
    component: User
  },
  {
    path: '/user/:code/clone',
    name: 'userClone',
    component: UserCreation
  },
  {
    path: '/user/:code/edit',
    name: 'userEdit',
    component: UserEdit
  },
  {
    path: '/signCharter',
    name: 'signCharter',
    component: Charter
  },
  {
    path: '/checkCredentials',
    name: 'checkCredentials',
    component: CheckCredentials
  },
  {
    path: '/protected',
    name: 'protected',
    component: Protected
  },
  {
    path: '/oidc-redirect_uri', // Needs to match redirectUri in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isOidcCallback: true,
      isPublic: true
    }
  },
  {
    path: '/auth-error',
    name: 'authError',
    component: AuthError,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/disconnected',
    name: 'authDisconnected',
    component: AuthDisconnected,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/',
    name: 'home',
    redirect: '/prestations'
  },
  {
    path: '*',
    redirect: '/prestations'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.isPublic)
  if (isPublic) {
    return next()
  }
  authService.isAuthenticated() ? next() : authService.signIn(to)
})

export default router
