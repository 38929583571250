/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bag': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<path pid="0" d="M21.143 4.104h-2.851a.733.733 0 00-.733-.686h-.037v-1.57c0-1.02-.84-1.848-1.873-1.848H9.351C8.318 0 7.478.829 7.478 1.848v1.57H7.44a.733.733 0 00-.732.686H3.857A3.855 3.855 0 000 7.949v13.91A3.15 3.15 0 003.15 25h18.7A3.15 3.15 0 0025 21.86V7.948c0-2.12-1.73-3.845-3.857-3.845zm-8.186 1.464c.656.13 1.226.501 1.61 1.05.396.563.547 1.246.426 1.923-.047.267-.135.52-.26.756l-2.064.236a.735.735 0 00-.522.313l-3.024 4.383a.731.731 0 00.19 1.019l3.015 2.068a.733.733 0 001.022-.189l3.024-4.383a.73.73 0 00.106-.598l-.523-2.028c.237-.408.4-.851.483-1.32a4.003 4.003 0 00-.827-3.23h2.653v17.967H6.734V5.568h6.223zm2.017 6.62l-2.419 3.506-1.805-1.238 2.418-3.505 1.444-.166.362 1.403zM8.948 1.848c0-.219.173-.383.403-.383h6.298c.23 0 .403.164.403.383v1.57h-.037a.733.733 0 00-.733.686H9.718a.733.733 0 00-.732-.686h-.038v-1.57zM1.469 21.859V7.95a2.387 2.387 0 012.388-2.38h1.408v17.966H3.15a1.68 1.68 0 01-1.68-1.676zm22.062 0a1.68 1.68 0 01-1.681 1.676h-2.115V5.568h1.408a2.387 2.387 0 012.388 2.38V21.86z" _fill="#CCC"/>'
  }
})
