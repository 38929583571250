import store from '@/store/store.js'
import backendConnector from '@/services/backendConnector'
import prestationConverterService from '@/services/prestationConverterService'
import permissionService from '@/services/permissionService'
import { ALERT_NOTIFS, ADMINISTRATOR, REFERENT, ADMINTRANSPO, REFTRANSPO } from '@/utils/constantsUtils'

const usersCanRecieveAlertsNotifications = [ADMINISTRATOR, REFERENT, ADMINTRANSPO, REFTRANSPO]

const vapidPublicKey = process.env.VUE_APP_BACK_VAPID_PUBKEY
let notifsCallback = new Map()
let socket = null

const notifUtils = {
  urlBase64ToUint8Array (base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  },
  // Ask client navigator for notif permission
  askNavigatorNotifPermission () {
    return new Promise(function (resolve, reject) {
      Notification.requestPermission(function (status) {
        if (status === 'granted') {
          resolve()
        } else {
          reject(new Error(status))
        }
      })
    })
  },
  // Ask service worker to subscribe for push
  registerPushServiceWorker () {
    return new Promise(function (resolve, reject) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: notifUtils.urlBase64ToUint8Array(vapidPublicKey)
        })
          .then(function (res) {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }).catch(e => console.log('registerPushServiceWorker', e))
    })
  }
}

const notificationService = {
  initNotificationServiceGlobal() {
    // init notif active uics
    store.commit(
      "notifications/setUics",
      store.getters["contextStations"].reduce(function(prev, curr) {
        return [...prev, curr.codeUic]
      }, [])
    )

    // If user changes
    store.watch(
      (state, getters) => getters["userCode"],
      (newValue, oldValue) => {
        if (socket && newValue !== oldValue) {
          socket.emit("socaAuth", {
            token: store.getters["auth/accessToken"],
            impersonationUserCode: store.getters["impersonationUserCode"],
          })
          store.commit("notifications/reset")
        }
      }
    )
    store.watch(
      (state, getters) => getters["impersonationUserCode"],
      (newValue, oldValue) => {
        if (socket && newValue !== oldValue) {
          socket.emit("socaAuth", {
            token: store.getters["auth/accessToken"],
            impersonationUserCode: store.getters["impersonationUserCode"],
          })
          store.commit("notifications/reset")
        }
      }
    )
    // Update notifications store when train number change.
    store.watch(
      (state, getters) => getters["trainNumber"],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          store.commit("notifications/reset")
          if (socket) {
            socket.emit("registerPushActiveStations", store.getters["notifications/getUics"])
          }

          this.resyncNotifs()
        }
      }
    )
    // React to context station changes
    store.watch(
      (state, getters) => getters["contextStations"],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          // change notif active uics
          store.commit(
            "notifications/setUics",
            newValue.reduce(function(prev, curr) {
              return [...prev, curr.codeUic]
            }, [])
          )
          if (socket) {
            if (this.shouldSendAlertStations()) {
              socket.emit("registerPushActiveStations", store.getters["notifications/getUics"], store.getters["notifications/getUicForAlertNotifs"])
            } else {
              socket.emit("registerPushActiveStations", store.getters["notifications/getUics"])
            }
          }

          this.resyncNotifs()
        }
      }
    )

    // Get first notifs for current context stations
    this.resyncNotifs()
  },
  initNotificationServiceSocket(socketIn) {
    socket = socketIn
    // Authent du socket afin de pouvoir en récupérer des infos
    socket.emit("socaAuth", {
      token: store.getters["auth/accessToken"],
      impersonationUserCode: store.getters["impersonationUserCode"],
    })
  },
  initNotificationServiceSocketAuthOk() {
    if (this.shouldSendAlertStations()) {
      socket.emit("registerPushActiveStations", store.getters["notifications/getUics"], store.getters["notifications/getUicForAlertNotifs"])
    } else {
      socket.emit("registerPushActiveStations", store.getters["notifications/getUics"])
    }
    socket.emit("registerPushActiveUser", store.getters["userCode"])
  },
  // launch push service
  initPushService() {
    // ask for push permission
    notifUtils
      .askNavigatorNotifPermission()
      .then(function() {
        // notif permission ok
        // console.log('Navigator notification permission ok')
        return notifUtils
          .registerPushServiceWorker()
          .then(function(subscribe) {
            // subscribe push ok
            // console.log('Push Subscribe ok : ', subscribe)
            socket.emit("registerPushSuscribe", subscribe)
          })
          .catch((err) => {
            // subscribe push not ok
            console.log("Service worker cannot subscribe to push : " + err)
          })
      })
      .catch((status) => {
        // notif permission not ok
        console.log("Navigator has refused notification permission : " + status)
      })
  },
  shouldSendAlertStations() {
    const uicForAlertNotifs = store.getters["notifications/getUicForAlertNotifs"]
    const userRole = store.getters["userRole"]

    return uicForAlertNotifs.length > 0 && usersCanRecieveAlertsNotifications.includes(userRole)
  },
  resyncNotifs() {
    // Resync stations socket subscribe and existing notifications
    // delete old notifs
    store.commit("notifications/sanitizeNotifs")

    const userUics = store.getters["notifications/getUics"]
    const uicForAlertNotifs = store.getters["notifications/getUicForAlertNotifs"]
    const userCode = store.getters["userCode"]
    const userRole = store.getters["userRole"]
    const apiPromises = []

    // Check if the user has permissions to receive notifications
    if (userUics.length > 0 && permissionService.isUserAllowed("notifications")) {
      // Check condition and fetch notifications
      if (!(userRole === REFERENT && userUics.length > 10)) {
        const notificationsPromise = backendConnector.getNotifications(userUics, userCode)
          .then((notifs) => {
            return notifs
          })
          .catch((err) => {
            console.error(err)
          })

        apiPromises.push(notificationsPromise)
      } else {
        store.commit("notifications/reset")
      }
    }

    if (permissionService.isUserAllowed("notifications") && uicForAlertNotifs.length && usersCanRecieveAlertsNotifications.includes(userRole)) {
      // Fetch alerts notifications
      const alertsPromise = backendConnector.getAlertsNotifications(uicForAlertNotifs)
        .then(({ alertsNotifs }) => {
          return alertsNotifs
        })
        .catch((err) => {
          console.error(err)
        })

      apiPromises.push(alertsPromise)
    }

    // Wait for all promises to resolve
    Promise.all(apiPromises)
      .then((results) => {
        // Merge the results obtained from different API calls
        const mergedResults = results.flat()

        // Update the state with the merged results
        if (results.length) store.commit("notifications/addAll", mergedResults)
      })
      .catch((err) => {
        console.error('Error fetching notifications:', err)
      })
  },

  onNewNotification(notifObject) {
    // React to notification
    if (notifObject.Prestation) {
      notifObject.Prestation = prestationConverterService.convertToFront(notifObject.Prestation)
    }
    if (permissionService.isUserAllowed("notifications")) {
      if (!(store.getters["userRole"] === "REFERENT" && store.getters["notifications/getUics"].length > 10)) {
        // si referent, pas de notifs si plus de 10 gares
        store.commit("notifications/add", notifObject)
      }
    }
    for (let cbFunc of notifsCallback.values()) {
      cbFunc(notifObject)
    }
  },
  addNotificationCallback(identifier, callbackFunc) {
    // add a callback to be called when a new notif arrive
    notifsCallback.set(identifier, callbackFunc)
  },
  notifIsUnderstood(notif) {
    if (socket) {
      if (notif.type === ALERT_NOTIFS) {
        socket.emit("deactivateAlertNotif", notif.alertStationId)
      } else if (notif.uic) {
        socket.emit("desactivateStationsNotif", notif.code)
      } else if (notif.target) {
        socket.emit("desactivateUserNotif", notif.code)
      }
    }
  },
  initStopAndGoSocket(socketIn) {
    socket = socketIn
    if (socket) {
      socket.emit("stopAndGoListener")
    }
  },
  desactivateNotifCode(notifCode) {
    store.commit("notifications/desactiveByCode", notifCode)
  },
  deactivateAlertNotifBycode(alertNotifCode) {
    store.commit("notifications/deactivateAlertNotifByCode", alertNotifCode)
  },
}

export default notificationService
