/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" d="M0 13.834v3.542h3.542L13.994 6.924l-3.542-3.542L0 13.834zm16.724-9.64a.946.946 0 000-1.336L14.518.652a.946.946 0 00-1.336 0l-1.729 1.729 3.542 3.542 1.729-1.729z"/>'
  }
})
