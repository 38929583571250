import moment from 'moment'
import _ from 'lodash'
import { ELEVATOR_IN_MAINTENANCE, ELEVATOR_IN_SERVICE } from '@/utils/constantsUtils'

export default {
  setHoursMinute: (hour, toUpdate) => {
    if (hour && hour !== '' && toUpdate) {
      let h = null
      let m = null
      if (typeof hour === 'string') {
        const d = hour.split(':')
        h = d[0]
        m = d[1]
      } else {
        h = hour.HH
        m = hour.mm
      }
      let update = new Date(toUpdate)
      update.setHours(h, m, 0)
      return new Date(update)
    } else {
      return null
    }
  },
  getTimezonedHour: (hour) => {
    if (!hour) {
      return hour
    }
    if (typeof hour === 'string' || hour instanceof String) {
      let tdIsoStr = moment().toISOString(true) // 1996-10-15T10:05:32.000Z ou 1996-10-15T11:05:32.000+
      let hourIsoStr
      if (hour.includes('Z') || hour.includes('+') || hour.includes('-')) { // if hour contains the timezone
        hourIsoStr = tdIsoStr.substring(0, 10) + 'T' + hour
      } else { // we take the local timzone
        hourIsoStr = tdIsoStr.substring(0, 10) + 'T' + hour + tdIsoStr.substring(11 + hour.length)
      }
      return moment(hourIsoStr).format('HH:mm')
    } else {
      return moment(hour).format('HH:mm')
    }
  },
  getPrestationPrincipalState: (hcId, prestations) => {
    const presta = prestations.filter(p => p.herokuExternalId === hcId)[0]
    const statesList = prestations.filter(p => moment(p.datePrestation).isAfter(moment(presta.datePrestation))).map(p => p.state)
    let state = 'accepted'
    if (presta.length > 0 && presta.state === 'reportée') {
      state = 'reported'
    } else if (statesList.length > 0 && (statesList.includes('Refusée') || statesList.includes('refusée'))) {
      state = 'refused'
    } else if (statesList.includes(null)) {
      state = 'waiting'
    }
    return state
  },
  downloadBlob: (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    // the filename you want
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  },
  downloadCsv: (text, filename) => {
    var element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  },
  downloadUrl: (url, filename) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.target = '_blank'
    a.href = url
    // the filename you want
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  },
  /**
   * Calcule et renvoie les segments consolidés à partir d'une liste de prestation
   */
  getSegmentsFromPrestations: (prestationsIn, withReported = false) => {
    let segments = []
    let prestations = _.cloneDeep(prestationsIn)
    prestations = prestations.sort((a, b) => (moment(a.datePrestation).isAfter(moment(b.datePrestation))) ? 1 : -1)

    if (!withReported) {
      prestations = prestations.filter(p => p.state !== 'reportée')
    }

    while (prestations.length > 0) {
      let segment = []
      let currentPrestation = prestations[0]
      segment.push(currentPrestation)
      prestations.splice(0, 1)

      let otherPrestationIndex = prestations.findIndex((prestationTest) => {
        return (
          (currentPrestation.state === 'reportée' ? prestationTest.state === 'reportée' : prestationTest.state !== 'reportée') &&
          prestationTest.trainNumber === currentPrestation.trainNumber &&
          prestationTest.garePrestaUic === currentPrestation.gareAutreUic &&
          prestationTest.segmentPart !== currentPrestation.segmentPart
        )
      })

      if (otherPrestationIndex >= 0) {
        segment.push(prestations[otherPrestationIndex])
        prestations.splice(otherPrestationIndex, 1)
      }
      segments.push(segment)
    }

    return segments
  },
  getStationElevaticStatus: (equipment) => {
    if (equipment?.elevators?.length === 0) {
      return {
        noElevator: true,
        noEscalator: equipment?.escalators?.length === 0,
        // Set to false when the component is already created and displayed.
        elevatorOk: false,
        elevatorNok: false
      }
    } else {
      const validElevators = equipment?.elevators?.filter(elevator => elevator.statut === ELEVATOR_IN_MAINTENANCE || elevator.statut === ELEVATOR_IN_SERVICE)
      if (validElevators.length > 0) {
        const elevatorNotWorking = validElevators.filter(elevator =>
          elevator?.statut === ELEVATOR_IN_MAINTENANCE || (elevator?.statut === ELEVATOR_IN_SERVICE && elevator?.fonctionnement?.etat !== 'OK'))
        return {
          elevators: validElevators,
          elevatorNok: elevatorNotWorking?.length > 0,
          elevatorOk: elevatorNotWorking?.length === 0,
          noElevator: false
        }
      } else {
        return {
          elevatorNok: false,
          elevatorOk: false,
          noElevator: true
        }
      }
    }
  },
  duplicatePassages: (journeyData, passage, hour, hourDateTime, isArrival) => {
    const updatedJourneyData = { ...journeyData }
    const copy = JSON.parse(JSON.stringify(passage));
    copy.hour = hour
    copy.hourDateTime = hourDateTime
    copy.isArrival = isArrival
    updatedJourneyData.passages.push(copy)

    return updatedJourneyData
  }
}
