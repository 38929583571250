<template>
  <span>
    <vDatePicker
      :locale="$i18n.locale"
      :disabled-dates="disabledDates"
      :available-dates="availableDates"
      :selectAttribute="{ highlight: 'orange' }"
      :popover="{ positionFixed: true }"
      :masks="{
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['DD-MM-YYYY'],
        dayPopover: 'WWW, MMM D, YYYY',
        data: ['DD-MM-YYYY']
      }"
      :first-day-of-week="2"
      :value="value"
      @input="onInput"
      class="v-datepicker"
    />
  </span>
</template>

<script>
import vDatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'Datepicker',
  extends: vDatePicker,
  props: {
    value: { type: null, required: true },
    'disabled-dates': {
      type: Object,
      default: null
    },
    'available-dates': {
      type: Object,
      default: null
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    }
  },
  mounted: function () {
  },
  components: { vDatePicker }
}
</script>
