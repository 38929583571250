<template>
    <router-link :to="{ name: 'libraryDetailDocument', params: { id: document.id } }" class="libraryDocumentCard infoPanel">
      <div class="infoPanelLine grid-x grid-margin-x">
        <div class="cell medium-2 hide-for-small-only documentPublishDate">{{ publishDateLbl }}</div>
        <div class="cell medium-7 documentDescription">{{ document.title }}</div>
        <div class="cell medium-3 documentThemes">
          <span>{{ document.themes.map(t => t.name).join(' - ') }}</span>
          <button @click.capture.prevent="downloadFile" class="hide-for-small-only iconButton">
            <svgicon icon="download" class="button-icon"/>
          </button>
        </div>
      </div>
    </router-link >
</template>

<script>
import commonService from '@/services/common'
import moment from 'moment'

export default {
  name: 'LibraryDocumentCard',
  data () {
    return {
    }
  },
  props: {
    document: Object
  },
  mounted () {
  },
  methods: {
    downloadFile: function () {
      this.$backendConnector.libraryGetDocumentDownloadUrl(this.document.id)
        .then(fileUrl => {
          let fileNameSplit = this.document.fileKey.split('/')
          let fileName = fileNameSplit[fileNameSplit.length - 1]
          commonService.downloadUrl(fileUrl, fileName)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  },
  computed: {
    publishDateLbl: function () {
      return moment(this.document.publishDate).format('DD/MM/YYYY')
    }
  }
}
</script>
