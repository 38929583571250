import { uuid } from 'vue-uuid'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    nonce: null,
    state: null,
    accessToken: null,
    expiresAt: null,
    destinationRoute: null
  },
  mutations: {
    generateNonce (state) {
      state.nonce = uuid.v4()
    },
    clearNonce (state) {
      state.nonce = null
    },
    generateState (state) {
      state.state = uuid.v4()
    },
    clearState (state) {
      state.state = null
    },
    setExpiresAt (state, expiresAt) {
      state.expiresAt = expiresAt
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
    },
    setDestinationRoute (state, destinationRoute) {
      state.destinationRoute = destinationRoute
    },
    clear (state) {
      state.nonce = null
      state.state = null
      state.accessToken = null
      state.expiresAt = null
      state.destinationRoute = null
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.accessToken != null && moment(state.expiresAt) > moment()
    },
    nonce: state => {
      return state.nonce
    },
    state: state => {
      return state.state
    },
    accessToken: state => {
      return state.accessToken
    },
    expiresAt: state => {
      return state.expiresAt
    },
    destinationRoute: state => {
      return state.destinationRoute
    }
  }
}
