<template>
  <div class="noResponseInfoClient">
    <div class="grid-x">
      <span class="cell small-12 large-12 prestaCardInfo infoWithStep">
        {{ $t("notification.noResponseClientInfo", { step: stopAndGoState }) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResponseClientInfo",
  props: { notification: Object },
  computed: {
    stopAndGoState: function () {
      if (this.notification.context.stopAndGoState.startsWith("STARTED")) return this.$t("notification.theStart")
      else if (this.notification.context.stopAndGoState.startsWith("COMPLETED")) return this.$t("notification.theEnd")
      else return ""
    },
  },
}
</script>
