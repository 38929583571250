<template>
  <div class="formElement" @click="onChangeEvent">
    <label v-if="showLabel">{{ $t('user.status') }}</label>
    <div class="formRadio" :class="currentValue">
      <label><input type="radio" disabled value="ACTIVE" v-model="currentValue"><span>{{$t('user.statusList.ACTIVE') }}</span></label>
      <label><input type="radio" disabled value="INACTIVE" v-model="currentValue"><span>{{$t('user.statusList.INACTIVE') }}</span></label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserStatusToogle',
  props: { value: String, showLabel: Boolean },
  data () {
    return {
      currentValue: null
    }
  },
  created () {
    this.currentValue = this.value
  },
  methods: {
    onChangeEvent: function () {
      this.currentValue = this.currentValue === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  }
}
</script>
