/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 9,
    height: 7,
    viewBox: '0 0 9 7',
    data: '<path pid="0" d="M3.534 6.6L.4 3.54l1.495-1.46 1.64 1.6L7.304 0 8.8 1.46 3.534 6.6z"/>'
  }
})
