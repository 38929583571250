<template>
  <div class='socaContent'>
    <div data-alert class="alertBox warning">{{ $t('common.forbidden') }}</div>
  </div>
</template>

<script>
export default {
  name: 'AuthError'
}
</script>
