/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 40,
    height: 38,
    viewBox: '0 0 40 38',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M36 2H4a2 2 0 00-2 2v29.546l4.535-4.03A6 6 0 0110.52 28H36a2 2 0 002-2V4a2 2 0 00-2-2zM7.863 31.01A4 4 0 0110.521 30H36a4 4 0 004-4V4a4 4 0 00-4-4H4a4 4 0 00-4 4v34l7.863-6.99z" _fill="#1E1E28"/>'
  }
})
