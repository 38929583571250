<template>
  <button id="headerNotifZone" data-toggle="notificationPanel" :class="{ 'actionNeeded': $store.getters['notifications/numberWithRank']('ACTION') > 0 }">
    <div id="notificationButton" :title="`${$t('navigation.notification') }`">
      <div v-if="$store.getters['notifications/number'] > 0" :class="{ 'notificationNumber': true, 'highlight': highlightNumber }">{{  $store.getters['notifications/numberWithRank']('ACTION') > 0 ? '!' : $store.getters['notifications/number'] }}</div>
      <svgicon icon="notif"/>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Notification',
  props: {
  },
  data: function () {
    return {
      highlightNumber: false
    }
  },
  mounted: function () {
    const self = this
    this.$store.watch(
      (state, getters) => getters['notifications/number'],
      (newValue, oldValue) => {
        if (newValue > oldValue) {
          self.highlightNumber = true
          setTimeout(() => { self.highlightNumber = false }, 800) // remove highlight after 1sec
        }
      }
    )
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
