<template>
<div class="infoPanel prestationPanel panelIsCompleted">
  <div class="infoPanelLine botBd infoPanelTitle">
    <span class="stationName">{{ prestation.garePresta.name }}</span>
    <router-link :to="{ name: 'prestationDetail', params: { herokuExternalId: prestation.herokuExternalId }}" v-if="canAccessStation && !showAsCurrentPresta" class="button-theme-round-light no-padding-in-small floatRight">
      <svgicon icon="arrow_right" class="button-icon"/><span class="button-text hide-for-small-only">{{$t('prestation.file')}}</span>
    </router-link>
    <span class="floatRight button-theme-round-light prestationIsCurrent" v-if="showAsCurrentPresta">{{$t('prestation.current')}}</span>
  </div>
  <div class="infoPanelLine botBd">{{ datePrestationDateLbl }}
    <div class="panelLineRight">
      <div :class="`prestationStatus prestationStatus-${prestation.statusTN}`" v-if="prestation.statusTN !== null">
        {{ $t("prestation.status." + prestation.statusTN) }}
      </div>
    </div>
  </div>
  <div v-if="prestation.state !== undefined && !prestation.autoAccepted && !isOnBoard">
    <div :class="{
          'alertBox prestationState' : true,
          'wait' : prestation.state === null,
          'success' : prestation.state === $sncfRefs.getPrestaStatesByTechnicalName().accepted,
          'alert' : prestation.state === $sncfRefs.getPrestaStatesByTechnicalName().refused
          }">
        <span v-if="prestation.state === $sncfRefs.getPrestaStatesByTechnicalName().accepted">{{$t('prestation.accepted', {name: prestation.garePresta.name, mention: prestation.mention})}}</span>
        <span v-if="prestation.state === $sncfRefs.getPrestaStatesByTechnicalName().refused">{{$t('prestation.refused', {name: prestation.garePresta.name, mention: prestation.mention})}}</span>
        <span v-if="prestation.state === null">{{$t('prestation.waiting', {name: prestation.garePresta.name, mention: prestation.mention})}}</span>
    </div>
  </div>
  <div class="infoPanelLine">
    <TrainNumberBox :prestation="prestation" />
    <div class="panelLineRight">
      <div class="prestationIcons natureAssistanceIcons">
        <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_BORD"><svgicon icon="casquette" class="natureBordIcon"/></div>
        <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_GARE"><svgicon icon="gare" class="natureGareIcon"/></div>
        <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_ROUTE"><svgicon icon="taxi_noir" class="natureGareIcon"/></div>
      </div>
      <span class="mention">{{prestation.mention}}</span>
      <PrestaAssistBox :element="prestation.Parcour" />
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'
import userService from '@/services/userService'
import { PRESTA_NATURE_ASSISTANCE_BORD, PRESTA_NATURE_ASSISTANCE_GARE, PRESTA_NATURE_ASSISTANCE_ROUTE } from '../../utils/constantsUtils'

export default {
  name: 'Segment',
  props: {
    prestation: Object,
    account: Object,
    showAsCurrentPresta: { type: Boolean, default: false }
  },
  data () {
    return {
      canAccessStation: false,
      PRESTA_NATURE_ASSISTANCE_BORD,
      PRESTA_NATURE_ASSISTANCE_GARE,
      PRESTA_NATURE_ASSISTANCE_ROUTE
    }
  },
  mounted () {
    if (this.$store.getters.possibleStations.map(s => s.codeUic).includes(this.prestation.garePresta.codeUic) ||
      this.$perms.isUserAllowed('presta.stations.all')) {
      this.canAccessStation = true
    }
  },
  computed: {
    datePrestationDateLbl: function () {
      if (this.prestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('DD MMMM YYYY HH:mm')
      }
      return null
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  filters: {
    hour: function (date) {
      if (!date) return ''
      return moment(date).format('HH:mm')
    }
  },
  components: { PrestaAssistBox, TrainNumberBox }
}
</script>
