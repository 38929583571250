<template>

  <socamodal v-if='isLoaded'
    :title="$t('prestation.client.information')"
    @close="closeModal"
    @submit="validate">
    <template v-slot:header>
      <div class="modalHeaderInfo hide-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
    </template>
    <form>
      <div class="prestationAccountForm">
        <div class="grid-x grid-margin-x">
          <div class="small-12 medium-6 cell">
              <div class="full-width">
                  <div :class="{'isAccountChosen': accountExists}">
                    <span @click="resetAccount" v-if='accountExists' class="closeFile">&times;</span>
                    <div class="accountTitle">
                        <label>{{ $t('account.title') }}
                            <select v-model="account.civility" :disabled="accountExists">
                            <option v-for="civility in civilities" :value="civility" :key="civility">{{ $t('common.civility.'+$sncfRefs.getCivilityTechnicalNameByLabel(civility)) }}</option>
                            </select>
                            <small v-if="validation.hasError('account.civility')" class="error">{{ $t('error.field.mandatory') }}</small>
                        </label>
                    </div>
                    <div class="accountFirstname">
                        <label>{{ $t('account.firstname') }}
                        <input type="text" :placeholder="$t('account.firstname')" v-model="account.firstname" :disabled="accountExists">
                        </label>
                    </div>
                    <div class="accountLastname">
                        <label>
                            <span v-tooltip="{
                                    content: 'Si le client refuse de communiquer son nom, indiquez Anonyme',
                                    trigger: 'hover click',
                                }">
                            {{ $t('account.lastname') }}
                            </span>
                        <input type="text" :placeholder="$t('account.lastname')" v-model="account.lastname" :disabled="accountExists">
                        <small v-if="validation.hasError('account.lastname')" class="error">{{ $t('error.field.mandatory') }}</small>
                        </label>
                    </div>
                  </div>
                  <div class="accountPhone">
                      <label>
                          <span v-tooltip="{
                                  content: 'Si le client refuse de communiquer son n° de téléphone, laissez le champ vide.',
                                  trigger: 'hover click',
                              }">
                          {{ $t('account.phone') }}
                          </span>
                      <input type="tel" :placeholder="$t('account.phone')" v-model="account.phone">
                      </label>
                  </div>
                  <div class="accountEmail">
                      <label>{{ $t('account.email') }} ({{ $t('common.field.optionnal') }})
                      <input type="email" :placeholder="$t('account.email')" v-model="account.email">
                      <small v-if="validation.hasError('account.email')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
                      </label>
                  </div>
              </div>
          </div>

          <div class="small-12 medium-6 cell">
              <div class="full-width">
                  <div class="accountTypeAssist">
                      <label>{{ $t('account.typeAssist') }} <small v-if="validation.hasError('account.typeAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                      <select v-model="account.typeAssist" @change="onChangeType($event.target.value)">
                          <option :key="option.complete" v-for="option in typeAssist" :value="option.acronym">{{option.allLetters}}</option>
                      </select>
                      </label>
                  </div>
                  <div class="accountSpecAssist">
                      <label>{{ $t('account.specAssist') }} <small v-if="validation.hasError('account.specAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                      <select v-model="account.specAssist">
                          <option :value="null">-</option>
                          <option :key="spec.complete" v-for="spec in specAssist" :value="spec.acronym">{{spec.allLetters}}</option>
                      </select>
                      </label>
                  </div>
                  <div class="accountDetailAssist">
                      <label>{{ $t('account.detailAssist') }}
                      <select v-model="account.detailAssist">
                          <option :value="null">-</option>
                          <option :key="detail.complete" v-for="detail in detailAssist" :value="detail.acronym">{{detail.allLetters}}</option>
                      </select>
                      </label>
                  </div>
                  <div class="accountSpecAssistSecondary">
                      <label>{{ $t('account.specAssistSecondary') }}
                      <select v-model="account.specAssistSecondary" @change="onChangeSpecSecondary($event.target.value)">
                          <option :value="null">-</option>
                          <option :key="spec.complete" v-for="spec in specAssistSecondary" :value="spec.acronym">{{spec.allLetters}}</option>
                      </select>
                      </label>
                  </div>
                  <div class="accountDetailAssistSecondary">
                      <label>{{ $t('account.detailAssistSecondary') }}
                      <select v-model="account.detailAssistSecondary">
                          <option :value="null">-</option>
                          <option :key="detail.complete" v-for="detail in detailAssistSecondary" :value="detail.acronym">{{detail.allLetters}}</option>
                      </select>
                      </label>
                  </div>
                  <div class="parcoursBagageNumber" v-if="!isOnBoard">
                    <label><span v-tooltip="{
                    content: $t('tooltip.bagage'),
                    trigger: 'hover click',
                  }">{{ $t('parcours.bagageNumber') }}</span>
                        <input type="number" min="0" :placeholder="$t('parcours.bagageNumber')" v-model="parcours.bagage">
                        <small v-if="validation.hasError('parcours.bagage')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
                    </label>
                  </div>
              </div>
          </div>
          <div class="mandatoryElementInfo show-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
          </div>
      </div>
    </form>
  </socamodal>
</template>

<script>
import assist from '@/services/assist'
import moment from 'moment'
import SimpleVueValidation from 'simple-vue-validator'
import userService from '@/services/userService'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'StationPrestaForm',
  props: { accountData: Object, parcoursData: Object },
  data () {
    return {
      account: null,
      parcours: null,
      civilities: [],
      typeAssist: [],
      specAssist: [],
      specAssistSecondary: [],
      detailAssist: [],
      detailAssistSecondary: [],
      isLoaded: false
    }
  },
  mounted () {
    this.account = this._.cloneDeep(this.accountData)
    this.parcours = this._.cloneDeep(this.parcoursData)
    this.civilities = this.$sncfRefs.getCivilities()
    this.typeAssist = this.$sncfRefs.getTypeAssist()
    this.specAssistSecondary = this.$sncfRefs.getSpecAssist()
    if (this.accountData.typeAssist) {
      this.account.typeAssist = this.accountData.typeAssist
      this.specAssist = assist.getSpecAssist(this.accountData.typeAssist)
      this.account.specAssist = this.accountData.specAssist
      this.detailAssist = assist.getDetailAssist(this.accountData.typeAssist)
      this.account.detailAssist = this.accountData.detailAssist
      this.account.specAssistSecondary = this.accountData.specAssistSecondary
      if (this.account.specAssistSecondary) {
        this.detailAssistSecondary = assist.getDetailAssistSecondary(this.accountData.specAssistSecondary)
        this.account.detailAssistSecondary = this.accountData.detailAssistSecondary
      }
    }
    this.isLoaded = true
  },
  computed: {
    accountExists () {
      return !!this.account.sfid
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  methods: {
    resetAccount () {
      this.account.sfid = null
      this.account.herokuExternalId && delete this.account.herokuExternalId
      this.account.socaUser && delete this.account.socaUser
    },
    onChangeType (val) {
      this.specAssist = assist.getSpecAssist(val)
      this.detailAssist = assist.getDetailAssist(val)
      this.account.specAssist = null
      this.account.detailAssist = null
    },
    onChangeSpecSecondary (val) {
      if (val === '' || val === null) {
        this.detailAssistSecondary = null
        this.account.detailAssistSecondary = null
      } else {
        this.detailAssistSecondary = assist.getDetailAssistSecondary(val)
        if (!this.detailAssistSecondary.map(d => d.acronym).includes(this.account.detailAssistSecondary)) {
          this.account.detailAssistSecondary = null
        }
      }
    },
    closeModal () {
      this.$emit('closeModal')
    },
    validate () {
      const self = this
      this.$validate().then(function (success) {
        if (success) {
          self.$emit('saveAccount', { account: self.account, parcours: self.parcours })
          self.closeModal()
        }
      })
    }
  },

  validators: {
    'account.civility': function (value) {
      return Validator.value(value).required()
    },
    'account.lastname': function (value) {
      return Validator.value(value).required()
    },
    'account.email': function (value) {
      return Validator.value(value).email()
    },
    'account.typeAssist': function (value) {
      return Validator.value(value).required()
    },
    'account.specAssist': function (value) {
      return Validator.value(value).required()
    },
    'parcours.bagage': function (value) {
      return !this.isOnBoard && Validator.value(value).integer().greaterThanOrEqualTo(0)
    }
  },
  filters: {
    hour: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  components: { }
}
</script>
