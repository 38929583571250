<template>
  <div v-if="station" class="stationInfo">
    <div :class="{ 'stationInfoContent': true, 'hideContent': !showMore, 'showContent': showMore }" ref="stationInfoContent">
      <div data-alert class="alertBox warning" v-if="station.extraInfo">
        {{ station.extraInfo }}
      </div>
      <div v-if="!station.compatibleHardware" data-alert class="alertBox warning">
        <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ $t('station.noComptibleHardware') }}</span>
      </div>
      <div v-if="!station.soca" data-alert class="alertBox alert">
        {{ $t('common.nosoca')}}
      </div>
      <div v-if="!station.garanteed" data-alert class="alertBox alert">
        {{ $t('common.nogarantee')}}
      </div>
      <div v-if="!station.spontaneous" data-alert class="alertBox alert">
        {{ $t('common.nospontaneous')}}
      </div>
      <div v-for="alert in station.alerts" :key="alert.id">
        <div class="alertBox alert" data-alert>
          <span class="alertBoxTitle">{{$t('station.alertTypeList.' + alert.type)}}</span>
          <template v-if="!isTransporter">
            <span v-if="alert.solution">{{ alert.solution }}</span>
          </template>
          <template v-else>
            <span v-if="alert.description">{{ alert.description }}</span>
          </template>
        </div>
      </div>
      <div>
        <StationTags :station="station"></StationTags>
        <div class="assistActorCard" v-for="assistActor in station.assistActors" :key="assistActor.herokuExternalId">
          <div class="responsible">
            <span class="resp">{{ $t('station.resp')}}: {{ $sncfRefs.getAssistResponsible(assistActor.gcActorCode !== null) }}</span>
            <span class="incumbent">{{ $t('station.incumbent')}}: {{ assistActor.gcActorCode !== null ? assistActor.gcActor.name : assistActor.transporter.name }}</span>
          </div>
          <div class="stationBlockInfoLine1">{{$t('station.handicaps')}}</div>
          <div class="pmrIcons">
            <div class="pmrIconWrapper" :class="{ isActive: assistActor.handicapMoteur }"><svgicon class="pmrIcon" icon="handicap"/><span class="checkMark"><svgicon icon="check"/></span></div>
            <div class="pmrIconWrapper" :class="{ isActive: assistActor.handicapVisuel }"><svgicon class="pmrIcon" icon="eye"/><span class="checkMark"><svgicon icon="check"/></span></div>
            <div class="pmrIconWrapper" :class="{ isActive: assistActor.handicapMental }"><svgicon class="pmrIcon" icon="face"/><span class="checkMark"><svgicon icon="check"/></span></div>
            <div class="pmrIconWrapper" :class="{ isActive: assistActor.handicapAudio }"><svgicon class="pmrIcon" icon="ear"/><span class="checkMark"><svgicon icon="check"/></span></div>
            <div class="pmrIconWrapper" :class="{ isActive: assistActor.handicapAutre }"><svgicon class="pmrIcon" icon="canne"/><span class="checkMark"><svgicon icon="check"/></span></div>
          </div>
          <div class="stationBlockInfoLine1" v-if="showStationHours(assistActor)">{{$t('station.agentTimetable')}}</div>
          <Hours class="stationBlockInfoLine2" :assistActor="assistActor" />
          <div class="stationPhones">
          <div v-if="assistActor.external1">
            <svgicon icon="phone"/>
            <span>{{ $t('station.extPhone') }} :&nbsp;</span>
            <a :href="'tel:'+ assistActor.external1">{{ assistActor.external1 }}</a>
          </div>
          <div v-if="assistActor.mobile1">
            <svgicon icon="phone"/>
            <span>{{ $t('station.mobilePhone') }} :&nbsp;</span>
            <a :href="'tel:' + assistActor.mobile1">{{ assistActor.mobile1 }}</a>
          </div>
        </div>
        </div>
        <div class="stationBlockInfoLine1">{{$t('station.substituteHandicaps')}}</div>
        <div class="pmrIcons">
          <div class="pmrIconWrapper" :class="{ isActive: station.handicapMoteurTaxi }"><svgicon class="pmrIcon" icon="handicap"/><span class="checkMark"><svgicon icon="check"/></span></div>
          <div class="pmrIconWrapper" :class="{ isActive: station.handicapVisuelTaxi }"><svgicon class="pmrIcon" icon="eye"/><span class="checkMark"><svgicon icon="check"/></span></div>
          <div class="pmrIconWrapper" :class="{ isActive: station.handicapMentalTaxi }"><svgicon class="pmrIcon" icon="face"/><span class="checkMark"><svgicon icon="check"/></span></div>
          <div class="pmrIconWrapper" :class="{ isActive: station.handicapAudioTaxi }"><svgicon class="pmrIcon" icon="ear"/><span class="checkMark"><svgicon icon="check"/></span></div>
          <div class="pmrIconWrapper" :class="{ isActive: station.handicapAutreTaxi }"><svgicon class="pmrIcon" icon="canne"/><span class="checkMark"><svgicon icon="check"/></span></div>
        </div>
        <div class="stationBlockInfoLine1" v-if="showSubstituteStationHours">{{$t('station.substituteTime')}}</div>
        <Hours class="stationBlockInfoLine2" :station="station" />
      </div>
    </div>
    <div class="showMore show-for-small-only"><a class="button" @click="showMoreClick">{{ showMore ? $t('common.showLess') : $t('common.showMore') }}</a></div>
  </div>
</template>

<script>
import StationTags from '@/components/Stations/Tags'
import Hours from '@/components/Stations/Hours'

export default {
  name: 'StationInfo',
  props: { station: Object },
  components: { StationTags, Hours },
  data: function () {
    return {
      showMore: false
    }
  },
  computed: {
    showSubstituteStationHours: function () {
      return (
        this.station.mondayTaxi ||
        this.station.tuesdayTaxi ||
        this.station.wednesdayTaxi ||
        this.station.thursdayTaxi ||
        this.station.fridayTaxi ||
        this.station.saturdayTaxi ||
        this.station.sundayTaxi ||
        this.station.ferieTaxi
      )
    },
    isTransporter () {
      return this.$store.getters.userRole === "TRANSPORTER"
    }
  },
  methods: {
    showMoreClick () {
      this.showMore = !this.showMore

      // set a specific height to make animation works
      if (this.showMore) {
        let totalHeight = 0
        $(this.$refs.stationInfoContent).children().each(function () {
          totalHeight = totalHeight + $(this).outerHeight(true)
        })
        this.$refs.stationInfoContent.style.height = Math.ceil(totalHeight) + 'px'
      } else {
        this.$refs.stationInfoContent.style.height = null
      }
    },
    showStationHours: function (assistActor) {
      return (
        assistActor.mondayAgent ||
        assistActor.tuesdayAgent ||
        assistActor.wednesdayAgent ||
        assistActor.thursdayAgent ||
        assistActor.fridayAgent ||
        assistActor.saturdayAgent ||
        assistActor.sundayAgent ||
        assistActor.ferieAgent ||
        assistActor.mondayAgent2 ||
        assistActor.tuesdayAgent2 ||
        assistActor.wednesdayAgent2 ||
        assistActor.thursdayAgent2 ||
        assistActor.fridayAgent2 ||
        assistActor.saturdayAgent2 ||
        assistActor.sundayAgent2 ||
        assistActor.ferieAgent2 ||
        assistActor.mondayAgent3 ||
        assistActor.tuesdayAgent3 ||
        assistActor.wednesdayAgent3 ||
        assistActor.thursdayAgent3 ||
        assistActor.fridayAgent3 ||
        assistActor.saturdayAgent3 ||
        assistActor.sundayAgent3 ||
        assistActor.ferieAgent3 ||
        assistActor.mondayAgentClosed ||
        assistActor.tuesdayAgentClosed ||
        assistActor.wednesdayAgentClosed ||
        assistActor.thursdayAgentClosed ||
        assistActor.fridayAgentClosed ||
        assistActor.saturdayAgentClosed ||
        assistActor.sundayAgentClosed ||
        assistActor.ferieAgentClosed
      )
    },
  }
}
</script>
