/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 43,
    height: 43,
    viewBox: '0 0 43 43',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M43 4.3L38.7 0 21.5 17.2 4.3 0 0 4.3l17.2 17.2L0 38.7 4.3 43l17.2-17.2L38.7 43l4.3-4.3-17.2-17.2L43 4.3z" _fill="#1E1E28"/>'
  }
})
