<template>
  <div class="adminPage">
    <div class="adminHeader">
        <div class="pageTitle">
          <div class="socaContent adminContent">
            <div class="headerTitle">
              {{$t('news.list.title') }}
            </div>
            <router-link class="addNewsButton button-theme-round-light" :to="{name: 'newsCreation'}">{{ $t('common.create') }}</router-link>
          </div>
        </div>
      </div>
      <div class="socaContent adminContent">
        <NewsListItems :news="news" />
      </div>
  </div>
</template>

<script>
import NewsListItems from '@/components/News/ListItems'

export default {
  name: 'NewsList',
  components: { NewsListItems },
  data () {
    return {
      news: null
    }
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    fetchNews () {
      this.$backendConnector.getAllNews()
        .then(res => { this.news = res.sort((a, b) => (a.startDate < b.startDate) ? 1 : -1) })
    }
  }
}
</script>
