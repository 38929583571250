<template>
  <div>{{$t('prestation.report.bloqued')}} {{ station.name }} <a :href="'tel:' + station.external1"><svgicon icon="phone"/></a></div>
</template>

<script>
export default {
  name: 'ReportImpossible',
  props: { contactStationUIC: String },
  data () {
    return {
      station: {}
    }
  },
  mounted () {
    this.updateStation()
  },
  methods: {
    updateStation () {
      this.$backendConnector.getGareByUic(this.contactStationUIC)
        .then(res => (this.station = res))
    }
  }
}
</script>

<style scoped>
  div {
    float: right;
    padding: 4px 10px;
    background: #FFB612;
    border-radius: 15px;
  }
</style>
