<template>
  <div id="footerNav" class="hide-for-large" v-if="$store.state.showFooter">
    <div class="centeredContent">
      <router-link :to="{name: 'prestations'}" class="footerElement" id="footerGotoPrestas">
        <svgicon icon="logo_soca"/>
        <div class="footerElementLbl">{{ $t('navigation.footer.prestations') }}</div>
      </router-link>
      <router-link :to="{name: 'planning'}" class="footerElement" id="footerPlanning">
        <svgicon icon="menu"/>
        <div class="footerElementLbl">{{ $t('navigation.footer.planning') }}</div>
      </router-link>
      <router-link :to="{name: 'PrestationCreation'}" class="footerElement" id="footerCreate">
        <svgicon icon="add"/>
        <div class="footerElementLbl">{{ $t('navigation.footer.create') }}</div>
      </router-link>
      <router-link :to="{name: 'station'}" class="footerElement" id="footerStation">
        <svgicon icon="station"/>
        <div class="footerElementLbl">{{ $t('navigation.footer.station') }}</div>
      </router-link>
      <router-link :to="{name: 'newsPublic'}" class="footerElement" id="footerNews">
        <svgicon icon="news"/>
        <span class="newsFooterUnreadIndicator" v-if="$store.getters.hasUnreadNews"></span>
        <div class="footerElementLbl">{{ $t('navigation.menu.news') }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
