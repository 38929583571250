import { render, staticRenderFns } from "./EnvPill.vue?vue&type=template&id=0438ca16&scoped=true"
import script from "./EnvPill.vue?vue&type=script&lang=js"
export * from "./EnvPill.vue?vue&type=script&lang=js"
import style0 from "./EnvPill.vue?vue&type=style&index=0&id=0438ca16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0438ca16",
  null
  
)

export default component.exports