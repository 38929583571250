<template>
  <div class="modalContainer socaContent" @click="modalCancel">
      <div class="lightModal" @click.stop>
        <div class="modalHeader">
          <div class="modalTitle">{{ title }}</div>
        </div>
        <div class="modalContent">
          <slot />
        </div>
        <div class="modalFooter">
          <div class="grid-x grid-margin-x">
            <div class="cell small-6">
              <button @click="modalCancel" class="button-theme-secondary">{{$t('common.cancel')}}</button>
            </div>
            <div class="cell small-6">
              <button @click="modalConfirm" class="button-theme">{{$t('common.confirm')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String
    }
  },
  methods: {
    modalCancel: function () {
      this.$emit('cancel')
    },
    modalConfirm: function () {
      this.$emit('confirm')
    }
  }
}
</script>
