<script>
import authService from '@/services/authService'

export default {
  name: 'AuthComponent',
  render () {
    return ''
  },
  computed: {
    oidcIsAuthenticated: function () {
      return authService.isAuthenticated()
    }
  },
  mounted () {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.addEventListener('vuexoidc:oidcError', this.oidcError)
  },
  destroyed () {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.removeEventListener('vuexoidc:oidcError', this.oidcError)
  },
  methods: {
    userLoaded: function (e) {
      console.log('userLoaded', e)
    },
    oidcError: function (e) {
      console.log('I am listening to the oidc error event in vuex-oidc', e.detail)
    }
  }
}
</script>
