<template>
  <div class="off-canvas position-left reveal-for-large" id="mainMenuNav" data-off-canvas>
    <button id="mainMenuClose" :aria-label="`${$t('navigation.closemenu')}`" type="button" data-close class="hide-for-large">
      <svgicon icon="close" />
    </button>
    <div id="mainMenuUserZone">
      <div v-if="userLoaded">
        <span>{{ $store.getters.userFullName }}</span>
        <span>{{ displayRoleLabel }}</span>
      </div>
    </div>
    <div id="mainMenuUserDisconnect">
      <a v-if="$store.getters.isImpersonated" href @click.prevent="disableImpersonation">{{ $t("navigation.disconnect") }}</a>
      <SignOut v-if="!$store.getters.isImpersonated && $store.getters['auth/isAuthenticated']">
        <a slot-scope="{ oidcIsAuthenticated, signOut }" v-if="!$store.getters.isImpersonated" href @click.prevent="signOut">{{ $t("navigation.disconnect") }}</a>
      </SignOut>
      <router-link v-if="!$store.getters.isImpersonated && !$store.getters['auth/isAuthenticated']" :to="{ name: 'home' }">{{ $t("navigation.connect") }}</router-link>
      <div v-if="isOnBoard" class="onBoardTrain">
        <span class="onBoardTrainNumber">{{ $t('navigation.trainNumber') }} {{ trainNumber }}</span>
        <button class="changeTrainNumber" @click="resetTrainNumber" data-close>Modifier</button>
      </div>
    </div>
    <div id="mainMenuNavContent">
      <div id="mainMenuActions">
        <router-link :to="{ name: 'PrestationCreation' }" id="menuActionCreate" v-if="canCreatePrestation" class="hide-for-small-only hide-for-medium-only">
          <svgicon icon="add" />
          <span>{{ $t("navigation.footer.create") }}</span>
        </router-link>
        <router-link :to="{ name: 'prestations' }" id="menuActionGotoPrestas" class="hide-for-small-only hide-for-medium-only">
          <svgicon icon="logo_soca" />
          <span>{{ $t("navigation.footer.prestations") }}</span>
        </router-link>
        <router-link :to="{ name: 'planning' }" id="menuActionPlanning" class="hide-for-small-only hide-for-medium-only">
          <svgicon icon="menu" />
          <span>{{ $t("navigation.footer.planning") }}</span>
        </router-link>
        <router-link :to="{ name: 'station' }" id="menuActionStation" class="hide-for-small-only hide-for-medium-only">
          <svgicon icon="station" />
          <span>{{ $t("navigation.footer.station") }}</span>
        </router-link>
        <router-link :to="{ name: 'newsPublic' }" id="menuActionNewsPublic" class="hide-for-small-only hide-for-medium-only">
          <svgicon icon="news" />
          <span>
            {{ $t("navigation.menu.news") }}
            <span class="newsMenuUnreadIndicator" v-if="$store.getters.hasUnreadNews"></span>
          </span>
        </router-link>
        <router-link :to="{ name: 'library' }" id="menuActionLibrary">
          <svgicon icon="library" />
          <span>{{ $t("navigation.menu.library") }}</span>
        </router-link>
      </div>
      <div id="mainMenuList" v-if="permAdmin || permNews || permFeatureFlipping">
        {{ $t('navigation.menu.admin') }}
        <router-link :to="{name: 'users'}" id="menuActionAdmin" v-if="permAdmin">
          <svgicon icon="shield"/><span>{{ $t('navigation.menu.users') }}</span>
        </router-link>
        <router-link :to="{ name: 'news' }" id="menuActionNews" v-if="permNews">
          <svgicon icon="news" />
          <span>{{ $t("navigation.menu.news") }}</span>
        </router-link>
        <router-link :to="{name: 'featureFlipping'}" id="menuActionFeatureFlipping" v-if="permFeatureFlipping">
          <svgicon icon="star"/><span>{{ $t('navigation.menu.featureFlipping') }}</span>
        </router-link>
      </div>
    </div>
    <div id="mainMenuUserAssistance">
      <UserAssistance />
    </div>
    <div id="mainMenuLocaleChanger">
      <LocaleChanger />
    </div>
    <div id="mainMenuVersionNumber">v{{ version }}</div>
  </div>
</template>

<script>
import SignOut from '@/components/Auth/SignOut'
import LocaleChanger from './Menu/LocaleChanger'
import UserAssistance from '@/components/Common/UserAssistance'
import pwaService from '@/services/pwa'
import userService from '@/services/userService'
import { version } from '../../../package.json'

export default {
  name: 'MainMenu',
  components: {
    SignOut,
    LocaleChanger,
    UserAssistance
  },
  computed: {
    userLoaded () {
      return this.$store.getters.userLoaded
    },
    permAdmin: function () {
      return this.$perms.isUserAllowed('users.all') || this.$perms.isUserAllowed('users.restricted.roles')
    },
    permNews: function () {
      return this.$perms.isUserAllowed('news.creation')
    },
    displayRoleLabel: function () {
      return this.$sncfRefs.getUserRoles()[this.$store.getters.userRole]
    },
    permFeatureFlipping: function () {
      return this.$perms.isUserAllowed('feature_flipping.update')
    },
    trainNumber() {
      return this.$store.getters.trainNumber
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  data () {
    return {
      canCreatePrestation: true,
      version
    }
  },
  mounted () {
    $(this.$el).foundation()
    $('#mainMenuNav a').click(function () {
      $('#mainMenuNav').foundation('close')
    })
    this.checkIfCanCreate()
    // Synchro to context stations
    this.$store.watch(
      (state, getters) => getters.selectedStations,
      (newValue, oldValue) => {
        this.checkIfCanCreate()
      }
    )
  },
  methods: {
    promptPwaInstall: function (e) {
      pwaService.promptInstall()
    },
    checkIfCanCreate: function () {
      let stations = this.$store.getters.selectedStation ? this.$store.getters.selectedStation : this.$store.getters.possibleStations
      this.canCreatePrestation = this.$perms.isUserAllowed('presta.creation')
      if (!this.$perms.isUserAllowed('presta.creation.garanteed') && !stations.spontaneous && stations.garanteed) {
        this.canCreatePrestation = false
      }
    },
    disableImpersonation: function () {
      let self = this
      this.$store.commit('setImpersonationUserCode', null)
      userService.loadUserInfos().then((data) => {
        self.$router.go() // refresh page
      })
    },
    resetTrainNumber() {
      this.$store.commit('clearOnBoardRole')
      this.$store.commit('homeFilters/resetModalFilters')
      this.$store.commit('homeFilters/modalFiltered', false)
      this.$store.commit('resetStationsUser')
    }
  }
}
</script>

<style scoped></style>
