/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" d="M.214 1.27a.751.751 0 010-1.052.724.724 0 011.036 0l4.142 4.205a.751.751 0 010 1.051.724.724 0 01-1.035 0L.214 1.27z" _fill="#fff"/><path pid="1" d="M.214 1.27a.751.751 0 010-1.052.724.724 0 011.036 0l4.142 4.205a.751.751 0 010 1.051.724.724 0 01-1.035 0L.214 1.27z" _fill="url(#paint0_linear)"/><path pid="2" d="M8.75.526a.724.724 0 011.036 0 .751.751 0 010 1.051L5.643 5.782a.724.724 0 01-1.035 0 .751.751 0 010-1.051L8.75.526z" _fill="#fff"/><path pid="3" d="M8.75.526a.724.724 0 011.036 0 .751.751 0 010 1.051L5.643 5.782a.724.724 0 01-1.035 0 .751.751 0 010-1.051L8.75.526z" _fill="url(#paint1_linear)"/><defs><linearGradient id="paint0_linear" x1="5" y1="0" x2="5" y2="6" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="paint1_linear" x1="5" y1="0" x2="5" y2="6" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
