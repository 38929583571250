/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notif': {
    width: 41,
    height: 48,
    viewBox: '0 0 41 48',
    data: '<path pid="0" d="M20.5 48c2.653 0 4.823-2.16 4.823-4.8h-9.646c0 2.64 2.17 4.8 4.823 4.8zm15.676-14.4V20.4c0-7.44-5.064-13.44-12.058-15.12V3.6c0-1.92-1.689-3.6-3.618-3.6-1.93 0-3.618 1.68-3.618 3.6v1.68C9.888 6.96 4.824 12.96 4.824 20.4v13.2L0 38.4v2.4h41v-2.4l-4.824-4.8z" _fill="#1E1E28"/>'
  }
})
