<template>
  <Multiselect v-model="selectedBackupStation" :options="stationsList"
  :searchable="true" :internal-search="false" :options-limit="100"
  @search-change="debouncedSearchStation" @input="selectBackup"
  label="name" track-by="codeUic" :placeholder="`${$t('prestation.station.typeToSearch') }`"
  deselectLabel="" selectLabel="" selectedLabel="">
    <template v-slot:placeholder>{{$t('prestation.station.chooseStation') }}</template>
    <template v-slot:noOptions>{{$t('prestation.station.searchNoResults') }}</template>
    <template v-slot:noResult>{{$t('prestation.station.searchNoResults') }}</template>
    <template v-slot:clear v-if="selectedBackupStation"><svgicon icon="close" class="clear" @click="clearAll"/></template>    <template v-slot:option="props">
      <div class="stationOption">
        <span class="stationOptionName">{{ props.option.name }}</span>
        <span v-if="props.option.fromPiv && (props.option.arrivalTime || props.option.departureTime )" class="pivInfo">
          <span v-if="props.option.arrivalTime">{{ props.option.arrivalTime | hour }}</span>
          <svgicon icon="train" />
          <span v-if="props.option.departureTime">{{ props.option.departureTime | hour }}</span>
        </span>
      </div>
    </template>
    <template v-slot:singleLabel="value">
      <span>{{$t('prestation.station.stationOfX', {station: value.option.name}) }}</span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect/src/Multiselect'
import { debounce } from 'simple-vue-validator/src/utils'

export default {
  name: "SearchStationMultiselect",
  props: [ 'gareRabattement', 'whichBackup' ],
  data () {
    return {
      station: null,
      selectedBackupStation: null,
      stationsSearchResult: [],
      searchStationCallId: 0,
    }
  },
  created() {
    this.debouncedSearchStation = debounce((query) => this.asyncStationSearch(query), 500)
  },
  mounted () {
    $(this.$el).foundation()
    if (this.gareRabattement) {
      this.selectBackup(this.gareRabattement)
    }
  },
  updated() {
    $(this.$el).foundation()
  },
  methods: {
    clearAll () {
      this.selectedBackupStation = null
      this.$emit('updateBackupStation', null, this.whichBackup)
    },
    asyncStationSearch (query) {
      if (!query.length) {
        this.stationsSearchResult = []
        return
      }

      this.searchStationCallId++
      let self = this
      let currentSearchStationCallId = this.searchStationCallId
      this.$backendConnector.searchStations(query)
        .then(function (results) {
          if (self.searchStationCallId !== currentSearchStationCallId) { return }
          self.stationsSearchResult = results.map(v => ({ ...v, fromPiv: false }))
        })
        .catch(err => console.log(err))
    },
    async selectBackup(valSelected) {
      try {
        if (valSelected !== null) {
          this.selectedBackupStation = valSelected
          this.$emit('updateBackupStation', valSelected, this.whichBackup)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  computed: {
    stationsList: function () {
      return this.stationsSearchResult
    },
  },
  components: {
    Multiselect
  }
}
</script>
