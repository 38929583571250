/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 23,
    height: 17,
    viewBox: '0 0 23 17',
    data: '<path pid="0" d="M.719 3.536H7.12A2.871 2.871 0 009.902 5.66c1.34 0 2.46-.906 2.782-2.124h9.597A.712.712 0 0023 2.83a.712.712 0 00-.719-.705h-9.597A2.871 2.871 0 009.902 0c-1.34 0-2.46.906-2.781 2.125H.719A.712.712 0 000 2.83c0 .39.322.706.719.706zM9.902 1.41c.798 0 1.446.636 1.446 1.42 0 .782-.648 1.418-1.446 1.418-.797 0-1.445-.636-1.445-1.419s.648-1.42 1.445-1.42zM22.281 7.768h-1.539a2.871 2.871 0 00-2.782-2.124c-1.34 0-2.46.906-2.781 2.124H.719A.712.712 0 000 8.474c0 .39.322.705.719.705h14.46a2.871 2.871 0 002.781 2.125c1.34 0 2.46-.907 2.782-2.125h1.54A.712.712 0 0023 8.474a.712.712 0 00-.719-.706zm-4.32 2.125c-.798 0-1.447-.637-1.447-1.42 0-.782.649-1.418 1.446-1.418.798 0 1.446.636 1.446 1.419 0 .782-.648 1.419-1.446 1.419zM22.281 13.412H8.355a2.87 2.87 0 00-2.78-2.125c-1.341 0-2.461.907-2.783 2.125H.72a.712.712 0 00-.719.705c0 .39.322.706.719.706h2.073a2.871 2.871 0 002.782 2.124 2.87 2.87 0 002.78-2.124h13.927a.712.712 0 00.719-.706.712.712 0 00-.719-.705zM5.574 15.536c-.797 0-1.446-.636-1.446-1.419 0-.782.649-1.419 1.446-1.419.796 0 1.445.637 1.445 1.42 0 .782-.649 1.418-1.445 1.418z"/>'
  }
})
