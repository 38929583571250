/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thunder': {
    width: 10,
    height: 13,
    viewBox: '0 0 10 13',
    data: '<path pid="0" d="M6.454.03L.29 6.97l3.729.044-1.372 5.37L9.29 5.347H5.16L6.453.03z" _fill="#fff"/><path pid="1" d="M6.454.03L.29 6.97l3.729.044-1.372 5.37L9.29 5.347H5.16L6.453.03z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="4.79" y1=".029" x2="4.79" y2="12.384" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
