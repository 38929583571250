/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'previous': {
    width: 12,
    height: 20,
    viewBox: '0 0 12 20',
    data: '<path pid="0" d="M12 17.642L4.58 10 12 2.358 9.71 0 0 10l9.71 10L12 17.642z"/>'
  }
})
