/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hours': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 16A8 8 0 118 0a8 8 0 010 16zM8 1.23a6.77 6.77 0 100 13.54A6.77 6.77 0 008 1.23z"/><path pid="1" d="M11.385 12a.615.615 0 01-.437-.178L7.563 8.437A.615.615 0 017.385 8V3.077a.615.615 0 111.23 0v4.67l3.207 3.2A.614.614 0 0111.385 12z"/>'
  }
})
