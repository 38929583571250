import _ from 'lodash'
import momentJS from 'moment'
import i18n from '@/plugins/vue-i18n'
import sncfRefs from './sncfRefs'
import userService from '@/services/userService'

export default {
  checkSegmentsMentions: (prestations) => {
    return new Promise(function (resolve, reject) {
      let segs = []
      let d = 0
      let a = 0
      prestations.map(s => {
        segs.push(s.mention)
      })
      for (let i in segs) {
        if (segs[i] === 'D') {
          d++
        } else if (segs[i] === 'A') {
          a++
        }
      }
      let isWrong = (a > 1 || d > 1)
      !isWrong ? resolve(!isWrong) : reject(new Error(i18n.t('prestation.error.onlyOneDepartureAndArrival')))
    })
  },
  checkSegmentsTrainNumber: (prestations) => {
    return new Promise(function (resolve, reject) {
      let hasNoDupes = true
      prestations.filter(p => p.trainNumber !== null).map(prestation => {
        if (prestations.filter(p2 => p2.trainNumber === prestation.trainNumber).length > 2) {
          hasNoDupes = false
        }
      })
      hasNoDupes ? resolve(hasNoDupes) : reject(new Error(i18n.t('prestation.error.trainNumberMustBeUnique')))
    })
  },
  checkSegmentsGare: (prestations) => {
    const error = new Error(i18n.t('prestation.error.maxTwoSameStationInTrip'))
    const errorSameDepAndArrival = new Error(i18n.t('prestation.error.sameDepartureAndArrival'))
    return new Promise(function (resolve, reject) {
      let segs = []
      let gares = []

      prestations.map(s => {
        gares.push(s.garePrestaUic)
        segs.push({ mention: s.mention, gare: s.garePrestaUic })
      })
      // return array of duplicate
      const dupes = gares.reduce((acc, v, i, arr) => arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc, [])
      // if there's duplicates
      if (dupes.length > 0) {
        for (let i in dupes) {
          let obj = segs.filter(o => o.gare === dupes[i])
          if (obj.length > 2) return reject(error) // there can't me more than twice the same train station
          let m = obj.map(o => o.mention)
          if (!m.includes('N')) {
            if (_.isEqual(['A', 'D'], m.sort())) return reject(errorSameDepAndArrival)
            if (!_.isEqual(['AC', 'DC'], m.sort())) return reject(errorSameDepAndArrival)
          }
        }
      }
      return resolve(true)
    })
  },
  checkPresentationTime: (prestation) => {
    return prestation.service !== 'Spontané' || prestation.mention === 'A' || !!prestation.presentationTime
  },
  garantee: (journey) => {
    const orderDate = momentJS(journey.dateAsked)
    const datePrestation = momentJS(journey.datePrestation)
    const service = sncfRefs.getServicesModelsForUpsert().find((service) => journey.service === service.code)
    if (service && orderDate.isValid() && datePrestation.isValid()) {
      let stringDateMiniForGarantee = service.garanteeDate.replaceAll("dateAskedNoTime", orderDate.format("DD/MM/YYYY"))
      stringDateMiniForGarantee = stringDateMiniForGarantee.replaceAll("dateAsked", '"' + orderDate.format("DD/MM/YYYY HH:mm") + '", "DD/MM/YYYY HH:mm"')
      // eslint-disable-next-line no-unused-vars
      const moment = momentJS // Used for the eval execution
      const dateMiniForGarantee = eval(stringDateMiniForGarantee)
      return datePrestation.isAfter(dateMiniForGarantee)
    } else {
      return true
    }
  },
  // Vérifie la cohérence des dates entre les prestations
  checkPrestasDates: (prestations) => {
    return new Promise(function (resolve, reject) {
      let dates = []
      prestations.map(p => {
        if (p.datePrestation) {
          dates.push(p.datePrestation)
        }
      })
      for (let i = 0; i < dates.length - 1; i++) {
        if (momentJS(dates[i]).isAfter(momentJS(dates[i + 1]))) {
          reject(new Error(i18n.t('prestation.error.dateIsAfter')))
          return
        }
      }
      resolve(true)
    })
  },
  checkSeatAndCarForOnBoardPrestations: async function (prestations) {
    const isOnBoardCrew = userService.isOnBoardCrew()

    if (!isOnBoardCrew) return true

    const transportersWithMandatoryCarAndPlace = sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous()

    const results = await Promise.all(prestations.map(async (prestation) => {
      const hasMandatoryInfoMissing = isOnBoardCrew &&
        !prestation.trainCar && !prestation.trainPlace &&
        transportersWithMandatoryCarAndPlace.includes(prestation.transporter) &&
        prestation.transportMode !== sncfRefs.getTransportModesByTechnicalName().taxi

      if (hasMandatoryInfoMissing) {
        throw new Error(i18n.t('prestation.create.error.missingSeatAndCarNumber'))
      }

      return true
    }))

    return results.every(result => result)
  },
  // Vérifie que les mentions et les dates soient cohérentes dans le parcours, sinon renvoie une erreur
  // Affiche les erreurs en direct et renvoie vrai ou faux
  checkTripConsistency: function (prestations) {
    let promises = []
    promises.push(this.checkSegmentsMentions(prestations))
    promises.push(this.checkPrestasDates(prestations))
    promises.push(this.checkSegmentsTrainNumber(prestations))
    promises.push(this.checkSegmentsGare(prestations))
    promises.push(this.checkSeatAndCarForOnBoardPrestations(prestations))
    return Promise.all(promises)
  }
}
