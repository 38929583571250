<template>
  <div id="libraryEditDocument">
    <div class="socaContent">
      <div class="pageTitleLight">{{ $t('library.editDocument') }}</div>
      <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
      <div v-if="!isLoading && document">
        <DocumentForm :documentIn="document" @validate="updateDocument" @cancel="cancel"/>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentForm from '@/components/Library/DocumentForm'
import axios from 'axios'

export default {
  name: 'LibraryEditDocument',
  data () {
    return {
      documentId: null,
      document: null,
      isLoading: false
    }
  },
  props: { },
  mounted () {
    this.documentId = this.$route.params.id
    this.isLoading = true
    return this.$backendConnector.getDocumentById(this.documentId)
      .then(result => {
        result.roles = result.roles.map(r => {
          r.name = this.$t('user.roleList.' + r.code)
          return r
        })
        result.publishDate = new Date(result.publishDate)
        this.document = result
        this.isLoading = false
      })
  },
  methods: {
    updateDocument: function (document, file) {
      // if there is a new version of the document, will send it to s3 and update the filekey
      if (file) {
        // Get a signed request to push file to s3
        this.$backendConnector.libraryGetDocumentUploadUrl(encodeURI(file.name), file.type)
          .then(result => {
            let oldFileKeyToDelete = document.fileKey
            document.fileKey = result.fileKey // save the file key in document for later save
            console.log(oldFileKeyToDelete)
            // Send the file content to s3 via the signed url
            axios.put(result.signedUrl, file, { headers: { 'Content-Type': file.type } })
              .then(response => {
                this.finalUpdateDocument(document)
                console.log(oldFileKeyToDelete)
                this.$backendConnector.deleteLibraryFile(oldFileKeyToDelete) // delete old file to save space
              })
              .catch(err => { console.log('error sending file to s3', err) })
          })
          .catch(err => { console.log('err', err) })
      } else {
        this.finalUpdateDocument(document)
      }
    },
    finalUpdateDocument: function (document) { // send the final updated document (with updated filekey if needed) to the back
      this.$backendConnector.libraryPatchDocument(document)
        .then(res => {
          // message de confirmation + homepage
          this.$toastService.showToast(
            this.$t('library.documentEdited'),
            null,
            'success'
          )
          this.$router.push({ name: 'libraryDetailDocument', params: { id: this.documentId } })
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    cancel: function () {
      this.$router.push({ name: 'libraryDetailDocument', params: { id: this.documentId } })
    }
  },
  components: { DocumentForm }
}
</script>
