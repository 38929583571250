/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'taxi_noir': {
    width: 25,
    height: 23,
    viewBox: '0 0 25 23',
    data: '<g clip-path="url(#clip0_5777_1068)"><path pid="0" d="M9.25.42c-.76 0-1.375.615-1.375 1.376v1.383a4.125 4.125 0 00-3.66 2.755l-1.513 4.318A2.755 2.755 0 001 12.796v8.25c0 .76.614 1.375 1.375 1.375H3.75c.76 0 1.375-.615 1.375-1.375v-2.063h13.75v2.063c0 .76.614 1.375 1.375 1.375h1.375c.76 0 1.375-.615 1.375-1.375v-8.25a2.755 2.755 0 00-1.702-2.544l-1.512-4.318a4.119 4.119 0 00-3.661-2.755V1.796c0-.76-.614-1.375-1.375-1.375h-5.5zm-1.143 5.5h7.786c.584 0 1.104.37 1.298.92l1.121 3.206H5.688L6.809 6.84c.194-.55.714-.92 1.298-.92zm-2.982 6.876a1.375 1.375 0 110 2.75 1.375 1.375 0 010-2.75zM17.5 14.17a1.375 1.375 0 112.75 0 1.375 1.375 0 01-2.75 0z" _fill="#3C3732"/></g><defs><clipPath id="clip0_5777_1068"><path pid="1" _fill="#fff" transform="translate(1 .42)" d="M0 0h22v22H0z"/></clipPath></defs>'
  }
})
