<template>
<div class="infoPanel prestationPanel panelIsCompleted reportCard">
  <div class="infoPanelLine botBd infoPanelTitle">
    <div class="reportTitle"> {{$t('common.station')}}</div>
    <div class="reportButtons" v-if="reportable">
      <toggle-button v-model="prestation.checked" :sync="true" v-if="canToggle" />
      <a v-if="!prestation.herokuExternalId && !prestation.checked" class="report openTrajet" @click="editSegment"><svgicon icon="pencil"/></a>
    </div>
  </div>
  <div class="infoPanelLine botBd">
    <span class="stationName">{{ station.name }}</span>
  </div>
  <div class="infoPanelLine botBd" v-if="datePrestationLbl">
    <span>{{ datePrestationLbl  }}</span>
  </div>
  <div class="infoPanelLine">
    <span class="trainHour">{{ hourPrestationLbl  }}</span>
      <TrainNumberBox :prestation="prestation" />
    <div class="panelLineRight">
      <span class="mention">{{prestation.mention}}</span>
      <PrestaAssistBox :element="parcours"/>
    </div>
  </div>
  <div v-if="openedModal">
      <ReportSegmentForm @closeTrajet="closeModal" @savePrestation="savePrestation" :prestationData="prestation" :availableDates="availableDates"/>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import ReportSegmentForm from '@/components/Prestations/Report/ReportSegmentForm'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'

export default {
  name: 'ReportSegment',
  props: { prestation: Object, parcours: Object, availableDates: Object, reportable: Boolean },
  data () {
    return {
      openedModal: false,
      isToggled: false,
      checkChangeGarantee: null,
      mentions: null,
      canBeReported: null,
      askStatus: false,
      station: {}
    }
  },
  model: {
    prop: 'prestation',
    event: 'update'
  },
  watch: {
    'prestation.garePrestaUic': function () {
      this.updateStation()
    }
  },
  mounted () {
    this.mentions = this.$sncfRefs.getMentionsByTechnicalName()
    this.updateStation()
  },
  methods: {
    updateStation () {
      this.$backendConnector.getGareByUic(this.prestation.garePrestaUic)
        .then(res => (this.station = res))
    },
    editSegment () {
      this.openedModal = true
    },
    openReason () {
      this.askStatus = true
    },
    closeModal () {
      this.openedModal = false
    },
    savePrestation (val) {
      this.$emit('update', val)
      this.updateStation()
    }
  },
  computed: {
    datePrestationLbl: function () {
      if (this.prestation && this.prestation.datePrestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('DD MMMM YYYY')
      }
      return null
    },
    hourPrestationLbl: function () {
      if (this.prestation && this.prestation.datePrestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('HH:mm')
      }
      return null
    },
    canToggle: function () {
      return this.prestation.datePrestation && this.prestation.garePrestaUic && this.prestation.transporter
    }
  },
  components: { ReportSegmentForm, PrestaAssistBox, TrainNumberBox }
}
</script>
