<template>
  <div class="changeStateButtons">
    <div class="grid-x">
      <button class="cell small-12 large-12 prestaCardButton understoodButton" @click="understood">{{$t('notification.understood')}}</button>
    </div>
  </div>
</template>

<script>
import notificationService from '@/services/notification'

export default {
  name: 'NotificationButtonUnderstood',
  props: { notification: Object },
  methods: {
    understood: function () {
      notificationService.notifIsUnderstood(this.notification)
    }
  }
}
</script>
