<template>
<div>
  <router-link :to="{ name: 'prestationDetail', params: { herokuExternalId: prestation.herokuExternalId }}" class="prestationCard grid-x">
    <div class="prestationCardContent">
      <div class="grid-x full-width">
        <div class="cell small-6">
          <span class="datePrestationHour">{{ departureTime }}</span>
           <TrainNumberBox :prestation="prestation" />
          <div v-if="prestation.gareAutre" class="stationOtherName hide-for-small-only">{{ prestation.gareAutre.name }}</div>
        </div>
        <div class="cell small-6">
          <span class="prestationMention">{{ prestation.mention }}</span>
          <PrestaAssistBox :element="prestation.Parcour" :hideSecondary="true" />
          <div class="prestationClient hide-for-small-only">
            <div class="prestationClientCivility">{{ $t('common.civility.'+$sncfRefs.getCivilityTechnicalNameByLabel(this.prestation.Parcour.Account.civility)) }}</div>
            <div class="prestationClientName">{{ prestation.Parcour.Account.lastname }}</div>
          </div>
          <svgicon icon="comment_dot" class="commentIcon" v-tooltip.left="{ content: comments, trigger: 'hover click' }" @click.prevent.capture v-if="prestation.commentary || prestation.commentaryTransporter || prestation.commentaryPfu || prestation.commentaryPfuOther" />
        </div>
      </div>
    </div>
  </router-link>
</div>
</template>

<script>
import moment from 'moment'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'

export default {
  name: 'PrestationCard',
  props: { prestation: Object },
  data () {
    return {
      departureTime: null,
      comments: ''
    }
  },
  mounted () {
    this.departureTime = this.prestation.datePrestation ? moment(this.prestation.datePrestation).format('HH:mm') : null
    this.calculateComments()
  },
  methods: {
    calculateComments: function () {
      this.comments = null
      if (this.prestation.commentary) {
        this.comments = `${this.$t('common.comment')} : ${this.prestation.commentary} `
      }
      if (this.prestation.commentaryTransporter || this.prestation.commentarySecondaryTransporter) {
        this.comments = this.createCallCenterComment(this.prestation.commentaryTransporter, this.prestation.commentarySecondaryTransporter)
      }
      if (this.prestation.commentaryPfu || this.prestation.commentaryPfuOther) {
        const pfuComment = this.prestation.commentaryPfu ? this.$t(`prestation.pfuComment.${this.prestation.commentaryPfu}`) : null
        this.comments = this.createCallCenterComment(pfuComment, this.prestation.commentaryPfuOther)
      }
    },
    createCallCenterComment: function (firstComment, secondComment) {
      let commTransp = this.comments && this.comments.indexOf(this.$t('common.commentCallCenter')) > -1 ? `${firstComment || ''}` : `${this.$t('common.commentCallCenter')} : ${firstComment || ''}`
      if (secondComment) {
        commTransp += `${firstComment ? '<br>' : ''}${secondComment}`
      }
      return this.comments ? `${this.comments}<br>${commTransp}` : commTransp
    }
  },
  components: { PrestaAssistBox, TrainNumberBox }
}
</script>
