/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass': {
    width: 10,
    height: 14,
    viewBox: '0 0 10 14',
    data: '<path pid="0" d="M9.333 3.432V.19H.667v3.242c0 .358.068.712.202 1.043.134.33.33.63.578.884L3.06 7 1.447 8.64a2.795 2.795 0 00-.78 1.927v3.242h8.666v-3.242a2.795 2.795 0 00-.78-1.927L6.94 7l1.613-1.641c.248-.253.444-.554.578-.884.134-.331.203-.685.202-1.043zM8 12.448H2v-1.88a1.38 1.38 0 01.3-.844h5.4c.19.238.296.536.3.844v1.88zm0-9.016c-.002.36-.143.706-.393.96l-1.84 1.886a1.033 1.033 0 000 1.444l.626.64H3.607l.626-.64a1.033 1.033 0 000-1.444l-1.84-1.886c-.25-.254-.392-.6-.393-.96v-1.88h6v1.88z" _fill="#fff"/><path pid="1" d="M9.333 3.432V.19H.667v3.242c0 .358.068.712.202 1.043.134.33.33.63.578.884L3.06 7 1.447 8.64a2.795 2.795 0 00-.78 1.927v3.242h8.666v-3.242a2.795 2.795 0 00-.78-1.927L6.94 7l1.613-1.641c.248-.253.444-.554.578-.884.134-.331.203-.685.202-1.043zM8 12.448H2v-1.88a1.38 1.38 0 01.3-.844h5.4c.19.238.296.536.3.844v1.88zm0-9.016c-.002.36-.143.706-.393.96l-1.84 1.886a1.033 1.033 0 000 1.444l.626.64H3.607l.626-.64a1.033 1.033 0 000-1.444l-1.84-1.886c-.25-.254-.392-.6-.393-.96v-1.88h6v1.88z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="5" y1=".19" x2="5" y2="13.809" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
