<template>
  <div id="libraryCreateDocument">
    <div class="socaContent">
      <div class="pageTitleLight">{{ $t('library.createDocument') }}</div>
      <div>
        <DocumentForm :documentIn="document" @validate="createDocument" @cancel="cancel"/>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentForm from '@/components/Library/DocumentForm'
import axios from 'axios'

export default {
  name: 'LibraryCreateDocument',
  data () {
    return {
      document: {
        title: null,
        themes: [],
        publishDate: null,
        author: null,
        description: null,
        externalAccess: false,
        roles: []
      }
    }
  },
  props: { },
  mounted () {
  },
  methods: {
    createDocument: function (document, file) {
      // Get a signed request to push file to s3
      this.$backendConnector.libraryGetDocumentUploadUrl(encodeURI(file.name), file.type)
        .then(result => {
          document.fileKey = result.fileKey // save the file key in document for later save
          // Send the file content to s3 via the signed url
          axios.put(result.signedUrl, file, { headers: { 'Content-Type': file.type } })
            .then(response => {
              // File is in s3, save the document in backoffice
              this.$backendConnector.libraryCreateDocument(document)
                .then(res => {
                  // message de confirmation + homepage
                  this.$toastService.showToast(
                    this.$t('library.documentCreated'),
                    null,
                    'success',
                    5000,
                    () => this.$router.push({ name: 'libraryDetailDocument', params: { id: res.id } })
                  )
                  this.$router.push({ name: 'library' })
                })
                .catch(err => {
                  console.log('err', err)
                })
            })
            .catch(err => { console.log('error sending file to s3', err) })
        })
        .catch(err => { console.log('err', err) })
    },
    cancel: function () {
      this.$router.push({ name: 'library' })
    }
  },
  components: { DocumentForm }
}
</script>
