/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M15 11.847c0 .191-.036.442-.107.75a4.23 4.23 0 01-.223.73c-.15.355-.583.732-1.3 1.13-.668.362-1.328.543-1.981.543-.192 0-.379-.012-.56-.037a4.585 4.585 0 01-.612-.133 8.736 8.736 0 01-.506-.155c-.11-.039-.308-.112-.592-.218-.284-.107-.458-.17-.522-.192a9.65 9.65 0 01-1.864-.884c-.91-.561-1.848-1.327-2.818-2.296-.97-.97-1.735-1.909-2.296-2.818a9.649 9.649 0 01-.884-1.864 22.696 22.696 0 00-.192-.522c-.106-.284-.179-.482-.218-.592-.04-.11-.09-.278-.155-.506a4.604 4.604 0 01-.133-.612A4.11 4.11 0 010 3.61c0-.653.181-1.313.543-1.981.398-.717.774-1.15 1.13-1.3.177-.078.42-.152.73-.223C2.71.036 2.961 0 3.152 0c.1 0 .174.01.224.032.128.043.316.312.565.81.078.135.184.326.32.575.134.248.259.474.372.676.114.203.224.393.33.57.022.029.084.118.187.267.103.149.18.275.229.378.05.103.075.204.075.304 0 .142-.102.32-.304.532a5.193 5.193 0 01-.66.586 6.418 6.418 0 00-.661.565c-.203.199-.304.362-.304.49a.72.72 0 00.054.24c.035.095.065.168.09.218.025.05.075.135.15.256.074.12.115.188.122.202.54.973 1.157 1.808 1.853 2.504s1.531 1.313 2.504 1.853c.014.007.082.048.202.123.121.074.206.124.256.149.05.025.123.055.219.09a.72.72 0 00.24.054c.127 0 .29-.101.49-.304.198-.202.386-.422.564-.66.177-.238.373-.458.586-.66.213-.203.39-.305.533-.305.099 0 .2.025.303.075.103.05.23.126.378.23.15.102.238.164.267.186.177.106.367.216.57.33.202.113.428.238.676.373.249.135.44.241.575.32.498.248.767.436.81.564.021.05.032.124.032.224z"/>'
  }
})
