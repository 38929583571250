<template>
  <div id="libraryPage">
    <div class="pageTitle">
      <div class="socaContent">
        <div class="headerTitle">{{ $t('library.title') }}</div>
        <div class="libraryRightButtons">
          <button  v-if="$perms.isUserAllowed('library.manageThemes')"
            class="libraryThemeManagementButton button-theme-round-light button-transparent" @click="openManageThemesModal = true">
            {{ $t('library.manageThemes') }}
          </button>
          <router-link v-if="$perms.isUserAllowed('library.createDocument')"
            class="addDocumentButton button-theme-round-light hide-for-small-only" :to="{ 'name': 'libraryCreateDocument' }">
            <svgicon icon="plus" class="button-icon"/>
            <span class="button-text">{{ $t('library.createDocument') }}</span>
          </router-link>
          <button class="libraryFilterButton show-for-small-only" @click="openFilterThemesModal = true">
            <svgicon icon="filter"/>
          </button>
        </div>
      </div>
    </div>
    <div class="adminPageAddItem show-for-small-only" v-if="$perms.isUserAllowed('library.createDocument')">
      <router-link :to="{ 'name': 'libraryCreateDocument' }">+</router-link>
    </div>
    <div id="libraryContent" class="socaContent contentAfterPageTitle">
      <div class="libraryFilterThemes">
        <div class="prestaDirectFilters hide-for-small-only">
          <div class="filterTitle">{{ $t('library.themes') }}</div>
          <div class="filterContent">
            <div
              v-for="theme in allThemes" :key="theme.id"
              @click="(selectedThemes.includes(theme.id) ? selectedThemes.splice(selectedThemes.indexOf(theme.id), 1) : selectedThemes.push(theme.id));fetchDocuments();"
              :class="{'filterValue': true, 'filterChecked': selectedThemes.includes(theme.id)}"
              >{{ theme.name }}</div>
          </div>
        </div>
        <socamodal
          v-if="openFilterThemesModal"
          :title="$t('library.themes')"
          :submitText="$t('common.close')"
          @close="openFilterThemesModal = false"
          @submit="openFilterThemesModal = false">
          <div class="libraryFilterThemesModal">
            <div
              v-for="theme in allThemes" :key="theme.id"
              @click="(selectedThemes.includes(theme.id) ? selectedThemes.splice(selectedThemes.indexOf(theme.id), 1) : selectedThemes.push(theme.id));fetchDocuments();"
              :class="{'filterTheme': true, 'filterThemeSelected': selectedThemes.includes(theme.id)}"
              >{{ theme.name }}</div>
          </div>
        </socamodal>
      </div>
      <input type="text" v-model="filterQuery" :placeholder="$t('library.search')" class="adminSearchInput" v-debounce="fetchDocuments">
      <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
      <div class="libraryDocumentList">
          <div v-if="documents.length == 0 && !(isLoading)" class="listEmptyMessage">{{ $t('library.listisempty') }}</div>
          <div v-if="documents.length > 0 && !(isLoading)" class="libraryDocumentListHeader grid-x grid-margin-x hide-for-small-only">
            <div class="cell medium-2">{{ $t('library.column.date') }}</div>
            <div class="cell medium-7">{{ $t('library.column.description') }}</div>
            <div class="cell medium-3">{{ $t('library.column.theme') }}</div>
          </div>
          <div v-for="document in documents" :key="document.id" class="libraryDocumentCardContainer">
            <LibraryDocumentCard :document="document" />
          </div>
      </div>
    </div>
    <LibraryManageThemes v-if="openManageThemesModal" @close="openManageThemesModal = false;refreshTheme();"/>
  </div>
</template>

<script>
import LibraryDocumentCard from '@/components/Library/DocumentCard'
import LibraryManageThemes from '@/components/Library/ManageThemes'

export default {
  name: 'Library',
  data () {
    return {
      documents: [], // documents fetched from the back
      allThemes: this.$sncfRefs.getLibraryThemes(), // all available themes
      selectedThemes: [], // selected themes ids
      filterQuery: null,
      isLoading: false,
      openFilterThemesModal: false,
      openManageThemesModal: false
    }
  },
  mounted () {
    this.fetchDocuments()
  },
  methods: {
    fetchDocuments: function () {
      return this.$backendConnector.fetchDocuments(this.filterQuery ? this.filterQuery : '', this.selectedThemes)
        .then(results => {
          this.documents = results
        })
    },
    refreshTheme: function () {
      // refresh themes
      this.$backendConnector.getLibraryThemes()
        .then(results => {
          this.$sncfRefs.setLibraryThemes(results)
          this.allThemes = results
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  components: {
    LibraryDocumentCard, LibraryManageThemes
  }
}
</script>
