import moment from 'moment'
import globalStore from '../store.js'
import userService from '@/services/userService'
import { ALERT_NOTIFS } from '@/utils/constantsUtils'

function shouldPushNotification(notif) {
  const trainNumber = globalStore.getters.trainNumber
  const isOnBoard = userService.isOnBoardCrew()

  if (isOnBoard) {
    if (notif.Prestation.trainNumber === trainNumber && notif.authorRole === 'ONBOARD') {
      return true
    }
  } else {
    return true
  }
  return false
}

function updateNotifPrestation (state, prestationNew) {
  if (prestationNew) {
    let notifsForPresta = state.notifications.filter(notifToCheck => notifToCheck.prestationHcId === prestationNew.herokuExternalId)
    for (let i in notifsForPresta) {
      notifsForPresta[i].Prestation = prestationNew
    }
  }
}

function findNotificationIndexByCode(notifications, code) {
  return notifications.findIndex(notif => notif.code === code)
}

function desactiveByCode (state, notifCode) {
  let codeI = state.notifications.findIndex(notifToCheck => notifToCheck.code === notifCode)
  if (codeI >= 0) {
    state.notifications[codeI].active = false
    state.notifications.splice(codeI, 1) // we remove the notif to force notif list refresh
  }
}

function deactivateAlertNotifByCode (state, alertNotifCode) {
  const notifId = state.notifications.findIndex(notifToCheck => notifToCheck.code === alertNotifCode)
  if (notifId >= 0) {
    state.notifications[notifId].active = false
    state.notifications.splice(notifId, 1)
  }
}

export default {
  namespaced: true,
  state: {
    notifications: [],
    activeUics: [],
    uicForAlertNotifs: []
  },
  mutations: {
    add (state, notif) {
      if (!shouldPushNotification(notif)) {
        return
      }
      updateNotifPrestation(state, notif.Prestation)
      if (['INFORMATION', 'ACTION'].includes(notif.rank) && state.notifications.filter(notifToCheck => notifToCheck.code === notif.code).length === 0) {
        // if not dontNotifyAuthor and user the author
        if (!(notif.context && notif.context.dontNotifyAuthor && notif.author === globalStore.getters.userCode)) {
          state.notifications.push(notif)
        }
      }
      if (notif.codesToDesactive && notif.codesToDesactive.length > 0) {
        for (let i in notif.codesToDesactive) {
          desactiveByCode(state, notif.codesToDesactive[i])
        }
      }
    },
    addAll (state, notifs) {
      if (!notifs) {
        return
      }
      // On reçoit les notifs dans l'ordre décroissant et on les insère dans le même ordre (donc en commençant pas la fin)
      for (let i = notifs.length - 1; i >= 0; i--) {
        if (notifs[i]?.type === ALERT_NOTIFS) {
          const codeI = findNotificationIndexByCode(state.notifications, notifs[i].code)
          if (codeI >= 0) { // already here, replace
            state.notifications[codeI] = notifs[i]
          } else { // add in start of array
            state.notifications.push(notifs[i])
          }
        } else if (shouldPushNotification(notifs[i])) {
          updateNotifPrestation(state, notifs[i].Prestation)
          if (['INFORMATION', 'ACTION'].includes(notifs[i].rank)) {
            // if not dontNotifyAuthor and user the author
            if (!(notifs[i].context && notifs[i].context.dontNotifyAuthor && notifs[i].author === globalStore.getters.userCode)) {
              const codeI = findNotificationIndexByCode(state.notifications, notifs[i].code)
              if (codeI >= 0) { // already here, replace
                state.notifications[codeI] = notifs[i]
              } else { // add in start of array
                state.notifications.push(notifs[i])
              }
            }
          }
        }
      }
      state.notifications.sort(function (a, b) {
        return (moment(a.date).isAfter(moment(b.date)))
      })
    },
    setUics(state, uics) {
      const isTooManyNotifications = uics.length > 100

      state.activeUics = isTooManyNotifications ? [] : uics
      state.uicForAlertNotifs = uics

      if (isTooManyNotifications) {
        console.log('Too many notification UICs, prestations notifications disabled')
      }
    },
    remove (state, notif) {
      for (let i in state.notifications) {
        if (state.notifications[i].code === notif.code) {
          state.notifications.splice(i, 1)
        }
      }
    },
    reset (state) {
      state.notifications = []
    },
    sanitizeNotifs (state) {
      state.notifications = state.notifications.filter(notif => {
        return notif.type !== ALERT_NOTIFS && moment(notif.Prestation.datePrestation) >= moment(Date.now()).subtract(1, 'hour') // toutes les prestas pas encore passés (avec une heure de battement)
      })
    },
    desactiveByCode (state, notifCode) {
      desactiveByCode(state, notifCode)
    },
    deactivateAlertNotifByCode (state, alertNotifCode) {
      deactivateAlertNotifByCode(state, alertNotifCode)
    }
  },
  getters: {
    number: state => {
      return state.notifications.filter((notif) => notif.active !== false && (state.activeUics.includes(notif.uic) || state.uicForAlertNotifs.includes(notif.uic) || notif.target)).length
    },
    numberPersonnal: state => {
      return state.notifications.filter((notif) => notif.active !== false && notif.target).length
    },
    all: state => {
      return state.notifications.filter((notif) => notif.active !== false && (state.activeUics.includes(notif.uic) || state.uicForAlertNotifs.includes(notif.uic) || notif.target))
    },
    allWithRank: (state) => (rank) => {
      return state.notifications.filter((notif) => notif.active !== false && (state.activeUics.includes(notif.uic) || state.uicForAlertNotifs.includes(notif.uic) || notif.target) && notif.rank === rank)
    },
    numberWithRank: (state) => (rank) => {
      return state.notifications.filter((notif) => notif.active !== false && (state.activeUics.includes(notif.uic) || state.uicForAlertNotifs.includes(notif.uic) || notif.target) && notif.rank === rank).length
    },
    numberPersonnalWithRank: (state) => (rank) => {
      return state.notifications.filter((notif) => notif.active !== false && notif.target && notif.rank === rank).length
    },
    getUics (state) {
      return state.activeUics
    },
    getUicForAlertNotifs (state) {
      return state.uicForAlertNotifs
    },
    isPersonnalNotif: (state) => (notifToCheck) => {
      return state.notifications.find((notif) => notif.code === notifToCheck.code && notif.target)
    }
  }
}
