<template>
  <div class="prestaHistoLine" v-if="histo">
    <div class="histoDate">{{ histo.modifiedDate | prettyDate }}</div>
    <div class="histoContent" v-if="histo.fieldName === 'CREATED'">
      {{ $t('prestation.createdBy') }}  <span class="histoUser">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}}</span>
    </div>
    <div class="histoContent" v-else-if="histo.fieldName === 'OLD_SOCA'">
      {{ $t('prestation.oldSocaBy') }}  <span class="histoUser">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}} : </span><span class="histoVal">{{histo.newValue}}</span>
    </div>
    <div class="histoContent" v-else-if="histo.fieldName === 'stopAndGoState'">
      <span class="histoField">{{ $t('prestation.fieldhisto.'+ histo.fieldName) }}</span> {{ $t('common.updatedBy') }} <span class="histoUser">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}}</span>
      <span v-if="histo.oldValue">
        {{ $t('common.histo.by') }} <span class="histoVal">{{ $t('prestation.fieldhisto.' + stopAndGoTechnicalName(histo.oldValue)) }}</span>
      </span>
      <span v-if="histo.newValue">
        {{$t('common.histo.to')}}
          <span class="histoVal">{{ $t('prestation.fieldhisto.' + stopAndGoTechnicalName(histo.newValue)) }}</span>
      </span>
    </div>
    <div class="histoContent" v-else>
      <span class="histoField">{{ $t('prestation.fieldhisto.'+ histo.fieldName) }}</span> {{ $t('common.updatedBy') }} <span class="histoUser">{{histo.user === null ? histo.userCode : histo.user.firstname + ' ' + histo.user.lastname}}</span>
      <span v-if="histo.oldValue && histo.fieldName !== 'state'">
        {{ $t('common.histo.by') }} <span class="histoVal">{{histo.oldValue}}</span>
      </span>
      <span v-if="histo.newValue">
        {{$t('common.histo.to')}}
          <span class="histoVal" v-if="histo.fieldName !== 'reason'">{{histo.newValue}}</span>
          <span class="histoVal" v-else>{{$t('prestation.reason.' + histo.newValue)}}</span>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HistoPrestationLine',
  props: { histo: Object },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    stopAndGoTechnicalName (state) {
      if (state.startsWith('STARTED')) return 'started'
      else if (state.startsWith('COMPLETED')) return 'completed'
      else return null
    }
  },
  computed: {
  },
  filters: {
    prettyDate (date) {
      return date ? moment(date).format('DD/MM/YYYY - HH:mm') : null
    }
  },
  components: { }
}
</script>
