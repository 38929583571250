<template>
  <div class="oneNotif">
      <div class="oneNotifTime">{{ notifTimeLbl }}</div>
      <div class="oneNotifTitle">
        <span>{{$t('notification.type.'+notification.type) }}</span>
        <span v-if="notification.type == 'STATUSCHANGE' && notification.Prestation.statusTN === 'notDone' && notification.Prestation.reason === 'postponementDeparture'"
          >&nbsp;-&nbsp;{{$t('prestation.status.'+notification.Prestation.statusTN) }}&nbsp;-&nbsp;{{ $t('prestation.reason.'+notification.Prestation.reason) }}</span>
        <span v-else-if="notification.type == 'STATUSCHANGE' && notification.context && notification.context.oldStatus && notification.context.newStatus"
          >&nbsp;-&nbsp;{{ notification.context.oldStatus }}&nbsp;->&nbsp;{{ notification.context.newStatus }}</span>
        <span v-else-if="(notification.type == 'ACCEPTED' || notification.type == 'REFUSED' ) && notification.context && notification.context.originMention && notification.context.originStation"
          >&nbsp;-&nbsp;{{ notification.context.originMention }}&nbsp;-&nbsp;{{ notification.context.originStation }}</span>
      </div>
      <router-link :to="{ name: 'prestationDetail', params: { herokuExternalId: notification.Prestation.herokuExternalId }}" class="prestationCard grid-x">
        <PrestationCardContent :prestation="notification.Prestation" />
      </router-link>
      <PrestationCardButtons v-if="notification.type === 'CREATED' && !notification.Prestation.state && notification.Prestation.statusTN === 'todo'" :prestation="notification.Prestation" @prestationEdited="prestationEdited" />
      <PrestationCardButtonQualify
        v-if="notification.context.action === 'qualify' && notification.Prestation.statusTN != notification.context.statusTN"
        :prestation="notification.Prestation" :statusTN="notification.context.statusTN" :reason="notification.context.reason" @prestationEdited="prestationEdited" />
      <NoResponseClientInfo
      v-if="notification.context.stopAndGoState"
      :notification="notification"/>
      <NotificationButtonUnderstood
        v-if="notification.context.action === undefined && !(notification.type === 'CREATED' && !notification.Prestation.state)"
        :notification="notification"/>
  </div>
</template>

<script>
import moment from 'moment'
import PrestationCardButtons from '@/components/Prestations/Search/PrestationCardButtons'
import PrestationCardButtonQualify from '@/components/Prestations/Search/PrestationCardButtonQualify'
import PrestationCardContent from '@/components/Prestations/Search/PrestationCardContent'
import NotificationButtonUnderstood from '@/components/Notification/NotificationButtonUnderstood'
import NoResponseClientInfo from '@/components/Notification/NoResponseClientInfo'

export default {
  name: 'PrestationNotification',
  props: { notification: Object },
  methods: {
    prestationEdited (prestaNewVal) {
      console.log(prestaNewVal)
      // this.$store.commit('notifications/remove', this.notification)
    }
  },
  computed: {
    notifTimeLbl: function () {
      return moment(this.notification.date).locale(this.$i18n.locale).calendar()
    }
  },
  filters: {
    showHourForDate (date) {
      return date ? moment(date).format('HH:mm') : null
    }
  },
  components: {
    PrestationCardContent,
    PrestationCardButtons,
    PrestationCardButtonQualify,
    NotificationButtonUnderstood,
    NoResponseClientInfo
  }
}
</script>
