/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 10.5a1 1 0 011 1v3.333a.667.667 0 00.667.667h11.666a.666.666 0 00.667-.667V11.5a1 1 0 112 0v3.333a2.667 2.667 0 01-2.667 2.667H3.167A2.667 2.667 0 01.5 14.833V11.5a1 1 0 011-1z" _fill="#1E1E28"/><path pid="1" d="M9.225 12.5a1 1 0 01-1-1V3.914l-2.46 2.46A1 1 0 014.351 4.96L8.518.793a1 1 0 011.414 0L14.1 4.96a1 1 0 01-1.415 1.414l-2.459-2.46V11.5a1 1 0 01-1 1z" _fill="#1E1E28"/>'
  }
})
