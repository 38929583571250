<template>
  <div class="changeStateButtons">
    <div class="grid-x">
      <div class="cell small-6 large-6"></div>
      <button class="cell small-6 large-6 prestaCardButton qualifyButton" @click="qualify">{{$t('qualify')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrestationCardButtonQualify',
  props: { prestation: Object, statusTN: String, reason: String },
  methods: {
    qualify: function () {
      const presta = {
        herokuExternalId: this.prestation.herokuExternalId,
        statusTN: this.statusTN,
        reason: this.reason
      }
      this.$backendConnector.updatePrestation(presta)
        .then(newPrestation => this.$emit('prestationEdited', newPrestation))
        .catch(console.error)
    }
  }
}
</script>
