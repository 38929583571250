<template>
  <div>
    <SignedInUser />
  </div>
</template>

<script>
import SignedInUser from '@/components/Auth/SignedInUser'

export default {
  name: 'CheckCredentials',
  components: { SignedInUser }
}
</script>
