<template>
  <div class="adminList" v-if="users">
    <div v-for="user in users.rows" v-bind:key="user.code" class="adminListItem" @click="selectUser(user)" :id="'user-' + user.code">
      <div class="itemLine">
        <span class="userName hide-for-medium" :class="'status-' + user.status">{{user.lastname}} {{user.firstname}}</span>
        <span class="userName hide-for-small-only">{{user.lastname}} {{user.firstname}}</span>
        <span class="hide-for-small-only userStatus" :class="'status-' + user.status">{{ $t('user.statusList.' + user.status) }}</span>
        <span class="userRole">{{ $t('user.roleList.' + user.role) }}</span>
      </div>
      <div class="itemLine">
        <span class="userStations">{{ stationsDisplay(user.stations, 3) }}</span>
        <span class="userTransporter">{{user.transporter}}</span>
      </div>
    </div>
    <div v-if="noResults">
      <label>{{ $t('user.list.noResults') }}</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserListItems',
  props: { users: Object },
  data () {
    return {
      user: {
        firstname: null,
        lastname: null,
        role: null,
        stations: null
      }
    }
  },
  methods: {
    selectUser (userSelected) {
      this.$emit('userSelected', userSelected)
    },
    stationsDisplay (stations, max) {
      let stationsNames = stations.map(function (station) { return station ? station.name : null })
      if (stationsNames.length > max) {
        stationsNames = stationsNames.slice(0, max)
      }
      return stationsNames.join(', ')
    }
  },
  computed: {
    noResults () {
      return this._.isEmpty(this.users)
    }
  }
}
</script>
