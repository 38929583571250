/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import': {
    width: 16.917,
    height: 19.333,
    viewBox: '0 0 16.917 19.333',
    data: '<path pid="0" d="M15.708 19.333h-14.5a1.208 1.208 0 110-2.416h14.5a1.208 1.208 0 110 2.416zM13.195 9.207a1.208 1.208 0 00-1.112-.749H9.667v-7.25a1.208 1.208 0 10-2.417 0v7.25H4.833a1.208 1.208 0 00-1.111.75 1.208 1.208 0 00.253 1.317L7.6 14.15c.115.11.25.196.4.253a1.136 1.136 0 00.917 0 1.21 1.21 0 00.4-.253l3.624-3.625a1.209 1.209 0 00.254-1.318z" _fill="#75757a"/>'
  }
})
