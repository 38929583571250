/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Delete': {
    width: 13,
    height: 16,
    viewBox: '0 0 13 16',
    data: '<g _fill="#2E2E37"><path pid="0" d="M4 4.977h1v8.017H4V4.977zM6 4.977h1v8.017H6V4.977zM8 4.977h1v8.017H8V4.977zM0 1.97h13v1.003H0V1.97z"/><path pid="1" d="M8.967 2.472h-.934V1.47c0-.3-.233-.535-.533-.535h-2c-.3 0-.533.234-.533.535v1.002h-.934V1.47C4.033.668 4.7 0 5.5 0h2c.8 0 1.467.668 1.467 1.47v1.002z"/><path pid="2" d="M9.5 16h-6c-.8 0-1.5-.668-1.567-1.47L1 2.505l1-.067.933 12.025c.034.301.3.535.567.535h6c.3 0 .567-.267.567-.534L11 2.438l1 .067-.933 12.025C11 15.365 10.3 16 9.5 16z"/></g>'
  }
})
