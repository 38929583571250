<template>
  <div class="adminPage">
    <div class='socaContent'>
        <div class="pageTitleLight">{{$t('user.form.createTitle') }}</div>
    </div>
    <UserForm v-model="user" :validateLabel="'user.form.create'" :isUpdate="false" @input="save"></UserForm>
  </div>
</template>

<script>
import userService from '@/services/userService'
import UserForm from '@/components/Users/Form'

export default {
  name: 'UserCreation',
  components: { UserForm },
  data () {
    return {
      user: {
        code: null,
        lastname: null,
        firstname: null,
        role: null,
        stations: [],
        status: 'ACTIVE',
        externalUser: false
      }
    }
  },
  created () {
    return this.$route.params.code && this.fetchUser(this.$route.params.code)
  },
  methods: {
    fetchUser (code) {
      let self = this
      return userService.getUser(code)
        .then(res => {
          self.user = res
          delete self.user.id
          delete self.user.sfid
          self.user.hcErr = null
          self.user.hcLastOp = null
          self.user.charterAccepted = null
          self.user.pushDataStr = null
          self.user.code = null
          self.user.lastname = null
          self.user.firstname = null
          self.user.lastConnection = null
          delete self.user.createdAt
          delete self.user.updatedAt
        })
        .catch(err => console.log(err))
    },
    save: function (user) {
      let self = this
      userService.createUser(user).then(res => {
        if (res !== false) {
          this.$toastService.showToast(this.$t('user.form.usercreatesuccess'), null, 'success')
          self.$router.push({ name: 'users' })
        } else {
          this.$toastService.showToast(this.$t('user.form.usercreateerror'), null, 'error')
        }
      })
    }
  }
}
</script>
