<template>
    <div class="stationSelectZone">
        <div class="stationSelectBorder stationSelectBorderLeft"></div>
        <Multiselect :options="options" v-model="currentValue" @input="change" :searchable="false"
          :multiple="true" :label="labelName" :track-by="valueName" :close-on-select="false" :clear-on-select="false"
          selectLabel="" placeholder="" :internal-search="false" deselectLabel="✕" selectedLabel="✔">
          <template slot="caret"><svgicon icon="arrow"/></template>
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="renderSelectionFunc !== null">
              <span :class="{ 'hide-for-small-only': renderSelectionFuncMobile !== null }">{{ renderSelectionFunc(values) }}</span>
              <span v-if="renderSelectionFuncMobile !== null" class="show-for-small-only">{{ renderSelectionFuncMobile(values) }}</span>
            </span>
            <span v-if="values.length == 0 && !isOpen && renderSelectionFunc === null">{{ $t('common.select') }}</span>
            <span v-if="values.length == 1 && !isOpen && renderSelectionFunc === null">{{ values[0][labelName] }}</span>
            <span v-if="values.length > 1 && !isOpen && renderSelectionFunc === null">{{ $tc('navigation.xvalSelected', values.length) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <span class="option__title">{{ buildLabel(props.option) }}</span>
            <span class="option__circle"><span class="option__circle_in"></span></span>
          </template>
        </Multiselect>
        <div class="stationSelectBorder stationSelectBorderRight"></div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect/src/Multiselect'

export default {
  name: 'MultipleDropdown',
  props: {
    options: { default: () => [], type: Array },
    value: { default: () => [], type: Array },
    labelName: { default: 'label', type: String },
    valueName: { default: 'value', type: String },
    renderSelectionFunc: { default: null, type: Function },
    renderSelectionFuncMobile: { default: null, type: Function },
    renderLabelFunc: { default: null, type: Function }
  },
  data: function () {
    return {
      currentValue: []
    }
  },
  created () {
    this.currentValue = this.value
  },
  mounted () {
    this.currentValue = this.value
  },
  methods: {
    change () {
      this.$emit('input', this.currentValue)
    },
    buildLabel (value) {
      return this.renderLabelFunc ? this.renderLabelFunc(value) : value[this.labelName]
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.currentValue = newValue
    }
  },
  components: { Multiselect }
}
</script>
