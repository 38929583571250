/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hardware': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#a)"><path pid="0" _fill="#A152BE" d="M.652 19.348c.436.435.96.652 1.57.652h15.556c.61 0 1.134-.217 1.57-.652.435-.436.652-.96.652-1.57V2.222c0-.61-.217-1.134-.652-1.57A2.143 2.143 0 0017.778 0H2.222c-.61 0-1.134.217-1.57.652A2.143 2.143 0 000 2.222v15.556c0 .61.217 1.134.652 1.57z"/><path pid="1" _fill="#fff" d="M3 7.9a2.1 2.1 0 012.1-2.1h.7v-.7A2.1 2.1 0 017.9 3h4.2a2.1 2.1 0 012.1 2.1v.7h.7A2.1 2.1 0 0117 7.9v5.6a2.1 2.1 0 01-.615 1.485 2.1 2.1 0 01-1.485.615H5.1A2.1 2.1 0 013 13.5V7.9zm11.9-.7H5.1a.7.7 0 00-.7.7V10h2.8v-.7a.7.7 0 111.4 0v.7h2.8v-.7a.7.7 0 01.7-.7.7.7 0 01.7.7v.7h2.8V7.9a.7.7 0 00-.7-.7zm-3.5 4.2H8.6v.7a.7.7 0 11-1.4 0v-.7H4.4v2.1a.7.7 0 00.7.7h9.8a.7.7 0 00.7-.7v-2.1h-2.8v.7a.7.7 0 01-.7.7.7.7 0 01-.7-.7v-.7zM7.2 5.1v.7h5.6v-.7a.7.7 0 00-.7-.7H7.9a.7.7 0 00-.7.7z"/><path pid="2" _fill="url(#b)" d="M3 7.9a2.1 2.1 0 012.1-2.1h.7v-.7A2.1 2.1 0 017.9 3h4.2a2.1 2.1 0 012.1 2.1v.7h.7A2.1 2.1 0 0117 7.9v5.6a2.1 2.1 0 01-.615 1.485 2.1 2.1 0 01-1.485.615H5.1A2.1 2.1 0 013 13.5V7.9zm11.9-.7H5.1a.7.7 0 00-.7.7V10h2.8v-.7a.7.7 0 111.4 0v.7h2.8v-.7a.7.7 0 01.7-.7.7.7 0 01.7.7v.7h2.8V7.9a.7.7 0 00-.7-.7zm-3.5 4.2H8.6v.7a.7.7 0 11-1.4 0v-.7H4.4v2.1a.7.7 0 00.7.7h9.8a.7.7 0 00.7-.7v-2.1h-2.8v.7a.7.7 0 01-.7.7.7.7 0 01-.7-.7v-.7zM7.2 5.1v.7h5.6v-.7a.7.7 0 00-.7-.7H7.9a.7.7 0 00-.7.7z"/></g><defs><linearGradient id="b" x1="10" x2="10" y1="3" y2="15.6" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><clipPath id="a"><path pid="3" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
