<template>
  <div id="charterPage">
    <div class="socaContent socaContentNarrow">
      <div class="charterTitle">{{$t('common.charter.modalTitle') }}</div>
      <div class="charterCondition">{{$t('common.charter.condition') }}</div>
      <div class="charterContent" v-html="$t('common.charter.txt')"></div>
      <button @click="accept" class="cell large-6 small-6 button-theme">{{$t('common.accept')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Charter',
  data () {
    return {
      charterSigned: false
    }
  },
  mounted () {
  },
  methods: {
    accept: function () {
      this.$backendConnector.signUserCharter()
        .then(res => {
          this.charterSigned = true
          this.$store.commit('setUserCharterAccepted', true)
          this.$router.push({ name: 'home' })
          this.$router.go('/')
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
