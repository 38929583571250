/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Train': {
    width: 50,
    height: 37,
    viewBox: '0 0 50 37',
    data: '<path pid="0" d="M48.542 29.512C49.417 28.778 50 27.456 50 25.4 50 8.222 33.965 0 24.927 0H2.624A2.622 2.622 0 000 2.643v2.643a2.622 2.622 0 002.624 2.643H14.43c1.02 0 1.604.293 2.041.88 1.02 1.175 5.685 6.901 7.289 8.81 1.312 1.615.583 2.937-1.02 2.937H3.936C1.749 20.556 0 22.317 0 24.52v2.055c0 2.203 1.75 3.965 3.936 3.965h40.67c1.458 0 2.77.88 2.77 1.908s-1.312 1.91-2.77 1.91H1.166c-.729 0-1.166.586-1.166 1.32C0 36.414.583 37 1.166 37h44.17C47.958 36.706 50 34.798 50 32.595c0-1.174-.583-2.202-1.458-3.083zm-1.749-9.25h-6.268c-1.895 0-3.79-.881-4.81-2.35-2.042-2.789-4.52-5.872-6.415-8.222-.146-.146-.146-.44-.146-.734.146-.293.292-.44.584-.44h9.766c3.353 2.79 6.123 6.754 7.289 11.746zm-1.458 7.928h-41.4a1.466 1.466 0 01-1.457-1.468v-2.055c0-.735.583-1.469 1.458-1.469h18.95c1.75 0 3.207-.88 3.936-2.202.292-.734.875-2.496-.874-4.845-1.458-1.909-6.269-7.782-7.435-8.957-1.166-1.174-2.478-1.761-4.082-1.761H2.624s-.146 0-.146-.147V2.643s0-.147.146-.147h22.303c3.061 0 7.143 1.175 10.787 3.377h-6.122c-1.312 0-2.332.734-2.916 1.909-.437 1.174-.291 2.496.438 3.524 1.895 2.349 4.373 5.432 6.414 8.075 1.603 2.202 4.227 3.377 6.851 3.377h6.851c.146.88.146 1.762.146 2.643 0 1.909-.583 2.79-2.04 2.79z" _fill="#1E1E28"/>'
  }
})
