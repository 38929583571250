<template>
  <socamodal
    class="newsModal"
    v-if="news"
    :title="news.title"
    @close="$emit('close', news.id)">
    <div v-html="modified_news_content" />
    <template v-slot:footer>
      <div class="grid-x grid-margin-x">
        <div class="cell small-6">
          <button @click="$emit('close', news.id)" class="button-theme-secondary">{{$t('common.close')}}</button>
        </div>
        <div class="cell small-6">
          <button @click="$emit('read', news.id)" class="button-theme" :disabled="isRead">{{$t('news.markAsRead')}}</button>
        </div>
      </div>
    </template>
  </socamodal>
</template>

<script>
import quillEditorVariables from '../../../config/quill-editor-variables.json'

export default {
  name: 'NewsModal',
  components: { },
  props: {
    'news': {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    isRead () {
      return this.news.NewsReads && this.news.NewsReads.length > 0
    },
    modified_news_content () {
      let content = this.news.content
      const user = this.$store.getters.user

      for (const [key, value] of Object.entries(quillEditorVariables)) {
        content = content.replaceAll(key, user[value])
      }

      return content
    }
  }
}
</script>
