<template>
  <span v-bind="$attrs"><a v-if="value" :href="'mailto:' + value">{{ value }}</a><template v-else>{{ emptyValue }}</template></span>
</template>

<script>
export default {
  name: 'EmailLink',
  props: {
    value: {
      type: String
    },
    emptyValue: {
      type: String,
      default: '-'
    }
  }
}
</script>

<style scoped>
  a {
    font: inherit;
    color: inherit;
  }
</style>
