<template>
  <div id="prestationNewStepAccount">
    <form>
      <div class="socaContent socaContentNarrow prestationAccountForm">
        <div class="prestationCreationTitle">{{$t('prestation.new') }}</div>
        <div class="titleStep">{{ $t('prestation.create.base') }}</div>
        <div class="prestationTypeChoices">
          <div class="grid-x grid-margin-x">
            <div class="button-round button-round-selected cell small-6">{{$t('prestation.create.standard') }}</div>
            <div @click="$emit('toSansSuite')" class="button-round cell small-6">{{$t('prestation.create.sanssuite') }}</div>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="small-12 medium-6 cell" >
              <div :class="{'isAccountChosen': isDisabled}">
                <span @click="closeFile" v-if='this.isDisabled' class="closeFile">&times;</span>
                  <div class="formElement accountTitle">
                      <label>{{ $t('account.title') }}* <small v-if="validation.hasError('account.civility')" class="error">{{ $t('error.field.mandatory') }}</small>
                        <selectpl v-model="account.civility" :disabled="isDisabled">
                          <option v-for="civility in civilities" :value="civility" :key="civility">{{ $t('common.civility.'+$sncfRefs.getCivilityTechnicalNameByLabel(civility)) }}</option>
                        </selectpl>
                      </label>
                  </div>
                  <div class="formElement accountFirstname position-relative">
                      <svgicon icon="loader" class="fieldLoaderAccount" v-if="isSearchingAccounts"/>
                      <label>{{ $t('account.firstname') }}
                      <input type="text" :placeholder="$t('account.firstname')" v-model="account.firstname" v-debounce="searchAccount" :disabled="isDisabled">
                      </label>
                  </div>
                  <div class="formElement accountLastname position-relative">
                      <svgicon icon="loader" class="fieldLoaderAccount" v-if="isSearchingAccounts"/>
                      <label>
                        <span v-tooltip="{
                                content: $t('account.lastnametooltip'),
                                trigger: 'hover click',
                              }">
                          {{ $t('account.lastname') }}* <small v-if="validation.hasError('account.lastname')" class="error">{{ $t('error.field.mandatory') }}</small>

                        </span>
                        <input type="text" :placeholder="$t('account.lastname')" v-model="account.lastname" v-debounce="searchAccount" :disabled="isDisabled">
                      </label>
                  </div>
              </div>
              <div>
                  <div class="formElement accountPhone position-relative">
                      <svgicon icon="loader" class="fieldLoaderAccount" v-if="isSearchingAccounts"/>
                      <label>
                        <span v-tooltip="{
                                content: $t('account.phonetooltip'),
                                trigger: 'hover click',
                              }">
                          {{ $t('account.phone') }}
                        </span>
                      <input type="tel" :placeholder="$t('account.phone')" v-model="account.phone" v-debounce="searchAccount">
                      </label>
                  </div>
                  <div class="formElement accountEmail position-relative">
                      <svgicon icon="loader" class="fieldLoaderAccount" v-if="isSearchingAccounts"/>
                      <label>{{ $t('account.email') }} <small v-if="validation.hasError('account.email')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
                        <input type="email" :placeholder="$t('account.email')" v-model="account.email" v-debounce="searchAccount">
                      </label>
                  </div>
              </div>
          </div>

          <div class="small-12 medium-6 cell">
            <div>
                <div class="formElement accountTypeAssist">
                    <label>{{ $t('account.typeAssist') }}* <small v-if="validation.hasError('account.typeAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                      <select v-model="account.typeAssist" @change="onChangeType($event.target.value)" :class="{ 'valueIsNull': account.typeAssist === null }">
                        <option :value="null" hidden>{{ $t('common.select') }}</option>
                        <option :key="option.acronym" v-for="option in typeAssist" :value="option.acronym">{{option.allLetters}}</option>
                      </select>
                    </label>
                </div>
                <div class="formElement accountSpecAssist">
                    <label>{{ $t('account.specAssist') }}* <small v-if="validation.hasError('account.specAssist')" class="error">{{ $t('error.field.mandatory') }}</small>
                      <select v-model="account.specAssist" :class="{ 'valueIsNull': account.specAssist === null }">
                        <option :value="null" hidden>{{ $t('common.select') }}</option>
                        <option :key="spec.complete" v-for="spec in specAssist" :value="spec.acronym">{{spec.allLetters}}</option>
                      </select>
                    </label>
                </div>
                <div class="formElement accountDetailAssist">
                    <label>{{ $t('account.detailAssist') }}
                      <select v-model="account.detailAssist" :class="{ 'valueIsNull': account.detailAssist === null }">
                        <option :value="null" hidden>{{ $t('common.select') }}</option>
                        <option :value="null">-</option>
                        <option :key="detail.complete" v-for="detail in detailAssist" :value="detail.acronym">{{detail.allLetters}}</option>
                      </select>
                    </label>
                </div>
                <div class="formElement accountSpecAssistSecondary">
                    <label>{{ $t('account.specAssistSecondary') }}
                      <select v-model="account.specAssistSecondary" @change="onChangeSpecSecondary($event.target.value)" :class="{ 'valueIsNull': account.specAssistSecondary === null }">
                        <option :value="null" hidden>{{ $t('common.select') }}</option>
                        <option :value="null">-</option>
                        <option :key="spec.complete" v-for="spec in specAssistSecondary" :value="spec.acronym">{{spec.allLetters}}</option>
                      </select>
                    </label>
                </div>
                <div class="formElement accountDetailAssistSecondary">
                    <label>{{ $t('account.detailAssistSecondary') }}
                      <select v-model="account.detailAssistSecondary" :class="{ 'valueIsNull': account.detailAssistSecondary === null }">
                        <option :value="null" hidden>{{ $t('common.select') }}</option>
                        <option :value="null">-</option>
                        <option :key="detail.complete" v-for="detail in detailAssistSecondary" :value="detail.acronym">{{detail.allLetters}}</option>
                      </select>
                    </label>
                </div>
                <div class="formElement parcoursBagageNumber" v-if="!isOnBoardCrew()">
                  <label><span v-tooltip="{
                      content: $t('tooltip.bagage'),
                      trigger: 'hover click',
                    }">{{ $t('parcours.bagageNumber') }}</span>
                    <small v-if="validation.hasError('parcours.bagage')" class="error">{{ $t('error.field.incorrectFormat') }}</small>
                    <input type="number" min="0" :placeholder="$t('parcours.bagageNumber')" v-model="parcours.bagage">
                  </label>
                </div>
            </div>
          </div>
        </div>
        <div class="prestationAccountFormFooter">
          <div class="mandatoryElementInfo">{{$t('common.field.mandatoryFields')}}</div>
          <button @click.prevent="validateFormContact" :disabled="isLoading" class="button-theme">{{$t('prestation.create.nextStep')}}</button>
        </div>
      </div>
    </form>

    <AccountSearchCards @chooseAccount='chooseAccount' :accountSearch='accountSearch' :accounts='accounts' v-if="$perms.isUserAllowed('account.search')"/>
  </div>
</template>

<script>
import assist from '@/services/assist'

import AccountSearchCards from '@/components/Accounts/SearchCards'
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator

export default {
  name: 'PrestationNewStepAccount',
  props: { accountData: Object, parcoursData: Object },
  data () {
    return {
      isDisabled: false,
      accountChosen: null,
      account: {
        civility: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        typeAssist: null,
        specAssist: null,
        detailAssist: null,
        specAssistSecondary: null,
        detailAssistSecondary: null,
        sfid: null
      },
      accountFormCopy: {},
      parcours: {
        bagage: null,
        status: 'Non réalisé'
      },
      civilities: [],
      typeAssist: [],
      specAssist: [],
      detailAssist: [],
      specAssistSecondary: [],
      detailAssistSecondary: [],
      accounts: null,
      accountSearch: false,
      isSearchingAccounts: false,
      isLoading: false
    }
  },
  methods: {
    closeFile () {
      this.isDisabled = false
      this.account = this._.cloneDeep(this.accountFormCopy)
      this.accountFormCopy = {}
      this.searchAccount(this.account)
    },
    validateFormContact () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true;
      const self = this
      this.$validate().then(function (success) {
        if (success) {
          let isAccountUpdated = false
          if (!self._.isEqual(self.accountChosen, self.account)) {
            isAccountUpdated = true
          }
          self.$emit('stepAccountOk', self.account, self.parcours, isAccountUpdated)
        }
      })
      this.isLoading = false
    },
    searchAccount (target) {
      if (!this.$perms.isUserAllowed('account.search')) { return }
      this.isSearchingAccounts = true
      if (this.account.lastname && this.account.lastname.length >= 3) {
        let keys = this._.keys(this.account)
        let sendReq = false
        let params = {}
        let searchableKeys = ['firstname', 'lastname', 'email', 'phone']
        for (let i in keys) {
          if (searchableKeys.includes(keys[i]) && !this._.isEmpty(this.account[keys[i]])) {
            params[keys[i]] = this.account[keys[i]]
            sendReq = true
          }
        }
        if (sendReq) {
          return this.$backendConnector.searchAccounts({ searchParams: params })
            .then(results => {
              this.isSearchingAccounts = false
              if (results.length > 0) {
                this.accountSearch = true
                this.accounts = results
              } else {
                this.accountSearch = false
              }
            })
            .catch(err => console.log(err))
        } else {
          this.accountSearch = false
        }
      } else {
        this.accountSearch = false
      }
    },
    chooseAccount (val) {
      this.accountFormCopy = this._.cloneDeep(this.account)
      this.account = this._.cloneDeep(val)
      this.accountChosen = this._.cloneDeep(val)
      this.onChangeType(val.typeAssist)
      this.account.specAssist = val.specAssist
      this.account.detailAssist = val.detailAssist
      this.account.specAssistSecondary = val.specAssistSecondary
      this.onChangeSpecSecondary(this.account.specAssistSecondary)
      this.account.detailAssistSecondary = val.detailAssistSecondary
      this.accountSearch = false
      this.isDisabled = true
    },
    onChangeType (val) {
      this.specAssist = assist.getSpecAssist(val)
      this.detailAssist = assist.getDetailAssist(val)
      this.account.specAssist = null
      this.account.detailAssist = null
    },
    onChangeSpecSecondary (val) {
      if (val === '' || val === null) {
        this.detailAssistSecondary = null
        this.account.detailAssistSecondary = null
      } else {
        this.detailAssistSecondary = assist.getDetailAssistSecondary(val)
        if (!this.detailAssistSecondary.map(d => d.acronym).includes(this.account.detailAssistSecondary)) {
          this.account.detailAssistSecondary = null
        }
      }
    },
    isOnBoardCrew() {
      return this.$store.getters.userRole === 'ONBOARD'
    }
  },
  mounted () {
    this.civilities = this.$sncfRefs.getCivilities()
    if (this.accountData) { // load datas
      const acc = this.accountData
      for (let option in acc) {
        this.account[option] = acc[option]
      }
    }

    this.typeAssist = this.$sncfRefs.getTypeAssist()
    this.specAssistSecondary = this.$sncfRefs.getSpecAssist()
    this.parcours.bagage = this.parcoursData.bagage || 0

    // load datas
    if (this.accountData.typeAssist) {
      this.account.typeAssist = this.accountData.typeAssist
      this.onChangeType(this.accountData.typeAssist)
      this.account.specAssist = this.accountData.specAssist
      this.account.detailAssist = this.accountData.detailAssist
      this.account.specAssistSecondary = this.accountData.specAssistSecondary
      if (this.account.specAssistSecondary) {
        this.onChangeSpecSecondary(this.accountData.specAssistSecondary)
        this.account.detailAssistSecondary = this.accountData.detailAssistSecondary
      }
    }
  },
  validators: {
    'account.civility': function (value) {
      return Validator.value(value).required()
    },
    'account.lastname': function (value) {
      return Validator.value(value).required()
    },
    'account.email': function (value) {
      return Validator.value(value).email()
    },
    'account.typeAssist': function (value) {
      return Validator.value(value).required()
    },
    'account.specAssist': function (value) {
      return Validator.value(value).required()
    },
    'parcours.bagage': function (value) {
      return Validator.value(value).integer().greaterThanOrEqualTo(0)
    }
  },
  components: { AccountSearchCards }
}
</script>

<style scoped>

</style>
