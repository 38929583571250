<template>
  <div
    class="trainNumberBox"
    :class="{'withCarAndPlace': (showCarAndPlace && (prestation.trainCar || prestation.trainPlace)) }"
    v-tooltip.left="{
      content:  (showCarAndPlace && (prestation.trainCar || prestation.trainPlace)) ? 'Voiture: '+(prestation.trainCar || '') +' <br>Place: '+(prestation.trainPlace || '') : null,
      trigger: 'hover click'
    }"
    @click.prevent.capture
    >
    <svgicon :icon="prestation.transportMode"/>
    <div class="trainNumber" v-if="prestation.transportMode !== 'Taxi'">{{ displayCapsuleOption }}</div>
    <div class="trainNumber" v-if="prestation.transportMode === 'Taxi'">{{ $t('common.transportMode.'+prestation.transportMode) }}</div>
    <div class="trainPlaceAndCar" v-if="(showCarAndPlace && (prestation.trainCar || prestation.trainPlace))">
      <div class="trainCar">{{ prestation.trainCar }}</div>
      <div class="trainPlace">{{ prestation.trainPlace }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrainNumberBox',
  props: {
    prestation: Object,
    showCarAndPlace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      transporters: []
    }
  },
  mounted () {
    this.transporters = this.$sncfRefs.getTransportersModelsForUpsert()
  },
  computed: {
    displayCapsuleOption () {
      if (!this.prestation.missionCode && this.prestation.trainNumber) {
        return this.prestation.trainNumber
      } else if (this.prestation.missionCode && !this.prestation.trainNumber) {
        return this.prestation.missionCode
      } else {
        let transporter = this.transporters.find((elem) => elem.code === this.prestation.transporter)
        if (transporter && transporter.displayCapsuleOption === 'mission_code') {
          return this.prestation.missionCode
        } else {
          return this.prestation.trainNumber
        }
      }
    }
  }
}
</script>
