<template>
  <div class='prestationCardContent'>
    <div class="prestationManager" v-if="prestation.manager">{{ prestation.manager }}</div>
    <div class="justUpdatedIndicator" />
    <div :class="`prestationStatus prestationStatusVertical prestationStatus-${prestation.statusTN}`"
      v-if="prestation.statusTN !== null"
      >{{ $t('prestation.status.'+prestation.statusTN) }}
    </div>
    <div class="prestationCardContentLine">
      <span class="datePrestationHour">{{ prestation.datePrestation | showHourForDate }}</span>
      <div class="trainNumberBoxContainer">
        <TrainNumberBox :prestation="prestation" :showCarAndPlace="true" />
      </div>
    </div>
    <div class="prestationAppendices">
      <div class="prestationComments">
        <svgicon icon="comment_dot" class="commentIcon" v-tooltip.left="{ content: comments, trigger: 'hover click' }" @click.prevent.capture v-if="comments !== null" />
      </div>
      <div class="prestationStopAndGo">
        <svgicon v-if="prestation.stopAndGoState && prestation.stopAndGoState.startsWith('STARTED')" icon="operation_started" class="stopAndGoIcon" />
      </div>
      <div class="prestationIcons">
        <div class="iconContainer" v-if="prestation.garantee === $sncfRefs.getPrestaTypesByTechnicalName().garanteed"><svgicon icon="star" class="prestaGarantedIcon" /></div>
        <div class="iconContainer" v-if="principalState === 'accepted' && shoudlDisplayIcons && !isOnBoardCrew()"><svgicon icon="check" class="prestaAcceptedIcon" /></div>
        <div class="iconContainer" v-if="refusedShouldBeDisplayed && shoudlDisplayIcons && !isOnBoardCrew()"><svgicon icon="exclamation" class="prestaRefusedIcon" /></div>
        <div class="iconContainer" v-if="principalState === 'waiting' && shoudlDisplayIcons && !isOnBoardCrew()"><svgicon icon="hourglass" class="prestaWaitingIcon"/></div>
        <div class="iconContainer" v-if="isWarned"><svgicon icon="person" class="prestaWarnedIcon"/></div>
      </div>
    </div>
    <div class="prestationCardContentLine prestationStationLine">
      <div class='stationPrestaName' v-if="!$store.getters.hasOneStation">{{ prestation.garePresta.name }}</div>
      <div v-if="prestation.gareAutre"
          class="stationOtherName" :class="{'stationWithoutSoca': (!prestation.gareAutre.soca || (otherSidePresta && otherSidePresta.mention === $sncfRefs.getMentionsByTechnicalName().none.sigle)) }"
          :title="`${ !prestation.gareAutre.soca ? $t('common.nosoca') : (otherSidePresta && otherSidePresta.mention === $sncfRefs.getMentionsByTechnicalName().none.sigle ? $t('common.noprestatotherside') : '') }`"
          >{{ prestation.gareAutre.name }}</div>
    </div>
    <div class="prestationIcons natureAssistanceIcons">
      <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_BORD"><svgicon icon="casquette" class="natureBordIcon"/></div>
      <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_GARE"><svgicon icon="gare" class="natureGareIcon"/></div>
      <div class="iconContainer" v-if="prestation.natureAssistance === PRESTA_NATURE_ASSISTANCE_ROUTE"><svgicon icon="taxi_noir" class="natureGareIcon"/></div>
    </div>
    <div class="prestationCardContentLine prestationClientLine">
      <div class="prestationMentionAndAssist">
        <span class="prestationMention">{{ prestation.mention }}</span>
        <PrestaAssistBox :element="prestation.Parcour" :hideSecondary="true" />
      </div>
      <div class="prestationClient">
        <div class="prestationClientCivility">{{ $t('common.civility.'+$sncfRefs.getCivilityTechnicalNameByLabel(this.prestation.Parcour.Account.civility)) }}</div>
        <div class="prestationClientName">{{ prestation.Parcour.Account.lastname }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/services/common'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'
import moment from 'moment'
import { PRESTA_NATURE_ASSISTANCE_GARE, PRESTA_NATURE_ASSISTANCE_BORD, PRESTA_NATURE_ASSISTANCE_ROUTE } from '../../../utils/constantsUtils'

export default {
  name: 'PrestationCard',
  props: { prestation: Object },
  data () {
    return {
      principalState: null,
      isWarned: null,
      refusedShouldBeDisplayed: false,
      otherStationQualifyComment: null,
      shoudlDisplayIcons: false,
      otherSidePresta: null,
      PRESTA_NATURE_ASSISTANCE_GARE,
      PRESTA_NATURE_ASSISTANCE_BORD,
      PRESTA_NATURE_ASSISTANCE_ROUTE
    }
  },
  mounted () {
    this.shoudlDisplayIcons =
      this.prestation.segmentPart === 'from' &&
      this.prestation.garantee !== this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed &&
      this.prestation.statusTN === 'todo'
  },
  watch: {
    prestation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler (val, oldVal) {
        this.isWarned = !!this.prestation.warningDatetime
        this.getOtherPrestationsStates()
      }
    }
  },
  computed: {
    comments: function () {
      const commentaryFields = {
        'commentary': this.$t('common.comment'),
        'commentaryQualif': this.$t('prestation.station.commentaryqualif'),
        'commentaryQualifOther': this.$t('prestation.transporter.commentaryqualifother')
      }
      let result = ''
      for (let field in commentaryFields) {
        let fieldLabel = commentaryFields[field] + ': '
        let fieldValue = this.prestation[field]
        result += fieldValue ? ((result ? '<br>' : '') + fieldLabel + fieldValue) : ''
      }
      if (this.prestation.commentaryTransporter || this.prestation.commentarySecondaryTransporter || this.prestation.commentaryPfu || this.prestation.commentaryPfuOther) {
        result += `${result ? '<br>' : ''}${this.$t('common.commentCallCenter')} : `
        if (this.prestation.commentaryTransporter) result += this.addToComment(this.prestation.commentaryTransporter)
        if (this.prestation.commentarySecondaryTransporter) result += this.addToComment(this.prestation.commentarySecondaryTransporter)
        if (this.prestation.commentaryPfu) result += this.addToComment(this.$t(`prestation.pfuComment.${this.prestation.commentaryPfu}`))
        if (this.prestation.commentaryPfuOther) result += this.addToComment(this.prestation.commentaryPfuOther)
      }
      return result !== '' ? result : null
    }
  },
  methods: {
    addToComment: function (comment) {
      return comment ? `<br> ${comment}` : ''
    },
    getOtherPrestationsStates: function () {
      this.principalState = common.getPrestationPrincipalState(this.prestation.herokuExternalId, this.prestation.Parcour.Prestations)
      let refusedPresta = null
      this.prestation.Parcour.Prestations.forEach(p => {
        if (p.state === 'refusée' && p.herokuExternalId !== this.prestation.herokuExternalId) refusedPresta = p
      })
      const mentions = this.$sncfRefs.getMentionsByTechnicalName()
      if (this.principalState === 'refused' &&
          [mentions.departure.sigle, mentions.transitDeparture.sigle, mentions.none.sigle].includes(this.prestation.mention) && // is departure
          this.prestation.state !== 'refusée') { // not self is refused
        if (moment(this.prestation.datePrestation) <= moment(refusedPresta.datePrestation)) {
          this.refusedShouldBeDisplayed = true
        }
      }

      // fetch presta at other side of segment
      let otherSideTab = this.prestation.Parcour.Prestations.filter(p => p.gareAutreUic === this.prestation.garePrestaUic && p.segmentPart !== this.prestation.segmentPart)
      if (otherSideTab.length === 1) {
        this.otherSidePresta = otherSideTab[0]
      } else {
        this.otherSidePresta = null
      }
    },
    isOnBoardCrew() {
      return this.$store.getters.userRole === 'ONBOARD'
    }
  },
  filters: {
    showHourForDate (date) {
      return date ? moment(date).format('HH:mm') : null
    }
  },
  components: { PrestaAssistBox, TrainNumberBox }
}
</script>
