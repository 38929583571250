<template>
  <div>
    <div class="off-canvas position-right" id="notificationPanel" data-off-canvas>
      <div class="notificationContent botSeparationLine">
        <div class="pageTitleLight">{{$t('notification.title') }}</div>
      </div>
      <div id="notificationTabsTitles" class="grid-x">
        <div v-for="rank in allRanks" :key="rank" class="small-6 rank" :class="{'rankActive' : rankOpened === rank, 'isRankAction' : rank === 'ACTION', 'isRankInfo' : rank === 'INFORMATION'}" @click="rankOpened = rank">
          {{$t('notification.rank.'+rank) }} ({{ $store.getters['notifications/numberWithRank'](rank)}})
        </div>
      </div>
      <div id="notificationTabs">
        <div v-for="rank in allRanks" :key="rank">
          <div class="notifications" v-if="rank === rankOpened">
            <div
              v-for="notif in $store.getters['notifications/allWithRank'](rankOpened)"
              :key="`${notif.code + notif.active}`"
              class="notificationContent botSeparationLine"
                >
                <PrestationNotification :notification="notif" v-if="notif.type !== ALERT_NOTIFS"></PrestationNotification>
                <AlertNotification :notification="notif" v-if="notif.type === ALERT_NOTIFS"></AlertNotification>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="notifBandobermann" :class="$store.getters['notifications/numberPersonnal'] > 0 ? 'large' : ''" v-if="showBandobermann" data-toggle="notificationPanel" @click="showDoberman = false">
      <div class="socaContent">
        <span>{{$tc('notification.XactionsToTakeIntoAccount', $store.getters['notifications/numberWithRank']('ACTION')) }} {{ $store.getters['notifications/numberPersonnalWithRank']('ACTION') > 0 ? $tc('notification.XpersonnalActionsToTakeIntoAccount', $store.getters['notifications/numberPersonnalWithRank']('ACTION')) : '' }}</span>
        <div class="arrowIcon"><svgicon icon="arrow_right" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import PrestationNotification from '@/components/Notification/PrestationNotification'
import AlertNotification from '@/components/Notification/AlertNotification'
import { ALERT_NOTIFS } from '@/utils/constantsUtils'

export default {
  name: 'Notifications',
  data () {
    return {
      allRanks: ['ACTION', 'INFORMATION'],
      rankOpened: 'ACTION', // Or 'INFORMATION'
      isPanelOpened: false,
      showDoberman: true,
      ALERT_NOTIFS
    }
  },
  mounted () {
    const self = this
    $(this.$el).foundation()
    $('#notificationPanel a').click(function () {
      $('#notificationPanel').foundation('close')
    })
    $('#notificationPanel').on('opened.zf.offCanvas', function () {
      self.isPanelOpened = true
    })
    $('#notificationPanel').on('close.zf.offCanvas', function () {
      self.isPanelOpened = false
    })

    // reshow the bandoberman when new action notif pops
    this.$store.watch(
      (state, getters) => getters['notifications/numberWithRank']('ACTION'),
      (newValue, oldValue) => {
        if (newValue > oldValue) {
          this.showDoberman = true
        }
      }
    )
  },
  computed: {
    showBandobermann: function () {
      return parseInt(process.env.VUE_APP_SHOW_BANDOBERMANN) && !this.isPanelOpened && this.showDoberman &&
        this.$store.getters['notifications/numberWithRank']('ACTION') > 0
    }
  },
  components: {
    PrestationNotification,
    AlertNotification
  }
}
</script>
