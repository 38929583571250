<template>
    <div v-if="accountSearch" class="accountsPanel">
      <div class="socaContent grid-x">
        <div class="small-10"><span class="accountsNumber">{{accounts.length}}{{accounts.length >= resultLimit ? '+' : ''}}</span> fiches clients existantes</div>
        <div class="small-2 showPanel">
          <span @click="showAccountsPanel" v-if="showAccounts">Masquer</span>
          <span @click="showAccountsPanel" v-else>Afficher</span>
        </div>
        <div class="accountProposal" v-if="showAccounts">
          <div v-for="account in accounts" :key="account.herokuExternalId" class="accountPanel">
            <div @click="chooseClient(account)" class="grid-x grid-margin-x">
              <div class="cell small-8 large-8">
                <span class="name">{{account.firstname}} {{account.lastname }}</span> <br>
                <span class="infos" v-if="account.email">{{ account.email }}<br></span>
                <span class="infos">{{ account.phone }}</span>
              </div>
              <div class="cell small-4 large-4">
                <div class="assists">
                  <div class="prestaAssistBox">
                      <div class="typeAssist">
                        <span v-tooltip="{
                          content: getAssist(account.typeAssist),
                          trigger: 'hover click',
                        }">
                          {{account.typeAssist }}
                        </span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import assist from '@/services/assist'

export default {
  name: 'SearchCards',
  props: { accountSearch: Boolean, accounts: Array },
  data () {
    return {
      resultLimit: 200,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      account: {
        civility: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        typeAssist: null,
        specAssist: null,
        detailAssist: null,
        sfid: null
      },
      showAccounts: true
    }
  },
  methods: {
    chooseClient (accountChoosed) {
      this.account = accountChoosed
      this.$emit('chooseAccount', this.account)
    },
    showAccountsPanel () {
      this.showAccounts = !this.showAccounts
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    getAssist: function (txt) {
      return assist.typeAssistDetails(txt)
    }
  },
}
</script>

<style scoped>

</style>
