/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M2.623 16.997l-.797-2.561c.18-.34.463-.784.848-1.28l1.697 5.435c.566.444 1.209.889 1.877 1.28l-2.52-8.023c.283-.313.592-.627.952-.94l3.008 9.722c.54.235 1.08.47 1.671.653L5.862 9.94c.335-.236.695-.47 1.055-.68l3.882 12.388a24.63 24.63 0 001.568.261l-1.208-3.868c.411.235.874.392 1.363.444l1.105 3.502h.437c.36 0 .694-.026 1.029-.026l-1.08-3.502c.386-.078.771-.209 1.131-.366l2.391 7.684a.71.71 0 00.669.497c.077 0 .154 0 .206-.027a.715.715 0 00.462-.914l-1.234-3.947c6.48-1.855 8.562-6.167 8.665-6.376l.128-.314-.128-.34c-.026-.078-3.188-8.05-12.11-8.493a6.482 6.482 0 00-1.003-.079c-.128 0-.283.026-.411.026L11.133.558c-.128-.392-.514-.6-.9-.47a.715.715 0 00-.462.915l1.568 4.991c-6.942.967-10.31 6.403-11.133 7.919.026.052.771 1.411 2.417 3.084zm21.983-2.378c-.437.731-1.723 2.509-4.371 3.92.823-1.307 1.311-2.849 1.311-4.443 0-1.751-.54-3.371-1.465-4.704 2.75 1.777 4.114 4.312 4.525 5.227zM13.447 7.536c.257 0 .489.026.72.026 3.24.47 5.708 3.215 5.708 6.534 0 2.17-1.105 4.181-2.854 5.41l-.72-2.326a4.492 4.492 0 001.234-3.11c0-2.17-1.542-3.999-3.574-4.39l-.668-2.17c.051.026.103.026.154.026zm-1.62.105l.643 2.064c-.411.053-.771.183-1.131.34l-.694-2.221c.385-.079.771-.13 1.182-.183zm-2.545.549l.848 2.691c-.334.34-.642.732-.848 1.176l-1.08-3.475c.334-.131.694-.262 1.08-.392z" _fill="#75757A"/>'
  }
})
