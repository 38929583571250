/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'library': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M19.64 3.267h-.018c-.342.002-.395-.296-.4-.434V.674a.417.417 0 00-.418-.417h-6.172c-1.55 0-2.127.574-2.27.755l-.04.054-.122.168c-.164.226-.307.13-.376.056L9.591.998 9.475.86c-.194-.204-.7-.602-1.703-.602H1.196a.417.417 0 00-.418.417v2.132c0 .373-.202.448-.326.46H.359c-.101.006-.337.065-.337.499v10.158c0 .249.202.45.451.45h6.002c1.915 0 2.75.723 3 1l.142.177s.383.432.787 0l.084-.099v.001l.107-.126a2.38 2.38 0 01.066-.07c.32-.311 1.119-.882 2.68-.882h6.185a.45.45 0 00.45-.452V3.666c.001-.367-.265-.398-.336-.4zm-1.349 9.062c0 .2-.167.362-.375.362h-5.088c-1.811 0-2.469.818-2.469.818l-.083.112-.04.054c-.173.235-.426.002-.428 0l-.117-.146c-.087-.104-.752-.838-2.362-.838H2.083a.368.368 0 01-.375-.362V1.504c0-.2.168-.362.375-.362h4.073c3.03 0 3.317 1.721 3.336 2.22v7.53c0 .547.26.649.408.664h.22c.149-.015.387-.104.387-.53v-7.76h-.003c.024-.626.267-2.124 2.09-2.124h5.322c.208 0 .375.162.375.362v10.825z" _fill="#75757A"/>'
  }
})
