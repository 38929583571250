<template>
  <div class="changeStateButtons">
    <div class="grid-x">
      <button class="cell small-6 large-6 prestaCardButton refuseButton" @click="chooseReason">
        <svgicon icon="refuse" />
        <span>{{$t('prestation.refuse')}}</span>
      </button>
      <button class="cell small-6 large-6 prestaCardButton acceptButton" @click="accept">
        <svgicon icon="accept" />
        <span>{{$t('prestation.accept')}}</span>
      </button>
    </div>
    <div v-if="motifs" class="grid-x motifsLine">
      <select class="cell small-8 large-6" v-model="motif">
        <option :value="null">{{ $t('common.selectOption') }}</option>
        <option v-for="m in motifs" :value="m" :key="m">{{ $t('prestation.reason.'+m) }}</option>
      </select>
      <button class="cell small-4 large-6 prestaCardButton refuseButton" @click="refuse">{{$t('prestation.chooseReason')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrestationCardButtons',
  props: { prestation: Object },
  data () {
    return {
      motifs: null,
      motif: null
    }
  },
  mounted () {
  },
  methods: {
    accept: function () {
      const presta = {
        herokuExternalId: this.prestation.herokuExternalId,
        state: this.$sncfRefs.getPrestaStatesByTechnicalName().accepted,
        statusTN: 'todo'
      }
      this.$backendConnector.updatePrestation(presta)
        .then(newPrestation => this.$emit('prestationEdited', newPrestation))
        .catch(console.error)
    },
    refuse: function () {
      if (!this.motif) {
        return this.$toastService.showToast(this.$t('prestation.errorReason'), null, 'error')
      }
      const presta = {
        herokuExternalId: this.prestation.herokuExternalId,
        state: this.$sncfRefs.getPrestaStatesByTechnicalName().refused,
        statusTN: 'notDone',
        reason: this.motif
      }
      this.$backendConnector.updatePrestation(presta)
        .then(newPrestation => this.$emit('prestationEdited', newPrestation))
        .catch(console.error)
    },
    chooseReason: function () {
      if (this.prestation.garanteed === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed) { // Garantie
        this.motifs = this.$sncfRefs.getPrestaReasonsTechnicalNames()
      } else { // Non garantie
        this.motifs = ['noPersonnelAvailable', 'trainRemoved', 'unavailableEquipment',
          'other', 'entryError']
      }
    }
  }
}
</script>
