<template>
  <div
    ref="element"
  >
    <div class="simplebar-wrapper">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer" />
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset">
            <div class="simplebar-content-wrapper" ref="scrollElement" v-on:scroll="onScroll">
              <div class="simplebar-content" ref="contentElement">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="simplebar-placeholder" />
      </div>
      <div class="simplebar-track simplebar-horizontal">
        <div class="simplebar-scrollbar" />
      </div>
      <div class="simplebar-track simplebar-vertical">
        <div class="simplebar-scrollbar" />
      </div>
      <div class="scrollbarBackToTop" v-if="backToTop && isAtScrollEnd" @click="scrollToTop">Retour<svgicon icon="arrow_right" /></div>
  </div>
</template>

<script>
import SimpleBar from 'simplebar' // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css'

export default {
  name: 'SimpleBar',
  props: {
    scrollBotTolerance: {
      type: Number,
      default: 100
    },
    backToTop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isAtScrollEnd: false,
      simpleBarInstance: null
    }
  },
  methods: {
    onScroll: function (el) {
      this.$emit('scroll', el)
      // check if scroll is at end
      if (this.scrollElement.scrollTop >= (this.scrollElement.scrollHeight - this.scrollElement.clientHeight - this.scrollBotTolerance)) {
        this.$emit('scrollEnd')
        this.isAtScrollEnd = true
      } else {
        this.isAtScrollEnd = false
      }
    },
    scrollToTop: function () {
      this.scrollElement.scrollTop = 0
    }
  },
  mounted: function () {
    const options = SimpleBar.getOptions(this.$refs.element.attributes)
    this.simpleBarInstance = new SimpleBar(this.$refs.element, options)
  },
  computed: {
    scrollElement () {
      return this.$refs.scrollElement
    },
    contentElement () {
      return this.$refs.contentElement
    }
  }
}
</script>
