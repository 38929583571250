/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accessible': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_3872_118159)"><circle pid="0" cx="10" cy="10" r="10" _fill="#0C66EE"/><path pid="1" d="M14.375 8.75V7.5h-8.75v1.25H8.75v1.709L6.332 14.69l1.086.62 2.32-4.06h.524l2.32 4.06 1.085-.62-2.417-4.231V8.75h3.125zM10 6.875a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" _fill="#fff"/></g><defs><clipPath id="clip0_3872_118159"><path pid="2" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
