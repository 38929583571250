<template>
  <div id="socaApp">
    <AuthComponent />
    <HeaderNav />
    <div id="socaCentral">
      <MainMenuNav />
      <div id="socaMainContent" :class="{'footerIsHidden' : !$store.state.showFooter}">
        <transition name="router-transition" v-on:before-enter="beforeEnterTransition" v-on:enter="enterTransition" v-on:leave="leaveTransition">
          <simplebar data-simplebar-auto-hide="false" class="socaMainContentSimpleBar">
            <router-view :key="$route.fullPath"></router-view>
          </simplebar>
        </transition>
      </div>
      <Notifications />
      <NewsTopReader v-if="isAuthenticated" @newsListChange="checkNews" />
      <OnBoardModal v-if="isAuthenticated && this.$route.path !== '/signCharter' && isOnBoard && !hasNews" />
    </div>
    <FooterNav />
    <EnvPill />
  </div>
</template>

<script>
import MainMenuNav from '@/components/Nav/MainMenu'
import HeaderNav from '@/components/Nav/Header'
import FooterNav from '@/components/Nav/Footer'
import Notifications from '@/components/Notification/Notifications'
import AuthComponent from '@/components/Auth/AuthComponent'
import NewsTopReader from '@/components/News/NewsTopReader'
import EnvPill from '@/components/Nav/Header/EnvPill'
import OnBoardModal from '@/components/Prestations/OnBoardModal'

import '@/assets/svgcompiledicons'
import notificationService from '@/services/notification'
import pwaService from '@/services/pwa'
import authService from '@/services/authService'
import userService from '@/services/userService'

export default {
  name: 'app',
  components: {
    MainMenuNav,
    HeaderNav,
    FooterNav,
    AuthComponent,
    Notifications,
    NewsTopReader,
    EnvPill,
    OnBoardModal,
  },
  data () {
    return {
      inverseTransition: null,
      hasNews: true
    }
  },
  created () {
    console.log('Vue app created')

    pwaService.init()// enregistrement service worker et install appli

    this.$store.state.showFooter = true
  },
  computed: {
    isAuthenticated: function () {
      return authService.isAuthenticated()
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    }
  },
  sockets: {
    connect (data) {
      notificationService.initNotificationServiceSocket(this.$socket)// register for notif
    },
    prestationNotification (data) {
      notificationService.onNewNotification(data)
    },
    desactivateNotifCode (notifCode) {
      notificationService.desactivateNotifCode(notifCode)
    },
    deactivateAlertNotifId (alertNotifCode) {
      notificationService.deactivateAlertNotifBycode(alertNotifCode)
    },
    authIsOk (data) {
      notificationService.initNotificationServiceSocketAuthOk()// finish the notif init process
      notificationService.initPushService()// register for web push
    },
  },
  mounted () {
    console.log('Vue app mounted')
    notificationService.initNotificationServiceGlobal()// register for notif
    if (this.$sncfRefs.isActiveFeatureByCode("STOP_AND_GO")) {
      notificationService.initStopAndGoSocket(this.$socket)
    }
    this.hasNews = true
    window.addEventListener("beforeunload", (event) => {
      this.$root.$emit('filterPrestationReset')
    })
  },
  destroyed () {
    console.log('Vue app destoyed')
  },
  methods: {
    beforeEnterTransition: function (el) {
      if (el.id === 'homeSoca') {
        this.inverseTransition = true
      } else {
        this.inverseTransition = false
      }
    },
    enterTransition: function (el, done) {
      if (this.inverseTransition) {
        $(el).addClass('inverse-transition')
      } else {
        $(el).removeClass('inverse-transition')
      }
    },
    leaveTransition: function (el, done) {
      if (this.inverseTransition) {
        $(el).addClass('inverse-transition')
      } else {
        $(el).removeClass('inverse-transition')
      }
      $(el).addClass('hide')
    },
    checkNews (newsList) {
      this.hasNews = newsList && newsList.length > 0
    }
  }
}
</script>
