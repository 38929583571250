<template>
  <div class='socaContent'>
    <div data-alert class="alertBox info">{{ $t('common.disconnected') }}</div>
    <a class="button button-theme" :href="urlIdpDisconnect">{{ $t('common.disconnectIdp') }}</a>
  </div>
</template>

<script>
export default {
  name: 'AuthDisconnected',
  computed: {
    urlIdpDisconnect: function () {
      return process.env.VUE_APP_OPENID_URL + '/openam/UI/Logout?goto=' +
        encodeURIComponent(process.env.VUE_APP_OPENID_REDIRECT_BASE_URL + '/disconnected')
    }
  }
}
</script>
