<template>
  <div class="formElement stationSearchInput">
    <label class="position-relative">
      <svgicon icon="loader" class="fieldLoaderStation" v-if="isSearching"/>
      <input type="text" :placeholder="$t('stations.search')" v-model="search" v-debounce="searchStation">
    </label>
  </div>
</template>

<script>
import { filterStations } from '@/services/stationService'

export default {
  name: 'SearchStationField',
  data: function () {
    return {
      search: null,
      searchStationCallId: 0,
      isSearching: false
    }
  },
  props: { stationsData: Array },
  methods: {
    searchStation (target) {
      this.isSearching = true
      if (this.search.length >= 3) {
        if (!this.stationsData || this.stationsData.length === 0) {
          this.searchStationCallId++
          let self = this
          let currentSearchStationCallId = this.searchStationCallId
          return this.$backendConnector.searchStations(this.search)
            .then(results => {
              this.isSearching = false
              if (self.searchStationCallId !== currentSearchStationCallId) { return }
              this.$emit('searchResults', results)
            })
            .catch(err => console.log(err))
        } else {
          this.isSearching = false
          let stations = this._.cloneDeep(this.stationsData)
          let res = filterStations(stations, this.search)
          this.$emit('searchResults', res)
        }
      } else {
        this.isSearching = false
        this.$emit('searchResults', [])
      }
      if (this.search.length === 0) {
        this.isSearching = false
        this.$emit('searchResults', this.$store.getters.possibleStations)
      }
    }
  },
  components: { }
}
</script>
