import moment from 'moment'
import _ from 'lodash'

const dayOptions = [
  {
    indexDay: -1,
    dateStr: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    date: moment().subtract(1, 'day').startOf('day').toDate()
  },
  {
    indexDay: 0,
    dateStr: moment().format('YYYY-MM-DD'),
    date: moment().startOf('day').toDate()
  },
  {
    indexDay: 1,
    dateStr: moment().add(1, 'day').format('YYYY-MM-DD'),
    date: moment().add(1, 'day').startOf('day').toDate()
  }
]

export default {
  namespaced: true,
  state: {
    isModalFiltered: false,
    daySelected: [dayOptions[1]], // 0, -1 or 1 => today, yesterday or tommorow
    statuses: ['todo'],
    mentions: ['D', 'AC', 'DC', 'A', 'N'],
    modalFilters: {
      number: null,
      name: null,
      datePrestation: {
        start: null,
        startHour: null,
        stop: null,
        stopHour: null
      },
      trainNumber: null,
      mention: null,
      status: null,
      parcours: null,
      missionCode: null,
      specAssist: null,
      service: null,
      transporter: null,
      garantee: null,
      stations: null,
      transportMode: null,
      lastUpdate: null,
      agency: null,
      region: null,
      reasons: null,
      assistNature: null,
      assistResp: null
    }
  },
  mutations: {
    setDaysSelected (state, days) {
      if (days.length === 0) return
      state.daySelected = days
      if (days[0].indexDay === 'isFiltered') return

      let stopDate = days[0].date
      if (days.length > 1) stopDate = days[days.length - 1].date
      state.modalFilters.datePrestation = {
        start: days[0].date,
        startHour: null,
        stop: stopDate,
        stopHour: null
      }
    },
    setLastUpdateDate (state, lastUpdateDate) {
      state.lastUpdate = lastUpdateDate
    },
    checkStatus (state, status) {
      if (state.statuses.includes(status)) {
        state.statuses.splice(state.statuses.indexOf(status), 1)
      } else {
        state.statuses.push(status)
      }
      state.modalFilters.status = state.statuses
    },
    checkMention (state, mention) {
      if (state.mentions.includes(mention)) {
        state.mentions.splice(state.mentions.indexOf(mention), 1)
      } else {
        state.mentions.push(mention)
      }
      state.modalFilters.mention = state.mentions
    },
    modalFilters (state, filters) {
      state.modalFilters = Object.assign(state.modalFilters, filters)
      if (state.modalFilters.status) {
        state.statuses = state.modalFilters.status
      }
      if (state.modalFilters.mention) {
        state.mentions = state.modalFilters.mention
      }
    },
    resetDirectFilters (state) {
      state.statuses = getStandardValues().statuses
      state.mentions = getStandardValues().mentions
    },
    resetModalFilters (state) {
      state.modalFilters = getStandardValues().modalFilters
    },
    resetFiltersExceptTrainNumber (state) {
      state.modalFilters = modalFiltersExceptTrainNumber(state.modalFilters)
    },
    resetDaySelected (state) {
      state.daySelected = getStandardValues().daySelected
    },
    modalFiltered (state, val) {
      state.isModalFiltered = val
    },
    reset (state) {
      state = getStandardValues()
    },
    trainNumber(state, value) {
      state.modalFilters.trainNumber = value
    },
    transporter(state, value) {
      state.modalFilters.transporter = value
    }
  },
  getters: {
    daySelected: state => {
      // if dayoption is out of date, reset it
      if (state.daySelected.length > 0 &&
          state.daySelected[0].dateStr !== dayOptions.find(dayo => dayo.indexDay === state.daySelected[0].indexDay).dateStr) {
        state.daySelected = [dayOptions[1]]
      }
      return state.daySelected
    },
    daySelectOptions: () => {
      return dayOptions
    },
    lastUpdateDate: state => {
      return (state.lastUpdate)
    },
    statuses: state => {
      return (state.statuses)
    },
    stations: state => {
      return (state.stations)
    },
    mentions: state => {
      return (state.mentions)
    },
    modalFilters: state => {
      return state.modalFilters
    },
    isModalFiltered: state => {
      return state.isModalFiltered
    }
  }
}

let getStandardValues = () => {
  return _.cloneDeep({
    isModalFiltered: false,
    daySelected: [dayOptions[1]], // 0, -1 or 1 => today, yesterday or tommorow
    statuses: ['todo'],
    mentions: ['D', 'AC', 'DC', 'A', 'N'],
    modalFilters: {
      number: null,
      name: null,
      datePrestation: {
        start: moment().startOf('day').toDate(),
        startHour: null,
        stop: moment().startOf('day').toDate(),
        stopHour: null
      },
      trainNumber: null,
      mention: ['D', 'AC', 'DC', 'A', 'N'],
      status: ['todo'],
      parcours: null,
      missionCode: null,
      specAssist: null,
      service: null,
      transporter: null,
      garantee: null,
      stations: null,
      transportMode: null,
      lastUpdate: null,
      agency: null,
      region: null,
      reasons: null,
      assistNature: null,
      assistResp: null
    }
  })
}

let modalFiltersExceptTrainNumber = (filter) => {
  return _.cloneDeep({
    ...filter,
    number: null,
    name: null,
    datePrestation: {
      start: moment().startOf('day').toDate(),
      startHour: null,
      stop: moment().startOf('day').toDate(),
      stopHour: null
    },
    mention: ['D', 'AC', 'DC', 'A', 'N'],
    status: ['todo'],
    parcours: null,
    missionCode: null,
    specAssist: null,
    service: null,
    garantee: null,
    stations: null,
    transportMode: null,
    lastUpdate: null,
    agency: null,
    region: null,
    reasons: null,
    assistNature: null,
    assistResp: null
  })
}
