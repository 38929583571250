/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_left': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M7.81 12.78a.983.983 0 000-1.413l-3.949-3.82H14A.992.992 0 0015 6.553a.992.992 0 00-.998-.994H3.838c1.509-1.501 3.794-3.82 3.794-3.82a.983.983 0 000-1.413.995.995 0 00-1.42 0L0 6.553l6.39 6.227c.4.375 1.043.375 1.42 0z"/>'
  }
})
