<template>
    <div class="stationSelectZone">
        <div class="stationSelectBorder stationSelectBorderLeft"></div>
        <Multiselect :options="stationsList" :value="currentValue"
          :multiple="true" label="name" track-by="codeUic" :close-on-select="false" :clear-on-select="false"
          deselectLabel="✕" selectLabel="" selectedLabel="✔" placeholder=""
          :searchable="searchable"
          :internal-search="false" :options-limit="120"
          @search-change="debouncedSearchStation" @input="selectStations"
          @open="isOpening" @close="isClosing">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length == 0 && !(isOpen)">{{ placeholderNoSelection || $t('navigation.stationselect.allstations') }}</span>
            <span v-if="values.length == 1 && !(isOpen)">{{ values[0].name }}</span>
            <span v-if="values.length > 1 && !(isOpen)">{{ $tc('navigation.stationselect.xstationsselected', values.length) }}</span>
          </template>
          <template slot="caret" v-if="useStore"><svgicon icon="arrow"/></template>
          <template slot="noResult">{{$t('navigation.stationselect.searchNoResults') }}</template>
          <template slot="noOptions">{{$t('navigation.stationselect.searchNoResults') }}</template>
          <template slot="option" slot-scope="props">
            <span class="option__title">{{ props.option.name }}</span><span class="option__circle"><span class="option__circle_in"></span></span>
          </template>
        </Multiselect>
        <div v-if="showOverlay" class="stationSelectOverlay"></div>
        <div class="stationSelectBorder stationSelectBorderRight"></div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect/src/Multiselect'
import accents from 'remove-accents'
import { debounce } from 'simple-vue-validator/src/utils'

export default {
  name: 'StationSelect',
  props: {
    useStore: { default: true, type: Boolean },
    value: { default: null, type: Array },
    allStations: { default: null, type: Boolean },
    placeholderNoSelection: { default: null, type: String }
  },
  data: function () {
    return {
      currentValue: null,
      stationsSearchResult: [],
      searchable: null,
      searchStationCallId: 0,
      showOverlay: false
    }
  },
  created () {
    this.debouncedSearchStation = debounce((query) => this.asyncStationSearch(query), 500)
    this.currentValue = this.useStore ? this.$store.getters.selectedStations : this.value
    if (this.useStore) {
      this.$store.watch(
        (state, getters) => getters.selectedStations,
        (newValue, oldValue) => {
          this.currentValue = newValue
        }
      )
    }
    // this.searchable = this.allStations == null ? this.$perms.isUserAllowed('presta.stations.all') : this.allStations
    this.searchable = true
  },
  methods: {
    asyncStationSearch (query) {
      let stations = this._.cloneDeep(this.$store.getters.possibleStations)
      if (query.length > 0) {
        if (this.$perms.isUserAllowed('presta.stations.all') || stations.length === 0) {
          this.searchStationCallId++
          let self = this
          let currentSearchStationCallId = this.searchStationCallId
          this.$backendConnector.searchStations(query)
            .then(results => {
              if (self.searchStationCallId !== currentSearchStationCallId) { return }
              this.stationsSearchResult = results
            })
        } else {
          let res = stations.filter(s => {
            if (!query) { return }
            if (!s.name) { return }
            const localStationName = accents.remove(s.name.toLowerCase()).replace(new RegExp(/[-_]/g), ' ')
            const queryStationName = accents.remove(query.toLowerCase()).replace(new RegExp(/[-_]/g), ' ')
            return localStationName.indexOf(queryStationName) !== -1
          })
          this.stationsSearchResult = res
        }
      } else {
        this.stationsSearchResult = []
      }
    },
    selectStations (value) {
      this.currentValue = value
      this.useStore && this.$store.commit('setSelectedStations', value)
      this.$emit('valueChanged', value)
    },
    isOpening () {
      if (this.useStore) {
        this.showOverlay = true
      }
    },
    isClosing () {
      if (this.useStore) {
        this.showOverlay = false
      }
    }
  },
  computed: {
    stationsList: function () {
      let stations = []
      if (this.stationsSearchResult.length > 0) {
        stations = [...this.stationsSearchResult]
      } else {
        stations = [...this.$store.getters.possibleStations]
      }

      // check if selected values are not present in selectables stations
      for (var i in this.currentValue) {
        let st = this.currentValue[i]
        if (stations.find(x => x.codeUic === st.codeUic) === undefined) {
          stations.push(st)
        }
      }

      return stations
    }
  },
  watch: {
    value: function (newVal) {
      this.currentValue = newVal
    }
  },
  components: { Multiselect }
}
</script>
