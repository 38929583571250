var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('vDatePicker',{staticClass:"v-datepicker",attrs:{"locale":_vm.$i18n.locale,"disabled-dates":_vm.disabledDates,"available-dates":_vm.availableDates,"selectAttribute":{ highlight: 'orange' },"popover":{ positionFixed: true },"masks":{
      title: 'MMMM YYYY',
      weekdays: 'W',
      navMonths: 'MMM',
      input: ['DD-MM-YYYY'],
      dayPopover: 'WWW, MMM D, YYYY',
      data: ['DD-MM-YYYY']
    },"first-day-of-week":2,"value":_vm.value},on:{"input":_vm.onInput}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }