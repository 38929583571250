<template>
  <div class="adminPage">
    <div class='socaContent'>
      <button v-if="previewNews" type='button' class="button-light previewButton" @click="previewNewsOn = true">{{ $t('news.form.preview') }}</button>
      <div class="pageTitleLight">{{$t('news.form.updateTitle') }}</div>
      <NewsModal v-if="previewNewsOn === true" :news="previewNews" @close="previewNewsOn = false" />
    </div>
    <NewsForm v-model="news" :validateLabel="'user.form.create'" :isUpdate="true" @input="save" @prevNewsEmit="prevNewsFunc" v-if="news"></NewsForm>
  </div>
</template>

<script>
import NewsForm from '@/components/News/Form'
import NewsModal from '@/components/News/NewsModal'
import moment from 'moment'

export default {
  name: 'NewsEdit',
  components: { NewsForm, NewsModal },
  data () {
    return {
      news: null,
      previewNews: null,
      previewNewsOn: false
    }
  },
  created () {
    return this.fetchNews(this.$route.params.id)
  },
  methods: {
    fetchNews (id) {
      let self = this
      return this.$backendConnector.getNews(id)
        .then(res => {
          self.news = res
          self.news.startDate = new Date(self.news.startDate)
          self.news.stopDate = new Date(self.news.stopDate)
        })
        .catch(err => console.log(err))
    },
    save: async function (news) {
      news.startDate = moment(news.startDate).format('YYYY-MM-DD')
      news.stopDate = moment(news.stopDate).format('YYYY-MM-DD')
      await this.$backendConnector.editNews(news.id, { news: news })
      this.$router.push({ name: 'news', params: { code: news.id } })
    },
    prevNewsFunc: function (newPrev) {
      this.previewNews = newPrev
    }
  }
}
</script>
