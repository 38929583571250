<template>
  <div id="prestationListPage">
    <div id="prestationList">
      <div id="prestationListHeader" class="pageTitle">
        <div class="socaContent">
          <div class="headerTitle">
            <div class="daySelect">
              <svgicon icon="arrow"/>
              <MultipleDropdown v-model="selectedDays" :options="$store.getters['homeFilters/daySelectOptions']" labelName="dateStr" valueName="indexDay"
                :renderSelectionFunc="formatDays" :renderSelectionFuncMobile="formatDaysMobile" :renderLabelFunc="prettifiedDate" @input="saveTheDate"/>
            </div>
            <div class="headerLbl">{{ selectedDateLbl }}</div>
          </div>
          <div class="headerSecond">
            <span class="prestationTotal">{{ $tc('prestation.prestations', nbTotalPrestations) }}</span>
          </div>
          <button class="filterButton"
            v-bind:class="{ 'isFiltered': $store.getters['homeFilters/isModalFiltered'] }"
            @click="swapComponent"
            v-tooltip="{
              content: $t('common.filterstooltip'),
              trigger: 'hover',
            }"
          ><svgicon icon="filter"/><span>{{ $t('common.filter') }}</span></button>
        </div>
      </div>
      <div id="desktopDirectFilters" class="hide-for-small-only">
        <div class="socaContent">
          <div class="prestaDirectFilters filterMention">
            <div class="filterTitle">{{ $t('prestation.filters.filterByMention') }}</div>
            <div class="filterContent">
              <div class="filterValue" v-for="mention in mentionsList" :key="mention.sigle" :value="mention.sigle"
                :class="{ 'filterChecked' : $store.getters['homeFilters/mentions'].includes(mention.sigle)}"
                v-on:click="$store.commit('homeFilters/checkMention', mention.sigle);clickDirectFiltersList()"
                >{{ mention.sigle }}</div>
            </div>
          </div>
          <div class="prestaDirectFilters filterStatus">
            <div class="filterTitle">{{ $t('prestation.filters.filterByStatus') }}</div>
            <div class="filterContent">
              <div class="filterValue" v-for="statusTN in statusesTNList" :key="statusTN" :value="statusTN"
                :class="{ 'filterChecked' : $store.getters['homeFilters/statuses'].includes(statusTN)}"
                v-on:click="$store.commit('homeFilters/checkStatus', statusTN);clickDirectFiltersList()"
                >{{ $t('prestation.status.'+statusTN) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="socaContent lastUpdateContainer hide-for-small-only">
        <div class="lastUpdateContent grid-x">
          <div class="lastUpdateInfo small-7" v-if="lastUpdateDate">{{ $t('prestation.lastupdate') }} : {{ lastUpdateDateLbl }}</div>
          <div class="small-5">
            <button @click="downloadAsCsv">
              <svgicon icon="download" class="download-icon" />
              <span class="button-text">{{$t('common.download.csv')}}</span>
            </button>
            <button @click="downloadAsPdf">
              <svgicon icon="download" class="download-icon" />
              <span class="button-text">{{$t('common.download.pdf')}}</span>
            </button>
          </div>
        </div>
      </div>
      <simplebar id="prestationItems" class="socaContent"
        v-on:scroll="mainContentScroll"
        v-on:scrollEnd="mainContentScrollEnd"
        :backToTop="true"
        data-simplebar-auto-hide="false">
        <div class="lastUpdateInfo grid-x hide-for-medium" v-if="lastUpdateDate">{{ $t('prestation.lastupdate') }}&nbsp;:&nbsp;{{ lastUpdateDateLbl }}</div>
        <div>
          <div v-for="(prestationDetails, index) in prestations" :key="prestationDetails.herokuExternalId">
            <div class="prestationDatePrestationTitle" v-if="(index === 0 && $store.getters['homeFilters/isModalFiltered']) ||
                      (index >  0 && !checkIfSameDate(prestations[index-1].datePrestation, prestationDetails.datePrestation))"
              >{{ prettyDate(prestationDetails.datePrestation) }}</div>
            <PrestationCard :prestation="prestationDetails"></PrestationCard>
          </div>
          <div v-if="prestations.length == 0 && !(isLoading)" class="listEmptyMessage">{{ $t('prestation.listisempty') }}</div>
        </div>
        <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
      </simplebar>
    </div>
    <div v-show="showFilters" v-bind:class="{ 'hidden': !showFilters }">
      <PrestationFilters ref="pageChanged" @close="close" @filteredPrestations="filteredPrestations" />
    </div>
  </div>
</template>

<script>
import PrestationCard from './PrestationCard'
import PrestationFilters from './PrestationFilters'
import moment from 'moment'
import omitEmpty from 'omit-empty'
import prestationConverterService from '@/services/prestationConverterService'
import notificationService from '@/services/notification'
import commonService from '@/services/common'
import MultipleDropdown from '@/components/Common/MultipleDropdown'

export default {
  name: 'PrestationList',
  data () {
    return {
      prestations: [],
      nbTotalPrestations: null,
      showFilters: false,
      pageSize: 50,
      pageLastDatetime: null,
      pageLastDatetimeId: null,
      pageScrollEnded: false,
      isLoading: false,
      lastUpdateDate: null,
      mentionsList: [],
      statusesTNList: [],
      daySelectOptions: null,
      selectedDays: null,
      searchPrestaCallId: 0,
    }
  },
  created () {
    this.generateDropdownData()
    // react to new prestation notification
    let self = this
    notificationService.addNotificationCallback('prestationlist', function (notification) {
      if (notification.author !== self.$store.getters['userCode']) {
        notification.Prestation.updatedFromNotif = true
      }
      if (notification.type === 'CREATED' &&
          !self.prestations.some(p => p.herokuExternalId === notification.Prestation.herokuExternalId) && // pas déjà présente
          self.checkIfPrestaRespectFilters(notification.Prestation)) { // la presta correspond t-elle aux filtres actifs
        let prestaAdded = false
        for (let i in self.prestations) {
          if (!prestaAdded && self.prestations[i].datePrestation > notification.Prestation.datePrestation) { // place the prestation at the right spot
            self.prestations.splice(i, 0, notification.Prestation)
            prestaAdded = true
          }
        }
        if (!prestaAdded) { // push in last position
          self.prestations.push(notification.Prestation)
        }
        self.lastUpdateDate = new Date()
        self.nbTotalPrestations++
      } else if (notification.type !== 'CREATED') { // maj
        // Replace prestation if present in list
        for (let i in self.prestations) {
          if (self.prestations[i].herokuExternalId === notification.Prestation.herokuExternalId) {
            self.prestations[i] = notification.Prestation
            self.lastUpdateDate = new Date()
          }
        }
        // todo add in list if it respects filters now ? (and not before)
      }
    })
  },
  mounted () {
    this.mentionsList = this.$sncfRefs.getMentions()
    this.statusesTNList = this.$sncfRefs.getPrestaStatusesTechnicalNames()
    this.statusesTNList.map((status, index) => {
      if (status === 'cancelled') {
        this.statusesTNList.splice(index, 1)
      }
    })
    this.getPrestations()
    // Synchro to context stations
    this.$store.watch(
      (state, getters) => getters.selectedStations,
      (newValue, oldValue) => {
        this.pageLastDatetime = null
        this.pageLastDatetimeId = null
        this.getPrestations()
      }
    )
    this.lastUpdateDate = new Date()
  },
  methods: {
    generateDropdownData () {
      this.daySelectOptions = this._.clone(this.$store.getters['homeFilters/daySelectOptions'])
      if (this.$store.getters['homeFilters/isModalFiltered']) {
        this.daySelectOptions.unshift({ indexDay: 'isFiltered', dateStr: this.getFilterDateStr() })
        this.selectedDays = [this.daySelectOptions[0]]
      } else {
        this.selectedDays = this.$store.getters['homeFilters/daySelected']
      }
    },
    formatDays (values, isMobile = false) {
      if (!values) return '...'
      let ret = null
      if (values.length === 1) {
        ret = this.prettifiedDate(values[0], isMobile)
      } else if (values.length > 1) {
        ret = this.prettifiedDate(values[0], isMobile) + ' - ' + this.prettifiedDate(values[values.length - 1], isMobile)
      } else {
        ret = '...'
      }
      return ret
    },
    formatDaysMobile (values) {
      return this.formatDays(values, true)
    },
    saveTheDate (v) {
      let hasFilter = this.daySelectOptions.length > 0 && this.daySelectOptions[0].indexDay === 'isFiltered'
      if (hasFilter && v.length > 1) {
        this.daySelectOptions.shift()
        if (this.selectedDays.some(d => d.indexDay === 'isFiltered')) {
          this.selectedDays.shift()
        }
      }
      if (this.selectedDays.length === 0) {
        this.selectedDays = [this.daySelectOptions[1]]
      }
      this.selectedDays.sort((a, b) => a.indexDay - b.indexDay)
      if (this.selectedDays.length > 0) this.$store.commit('homeFilters/setDaysSelected', this.selectedDays)
      this.onChangeDaySelected()
    },
    prettifiedDate (day, isMobile = false) {
      if (day.indexDay === 'isFiltered') {
        return this.getFilterDateStr(isMobile)
      } else {
        return isMobile ? this.prettyDateMobile(day.dateStr) : this.prettyDate(day.dateStr)
      }
    },
    swapComponent () {
      this.showFilters = !this.showFilters
    },
    close () {
      this.showFilters = false
    },
    filteredPrestations () {
      this.generateDropdownData()
      this.pageLastDatetime = null
      this.pageLastDatetimeId = null
      this.getPrestations()
      this.showFilters = false
    },
    getFilterDateStr (isMobile = false) {
      let prettyDateFunc = isMobile ? this.prettyDateMobile : this.prettyDate
      let modFilters = this.$store.getters['homeFilters/modalFilters']
      if (modFilters.datePrestation.start) {
        if (modFilters.datePrestation.stop) {
          if (modFilters.datePrestation.start.toString() === modFilters.datePrestation.stop.toString()) {
            return prettyDateFunc(moment(modFilters.datePrestation.start))
          }
          return prettyDateFunc(moment(modFilters.datePrestation.start)) + ' - ' + prettyDateFunc(moment(modFilters.datePrestation.stop))
        } else {
          return prettyDateFunc(moment(modFilters.datePrestation.start)) + ' - ...'
        }
      } else {
        if (modFilters.datePrestation.stop) {
          return '... - ' + prettyDateFunc(moment(modFilters.datePrestation.stop))
        } else {
          return '...'
        }
      }
    },
    onChangeDaySelected () {
      this.pageLastDatetime = null
      this.pageLastDatetimeId = null
      this.getPrestations()
    },
    getPrestations () {
      if (this.pageLastDatetime === null) {
        this.prestations = []
        this.pageScrollEnded = false
      }
      this.isLoading = true
      this.searchPrestaCallId++
      let currentSearchPrestaCallId = this.searchPrestaCallId
      let self = this
      return this.$backendConnector.filterPrestations({ filters: this.getBackendFilters() }, { pagesize: this.pageSize, pageLastDatetime: this.pageLastDatetime, pageLastDatetimeId: this.pageLastDatetimeId })
        .then(results => {
          if (this.pageLastDatetime === null) { // if new query
            this.nbTotalPrestations = results.count
          }
          if (self.searchPrestaCallId !== currentSearchPrestaCallId) { return }
          for (let i in results.rows) {
            let currentPresta = results.rows[i]
            if (!this.prestations.some(p => p.herokuExternalId === currentPresta.herokuExternalId)) { // if not already here
              this.prestations.push(currentPresta)
            }
            if (this.pageLastDatetime === null || moment(currentPresta.datePrestation).isAfter(this.pageLastDatetime)) {
              this.pageLastDatetime = currentPresta.datePrestation
              this.pageLastDatetimeId = currentPresta.herokuExternalId
            } else if (this.pageLastDatetime !== null && moment(currentPresta.datePrestation).isSame(this.pageLastDatetime) && currentPresta.herokuExternalId > this.pageLastDatetimeId) {
              this.pageLastDatetimeId = currentPresta.herokuExternalId
            }
          }
          if (results.rows.length < this.pageSize) { // pagination is at end
            this.pageScrollEnded = true
          }
          this.isLoading = false
          this.prestations.sort(function (a, b) {
            return (new Date(a.datePrestation)) - (new Date(b.datePrestation))
          })
        })
    },
    mainContentScroll (event) {
      let element = event.target
      let currentScroll = $(element).scrollTop()
      // check if scroll has started and is hover header
      $('#prestationListHeader').each(function (index) {
        if (currentScroll > $(this).height()) {
          $(this).addClass('scrollHasPassed')
        } else {
          $(this).removeClass('scrollHasPassed')
        }
      })
    },
    mainContentScrollEnd () {
      if (!this.pageScrollEnded && !this.isLoading) {
        this.getPrestations()
      }
    },
    getBackendFilters () {
      let backFilters = {}
      if (this.$store.getters['homeFilters/isModalFiltered']) {
        backFilters = Object.assign({}, omitEmpty(this.$store.getters['homeFilters/modalFilters']))
      } else {
        if (this.$store.getters['homeFilters/daySelected'].length > 0) {
          let dateStart = this.$store.getters['homeFilters/daySelected'][0].date
          let dateStop = dateStart
          if (this.$store.getters['homeFilters/daySelected'].length > 1) {
            dateStop = this.$store.getters['homeFilters/daySelected'][this.$store.getters['homeFilters/daySelected'].length - 1].date
          }
          backFilters.datePrestation = { start: dateStart, stop: dateStop }
        }
      }

      if (this.$store.getters['homeFilters/mentions'] && this.$store.getters['homeFilters/mentions'].length > 0) {
        backFilters.mention = [...this.$store.getters['homeFilters/mentions']]
      }
      if (this.$store.getters['homeFilters/statuses'] && this.$store.getters['homeFilters/statuses'].length > 0) {
        backFilters.status = [...this.$store.getters['homeFilters/statuses']]
      }

      // Use context stations
      if (this.$store.getters.selectedStations.length > 0) {
        backFilters.garePrestaUic = this.$store.getters.selectedStations.reduce(function (prev, curr) {
          return [...prev, curr.codeUic]
        }, [])
      }

      // Retravail des filtres
      if (backFilters.datePrestation) {
        if (backFilters.datePrestation.start) { // Date object
          if (backFilters.datePrestation.startHour) {
            let hourTab = backFilters.datePrestation.startHour.split(':')
            if (hourTab.length === 2) {
              backFilters.datePrestation.start = new Date(backFilters.datePrestation.start)
              backFilters.datePrestation.start.setHours(hourTab[0])
              backFilters.datePrestation.start.setMinutes(hourTab[1])
            }
          }
          backFilters.datePrestation.start = new Date(backFilters.datePrestation.start).toISOString()
        }
        if (backFilters.datePrestation.stop) { // Date object
          if (backFilters.datePrestation.stopHour) {
            let hourTab = backFilters.datePrestation.stopHour.split(':')
            if (hourTab.length === 2) {
              backFilters.datePrestation.stop = new Date(backFilters.datePrestation.stop)
              backFilters.datePrestation.stop.setHours(hourTab[0])
              backFilters.datePrestation.stop.setMinutes(hourTab[1])
            }
          } else { // no end hour, we set it to 04:00 the next morning
            backFilters.datePrestation.stop = moment(backFilters.datePrestation.stop).endOf('day').add('4', 'hours').toDate()
          }
          backFilters.datePrestation.stop = new Date(backFilters.datePrestation.stop).toISOString()
        }
      }
      if (backFilters.stations) { // objects array
        backFilters.garePrestaUic = backFilters.stations.reduce(function (prev, curr) {
          return [...prev, curr.codeUic]
        }, [])
        delete backFilters.stations
      }
      if (backFilters.status) {
        for (let i in backFilters.status) { // convert to back lbl
          backFilters.status[i] = prestationConverterService.getLabelFromStatus(backFilters.status[i])
        }
      }
      return backFilters
    },
    checkIfPrestaRespectFilters (prestaToCheck) {
      let filterRespected = true
      let backFilters = this.getBackendFilters()
      if (backFilters.datePrestation && (
        (new Date(prestaToCheck.datePrestation)) < (new Date(backFilters.datePrestation.start)) ||
        (new Date(prestaToCheck.datePrestation)) > (new Date(backFilters.datePrestation.stop))
      )) {
        filterRespected = false
      }
      if (backFilters.mention && !backFilters.mention.includes(prestaToCheck.mention)) {
        filterRespected = false
      }
      if (backFilters.garantee && backFilters.garantee !== prestaToCheck.garantee) {
        filterRespected = false
      }
      if (backFilters.garePrestaUic && !backFilters.garePrestaUic.includes(prestaToCheck.garePrestaUic)) {
        filterRespected = false
      }
      if (backFilters.transporter && !backFilters.transporter.includes(prestaToCheck.transporter)) {
        filterRespected = false
      }
      if (backFilters.service && !backFilters.service.includes(prestaToCheck.service)) {
        filterRespected = false
      }
      if (backFilters.transportMode && !backFilters.transportMode.includes(prestaToCheck.transportMode)) {
        filterRespected = false
      }
      if (backFilters.garePrestaUic && !backFilters.garePrestaUic.includes(prestaToCheck.garePrestaUic)) {
        filterRespected = false
      }
      if (backFilters.trainNumber && backFilters.trainNumber !== prestaToCheck.trainNumber) {
        filterRespected = false
      }
      if (backFilters.missionCode && backFilters.missionCode !== prestaToCheck.missionCode) {
        filterRespected = false
      }
      if (backFilters.status && !backFilters.status.includes(prestaToCheck.status)) {
        filterRespected = false
      }
      if (backFilters.specAssist && !backFilters.specAssist.includes(prestaToCheck.Parcour.specAssist)) {
        filterRespected = false
      }
      if (backFilters.number && backFilters.number !== prestaToCheck.name) { // number is prestation name
        filterRespected = false
      }
      if (backFilters.name && prestaToCheck.Parcour.Account.name && prestaToCheck.Parcour.Account.name.toLowerCase().contains(backFilters.name.toLowerCase())) { // check client name subset case insensitive
        filterRespected = false
      }
      if (backFilters.parcours && backFilters.parcours !== prestaToCheck.Parcour.name) { // parcours is parcours name
        filterRespected = false
      }
      return filterRespected
    },
    prettyDate (date) {
      return moment(date).locale(this.$i18n.locale).format('dddd Do MMMM YYYY')
    },
    prettyDateMobile (date) {
      return moment(date).locale(this.$i18n.locale).format('dd. DD/MM/YY')
    },
    // clickDirectFiltersList () {
    //   this.pageLastDatetime = null
    //   this.searchPrestaCallId++
    //   let currentSearchPrestaCallId = this.searchPrestaCallId
    //   console.log('searchPrestaCallId: ', this.searchPrestaCallId)
    //   this.getPrestations()
    //     .then(res => {
    //       if (self.searchPrestaCallId !== currentSearchPrestaCallId) {
    //         console.log('current ', currentSearchPrestaCallId, res)
    //         return false
    //       }
    //     })
    //     .catch(err => console.log(err))
    // },
    clickDirectFiltersList () {
      this.pageLastDatetime = null
      this.getPrestations()
    },
    checkIfSameDate (date1, date2) {
      if (!date1 || !date2) {
        return false
      }
      date1 = new Date(date1)
      date2 = new Date(date2)
      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    },
    downloadAsCsv () {
      const csvLimit = 10000
      if (this.nbTotalPrestations > csvLimit) {
        this.$toastService.showToast(this.$tc('prestation.download.downloadOverLimitLimitAt', csvLimit), null, 'warning')
      } else {
        return this.$backendConnector.filterPrestationsCsv({ filters: this.getBackendFilters() })
          .then(result => {
            commonService.downloadBlob(result.data, 'export.csv')
          })
      }
    },
    downloadAsPdf () {
      const pdfLimit = 10000
      if (this.nbTotalPrestations > pdfLimit) {
        this.$toastService.showToast(this.$tc('prestation.download.downloadOverLimitLimitAt', pdfLimit), null, 'warning')
      } else {
        return this.$backendConnector.filterPrestationsPdf({ filters: this.getBackendFilters() })
          .then(result => {
            commonService.downloadBlob(result.data, 'export.pdf')
          })
      }
    }
  },
  computed: {
    selectedDateLbl: function () {
      return this.formatDays(this.selectedDays, true)
    },
    lastUpdateDateLbl: function () {
      return moment(this.lastUpdateDate).locale(this.$i18n.locale).format('dddd Do MMMM YYYY - HH:mm')
    }
  },
  components: { PrestationCard, PrestationFilters, MultipleDropdown }
}
</script>
