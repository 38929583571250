/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accept': {
    width: 19,
    height: 14,
    viewBox: '0 0 19 14',
    data: '<path pid="0" d="M17.536 1.406c.621.611.621 1.613 0 2.223L7.32 13.667l-6.19-6.083a1.558 1.558 0 012.184-2.223l4.006 3.937 8.03-7.892a1.558 1.558 0 012.185 0z" _fill="#fff"/><path pid="1" d="M17.536 1.406c.621.611.621 1.613 0 2.223L7.32 13.667l-6.19-6.083a1.558 1.558 0 012.184-2.223l4.006 3.937 8.03-7.892a1.558 1.558 0 012.185 0z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="9.333" y1=".333" x2="9.333" y2="13.667" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
