import store from '@/store/store.js'

const isUserAllowedForFieldPermission = (permission, field, unixPermission) => {
  let fieldPermissionList = store.getters.userRestrictedRefs.fieldPermissions || {}
  let fieldList = fieldPermissionList[permission] || {}
  let unixPermissions = fieldList[field]
  return !!unixPermissions && unixPermissions.includes(unixPermission)
}

export default {
  isUserAllowed: (permission) => {
    let permissionList = store.getters.user.permissions || []
    return permissionList.includes(permission)
  },
  canUserRead: (permission, field) => {
    return isUserAllowedForFieldPermission(permission, field, 'r')
  },
  canUserEdit: (permission, field) => {
    return isUserAllowedForFieldPermission(permission, field, 'w')
  },
  canUserExecute: (permission, action) => {
    return isUserAllowedForFieldPermission(permission, action, 'x')
  }
}
