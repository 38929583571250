/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 23.547c5.825 0 10.547-4.722 10.547-10.547S18.825 2.453 13 2.453 2.453 7.175 2.453 13 7.175 23.547 13 23.547zM13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13 23.547c5.825 0 10.547-4.722 10.547-10.547S18.825 2.453 13 2.453 2.453 7.175 2.453 13 7.175 23.547 13 23.547zM13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z" _fill="url(#paint0_linear)"/><path pid="2" d="M14.129 7.18H11.7v7.673h2.428V7.18zm-2.704 10.07c0 .825.664 1.489 1.49 1.489.825 0 1.489-.664 1.489-1.49 0-.825-.664-1.49-1.49-1.49-.825 0-1.489.665-1.489 1.49z" _fill="#fff"/><path pid="3" d="M14.129 7.18H11.7v7.673h2.428V7.18zm-2.704 10.07c0 .825.664 1.489 1.49 1.489.825 0 1.489-.664 1.489-1.49 0-.825-.664-1.49-1.49-1.49-.825 0-1.489.665-1.489 1.49z" _fill="url(#paint1_linear)"/><defs><linearGradient id="paint0_linear" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="paint1_linear" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
