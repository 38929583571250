/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exclamation': {
    width: 4,
    height: 16,
    viewBox: '0 0 4 16',
    data: '<path pid="0" d="M.143 2.048a1.857 1.857 0 013.714 0v6.148a1.857 1.857 0 11-3.714 0V2.048zm3.714 11.085c0 1.061-.827 1.915-1.857 1.915S.143 14.194.143 13.133.971 11.22 2 11.22c1.03 0 1.857.853 1.857 1.914z" _fill="#fff"/><path pid="1" d="M.143 2.048a1.857 1.857 0 013.714 0v6.148a1.857 1.857 0 11-3.714 0V2.048zm3.714 11.085c0 1.061-.827 1.915-1.857 1.915S.143 14.194.143 13.133.971 11.22 2 11.22c1.03 0 1.857.853 1.857 1.914z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="2" y1=".19" x2="2" y2="15.048" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs>'
  }
})
