<template>
  <div class="adminPreviewContainer">
    <div class="adminPreview socaContent">
      <div class="closePreview" @click='closePreview'><svgicon icon="close"/></div>
      <div class="itemLine">
        <span class="userName">{{user.lastname}} {{user.firstname}}</span>
        <span class="userStatus" :class="'status-' + user.status">{{ $t('user.statusList.' + user.status) }}</span>
      </div>
      <div class="itemLine" v-if="user.stations">
        <span class="userStations">
          <label>{{ $t('user.stations') }}</label>
          <span v-if="user.stations">{{ stationsDisplay(user.stations, 3) }}</span>
        </span>
      </div>
      <div class="itemLine">
        <span class="userRole">
          <label>{{ $t('user.role') }}</label>
          <span>{{ $t('user.roleList.' + user.role) }}</span>
        </span>
      </div>
      <div class="itemLine" v-if="user.transporters.length > 0">
        <span class="userTransporter">
          <label>{{ $t('user.transporters') }}</label>
          <span>{{user.transporters[0].name}}</span>
        </span>
      </div>
      <div class="itemLine buttonLine">
        <router-link :to="{ name: 'userEdit', params: { code: user.code }}" class="userEdit"><svgicon icon="pencil"/></router-link>
        <span class="userRemove" v-if="$perms.isUserAllowed('users.remove')" @click="modalConfirmDelete = true"><svgicon icon="trash"/></span>
        <router-link :to="{ name: 'user', params: { code: user.code }}" class="userConsult">{{ $t('user.form.view') }}</router-link>
        <button type="button" class="userImpersonate"
          v-if=" $perms.isUserAllowed('users.impersonation')"
          @click="impersonate(user.code)"
        >{{$t('user.form.impersonate') }}</button>
      </div>
      <ConfirmModal :title="$t('user.form.removeConfirm', { user: user.code })" v-if="modalConfirmDelete" @cancel="modalConfirmDelete = false" @confirm="confirmRemove(user.code)">
      </ConfirmModal>
    </div>
  </div>
</template>

<script>
import ConfirmModal from '@/components/Common/ConfirmModal'
import userService from '@/services/userService'

export default {
  name: 'UserPreview',
  props: { user: Object },
  data () {
    return {
      isOpen: null,
      modalConfirmDelete: false
    }
  },
  methods: {
    stationsDisplay (stations, max) {
      let stationsNames = stations.map(station => { return station.name })
      if (stationsNames.length > max) {
        stationsNames = stationsNames.slice(0, max)
      }
      return stationsNames.join(', ')
    },
    impersonate (userCode) {
      let self = this
      this.$store.commit('setImpersonationUserCode', userCode)
      userService.loadUserInfos().then(data => {
        self.$router.go() // refresh page
      })
    },
    confirmRemove (userCode) {
      this.modalConfirmDelete = false
      userService.removeUser(userCode).then(response => {
        if (!response) { return }
        this.closePreview()
        return this.$emit('refresh') // refresh page
      }).then(() => {
        this.$toastService.showToast(this.$t('user.form.removeDone', { user: userCode }), null, 'success')
      })
    },
    closePreview () {
      this.$emit('closePreview')
    }
  },
  components: { ConfirmModal }
}
</script>
