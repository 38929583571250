var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element && _vm.specAssist != undefined)?_c('span',{staticClass:"prestaAssistBox"},[(!_vm.secondaryBox)?_c('div',{staticClass:"typeAssist"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.prestaAssistContent,
      trigger: 'hover click',
    }),expression:"{\n      content: prestaAssistContent,\n      trigger: 'hover click',\n    }"}]},[_vm._v(" "+_vm._s(_vm.element.typeAssist)+" ")])]):_vm._e(),(_vm.specAssist)?_c('div',{staticClass:"specAssist"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.prestaSpecContent,
      trigger: 'hover click',
    }),expression:"{\n      content: prestaSpecContent,\n      trigger: 'hover click',\n    }"}]},[_vm._v(" "+_vm._s(_vm.specAssist)+" ")])]):_vm._e(),(_vm.showDetail && _vm.detailAssist)?_c('div',{staticClass:"detailAssist"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.prestaDetailContent,
      trigger: 'hover click',
    }),expression:"{\n      content: prestaDetailContent,\n      trigger: 'hover click',\n    }"}]},[_vm._v(" "+_vm._s(_vm.detailAssist)+" ")])]):_vm._e(),(!_vm.hideSecondary && !_vm.secondaryBox && _vm.element.specAssistSecondary)?_c('div',{staticClass:"specAssistSecondary"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.prestaSpecSecondaryContent,
      trigger: 'hover click',
    }),expression:"{\n      content: prestaSpecSecondaryContent,\n      trigger: 'hover click',\n    }"}]},[_vm._v(" "+_vm._s(_vm.element.specAssistSecondary)+" ")])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }