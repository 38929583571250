<template>
  <div class="adminList newsList">
    <div v-for="n in news" v-bind:key="n.id" class="adminListItem" @click="selectNews(n)" :id="'new-' + n.id">
      <div class="itemLine newsLine">
        <div>
          <span class="newsName">{{n.title}}</span>
          <span class="newsStatus" :class="'news-status-' + findStatus(n)">{{ $t('news.statusList.' + findStatus(n)) }}</span>
        </div>
        <div>
          <span class="viewNumber" :title="$t('news.viewNumber')">
            <svgicon icon="eye_view"/>{{ n.totalReads || 0 }}
          </span>
          <span class="newsDate">{{ n.startDate|prettyDate }} - {{ n.stopDate|prettyDate }}</span>
        </div>
      </div>
    </div>
    <div v-if="noResults">
      <label>{{ $t('news.list.noResults') }}</label>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'NewsListItems',
  props: { news: Array },
  data () {
    return {
    }
  },
  methods: {
    findStatus (news) {
      let status = null
      let start = moment(news.startDate).startOf('day').format()
      let stop = moment(news.stopDate).endOf('day').format()
      if (moment().isBetween(start, stop)) {
        status = 'now'
      } else if (moment().isAfter(stop)) {
        status = 'done'
      } else {
        status = 'todo'
      }
      return status
    },
    selectNews (news) {
      if (this.findStatus(news) !== 'done') {
        this.$router.push({ name: 'newsEdit', params: { id: news.id } })
      } else {
        this.$toastService.showToast(this.$t('news.error.done'), null, 'error')
      }
    }
  },
  computed: {
    noResults () {
      return this._.isEmpty(this.news)
    }
  },
  filters: {
    prettyDate (date) {
      return date ? moment(date).format('DD/MM/YYYY') : null
    }
  }
}
</script>
