/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Taxi': {
    width: 154,
    height: 94,
    viewBox: '0 0 154 94',
    data: '<path pid="0" d="M139.086 43.472l-25.36-3.94-13.675-25.164a3.617 3.617 0 00-3.183-1.871H87.92V3.524C87.921 1.577 86.308 0 84.315 0H59.072c-1.991 0-3.606 1.577-3.606 3.524v8.973h-8.435c-1.335 0-2.56.719-3.185 1.87L30.13 39.612 2.896 44.956C1.212 45.286 0 46.733 0 48.41v31.562c0 1.946 1.614 3.524 3.606 3.524h16.187C23.095 89.726 29.743 94 37.403 94c7.661 0 14.31-4.276 17.611-10.504h38.25c3.3 6.23 9.949 10.504 17.61 10.504 7.66 0 14.309-4.276 17.612-10.504h10.296c8.39 0 15.218-6.673 15.218-14.873V58.34c.001-8.101-6.662-14.71-14.914-14.868zM94.698 19.544l10.69 19.669H75.3V19.544h19.398zM62.678 7.048h18.03v5.449h-18.03v-5.45zm-13.48 12.496h18.89v19.669H38.51l10.688-19.669zm-11.795 67.41c-6.96 0-12.62-5.533-12.62-12.334 0-6.8 5.66-12.333 12.62-12.333 6.96 0 12.622 5.532 12.622 12.333s-5.662 12.334-12.622 12.334zm73.471 0c-6.959 0-12.622-5.533-12.622-12.334 0-6.8 5.663-12.333 12.622-12.333 6.961 0 12.621 5.532 12.621 12.333s-5.66 12.334-12.621 12.334zm35.915-27.066h-6.505v7.048h6.505v1.688c0 4.315-3.592 7.825-8.005 7.825h-8.171c.059-.602.094-1.21.094-1.829 0-10.686-8.897-19.38-19.833-19.38-10.936 0-19.834 8.694-19.834 19.38 0 .617.037 1.227.096 1.83H57.142c.059-.603.095-1.212.095-1.83 0-10.686-8.898-19.38-19.834-19.38S17.57 63.933 17.57 74.62c0 .617.036 1.227.095 1.83H7.211V51.295l25.66-5.035h78.003v-.035l27.343 4.247c.185.028.377.043.567.043 4.413 0 8.005 3.51 8.005 7.824v1.548z" _fill="#CCC"/>'
  }
})
