/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 13,
    height: 17,
    viewBox: '0 0 13 17',
    data: '<path pid="0" d="M4 5.01h1v8.037H4V5.01zM6 5.01h1v8.037H6V5.01zM8 5.01h1v8.037H8V5.01zM0 1.996h13v1.005H0V1.996z" _fill="#2E2E37"/><path pid="1" d="M8.967 2.499h-.934V1.494A.528.528 0 007.5.958h-2c-.3 0-.533.235-.533.536v1.005h-.934V1.494C4.033.69 4.7.021 5.5.021h2c.8 0 1.467.67 1.467 1.473v1.005z" _fill="#2E2E37"/><path pid="2" d="M9.5 16.061h-6c-.8 0-1.5-.67-1.567-1.473L1 2.532l1-.067.933 12.056c.034.301.3.536.567.536h6c.3 0 .567-.268.567-.536L11 2.465l1 .067-.933 12.056C11 15.425 10.3 16.06 9.5 16.06z" _fill="#2E2E37"/>'
  }
})
