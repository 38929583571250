<script>
import authService from '@/services/authService'

export default {
  name: 'SignOut',
  render () {
    return this.$scopedSlots.default({
      oidcIsAuthenticated: this.oidcIsAuthenticated,
      signOut: this.signOut,
      disconnect: this.disconnect
    })
  },
  computed: {
    oidcIsAuthenticated: function () {
      return authService.isAuthenticated()
    }
  },
  methods: {
    disconnect: function () {
      this.signOut()
    },
    signOut: function () {
      authService.signOut()
      this.$root.$emit('filterPrestationReset')
      this.$store.commit('homeFilters/modalFiltered', false)
      this.$store.commit('homeFilters/setDaysSelected', [this.$store.getters['homeFilters/daySelectOptions'][1]])
      this.$store.commit('clearOnBoardRole')
      this.$router.push({ name: 'authDisconnected' })
    }
  }
}
</script>
