<template>
  <div id="prestationNewStepSelectStation">
    <div class="socaContent socaContentNarrow">
      <div class="prestationCreationTitle">{{$t('prestation.new') }}</div>
      <div class="titleStep">{{$t('prestation.create.selectStation') }}</div>
      <div class="prestationNewStepSelectStationContent">
        <SearchStationField @searchResults="searchStationResults" :stationsData="stationsList" />
        <button class="button-light"
          v-for="station in stationsList"
          :key="station.codeUic"
          :value="station.codeUic"
          :class="{ 'button-light-selected' : (stationSelected !== null && station.codeUic == stationSelected.codeUic)}"
          @click="stationSelected = station"
          >{{ station.name }}</button>
      </div>
      <button @click.prevent="next" :disabled="isLoading" class="button-theme">{{$t('common.validate')}}</button>
    </div>
  </div>
</template>

<script>
import SearchStationField from '@/components/Common/SearchStationField'

export default {
  name: 'PrestationNewStepSelectStation',
  data () {
    return {
      stationSelected: null,
      searchStations: [],
      isLoading: false
    }
  },
  methods: {
    next () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true;
      if (this.stationSelected !== null) {
        this.$emit('stationSelected', this.stationSelected)
      } else {
        this.$toastService.showToast(this.$t('common.choose_station'))
        this.isLoading = false;
      }
    },
    searchStationResults (res) {
      this.searchStations = res
    }
  },
  mounted () {
  },
  computed: {
    stationsList: function () {
      return this.searchStations.length > 0 ? this.searchStations : this.$store.getters.contextStations /* .filter(s => s.spontaneous && s.soca) */
    }
  },
  components: { SearchStationField }
}
</script>

<style lang="scss" scoped>
#prestationNewStepSelectStation {
  .button-light {
    margin: 0.625rem 0;
  }
}
</style>
