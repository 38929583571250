<template>
    <div v-bind="$attrs" v-html="station ? substituteStationHours : assistActorHours"></div>
</template>

<script>
export default {
  name: 'Hours',
  props: { assistActor: Object, station: { type: Object, required: false } },
  data: () => {
    return {
      daysConst: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'ferie']
    }
  },
  computed: {
    assistActorHours: function () {
      let assistActorHoursIn = []
      for (let i = 0; i < this.daysConst.length; i++) {
        let dayLabel = this.daysConst[i]
        if (this.assistActor[dayLabel + 'AgentClosed']) {
          assistActorHoursIn.push(false)
        } else {
          assistActorHoursIn.push(((this.assistActor[dayLabel + 'Agent'] || '') + ' ' + (this.assistActor[dayLabel + 'Agent2'] || '') + ' ' + (this.assistActor[dayLabel + 'Agent3'] || '')).trim())
        }
      }
      return this.buildHours(assistActorHoursIn)
    },
    substituteStationHours: function () {
      return this.buildHours([
        this.station.mondayTaxi,
        this.station.tuesdayTaxi,
        this.station.wednesdayTaxi,
        this.station.thursdayTaxi,
        this.station.fridayTaxi,
        this.station.saturdayTaxi,
        this.station.sundayTaxi,
        this.station.ferieTaxi
      ])
    }
  },
  methods: {
    buildHours (dayValues) {
      const self = this
      const days = this.daysConst.map((label, i) => {
        return { label: self.$t('common.' + label).toLowerCase(), value: self.formatHours(dayValues[i]) }
      })
      let dayLineList = this.buildDayLines(days.slice(0, -1))

      let result = ''
      for (let i in dayLineList) {
        result += this.formatDayLine(dayLineList[i])
      }
      // Jour fériés
      if (days[days.length - 1].value) {
        result += this.formatDayLine([ days[days.length - 1] ])
      }
      return result
    },
    formatHours (value) {
      if (value === false) {
        return this.$t('common.closed')
      } else if (!value) { return null }
      let returnStr = ''
      let valueTab = value.split(' ') // multi hours are separated by space
      for (let i = 0; i < valueTab.length; i++) {
        let hourStr = valueTab[i]
        if (hourStr || hourStr !== 'null-null') {
          let from = hourStr.split('-')[0] === 'null' ? '-' : hourStr.split('-')[0]
          let to = hourStr.split('-')[1] === 'null' ? '-' : hourStr.split('-')[1]
          if (i === 0) {
            returnStr += this.$t('hours.format', { from: from, to: to }).toLowerCase()
          } else {
            returnStr += ' ' + this.$t('hours.formatThen', { from: from, to: to }).toLowerCase()
          }
        }
      }
      return returnStr.trim() !== '' ? returnStr : null
    },
    capitalize (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    buildDayLines (days) {
      let dayLineList = []
      let dayLine = []
      for (let i in days) {
        let day = days[i]
        if (dayLine.length === 0 || dayLine[dayLine.length - 1].value === day.value) {
          day.value && dayLine.push(day)
        } else {
          dayLineList.push(dayLine)
          dayLine = day.value ? [ day ] : []
        }
      }
      dayLine.length > 0 && dayLineList.push(dayLine)
      return dayLineList
    },
    formatDayLine (dayLine) {
      let result = ''
      if (dayLine.length >= 3) {
        result += this.$t('common.day.from') + dayLine[0].label + this.$t('common.day.to') + dayLine[dayLine.length - 1].label
      } else {
        result += this.capitalize(dayLine.map(dl => dl.label).join(', '))
      }
      result += ' ' + dayLine[0].value + '<br>'
      return result
    }
  }
}
</script>
