/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goto': {
    width: 47,
    height: 41,
    viewBox: '0 0 47 41',
    data: '<path pid="0" d="M22.527.886c-1.252 1.25-1.252 3.195 0 4.445l12.375 12.017H3.13A3.114 3.114 0 000 20.474 3.114 3.114 0 003.129 23.6h31.843a2239.435 2239.435 0 00-11.89 12.017c-1.25 1.25-1.25 3.195 0 4.445 1.252 1.25 3.2 1.25 4.45 0L47 20.474 26.976.886c-1.251-1.181-3.267-1.181-4.45 0z" _fill="#1E1E28"/>'
  }
})
