export default {
  namespaced: true,
  state: {
    searchQuery: null,
    filters: {
      stations: null,
      role: null,
      services: null,
      transporters: null,
      status: null,
      externalUser: null
    }
  },
  mutations: {
    setFilters (state, filters) {
      Object.assign(state.filters, filters)
    },
    setSearchQuery (state, query) {
      state.searchQuery = query
    }
  },
  getters: {
    filters: state => {
      return state.filters
    },
    searchQuery: state => {
      return state.searchQuery
    }
  }
}
