/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casquette': {
    width: 51,
    height: 31,
    viewBox: '0 0 51 31',
    data: '<g _fill="#1E1E28"><path pid="0" d="M40.592.398H40.3c-.765.031-1.62.188-2.536.47-1.842.555-3.936 1.581-6.08 2.795-4.287 2.428-8.786 5.63-12.45 7.43-2.708 1.34-6.08 2.21-9.2 2.984-3.12.764-6.039 1.454-7.619 2.229-.735.366-1.117.743-1.298 1.067-.181.314-.212.566-.151.91.13.692.875 1.665 1.892 2.377l.332.23c16.658-.251 29.652-1.486 39.717-5.694.212-1.904.685-3.757 1.691-5.253l.01-.021c2.506-3.485.916-7.86-2.295-9.2-.513-.21-1.097-.314-1.741-.314l.02-.01z"/><path pid="1" d="M43.018 16.138c-4.489 2.92-10.316 4.417-17.1 5.17-7.136.785-14.715 1.162-22.013.628l1.118 1.236c3.26.209 9.098.774 16.879.24 8.032-.387 16.295-1.884 21.247-5.892-.04-.44-.08-.9-.12-1.371l-.01-.01z"/><path pid="2" d="M43.874 18.776c-4.741 3.621-11.656 5.16-18.53 5.777 4.82 4.019 9.531 5.557 13.507 5.819 4.73.314 8.445-1.308 9.784-2.575 1.308-1.224 1.74-2.187 1.791-2.92.04-.743-.262-1.433-.946-2.176-1.238-1.403-3.603-2.711-5.616-3.915l.01-.01z"/></g><defs><clipPath id="a"><path pid="3" _fill="#fff" d="M0 0h51v31H0z"/></clipPath></defs>'
  }
})
