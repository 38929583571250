<template>
<div class="infoPanel prestationPanel panelIsCompleted">
  <div class="infoPanelLine botBd infoPanelTitle">
    <span class="stationName">{{ prestation.garePresta.name }}
      <svgicon icon="star" class="prestaGarantedIcon" v-if="prestation.garantee === $sncfRefs.getPrestaTypesByTechnicalName().garanteed" />
    </span>
    <a class="openTrajet button-theme-round-light button-theme-simplify-in-small" v-if="canEdit" @click="editSegment">
      <svgicon icon="pencil" class="button-icon"/>
      <span class="button-text">{{$t('prestation.toComplete')}}</span>
    </a>
  </div>
  <div class="infoPanelLine botBd">
    <span>{{ datePrestationLbl  }}</span>
  </div>
  <div class="infoPanelLine">
    <span class="trainHour">{{ hourPrestationLbl  }}</span>
      <TrainNumberBox :prestation="prestation" />
    <div class="panelLineRight">
      <span class="mention">{{prestation.mention}}</span>
      <PrestaAssistBox :element="account" />
    </div>
  </div>
  <div v-if="openedModal">
      <RecapSegmentForm @closeTrajet="closeModal" @savePrestation="savePrestation" :prestationData="prestation" />
  </div>
</div>
</template>

<script>
import moment from 'moment'
import RecapSegmentForm from '@/components/Prestations/Creation/Part/RecapSegmentForm'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'

export default {
  name: 'RecapSegment',
  props: { prestation: Object, account: Object, canEdit: { type: Boolean, default: false } },
  data () {
    return {
      openedModal: false
    }
  },
  methods: {
    editSegment: function () {
      this.openedModal = true
    },
    closeModal () {
      this.openedModal = false
    },
    savePrestation (val) {
      this.$emit('editPresta', val)
    }
  },
  computed: {
    datePrestationLbl: function () {
      if (this.prestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('DD MMMM YYYY')
      }
      return null
    },
    hourPrestationLbl: function () {
      if (this.prestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('HH:mm')
      }
      return null
    }
  },
  components: { RecapSegmentForm, PrestaAssistBox, TrainNumberBox }
}
</script>
