import Vue from 'vue'
import ToastMessage from '@/components/Common/ToastMessage.vue'

export default {
  showToast (message, elem = null, type = 'info', delay = 3000, callback = null) {
    let TMClass = Vue.extend(ToastMessage)
    let instance = new TMClass({
      propsData: {
        type: type,
        delay: delay,
        attachedToDocumentRoot: (elem === null),
        isClickable: (callback !== null)
      }
    })
    const node = instance.$createElement('span', [message])
    instance.$slots.default = [node]
    if (callback) {
      instance.$on('toastClicked', function () {
        callback()
      })
    }
    instance.$mount()
    if (elem) {
      elem.appendChild(instance.$el)
    } else {
      window.document.body.appendChild(instance.$el)
    }
  }
}
