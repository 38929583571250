<template>
    <select
      :value="value"
      v-on:change="onChangeVal($event.target.value)"
      :class="{ 'valueIsNull': value === null }">
      <option :value="null" hidden>{{ placeholder ? placeholder : $t('common.select') }}</option>
      <slot></slot>
    </select>
</template>

<script>

export default {
  name: 'SelectWithPlaceholder',
  props: {
    value: {
      type: [String, Boolean]
    },
    placeholder: {
      type: String
    }
  },
  methods: {
    onChangeVal (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped>
</style>
