<template>
  <div class="unlinkedIndicator" v-if="$perms.isUserAllowed('hcsync.fixerrors') && element && (element.hcLastOp === 'FAILED' || !element.sfid)">
    <svgicon icon="unlink" v-tooltip="{
        content: hcErrorPretty,
        trigger: 'hover click',
      }" />
      <a v-if="isSynchroRetried === false" href="#" @click.prevent="retrySynchro">{{ $t('common.retrySynchro') }}</a>
      <span v-if="isSynchroRetried === true">{{ $t('common.querySent') }}</span>
  </div>
</template>

<script>
export default {
  name: 'UnlinkedIndicator',
  data () {
    return {
      isSynchroRetried: false
    }
  },
  props: { element: Object, type: String },
  mounted () {
  },
  methods: {
    retrySynchro: function () {
      this.$backendConnector.resyncHcRecord(this.type, this.getRecordId())
      this.isSynchroRetried = true
    },
    getRecordId: function () {
      if (this.type === 'user') {
        return this.element.code
      } else if (this.type === 'station') {
        return this.element.codeUic
      } else if (this.type === 'account' || this.type === 'prestation' || this.type === 'parcours') {
        return this.element.herokuExternalId
      } else {
        return this.element.id
      }
    }
  },
  computed: {
    hcErrorPretty: function () {
      if (this.element && this.element.hcErr) {
        let pretty = ''
        try {
          let errObj = JSON.parse(this.element.hcErr)
          for (let key in errObj) {
            pretty += key + ': ' + errObj[key] + '<br />'
          }
        } catch (e) {
          pretty = this.element.hcErr
        }
        return pretty
      }
      return null
    }
  }
}
</script>
