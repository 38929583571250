<template>
  <div id="prestationCreation">
    <div id="prestationCreationHeader" class="replaceSocaHeader" v-if="step < 4">
      <div v-if="step !== null && step > 1" @click="previous" class="headerLeft"><svgicon icon="previous" class="previousIcon"/></div>
      <div @click="cancel" class="headerRight">{{$t('common.cancel')}}</div>
    </div>
    <div class="prestationCreationMain" :class="{ 'hidden' : cancelled }">
        <div v-if="step === 0">
          <PrestationNewStepSelectStation @stationSelected="stationSelected"/>
        </div>
        <div v-if="sansSuite">
          <SansSuite :contextStationPresta="contextStationPresta" @toStandardPresta="showStandardPresta" @sansSuiteStepOk="sansSuiteStepOk" />
        </div>
        <div v-else>
          <div v-if="step === 1">
            <PrestationNewStepAccount @stepAccountOk="stepAccountOk" :accountData="data.account" :parcoursData="data.parcours" @toSansSuite="showSansSuite" />
          </div>
          <div v-if="step === 2">
            <PrestationNewStepSegments @saveAccount="saveAccount" :accountData="data.account" :parcoursData="data.parcours" :contextStationPresta="contextStationPresta" :stepSegmentsSave="stepSegmentsSave" @stepSegmentsOk="stepSegmentsOk" :onBoardPrestation="onBoardPrestation" :onBoardJourneyData="onBoardJourneyData"/>
          </div>
        </div>
        <div v-if="step === 3">
          <PrestationRecap @stepRecapOk="stepRecapOk" @saveAccount="saveAccount" :isLoading="isLoading" :accountData="data.account" :parcoursData="data.parcours" :prestations="data.prestations" />
        </div>
    </div>
  </div>
</template>

<script>
import PrestationNewStepSelectStation from '@/components/Prestations/Creation/PrestationNewStepSelectStation'
import PrestationNewStepAccount from '@/components/Prestations/Creation/PrestationNewStepAccount'
import PrestationNewStepSegments from '@/components/Prestations/Creation/PrestationNewStepSegments'
import PrestationRecap from '@/components/Prestations/Creation/PrestationRecap'
import SansSuite from '@/components/Prestations/Creation/SansSuite'
import moment from 'moment'
import pivService from '@/services/piv'
import common from '@/services/common'
import { SPONTANE, journeyDataObject } from '@/utils/constantsUtils'
import userService from '@/services/userService'

export default {
  name: 'PrestationCreation',
  data () {
    return {
      onBoardJourneyData: journeyDataObject,
      datePrestationHour: null,
      isLoading: false,
      sansSuite: false,
      step: null,
      contextStationPresta: null,
      onBoardPrestation: null,
      stepSegmentsSave: null,
      data: {
        account: {},
        parcours: {},
        prestations: [],
        isAccountUpdated: false
      },
      accounts: [],
      cancelled: false
    }
  },
  created () {
    // On doit forcément avoir une seule gare de contexte pour la création de presta
    this.step = 0
    if (this.$store.getters.selectedStation) {
      this.stationSelected(this.$store.getters.selectedStation)
    }
    this.$store.state.showFooter = false
    if (this.$route.params.step) {
      this.step = this.$route.params.step
      this.onBoardPrestation = this.$store.getters.onBoardPrestation
      this.data.account = this.$store.getters.onBoardAccount
      this.data.parcours = this.$store.getters.onBoardParcours
    } else {
      this.$store.commit('onBoardPrestation', null)
      this.$store.commit('isOnBoardArrival', false)
    }
  },
  beforeDestroy () {
    this.$store.state.showFooter = true
  },
  methods: {
    previous () {
      this.onBoardPrestation && this.step !== 3 ? this.cancel() : this.step--
      $('#socaMainContent').scrollTop(0)
    },
    next () {
      this.step++
      $('#socaMainContent').scrollTop(0)
    },
    cancel () {
      this.$store.state.showFooter = true
      this.cancelled = true
      let self = this
      setTimeout(function () {
        self.$router.push('/')
      }, 100)
    },

    showSansSuite () {
      this.sansSuite = true
      this.step = 1
    },
    showStandardPresta () {
      this.sansSuite = false
      this.step = 1
    },

    async stationSelected (selectedStation) {
      const isStationIncomplete = (
        selectedStation.handicapAudioTaxi === undefined ||
        selectedStation.handicapMentalTaxi === undefined ||
        selectedStation.handicapMoteurTaxi === undefined ||
        selectedStation.handicapVisuelTaxi === undefined ||
        selectedStation.handicapAutreTaxi === undefined ||
        !selectedStation.equipment
      );

      if (isStationIncomplete) {
        try {
          const activeAlertOnly = 'activeAlert'
          const station = await this.$backendConnector.getGareByUic(selectedStation.codeUic, activeAlertOnly);
          this.contextStationPresta = station;
        } catch (err) {
          console.error(err);
          return;
        }
      } else {
        this.contextStationPresta = selectedStation;
      }

      if (this.isOnBoard) {
        await this.loadTrainJourneyData()
      }

      this.next();
    },
    async loadTrainJourneyData () {
      const { getters } = this.$store
      const { getTransportModesByTechnicalName, getPrestaTypesByTechnicalName } = this.$sncfRefs
      this.onBoardJourneyData = journeyDataObject
      this.onBoardJourneyData.trainNumber = getters.trainNumber
      this.onBoardJourneyData.transporter = getters.onBoardTransporter
      this.onBoardJourneyData.transportMode = getTransportModesByTechnicalName().train
      this.onBoardJourneyData.garantee = getPrestaTypesByTechnicalName().notGaranteed
      this.onBoardJourneyData.datePrestation = new Date()
      this.datePrestationHour = moment(this.onBoardJourneyData.datePrestation).format('HH:mm')
      this.onBoardJourneyData.dateAsked = new Date()
      this.onBoardJourneyData.hourAsked = moment(this.datePrestationHour.dateAsked).format('HH:mm')
      this.onBoardJourneyData.service = SPONTANE
      this.$store.commit('resetNextStations')

      const { trainNumber, datePrestation } = this.onBoardJourneyData

      if (trainNumber) {
        this.onBoardJourneyData.passages = []

        try {
          const pivData = await pivService.fetchPivDataByTrainNumber(trainNumber, datePrestation, this.contextStationPresta.codeUic)
          this.$store.commit('nextStations', pivData.passages[0].nexts)

          if (pivData.passages.length > 0) {
            this.updateJourneyFromPivData(pivData)
            if (pivData.passages[0].missionCode) {
              this.onBoardJourneyData.missionCode = pivData.passages[0].missionCode
            }
          }

          if (this.datePrestationHour) {
            this.onBoardJourneyData.datePrestation = common.setHoursMinute(this.datePrestationHour, datePrestation)
          }

          if (this.onBoardJourneyData.hourAsked) {
            this.onBoardJourneyData.dateAsked = common.setHoursMinute(this.onBoardJourneyData.hourAsked, this.onBoardJourneyData.dateAsked)
          }
        } catch (err) {
          console.log('Error while fetching train data : ' + err)
        }
      }
    },
    updateJourneyFromPivData (pivData) {
      const passage = pivData.passages[0]

      let updatedJourneyData = { ...this.onBoardJourneyData }

      this.datePrestationHour = passage.hour
      updatedJourneyData.transporter = passage.transporter

      for (const passage of pivData.passages) {
        if (passage.arrHour && passage.arrHourDateTime) {
          updatedJourneyData = common.duplicatePassages(
            updatedJourneyData, passage, passage.arrHour, passage.arrHourDateTime, true
          )
        }

        if (passage.depHour && passage.depHourDateTime) {
          updatedJourneyData = common.duplicatePassages(
            updatedJourneyData, passage, passage.depHour, passage.depHourDateTime, false
          )
        }
      }
      if (!this.garanteedOnly) {
        for (const passI in updatedJourneyData.passages) {
          if (updatedJourneyData.passages[passI].hour === this.datePrestationHour) {
            updatedJourneyData.nextStops = updatedJourneyData.passages[passI].nexts
          }
        }
      }
      this.onBoardJourneyData = { ...updatedJourneyData }
    },
    // Step unique sans suite
    sansSuiteStepOk (account, parcours, presta) {
      this.data.account = account
      this.data.parcours = parcours
      this.data.prestations = [presta]
      this.finishPrestation()
    },

    // Steps multiples classique
    stepAccountOk (account, parcours, isAccountUpdated) {
      this.data.account = this._.assign(this.data.account, account)
      this.data.parcours = this._.assign(this.data.parcours, parcours)
      this.data.isAccountUpdated = isAccountUpdated
      this.next()
    },
    saveAccount ({ account, parcours }) {
      let isAccountUpdated = false
      if (!this._.isEqual(this.data.account, account)) {
        isAccountUpdated = true
      }
      this.data.account = account
      this.data.parcours = parcours
      this.data.isAccountUpdated = isAccountUpdated
    },
    stepSegmentsOk (stepSegSave, prestations) {
      this.stepSegmentsSave = stepSegSave
      this.data.prestations = prestations
      this.next()
    },
    stepRecapOk (val) {
      this.data.prestations = val
      const prestaDates = this.data.prestations.map(p => p.datePrestation)
      const found = prestaDates.find(p => moment().isAfter(moment(p)))
      if (found && !this.$perms.isUserAllowed('presta.creation.afterDeparture')) {
        this.$toastService.showToast(this.$t('prestation.error.afternow'), null, 'error')
        return false
      } else {
        this.finishPrestation()
      }
    },

    // Check account and create the prestations
    finishPrestation () {
      this.isLoading = true
      // check if account exists or create it
      if (this.data.account.sfid) {
        this.data.parcours.accountId = this.data.account.sfid
        this.data.parcours.accountHerokuExternalId = this.data.account.herokuExternalId
        if (this.data.isAccountUpdated) {
          this.editAccount(this.data.account)
        }
        if (this.onBoardPrestation) {
          this.createOnBoardArrivalPrestations()
        } else {
          this.createParcoursAndPrestations()
        }
      } else {
        this.createAccount()
      }
    },
    async createOnBoardArrivalPrestations () {
      this.data.parcours.typeAssist = this.data.account.typeAssist
      this.data.parcours.detailAssist = this.data.account.detailAssist
      this.data.parcours.specAssist = this.data.account.specAssist
      this.data.parcours.specAssistSecondary = this.data.account.specAssistSecondary
      this.data.parcours.detailAssistSecondary = this.data.account.detailAssistSecondary

      // If parcours.bagage is empty string => null instead
      this.data.parcours.bagage = this.data.parcours.bagage !== '' ? this.data.parcours.bagage : null

      // Update the prestations
      for (const presta of this.data.prestations) {
        if (presta.mention === 'D' || presta.mention === 'DC') {
          try {
            await this.$backendConnector.updatePrestation(presta)
          } catch (err) {
            console.error(err)
          }
        }

        if (presta.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed ||
            presta.mention === this.$sncfRefs.getMentionsByTechnicalName().none.sigle
        ) {
          presta.state = this.$sncfRefs.getPrestaStatesByTechnicalName().accepted
          presta.autoAccepted = true
        }
        delete presta.garePresta
        delete presta.passages
        delete presta.nextStops
      }

      try {
        const newPrestas = await Promise.all(this.data.prestations.map(p => {
          if (p.mention === 'A') {
            p.parcoursHerokuExternalId = this.data.prestations[1].parcoursHerokuExternalId
            delete p.herokuExternalId
            delete p.sfid
            delete p.id
            return this.$backendConnector.createPrestation(p)
          }
        }))
        this.data.prestations = newPrestas
        this.$toastService.showToast(
          this.$t((self.sansSuite ? 'prestation.sanssuite.created' : 'prestation.created')),
          null,
          'success',
          5000,
          () => this.$router.push({ name: 'prestationDetail', params: { herokuExternalId: newPrestas[0].herokuExternalId } })
        )
        this.$store.state.showFooter = true
        this.$router.push('/')
      } catch (err) {
        console.error(err)
      }
    },
    createParcoursAndPrestations () {
      // copy needs fields from account
      this.data.parcours.typeAssist = this.data.account.typeAssist
      this.data.parcours.detailAssist = this.data.account.detailAssist
      this.data.parcours.specAssist = this.data.account.specAssist
      this.data.parcours.specAssistSecondary = this.data.account.specAssistSecondary
      this.data.parcours.detailAssistSecondary = this.data.account.detailAssistSecondary

      // if parcours.bagage is empty string => null instead
      this.data.parcours.bagage = this.data.parcours.bagage !== '' ? this.data.parcours.bagage : null
      // create parcours
      this.$backendConnector.createParcours({ parcours: this.data.parcours })
        .then(parcours => {
          this.data.parcours = parcours
          this.data.prestations.map(presta => {
            presta.parcoursHerokuExternalId = parcours.herokuExternalId
            if (presta.garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed ||
                presta.mention === this.$sncfRefs.getMentionsByTechnicalName().none.sigle
            ) {
              presta.state = this.$sncfRefs.getPrestaStatesByTechnicalName().accepted
              presta.autoAccepted = true
            }
            // supression des élements piv inutiles et surchargeant la requete
            delete presta.garePresta
            delete presta.passages
            delete presta.nextStops
          })
          // if departure === N and same station, A is accepted automatically
          if (this.data.prestations.length === 2 &&
              this.data.prestations[0].mention === this.$sncfRefs.getMentionsByTechnicalName().none.sigle &&
              this.data.prestations[0].garePrestaUic === this.data.prestations[1].garePrestaUic) {
            this.data.prestations[1].state = this.$sncfRefs.getPrestaStatesByTechnicalName().accepted
            this.data.prestations[1].autoAccepted = true
          }
          if (this.data.prestations[0].mention === this.$sncfRefs.getMentionsByTechnicalName().none.sigle &&
                this.data.prestations[0].garantee === this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed) {
            this.data.prestations[0].statusTN = 'notDone'
          }
          return Promise.all(this.data.prestations.map(p => {
            this.$backendConnector.createPrestation(p)
          }))
            .then(newPrestas => {
              this.data.prestations = newPrestas
              // message de confirmation + homepage
              this.$toastService.showToast(
                this.$t((self.sansSuite ? 'prestation.sanssuite.created' : 'prestation.created')),
                null,
                'success',
                5000,
                () => this.$router.push({ name: 'prestationDetail', params: { herokuExternalId: newPrestas[0].herokuExternalId } })
              )
              this.$store.state.showFooter = true
              this.$router.push('/')
            })
        })
        .catch(console.error)
    },
    createAccount: function () {
      const self = this
      return this.$backendConnector.createAccount({ account: this.data.account })
        .then(account => {
          self.data.parcours.accountHerokuExternalId = account.herokuExternalId
          this.createParcoursAndPrestations()
        })
        .catch(err => console.log(err))
    },
    editAccount: function () {
      return this.$backendConnector.editAccount(this.data.account.herokuExternalId, { account: this.data.account })
        .then(account => { this.data.account = this._.assign(this.data.account, account) })
        .then(() => this.$toastService.showToast(this.$t('prestation.account.updated'), null, 'success'))
        .catch(error => console.log(error))
    }
  },
  computed: {
    garanteedOnly: function () {
      return this.$perms.isUserAllowed('presta.creation.garanteed.only')
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
  },
  components: { PrestationNewStepSelectStation, PrestationNewStepAccount, PrestationNewStepSegments, PrestationRecap, SansSuite }
}
</script>

<style scoped>
</style>
