/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.154.727c0-.401.31-.727.692-.727h8.308c.382 0 .692.326.692.727v3.178c.129-.061.256-.125.383-.192a.665.665 0 01.31-.077C16.444 3.636 18 5.271 18 7.273v5.818c0 .402-.31.727-.692.727h-.784l.667 1.051c.142.223.155.51.035.747a.688.688 0 01-.61.384H1.384a.688.688 0 01-.61-.384.759.759 0 01.034-.747l.667-1.05H.692c-.382 0-.692-.326-.692-.728V7.273C0 5.27 1.556 3.636 3.462 3.636c.107 0 .213.027.31.077.126.067.253.13.382.192V.727zm1.384 3.729c2.248.725 4.676.725 6.923 0V1.455H5.538v3.001zm-3.14 7.908l1.18-1.858a.684.684 0 01.576-.324h9.692c.232 0 .448.121.576.324l1.18 1.858h1.013V7.273c0-1.146-.855-2.095-1.927-2.176a12.593 12.593 0 01-11.376 0c-1.072.081-1.927 1.03-1.927 2.176v5.09h1.014zm12.14-5.091v1.454h-1.384V7.273h1.385zM4.525 11.636l-1.846 2.91h12.644l-1.846-2.91H4.524z" _fill="#2E2E37"/>'
  }
})
