<template>
  <div>
  </div>
</template>

<script>
import authService from '@/services/authService'

export default {
  name: 'OidcCallback',
  created () {
    const currentUrl = window.location.origin + window.location.pathname
    let self = this
    authService.signinRedirectCallback(this.$route.query, currentUrl).then((data) => {
      if (data == null) {
        return
      }
      // Si la page sauvegardée est la page de redirection, on retourne sur home.
      let savedRoute = this.$store.getters['auth/destinationRoute']
      let newRoute = (!savedRoute || window.location.pathname === savedRoute.fullPath) ? { name: 'home' } : savedRoute
      self.$router.push(newRoute)
    }).catch(err => {
      console.log(err)
      self.$router.push({ name: 'home' })
    })
  }
}
</script>
