import store from '@/store/store.js'
import router from '@/router/index.js'
import prestationConverterService from '@/services/prestationConverterService'
import { stationConverter, addEquipments } from '@/services/stationService'
import authService from '@/services/authService'
import axios from 'axios'
const baseUrl = process.env.VUE_APP_BACK_WS_URL

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (config.url.includes(baseUrl)) {
    config.headers.authorization = 'Bearer ' + store.getters['auth/accessToken']
    if (store.getters.impersonationUserCode) {
      config.headers.impersonation_user_code = store.getters.impersonationUserCode
    }

    if (store.getters.userRole === 'ONBOARD') {
      config.headers.uic_codes_board = store.getters['onBoardUIC']
    }

    config.headers['Accept-Language'] = store.getters['userLocale']
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

const routesWithoutAuthCheck = ['/notifications']
axios.interceptors.response.use(response => {
  return response
}, error => {
  console.error('axios interceptors error: ', error)
  if (error.response.config.url.includes(baseUrl)) {
    let isRouteWithoutAuthCheck = routesWithoutAuthCheck.some(r => error.response && error.response.config.url.includes(r))
    if (error.response.status === 401 && !isRouteWithoutAuthCheck) {
      authService.signOut()
      router.push({ name: 'authDisconnected' }, () => {})
    } else if (error.response.status === 403) { // auth failed
      router.push({ name: 'authError' }, () => {})
    } else if (error.response.status === 409) { // operation not possible while stopandgo runs
      return Promise.reject(error)
    } else if (error.response.status === 412) { // charter not signed
      router.push({ name: 'signCharter' }, () => {})
    } else if (error.response.status === 452) { // StopAndGo status has been changed by someone else
      return Promise.reject(error)
    }
  }
  return error
})

let convertRows = function (result, convertFunction) {
  if (Array.isArray(result)) {
    result = result.map(convertFunction)
  }
  if (result.rows) {
    result.rows = result.rows.map(convertFunction)
  }
  if (result.docs) {
    result.docs = result.docs.map(convertFunction)
  }
  return result
}

const createConfig = (params) => ({
  params: {
    activeAlertOnly: params.activeAlertOnly || '',
  },
})

const backendConnector = {
  // Gestion des gares
  getGares: () => {
    return axios.get(baseUrl + '/gares')
      .then(response => convertRows(response.data, stationConverter.convertToFront))
  },
  getGareByUic: (codeUic, activeAlertOnly = '') => {
    return axios.get(baseUrl + '/gares/uic/' + codeUic, createConfig({ activeAlertOnly }))
      .then(response => stationConverter.convertToFront(response.data))
  },
  getGaresByUic: (codeUic) => {
    return axios.get(baseUrl + '/gares/uics/', { params: { codeUic: codeUic } })
      .then(response => convertRows(response.data, stationConverter.convertToFront))
  },
  searchStations: (val, activeAlertOnly = '') => {
    return axios.get(baseUrl + '/gares/search/' + val, createConfig({ activeAlertOnly }))
      .then(response => convertRows(response.data, stationConverter.convertToFront))
  },
  getMyStations: () => {
    return axios.get(baseUrl + '/mystations')
      .then(response => convertRows(response.data, stationConverter.convertToFront))
  },
  getEquipments: (station, isIdRefActive) => {
    return axios.get(baseUrl + '/gare/equipments/' + isIdRefActive ? station.idRefGare : station.codeGare)
      .then(response => addEquipments(station, response.data))
      .catch(err => {
        console.error('Error while getting station equipments:', err);

        return station
      });
  },
  getMyRestrictedRefs: () => {
    return axios.get(baseUrl + '/ref/restricted/all')
      .then(response => response.data)
  },
  updateStation: (codeUic, params) => {
    params.station = stationConverter.convertFromFront(params.station)
    return axios.patch(baseUrl + '/gares/' + codeUic, params)
      .then(response => response.data)
  },
  upsertAlert: (params) => {
    return axios.post(baseUrl + '/alerts', params)
      .then(response => response.data)
      .catch(err => console.log(err))
  },
  deleteAlert: (id) => {
    return axios.delete(baseUrl + '/alert/' + id)
      .then(response => response.data)
      .catch(err => console.log(err))
  },
  getStationsCsv: (codeUic) => {
    return axios.post(baseUrl + '/gares/getCSV', { params: { codeUic: codeUic } }, {
      headers: {
        'accept': 'text/csv'
      },
      responseType: 'blob'
    })
  },
  getGcActors: () => {
    return axios.get(baseUrl + '/gc-actors')
      .then(response => response.data)
  },

  // Gestion des prestations
  getPrestations: (searchParams) => {
    return axios.get(baseUrl + '/prestations', { params: searchParams })
      .then(response => convertRows(response.data, prestationConverterService.convertToFront))
  },
  getPrestation: (herokuExternalId) => {
    return axios.get(baseUrl + '/prestations/' + herokuExternalId)
      .then(response => prestationConverterService.convertToFront(response.data))
  },
  getPrestationForReport: (herokuExternalId) => {
    return axios.get(baseUrl + '/prestationForReport/' + herokuExternalId)
      .then(response => prestationConverterService.convertToFront(response.data))
  },
  filterPrestations: (filters, getParams) => {
    return axios.post(baseUrl + '/prestations/search', filters, { params: getParams })
      .then(response => convertRows(response.data, prestationConverterService.convertToFront))
  },
  filterPrestationsCsv: (filters) => {
    return axios.post(baseUrl + '/prestations/search', filters, {
      headers: {
        'accept': 'text/csv'
      },
      responseType: 'blob'
    })
  },
  filterPrestationsPdf: (filters) => {
    return axios.post(baseUrl + '/prestations/search', filters, {
      headers: {
        'accept': 'application/pdf'
      },
      responseType: 'blob'
    })
  },
  createPrestation: (prestation) => {
    return axios.post(baseUrl + '/prestations', prestationConverterService.convertFromFront(prestation))
      .then(response => prestationConverterService.convertToFront(response.data))
  },
  updatePrestation: (prestation) => {
    return axios.patch(baseUrl + '/prestations', prestationConverterService.convertFromFront(prestation))
      .then(response => prestationConverterService.convertToFront(response.data))
  },
  acquitPlanning: (stationUic, planningDate, prestations, herokuExternalId) => {
    for (let i in prestations) {
      prestations[i] = prestationConverterService.convertFromFront(prestations[i])
    }
    return axios.patch(baseUrl + '/prestations/acquit', {
      stationUic: stationUic,
      planningDate: planningDate,
      prestationList: prestations,
      herokuExternalId: herokuExternalId
    }).then(response => response.data)
  },
  getStationsAcquitData: (uics, dateStart, dateEnd) => {
    return axios.get(baseUrl + '/prestations/acquit/data?uic=' + uics.join('&uic=') + '&startDate=' + dateStart + '&endDate=' + dateEnd)
      .then(response => response.data)
  },
  getStationsAcquitDataCsv: (uics) => {
    return axios.get(baseUrl + '/prestations/acquit/data/csv?uic=' + uics.join('&uic='), {
      headers: {
        'accept': 'text/csv'
      },
      responseType: 'blob'
    })
      .then(response => response.data)
  },
  getPDF: (url) => {
    return axios.get(baseUrl + '/solbord', { params: url, responseType: 'arraybuffer', headers: { 'Accept': 'application/pdf' } })
      .then(response => response.data)
  },
  getPrestationHisto: (hcid) => {
    return axios.get(baseUrl + '/prestations/' + hcid + '/histo')
      .then(response => prestationConverterService.convertToFront(response.data))
  },
  getParcoursHisto: (herokuExternalId) => {
    return axios.get(baseUrl + '/parcours/' + herokuExternalId + '/histo')
      .then(response => {
        let parcours = response.data
        parcours.Prestations = parcours.Prestations.map(p => prestationConverterService.convertToFront(p))
        return parcours
      })
  },
  printPrestation: (herokuExternalId) => {
    return axios.get(baseUrl + '/prestations/' + herokuExternalId + '/print')
      .then(response => response.data)
  },

  // Gestion piv
  pivByNumber: (number, date, codeUic) => {
    return new Promise(function (resolve, reject) {
      axios.get(baseUrl + '/piv/bynumber/' + number + '?date=' + date + '&codeUic=' + codeUic)
        .then(response => {
          if ((response instanceof Error)) {
            reject(new Error(response.response.data))
          } else {
            resolve(response.data)
          }
        })
    })
  },
  pivByUic: (uic, date, missionCode) => {
    return new Promise(function (resolve, reject) {
      axios.get(baseUrl + '/piv/byuic/' + uic + '?date=' + date + '&missionCode=' + missionCode)
        .then(response => {
          if ((response instanceof Error)) {
            reject(new Error(response.response.data))
          } else {
            resolve(response.data)
          }
        })
    })
  },
  pivByTrainNumber: (trainNumber, date) => {
    return new Promise(function (resolve, reject) {
      axios.get(baseUrl + '/piv/bytrainnumber/' + trainNumber + '?date=' + date)
        .then(response => {
          if ((response instanceof Error)) {
            reject(new Error(response.response.data))
          } else {
            resolve(response.data)
          }
        })
    })
  },

  // Gestion des comptes
  createAccount: (account) => {
    return axios.post(baseUrl + '/accounts/', account)
      .then(response => response.data)
  },
  getAccount: (herokuExternalId) => {
    return axios.get(baseUrl + '/accounts/' + herokuExternalId)
      .then(response => response.data)
  },
  getAccountByName: (lastname) => {
    return axios.get(baseUrl + '/accounts/name/' + lastname)
      .then(response => response.data)
  },
  searchAccounts: (searchParams) => {
    return axios.get(baseUrl + '/accounts/', { params: searchParams })
      .then(response => response.data)
  },
  editAccount: (id, params) => {
    return axios.patch(baseUrl + '/accounts/' + id, params)
      .then(response => response.data)
  },

  // Gestion des utilisateurs
  auth: (params) => {
    return axios.post(baseUrl + '/auth', params)
      .then(response => response.data)
  },
  whoAmI: () => {
    return axios.get(baseUrl + '/whoami')
      .then(response => response.data)
  },

  getUsers: (filters, params) => {
    return axios.get(baseUrl + '/user/search/', { params: { searchParams: filters, orderParams: params } })
      .then(response => response.data)
  },
  getUsersCsv: (filters) => {
    return axios.get(baseUrl + '/user/search/', { params: { searchParams: filters },
      headers: {
        'accept': 'text/csv'
      },
      responseType: 'blob'
    })
  },
  getUser: (code) => {
    return axios.get(baseUrl + '/user/' + code)
      .then(response => response.data)
  },
  createUser: (user) => {
    return axios.post(baseUrl + '/user', { user: user })
      .then(response => response.data)
  },
  updateUser: (user) => {
    return axios.patch(baseUrl + '/user/' + user.code, { user: user })
      .then(response => response.data)
  },
  removeUser: (userCode) => {
    return axios.delete(baseUrl + '/user/' + userCode)
      .then(response => response.data)
  },

  // Gestion des parcours
  createParcours: (parcours) => {
    return axios.post(baseUrl + '/parcours', parcours)
      .then(response => response.data)
  },
  getParcours: (herokuExternalId) => {
    return axios.get(baseUrl + '/parcours/' + herokuExternalId)
      .then(response => response.data)
  },
  editParcours: (herokuExternalId, params) => {
    return axios.patch(baseUrl + '/parcours/' + herokuExternalId, params)
      .then(response => response.data)
      .catch(err => err)
  },

  // Gestion des notifications
  getNotifications: (uics, userCode) => {
    return axios.get(baseUrl + '/notifications?uic=' + uics.join('&uic=') + '&user=' + userCode)
      .then((response) => {
        for (let i in response.data) {
          if (response.data[i].Prestation) {
            response.data[i].Prestation = prestationConverterService.convertToFront(response.data[i].Prestation)
          }
        }
        return response.data
      })
  },

  getAlertsNotifications: async (uicsForAlertsNotifs) => {
    const requestDataAlertsNotifs = {
      alertsUics: uicsForAlertsNotifs,
    }

    try {
      const alertsNotifs = await axios.post(`${baseUrl}/alert-notifications`, requestDataAlertsNotifs)
        .then(response => response.data)

      return {
        alertsNotifs,
      }
    } catch (error) {
      console.error('Error fetching notifications:', error)
      throw error
    }
  },

  // Gestion des référentiels
  getAllRefs: () => {
    return axios.get(baseUrl + '/ref/all')
      .then(response => response.data)
  },

  solBordPdf: (prestationId) => {
    return axios.get(baseUrl + '/prestations/' + prestationId + '/solbord', {
      headers: {
        'accept': 'application/pdf'
      },
      responseType: 'blob'
    })
  },
  solBord: (prestationId) => {
    return axios.get(baseUrl + '/prestations/' + prestationId + '/solbordHtml')
  },
  resyncHcRecord: (type, id) => {
    return axios.patch(baseUrl + '/utils/hcresync?type=' + type + '&id=' + id)
      .then(response => response.data)
  },
  signUserCharter: () => {
    return axios.patch(baseUrl + '/signcharter')
      .then(response => response.data)
  },

  // News
  createNews: (news) => {
    return axios.post(baseUrl + '/news/create', news)
      .then(response => response.data)
  },
  getUnreadNews: (params) => {
    return axios.get(baseUrl + '/news/unreads', params)
      .then(response => response.data)
  },
  setNewsRead: (newsId) => {
    return axios.patch(baseUrl + '/news/read/' + newsId)
      .then(response => response.data)
  },
  getAllNews: (params) => {
    return axios.get(baseUrl + '/news', params)
      .then(response => response.data)
  },
  getNews: (id) => {
    return axios.get(baseUrl + '/news/' + id)
      .then(response => response.data)
  },
  editNews: (id, params) => {
    return axios.patch(baseUrl + '/news/' + id, params)
      .then(response => response.data)
  },

  // Bibliothèque
  libraryCreateDocument: (document) => {
    return axios.post(baseUrl + '/library/document', { document: document })
      .then(response => response.data)
  },
  libraryPatchDocument: (document) => {
    return axios.patch(baseUrl + '/library/document/' + document.id, { document: document })
      .then(response => response.data)
  },
  libraryGetUrlDocument: (docName) => {
    return axios.get(baseUrl + '/library/fileUpload/url/' + docName)
      .then(response => response.data)
  },
  fetchDocuments: (txtFilter, themes) => { // themes is an array of theme ids
    let themeParam = (themes && themes.length > 0) ? '&theme=' + themes.join('&theme=') : ''
    return axios.get(baseUrl + '/library/documents?textfilter=' + txtFilter + themeParam)
      .then(response => response.data)
  },
  getDocumentById: (documentId) => {
    return axios.get(baseUrl + '/library/document/' + documentId)
      .then(response => response.data)
  },
  libraryGetDocumentFile: (documentId) => {
    return axios.get(baseUrl + '/library/document/' + documentId + '/file', {
      headers: {
        'accept': 'application/pdf'
      },
      responseType: 'blob'
    })
      .then(response => response.data)
  },
  libraryGetDocumentDownloadUrl: (documentId) => {
    return axios.get(baseUrl + '/library/document/' + documentId + '/download')
      .then(response => response.data)
  },
  libraryGetDocumentUploadUrl: (fileName, fileType) => {
    return axios.get(baseUrl + '/library/uploadfile?filename=' + fileName + '&filetype=' + fileType)
      .then(response => response.data)
  },
  saveLibraryTheme: (theme) => {
    return axios.post(baseUrl + '/library/theme', theme)
      .then(response => response.data)
  },
  deleteLibraryTheme: (themeId) => {
    return axios.delete(baseUrl + '/library/theme/' + themeId)
      .then(response => response.data)
  },
  getLibraryThemes: () => {
    return axios.get(baseUrl + '/library/themes')
      .then(response => response.data)
  },
  deleteLibraryDocument: (docId) => {
    return axios.delete(baseUrl + '/library/document/' + docId)
      .then(response => response.data)
  },
  deleteLibraryFile: (fileKey) => {
    return axios.delete(baseUrl + '/library/file?filekey=' + fileKey)
      .then(response => response.data)
  },

  // Feature Flipping
  getAllFeaturesFlipped: () => {
    return axios.get(baseUrl + "/featuresFlipped")
      .then(response => response.data)
  },
  updateFeatureFlipped: (featureFlipped) => {
    return axios.patch(baseUrl + "/featuresFlipped/update", featureFlipped)
      .then((response) => response.data)
  }
}

export default backendConnector
