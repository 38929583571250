<template>
    <small class='inputRemainingCharacters' :class="{'error': remainingCharacters === 0, 'info': remainingCharacters > 0 }">{{ `${remainingCharacters} ${$t('common.remainingCharacters')}` }}</small>
</template>

<script>
export default {
  name: 'InputRemainingCharacters',
  props: {
    maxCharacters: {
      type: Number
    },
    currentLength: {
      type: Number
    }
  },
  computed: {
    remainingCharacters: function () {
      return this.maxCharacters - this.currentLength
    }
  }
}
</script>
