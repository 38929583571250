/* eslint-disable */
require('./accept')
require('./accessible_for_all')
require('./accessible')
require('./add')
require('./alert')
require('./arrow_left')
require('./arrow_right')
require('./arrow')
require('./bag')
require('./barely_accessible')
require('./canne')
require('./Car')
require('./casquette')
require('./check')
require('./close')
require('./comment_dot')
require('./comment')
require('./Delete')
require('./download')
require('./ear')
require('./elevator')
require('./exceptionnal')
require('./exclamation')
require('./eye_view')
require('./eye')
require('./face')
require('./filter')
require('./gare')
require('./gear')
require('./goto')
require('./handicap')
require('./hardware')
require('./hourglass')
require('./hours')
require('./import')
require('./inaccessible')
require('./library')
require('./loader')
require('./logo_soca')
require('./mail')
require('./menu')
require('./message')
require('./news')
require('./notif')
require('./operation_started')
require('./pencil')
require('./person')
require('./phone')
require('./plus')
require('./previous')
require('./print')
require('./refuse')
require('./shield')
require('./star')
require('./station')
require('./taxi_noir')
require('./Taxi')
require('./thunder')
require('./Train')
require('./trash')
require('./unlink')
require('./upload')
