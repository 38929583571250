<template>
  <div class="libraryManageThemesModal" v-if="$perms.isUserAllowed('library.manageThemes')">
    <socamodal
      :title="$t('library.manageThemes')"
      :submitText="$t('common.close')"
      @close="close"
      @submit="close">
      <template v-slot:header>
        <div @click="newTheme" class="addThemeButton button-theme-round-light button-theme-simplify-in-small">
          <svgicon icon="plus" class="button-icon"/>
          <span class="button-text">{{ $t('library.addTheme') }}</span>
        </div>
      </template>
      <div class="libraryManageThemesList">
        <div v-for="theme in themes" v-bind:key="theme.id" class="libraryManageThemesListItem">
          <input v-if="!theme.isDeleting" type="text" v-model="theme.name" class="libraryThemeInput" />
          <div v-if="!theme.id || ((theme.originalName !== theme.name) && !theme.isDeleting)" class="libraryThemeButton libraryThemeButtonSave" @click="saveTheme(theme)"><svgicon icon="accept"/></div>
          <!-- Delete and confirm delete -->
          <div v-if="theme.name && (theme.originalName === theme.name) && !theme.isDeleting" class="libraryThemeButton libraryThemeButtonDelete" @click="theme.isDeleting = true"><svgicon icon="trash"/></div>
          <div v-if="theme.name && theme.isDeleting" class="libraryThemeConfirmDelete">{{$t('library.confirmThemeDelete') }} : {{ theme.name }}</div>
          <div v-if="theme.name && theme.isDeleting" class="libraryThemeButton libraryThemeButtonConfirmDelete" @click="deleteTheme(theme)"><svgicon icon="accept"/></div>
        </div>
      </div>
    </socamodal>
  </div>
</template>

<script>

export default {
  name: 'LibraryManageThemes',
  data () {
    return {
      themes: null
    }
  },
  props: {
  },
  created () {
    this.themes = this.$sncfRefs.getLibraryThemes().map((th) => {
      return {
        id: th.id,
        name: th.name,
        originalName: th.name,
        isDeleting: false
      }
    })
  },
  mounted () {
  },
  methods: {
    saveTheme: function (theme) {
      this.$backendConnector.saveLibraryTheme(theme)
        .then(result => {
          theme.id = result.id
          theme.name = result.name
          theme.originalName = result.name
          theme.isDeleting = false
          this.$toastService.showToast(this.$t('library.themeCreated'), null, 'success')
        })
        .catch(err => console.log(err))
    },
    newTheme: function () {
      this.themes.push({
        id: null,
        name: null,
        originalName: null,
        isDeleting: false
      })
    },
    deleteTheme: function (theme) {
      this.$backendConnector.deleteLibraryTheme(theme.id)
        .then(result => {
          if (result) {
            this.themes = this.themes.filter(th => th.id !== theme.id)
            this.$toastService.showToast(this.$t('library.themeDeleted'), null, 'info')
          } else {
            this.$toastService.showToast(this.$t('library.cannotDelete'), null, 'warning')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    close: function () {
      this.$emit('close')
    }
  },
  components: { }
}
</script>
