<template>
  <div class="pmrIcons">
      <div :class="{ isActive: assistActor.handicapMoteur}" class="pmrIconWrapper"><svgicon class="pmrIcon" icon="handicap"/><span class="checkMark"><svgicon icon="check"/></span></div>
      <div :class="{ isActive: assistActor.handicapVisuel }" class="pmrIconWrapper"><svgicon class="pmrIcon" icon="eye"/><span class="checkMark"><svgicon icon="check"/></span></div>
      <div :class="{ isActive: assistActor.handicapMental }" class="pmrIconWrapper"><svgicon class="pmrIcon" icon="face"/><span class="checkMark"><svgicon icon="check"/></span></div>
      <div :class="{ isActive: assistActor.handicapAudio }" class="pmrIconWrapper"><svgicon class="pmrIcon" icon="ear"/><span class="checkMark"><svgicon icon="check"/></span></div>
      <div :class="{ isActive: assistActor.handicapAutre }" class="pmrIconWrapper"><svgicon class="pmrIcon" icon="canne"/><span class="checkMark"><svgicon icon="check"/></span></div>
    </div>
</template>
<script>
export default {
  name: 'PmrIcons',
  props: { assistActor: Object },
}
</script>
