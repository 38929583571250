/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M4.105 3.86L.32 4.3a.366.366 0 00-.302.258.385.385 0 00.1.392C1.243 6.027 2.934 7.64 2.934 7.64l-.772 3.909a.389.389 0 00.141.381c.117.09.268.091.387.021L6 9.974l3.307 1.977c.12.07.272.068.389-.022a.386.386 0 00.14-.38l-.77-3.91 2.816-2.686c.1-.1.145-.25.1-.395a.362.362 0 00-.301-.257c-1.515-.178-3.786-.441-3.786-.441L6.327.22A.363.363 0 005.999 0a.358.358 0 00-.325.221L4.105 3.86z"/>'
  }
})
