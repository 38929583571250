<template>
  <socalightmodal v-if="showModal"
    title="Initialisation profil"
    submitText="Valider"
    @submit="fetchPrestationOnBoardCrew()"
    :closable="false">
    <button class="retryButton" v-if="onBoardError" @click="tryAgain()">{{$t('onBoardModal.newTry') }}</button>
    <div v-if="onBoardError" class="onBoardError">{{ errorMessage }}</div>
    <span>{{$t('onBoardModal.trainNumber') }}*</span>
    <input type="text" :placeholder="`${$t('onBoardModal.trainNumber')}`" :disabled="onBoardError" v-model="filters.trainNumber" @keyup.enter="fetchPrestationOnBoardCrew()"/>
    <div v-if="onBoardError">
      <label>
        {{ $t("onBoardModal.transporter") }}*
      </label>
      <select v-model="transporter">
        <option v-for="transporter in transporters" :value="transporter" :key="transporter">{{ transporter }}</option>
      </select>
      <label>{{ $t('onBoardModal.stations') }}*</label>
      <StationSelect :useStore="false" @valueChanged="stationSelected"/>
    </div>
  </socalightmodal>
</template>

<script>
import StationSelect from '@/components/Common/StationSelect'

import pivService from '@/services/piv'

export default {
  name: "OnBoardModal",
  components: {
    StationSelect
  },
  data() {
    return {
      filters: {
        trainNumber: null,
      },
      onBoardUic: [],
      onBoardError: null,
      errorMessage: null,
      transporter: null,
      stations: null,
      selectedStations: [],
      pivError: null,
      codesUic: []
    }
  },
  computed: {
    role() {
      return this.$store.getters.userRole
    },
    showModal() {
      return this.role === 'ONBOARD' && this.trainNumber === null
    },
    trainNumber() {
      return this.$store.getters.trainNumber
    },
    transporters() {
      return this.role === 'ONBOARD' ? this.$store.getters.userTransporter : null
    }
  },
  created() {
    this.onBoardError = false
    this.selectedStations = null
    this.pivError = false
  },

  methods: {
    fetchPrestationOnBoardCrew() {
      if ((!this.validateStations(this.selectedStations)) || !this.filters.trainNumber) {
        return
      }

      this.$store.commit('trainNumber', this.filters.trainNumber)
      if (!this.onBoardError) {
        pivService.fetchPivDataOnBoard(this.filters.trainNumber, new Date())
          .then(train => {
            this.pivError = false
            this.onBoardError = false
            this.codesUic = train.onBoardUic
            let transporterFound = this.transporters.find((transporter) => transporter === train.transporter)
            if (transporterFound) {
              this.$store.commit('homeFilters/transporter', transporterFound)
              this.$store.commit('onBoardTransporter', transporterFound)
            } else {
              // Inconsistency between retrieved transporter and habilitation profile.
              this.$store.commit('trainNumber', null)
              this.onBoardError = true
              this.errorMessage = this.$t('onBoardModal.transporterError')
            }
            this.filtersAndStations()
          })
          .catch((err) => {
            // Reset StationsUser on undefined train number.
            this.pivError = true
            this.$store.commit('resetStationsUser')
            this.$store.commit('trainNumber', null)
            this.onBoardError = true
            this.errorMessage = err.message
            this.selectedStations = null
            this.transporter = null
          })
      } else {
        if (this.transporter && this.selectedStations) {
          if (!this.pivError) {
            if (this.selectedStations && this.selectedStations.length) {
              this.codesUic = this.getUIC(this.selectedStations)
              this.$store.commit('onBoardUIC', this.codesUic)
              this.$store.commit('setUserStations', this.selectedStations)
            } else {
              this.filtersAndStations()
            }
            this.$store.commit('homeFilters/transporter', this.transporter)
            this.$store.commit('onBoardTransporter', this.transporter)
            this.onBoardError = false
            this.pivError = false
          } else {
            // Do not use filtersAndStations() call to not store an empty setUserStations.
            // And store selectedStations instead.
            this.codesUic = this.getUIC(this.selectedStations)
            this.$store.commit('onBoardUIC', this.codesUic)
            this.$store.commit('homeFilters/trainNumber', this.filters.trainNumber)
            this.$store.commit('homeFilters/modalFiltered', true)
            this.$store.commit('setUserStations', this.selectedStations)
            this.$store.commit('homeFilters/transporter', this.transporter)
            this.$store.commit('onBoardTransporter', this.transporter)
            this.onBoardError = false
            this.codesUic = this.getUIC(this.selectedStations)
          }
        } else if (!this.transporter) {
          this.$store.commit('trainNumber', null)
          this.errorMessage = this.$t('onBoardModal.noTransporter')
          this.onBoardError = true
        } else {
          this.$store.commit('trainNumber', null)
          this.errorMessage = this.$t('onBoardModal.noStations')
          this.onBoardError = true
        }
      }
    },
    tryAgain() {
      this.selectedStations = null
      this.onBoardError = false
      this.filters.trainNumber = null
    },
    stationSelected(station) {
      this.selectedStations = station
    },
    filtersAndStations() {
      this.$store.commit('onBoardUIC', this.codesUic)
      this.$store.commit('homeFilters/trainNumber', this.filters.trainNumber)
      this.$store.commit('homeFilters/modalFiltered', true)
      this.$backendConnector.getMyStations()
        .then(stations => {
          this.$store.commit('setUserStations', stations)
        })
    },
    validateStations(stations) {
      if (stations && stations.length <= 1) {
        this.$toastService.showToast(this.$t('onBoardModal.stationsNumberError'), null, 'error')

        return false
      }
      return true
    },
    getUIC(stations) {
      return stations.map(station => station.codeUic)
    }
  }
}
</script>
