<template>
  <div id="headerNav">
    <div class="centeredContent">
      <div id="headerToolsZone">
        <button class="hide-for-large" type="button" data-toggle="mainMenuNav" id="openMainMenuButton" :title="`${$t('navigation.openmenu') }`">
          <svgicon icon="menu"/>
        </button>
      </div>
      <div id="headerContextZone">
        <StationSelect @valueChanged="selectStations"/>
        <Notification />
      </div>
    </div>
  </div>
</template>

<script>
import StationSelect from '@/components/Common/StationSelect'
import Notification from '@/components/Nav/Header/Notification'

export default {
  name: 'Header',
  components: {
    StationSelect,
    Notification
  },
  methods: {
    selectStations (value) {
      let stations = value
      stations.concat(this.$store.getters['homeFilters/stations'])
      this.$store.commit('homeFilters/modalFilters', { stations: stations })
    }
  },
}
</script>

<style scoped>

</style>
